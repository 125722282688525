import { __assign, __extends } from "tslib";
import { PinpointServiceException as __BaseException } from "./PinpointServiceException";
export var __EndpointTypesElement;
(function (__EndpointTypesElement) {
    __EndpointTypesElement["ADM"] = "ADM";
    __EndpointTypesElement["APNS"] = "APNS";
    __EndpointTypesElement["APNS_SANDBOX"] = "APNS_SANDBOX";
    __EndpointTypesElement["APNS_VOIP"] = "APNS_VOIP";
    __EndpointTypesElement["APNS_VOIP_SANDBOX"] = "APNS_VOIP_SANDBOX";
    __EndpointTypesElement["BAIDU"] = "BAIDU";
    __EndpointTypesElement["CUSTOM"] = "CUSTOM";
    __EndpointTypesElement["EMAIL"] = "EMAIL";
    __EndpointTypesElement["GCM"] = "GCM";
    __EndpointTypesElement["IN_APP"] = "IN_APP";
    __EndpointTypesElement["PUSH"] = "PUSH";
    __EndpointTypesElement["SMS"] = "SMS";
    __EndpointTypesElement["VOICE"] = "VOICE";
})(__EndpointTypesElement || (__EndpointTypesElement = {}));
export var Action;
(function (Action) {
    Action["DEEP_LINK"] = "DEEP_LINK";
    Action["OPEN_APP"] = "OPEN_APP";
    Action["URL"] = "URL";
})(Action || (Action = {}));
export var AttributeType;
(function (AttributeType) {
    AttributeType["AFTER"] = "AFTER";
    AttributeType["BEFORE"] = "BEFORE";
    AttributeType["BETWEEN"] = "BETWEEN";
    AttributeType["CONTAINS"] = "CONTAINS";
    AttributeType["EXCLUSIVE"] = "EXCLUSIVE";
    AttributeType["INCLUSIVE"] = "INCLUSIVE";
    AttributeType["ON"] = "ON";
})(AttributeType || (AttributeType = {}));
export var DimensionType;
(function (DimensionType) {
    DimensionType["EXCLUSIVE"] = "EXCLUSIVE";
    DimensionType["INCLUSIVE"] = "INCLUSIVE";
})(DimensionType || (DimensionType = {}));
export var Duration;
(function (Duration) {
    Duration["DAY_14"] = "DAY_14";
    Duration["DAY_30"] = "DAY_30";
    Duration["DAY_7"] = "DAY_7";
    Duration["HR_24"] = "HR_24";
})(Duration || (Duration = {}));
export var RecencyType;
(function (RecencyType) {
    RecencyType["ACTIVE"] = "ACTIVE";
    RecencyType["INACTIVE"] = "INACTIVE";
})(RecencyType || (RecencyType = {}));
export var Operator;
(function (Operator) {
    Operator["ALL"] = "ALL";
    Operator["ANY"] = "ANY";
})(Operator || (Operator = {}));
export var MessageType;
(function (MessageType) {
    MessageType["PROMOTIONAL"] = "PROMOTIONAL";
    MessageType["TRANSACTIONAL"] = "TRANSACTIONAL";
})(MessageType || (MessageType = {}));
export var ChannelType;
(function (ChannelType) {
    ChannelType["ADM"] = "ADM";
    ChannelType["APNS"] = "APNS";
    ChannelType["APNS_SANDBOX"] = "APNS_SANDBOX";
    ChannelType["APNS_VOIP"] = "APNS_VOIP";
    ChannelType["APNS_VOIP_SANDBOX"] = "APNS_VOIP_SANDBOX";
    ChannelType["BAIDU"] = "BAIDU";
    ChannelType["CUSTOM"] = "CUSTOM";
    ChannelType["EMAIL"] = "EMAIL";
    ChannelType["GCM"] = "GCM";
    ChannelType["IN_APP"] = "IN_APP";
    ChannelType["PUSH"] = "PUSH";
    ChannelType["SMS"] = "SMS";
    ChannelType["VOICE"] = "VOICE";
})(ChannelType || (ChannelType = {}));
export var Alignment;
(function (Alignment) {
    Alignment["CENTER"] = "CENTER";
    Alignment["LEFT"] = "LEFT";
    Alignment["RIGHT"] = "RIGHT";
})(Alignment || (Alignment = {}));
export var Mode;
(function (Mode) {
    Mode["DELIVERY"] = "DELIVERY";
    Mode["FILTER"] = "FILTER";
})(Mode || (Mode = {}));
var BadRequestException = (function (_super) {
    __extends(BadRequestException, _super);
    function BadRequestException(opts) {
        var _this = _super.call(this, __assign({ name: "BadRequestException", $fault: "client" }, opts)) || this;
        _this.name = "BadRequestException";
        _this.$fault = "client";
        Object.setPrototypeOf(_this, BadRequestException.prototype);
        _this.Message = opts.Message;
        _this.RequestID = opts.RequestID;
        return _this;
    }
    return BadRequestException;
}(__BaseException));
export { BadRequestException };
export var ButtonAction;
(function (ButtonAction) {
    ButtonAction["CLOSE"] = "CLOSE";
    ButtonAction["DEEP_LINK"] = "DEEP_LINK";
    ButtonAction["LINK"] = "LINK";
})(ButtonAction || (ButtonAction = {}));
export var FilterType;
(function (FilterType) {
    FilterType["ENDPOINT"] = "ENDPOINT";
    FilterType["SYSTEM"] = "SYSTEM";
})(FilterType || (FilterType = {}));
export var Layout;
(function (Layout) {
    Layout["BOTTOM_BANNER"] = "BOTTOM_BANNER";
    Layout["CAROUSEL"] = "CAROUSEL";
    Layout["MIDDLE_BANNER"] = "MIDDLE_BANNER";
    Layout["MOBILE_FEED"] = "MOBILE_FEED";
    Layout["OVERLAYS"] = "OVERLAYS";
    Layout["TOP_BANNER"] = "TOP_BANNER";
})(Layout || (Layout = {}));
export var Frequency;
(function (Frequency) {
    Frequency["DAILY"] = "DAILY";
    Frequency["EVENT"] = "EVENT";
    Frequency["HOURLY"] = "HOURLY";
    Frequency["IN_APP_EVENT"] = "IN_APP_EVENT";
    Frequency["MONTHLY"] = "MONTHLY";
    Frequency["ONCE"] = "ONCE";
    Frequency["WEEKLY"] = "WEEKLY";
})(Frequency || (Frequency = {}));
export var CampaignStatus;
(function (CampaignStatus) {
    CampaignStatus["COMPLETED"] = "COMPLETED";
    CampaignStatus["DELETED"] = "DELETED";
    CampaignStatus["EXECUTING"] = "EXECUTING";
    CampaignStatus["INVALID"] = "INVALID";
    CampaignStatus["PAUSED"] = "PAUSED";
    CampaignStatus["PENDING_NEXT_RUN"] = "PENDING_NEXT_RUN";
    CampaignStatus["SCHEDULED"] = "SCHEDULED";
})(CampaignStatus || (CampaignStatus = {}));
var ConflictException = (function (_super) {
    __extends(ConflictException, _super);
    function ConflictException(opts) {
        var _this = _super.call(this, __assign({ name: "ConflictException", $fault: "client" }, opts)) || this;
        _this.name = "ConflictException";
        _this.$fault = "client";
        Object.setPrototypeOf(_this, ConflictException.prototype);
        _this.Message = opts.Message;
        _this.RequestID = opts.RequestID;
        return _this;
    }
    return ConflictException;
}(__BaseException));
export { ConflictException };
var ForbiddenException = (function (_super) {
    __extends(ForbiddenException, _super);
    function ForbiddenException(opts) {
        var _this = _super.call(this, __assign({ name: "ForbiddenException", $fault: "client" }, opts)) || this;
        _this.name = "ForbiddenException";
        _this.$fault = "client";
        Object.setPrototypeOf(_this, ForbiddenException.prototype);
        _this.Message = opts.Message;
        _this.RequestID = opts.RequestID;
        return _this;
    }
    return ForbiddenException;
}(__BaseException));
export { ForbiddenException };
var InternalServerErrorException = (function (_super) {
    __extends(InternalServerErrorException, _super);
    function InternalServerErrorException(opts) {
        var _this = _super.call(this, __assign({ name: "InternalServerErrorException", $fault: "server" }, opts)) || this;
        _this.name = "InternalServerErrorException";
        _this.$fault = "server";
        Object.setPrototypeOf(_this, InternalServerErrorException.prototype);
        _this.Message = opts.Message;
        _this.RequestID = opts.RequestID;
        return _this;
    }
    return InternalServerErrorException;
}(__BaseException));
export { InternalServerErrorException };
var MethodNotAllowedException = (function (_super) {
    __extends(MethodNotAllowedException, _super);
    function MethodNotAllowedException(opts) {
        var _this = _super.call(this, __assign({ name: "MethodNotAllowedException", $fault: "client" }, opts)) || this;
        _this.name = "MethodNotAllowedException";
        _this.$fault = "client";
        Object.setPrototypeOf(_this, MethodNotAllowedException.prototype);
        _this.Message = opts.Message;
        _this.RequestID = opts.RequestID;
        return _this;
    }
    return MethodNotAllowedException;
}(__BaseException));
export { MethodNotAllowedException };
var NotFoundException = (function (_super) {
    __extends(NotFoundException, _super);
    function NotFoundException(opts) {
        var _this = _super.call(this, __assign({ name: "NotFoundException", $fault: "client" }, opts)) || this;
        _this.name = "NotFoundException";
        _this.$fault = "client";
        Object.setPrototypeOf(_this, NotFoundException.prototype);
        _this.Message = opts.Message;
        _this.RequestID = opts.RequestID;
        return _this;
    }
    return NotFoundException;
}(__BaseException));
export { NotFoundException };
var PayloadTooLargeException = (function (_super) {
    __extends(PayloadTooLargeException, _super);
    function PayloadTooLargeException(opts) {
        var _this = _super.call(this, __assign({ name: "PayloadTooLargeException", $fault: "client" }, opts)) || this;
        _this.name = "PayloadTooLargeException";
        _this.$fault = "client";
        Object.setPrototypeOf(_this, PayloadTooLargeException.prototype);
        _this.Message = opts.Message;
        _this.RequestID = opts.RequestID;
        return _this;
    }
    return PayloadTooLargeException;
}(__BaseException));
export { PayloadTooLargeException };
var TooManyRequestsException = (function (_super) {
    __extends(TooManyRequestsException, _super);
    function TooManyRequestsException(opts) {
        var _this = _super.call(this, __assign({ name: "TooManyRequestsException", $fault: "client" }, opts)) || this;
        _this.name = "TooManyRequestsException";
        _this.$fault = "client";
        Object.setPrototypeOf(_this, TooManyRequestsException.prototype);
        _this.Message = opts.Message;
        _this.RequestID = opts.RequestID;
        return _this;
    }
    return TooManyRequestsException;
}(__BaseException));
export { TooManyRequestsException };
export var JobStatus;
(function (JobStatus) {
    JobStatus["COMPLETED"] = "COMPLETED";
    JobStatus["COMPLETING"] = "COMPLETING";
    JobStatus["CREATED"] = "CREATED";
    JobStatus["FAILED"] = "FAILED";
    JobStatus["FAILING"] = "FAILING";
    JobStatus["INITIALIZING"] = "INITIALIZING";
    JobStatus["PENDING_JOB"] = "PENDING_JOB";
    JobStatus["PREPARING_FOR_INITIALIZATION"] = "PREPARING_FOR_INITIALIZATION";
    JobStatus["PROCESSING"] = "PROCESSING";
})(JobStatus || (JobStatus = {}));
export var Format;
(function (Format) {
    Format["CSV"] = "CSV";
    Format["JSON"] = "JSON";
})(Format || (Format = {}));
export var DayOfWeek;
(function (DayOfWeek) {
    DayOfWeek["FRIDAY"] = "FRIDAY";
    DayOfWeek["MONDAY"] = "MONDAY";
    DayOfWeek["SATURDAY"] = "SATURDAY";
    DayOfWeek["SUNDAY"] = "SUNDAY";
    DayOfWeek["THURSDAY"] = "THURSDAY";
    DayOfWeek["TUESDAY"] = "TUESDAY";
    DayOfWeek["WEDNESDAY"] = "WEDNESDAY";
})(DayOfWeek || (DayOfWeek = {}));
export var State;
(function (State) {
    State["ACTIVE"] = "ACTIVE";
    State["CANCELLED"] = "CANCELLED";
    State["CLOSED"] = "CLOSED";
    State["COMPLETED"] = "COMPLETED";
    State["DRAFT"] = "DRAFT";
    State["PAUSED"] = "PAUSED";
})(State || (State = {}));
export var SourceType;
(function (SourceType) {
    SourceType["ALL"] = "ALL";
    SourceType["ANY"] = "ANY";
    SourceType["NONE"] = "NONE";
})(SourceType || (SourceType = {}));
export var Type;
(function (Type) {
    Type["ALL"] = "ALL";
    Type["ANY"] = "ANY";
    Type["NONE"] = "NONE";
})(Type || (Type = {}));
export var Include;
(function (Include) {
    Include["ALL"] = "ALL";
    Include["ANY"] = "ANY";
    Include["NONE"] = "NONE";
})(Include || (Include = {}));
export var SegmentType;
(function (SegmentType) {
    SegmentType["DIMENSIONAL"] = "DIMENSIONAL";
    SegmentType["IMPORT"] = "IMPORT";
})(SegmentType || (SegmentType = {}));
export var DeliveryStatus;
(function (DeliveryStatus) {
    DeliveryStatus["DUPLICATE"] = "DUPLICATE";
    DeliveryStatus["OPT_OUT"] = "OPT_OUT";
    DeliveryStatus["PERMANENT_FAILURE"] = "PERMANENT_FAILURE";
    DeliveryStatus["SUCCESSFUL"] = "SUCCESSFUL";
    DeliveryStatus["TEMPORARY_FAILURE"] = "TEMPORARY_FAILURE";
    DeliveryStatus["THROTTLED"] = "THROTTLED";
    DeliveryStatus["UNKNOWN_FAILURE"] = "UNKNOWN_FAILURE";
})(DeliveryStatus || (DeliveryStatus = {}));
export var TemplateType;
(function (TemplateType) {
    TemplateType["EMAIL"] = "EMAIL";
    TemplateType["INAPP"] = "INAPP";
    TemplateType["PUSH"] = "PUSH";
    TemplateType["SMS"] = "SMS";
    TemplateType["VOICE"] = "VOICE";
})(TemplateType || (TemplateType = {}));
export var ActivityResponseFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var ActivitiesResponseFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var AttributeDimensionFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var SetDimensionFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var MetricDimensionFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var EventDimensionsFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var EventConditionFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var SegmentConditionFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var RecencyDimensionFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var SegmentBehaviorsFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var SegmentDemographicsFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var GPSCoordinatesFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var GPSPointDimensionFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var SegmentLocationFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var SegmentDimensionsFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var SimpleConditionFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var ConditionFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var WaitTimeFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var ConditionalSplitActivityFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var ContactCenterActivityFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var JourneyCustomMessageFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var CustomMessageActivityFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var JourneyEmailMessageFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var EmailMessageActivityFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var HoldoutActivityFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var MultiConditionalBranchFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var MultiConditionalSplitActivityFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var JourneyPushMessageFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var PushMessageActivityFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var RandomSplitEntryFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var RandomSplitActivityFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var JourneySMSMessageFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var SMSMessageActivityFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var WaitActivityFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var ActivityFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var AddressConfigurationFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var ADMChannelRequestFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var ADMChannelResponseFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var ADMMessageFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var AndroidPushNotificationTemplateFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var APNSChannelRequestFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var APNSChannelResponseFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var APNSMessageFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var APNSPushNotificationTemplateFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var APNSSandboxChannelRequestFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var APNSSandboxChannelResponseFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var APNSVoipChannelRequestFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var APNSVoipChannelResponseFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var APNSVoipSandboxChannelRequestFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var APNSVoipSandboxChannelResponseFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var ResultRowValueFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var ResultRowFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var BaseKpiResultFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var ApplicationDateRangeKpiResponseFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var ApplicationResponseFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var CampaignHookFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var CampaignLimitsFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var QuietTimeFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var ApplicationSettingsResourceFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var ApplicationsResponseFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var AttributesResourceFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var BaiduChannelRequestFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var BaiduChannelResponseFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var BaiduMessageFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var CampaignCustomMessageFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var CampaignDateRangeKpiResponseFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var CampaignEmailMessageFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var CampaignEventFilterFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var InAppMessageBodyConfigFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var InAppMessageHeaderConfigFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var OverrideButtonConfigurationFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var DefaultButtonConfigurationFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var InAppMessageButtonFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var InAppMessageContentFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var CampaignInAppMessageFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var CustomDeliveryConfigurationFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var MessageFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var CampaignSmsMessageFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var MessageConfigurationFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var ScheduleFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var CampaignStateFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var TemplateFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var TemplateConfigurationFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var TreatmentResourceFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var CampaignResponseFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var CampaignsResponseFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var ChannelResponseFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var ChannelsResponseFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var ClosedDaysRuleFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var ClosedDaysFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var CreateApplicationRequestFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var CreateAppRequestFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var CreateAppResponseFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var WriteTreatmentResourceFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var WriteCampaignRequestFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var CreateCampaignRequestFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var CreateCampaignResponseFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var EmailTemplateRequestFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var CreateEmailTemplateRequestFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var CreateTemplateMessageBodyFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var CreateEmailTemplateResponseFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var ExportJobRequestFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var CreateExportJobRequestFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var ExportJobResourceFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var ExportJobResponseFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var CreateExportJobResponseFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var ImportJobRequestFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var CreateImportJobRequestFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var ImportJobResourceFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var ImportJobResponseFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var CreateImportJobResponseFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var InAppTemplateRequestFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var CreateInAppTemplateRequestFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var TemplateCreateMessageBodyFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var CreateInAppTemplateResponseFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var JourneyChannelSettingsFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var JourneyLimitsFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var OpenHoursRuleFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var OpenHoursFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var JourneyScheduleFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var EventFilterFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var EventStartConditionFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var StartConditionFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var WriteJourneyRequestFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var CreateJourneyRequestFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var JourneyResponseFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var CreateJourneyResponseFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var DefaultPushNotificationTemplateFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var PushNotificationTemplateRequestFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var CreatePushTemplateRequestFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var CreatePushTemplateResponseFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var CreateRecommenderConfigurationShapeFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var CreateRecommenderConfigurationRequestFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var RecommenderConfigurationResponseFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var CreateRecommenderConfigurationResponseFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var SegmentReferenceFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var SegmentGroupFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var SegmentGroupListFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var WriteSegmentRequestFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var CreateSegmentRequestFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var SegmentImportResourceFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var SegmentResponseFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var CreateSegmentResponseFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var SMSTemplateRequestFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var CreateSmsTemplateRequestFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var CreateSmsTemplateResponseFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var VoiceTemplateRequestFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var CreateVoiceTemplateRequestFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var CreateVoiceTemplateResponseFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var DefaultMessageFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var DefaultPushNotificationMessageFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var DeleteAdmChannelRequestFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var DeleteAdmChannelResponseFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var DeleteApnsChannelRequestFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var DeleteApnsChannelResponseFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var DeleteApnsSandboxChannelRequestFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var DeleteApnsSandboxChannelResponseFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var DeleteApnsVoipChannelRequestFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var DeleteApnsVoipChannelResponseFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var DeleteApnsVoipSandboxChannelRequestFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var DeleteApnsVoipSandboxChannelResponseFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var DeleteAppRequestFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var DeleteAppResponseFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var DeleteBaiduChannelRequestFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var DeleteBaiduChannelResponseFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var DeleteCampaignRequestFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var DeleteCampaignResponseFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var DeleteEmailChannelRequestFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var EmailChannelResponseFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var DeleteEmailChannelResponseFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var DeleteEmailTemplateRequestFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var MessageBodyFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var DeleteEmailTemplateResponseFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var DeleteEndpointRequestFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var EndpointDemographicFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var EndpointLocationFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var EndpointUserFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var EndpointResponseFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var DeleteEndpointResponseFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var DeleteEventStreamRequestFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var EventStreamFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var DeleteEventStreamResponseFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var DeleteGcmChannelRequestFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var GCMChannelResponseFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var DeleteGcmChannelResponseFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var DeleteInAppTemplateRequestFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var DeleteInAppTemplateResponseFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var DeleteJourneyRequestFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var DeleteJourneyResponseFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var DeletePushTemplateRequestFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var DeletePushTemplateResponseFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var DeleteRecommenderConfigurationRequestFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var DeleteRecommenderConfigurationResponseFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var DeleteSegmentRequestFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var DeleteSegmentResponseFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var DeleteSmsChannelRequestFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var SMSChannelResponseFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var DeleteSmsChannelResponseFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var DeleteSmsTemplateRequestFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var DeleteSmsTemplateResponseFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var DeleteUserEndpointsRequestFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var EndpointsResponseFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var DeleteUserEndpointsResponseFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var DeleteVoiceChannelRequestFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var VoiceChannelResponseFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var DeleteVoiceChannelResponseFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var DeleteVoiceTemplateRequestFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var DeleteVoiceTemplateResponseFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var RawEmailFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var SimpleEmailPartFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var SimpleEmailFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var EmailMessageFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var GCMMessageFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var SMSMessageFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var VoiceMessageFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var DirectMessageConfigurationFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var EmailChannelRequestFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var EmailTemplateResponseFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var EndpointBatchItemFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var EndpointBatchRequestFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var EndpointItemResponseFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var EndpointMessageResultFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var EndpointRequestFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var EndpointSendConfigurationFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var SessionFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var EventFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var EventItemResponseFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var PublicEndpointFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var EventsBatchFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var EventsRequestFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var ItemResponseFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var EventsResponseFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var ExportJobsResponseFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var GCMChannelRequestFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var GetAdmChannelRequestFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var GetAdmChannelResponseFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var GetApnsChannelRequestFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var GetApnsChannelResponseFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var GetApnsSandboxChannelRequestFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var GetApnsSandboxChannelResponseFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var GetApnsVoipChannelRequestFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var GetApnsVoipChannelResponseFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var GetApnsVoipSandboxChannelRequestFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var GetApnsVoipSandboxChannelResponseFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var GetAppRequestFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var GetAppResponseFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var GetApplicationDateRangeKpiRequestFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var GetApplicationDateRangeKpiResponseFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var GetApplicationSettingsRequestFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var GetApplicationSettingsResponseFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var GetAppsRequestFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var GetAppsResponseFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var GetBaiduChannelRequestFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var GetBaiduChannelResponseFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var GetCampaignRequestFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var GetCampaignResponseFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var GetCampaignActivitiesRequestFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var GetCampaignActivitiesResponseFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var GetCampaignDateRangeKpiRequestFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var GetCampaignDateRangeKpiResponseFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var GetCampaignsRequestFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var GetCampaignsResponseFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var GetCampaignVersionRequestFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var GetCampaignVersionResponseFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var GetCampaignVersionsRequestFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var GetCampaignVersionsResponseFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
