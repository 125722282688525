import { __assign, __awaiter, __generator, __read } from "tslib";
import { HttpRequest as __HttpRequest } from "@aws-sdk/protocol-http";
import { decorateServiceException as __decorateServiceException, expectBoolean as __expectBoolean, expectInt32 as __expectInt32, expectNonNull as __expectNonNull, expectObject as __expectObject, expectString as __expectString, limitedParseDouble as __limitedParseDouble, map as __map, parseRfc3339DateTime as __parseRfc3339DateTime, resolvedPath as __resolvedPath, serializeFloat as __serializeFloat, throwDefaultError, } from "@aws-sdk/smithy-client";
import { BadRequestException, ConflictException, ForbiddenException, InternalServerErrorException, MethodNotAllowedException, NotFoundException, PayloadTooLargeException, TooManyRequestsException, } from "../models/models_0";
import { PinpointServiceException as __BaseException } from "../models/PinpointServiceException";
export var serializeAws_restJson1CreateAppCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, hostname, _b, protocol, port, basePath, headers, resolvedPath, body;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4, context.endpoint()];
            case 1:
                _a = _c.sent(), hostname = _a.hostname, _b = _a.protocol, protocol = _b === void 0 ? "https" : _b, port = _a.port, basePath = _a.path;
                headers = {
                    "content-type": "application/json",
                };
                resolvedPath = "".concat((basePath === null || basePath === void 0 ? void 0 : basePath.endsWith("/")) ? basePath.slice(0, -1) : basePath || "") + "/v1/apps";
                if (input.CreateApplicationRequest !== undefined) {
                    body = serializeAws_restJson1CreateApplicationRequest(input.CreateApplicationRequest, context);
                }
                if (body === undefined) {
                    body = {};
                }
                body = JSON.stringify(body);
                return [2, new __HttpRequest({
                        protocol: protocol,
                        hostname: hostname,
                        port: port,
                        method: "POST",
                        headers: headers,
                        path: resolvedPath,
                        body: body,
                    })];
        }
    });
}); };
export var serializeAws_restJson1CreateCampaignCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, hostname, _b, protocol, port, basePath, headers, resolvedPath, body;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4, context.endpoint()];
            case 1:
                _a = _c.sent(), hostname = _a.hostname, _b = _a.protocol, protocol = _b === void 0 ? "https" : _b, port = _a.port, basePath = _a.path;
                headers = {
                    "content-type": "application/json",
                };
                resolvedPath = "".concat((basePath === null || basePath === void 0 ? void 0 : basePath.endsWith("/")) ? basePath.slice(0, -1) : basePath || "") + "/v1/apps/{ApplicationId}/campaigns";
                resolvedPath = __resolvedPath(resolvedPath, input, "ApplicationId", function () { return input.ApplicationId; }, "{ApplicationId}", false);
                if (input.WriteCampaignRequest !== undefined) {
                    body = serializeAws_restJson1WriteCampaignRequest(input.WriteCampaignRequest, context);
                }
                if (body === undefined) {
                    body = {};
                }
                body = JSON.stringify(body);
                return [2, new __HttpRequest({
                        protocol: protocol,
                        hostname: hostname,
                        port: port,
                        method: "POST",
                        headers: headers,
                        path: resolvedPath,
                        body: body,
                    })];
        }
    });
}); };
export var serializeAws_restJson1CreateEmailTemplateCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, hostname, _b, protocol, port, basePath, headers, resolvedPath, body;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4, context.endpoint()];
            case 1:
                _a = _c.sent(), hostname = _a.hostname, _b = _a.protocol, protocol = _b === void 0 ? "https" : _b, port = _a.port, basePath = _a.path;
                headers = {
                    "content-type": "application/json",
                };
                resolvedPath = "".concat((basePath === null || basePath === void 0 ? void 0 : basePath.endsWith("/")) ? basePath.slice(0, -1) : basePath || "") + "/v1/templates/{TemplateName}/email";
                resolvedPath = __resolvedPath(resolvedPath, input, "TemplateName", function () { return input.TemplateName; }, "{TemplateName}", false);
                if (input.EmailTemplateRequest !== undefined) {
                    body = serializeAws_restJson1EmailTemplateRequest(input.EmailTemplateRequest, context);
                }
                if (body === undefined) {
                    body = {};
                }
                body = JSON.stringify(body);
                return [2, new __HttpRequest({
                        protocol: protocol,
                        hostname: hostname,
                        port: port,
                        method: "POST",
                        headers: headers,
                        path: resolvedPath,
                        body: body,
                    })];
        }
    });
}); };
export var serializeAws_restJson1CreateExportJobCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, hostname, _b, protocol, port, basePath, headers, resolvedPath, body;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4, context.endpoint()];
            case 1:
                _a = _c.sent(), hostname = _a.hostname, _b = _a.protocol, protocol = _b === void 0 ? "https" : _b, port = _a.port, basePath = _a.path;
                headers = {
                    "content-type": "application/json",
                };
                resolvedPath = "".concat((basePath === null || basePath === void 0 ? void 0 : basePath.endsWith("/")) ? basePath.slice(0, -1) : basePath || "") + "/v1/apps/{ApplicationId}/jobs/export";
                resolvedPath = __resolvedPath(resolvedPath, input, "ApplicationId", function () { return input.ApplicationId; }, "{ApplicationId}", false);
                if (input.ExportJobRequest !== undefined) {
                    body = serializeAws_restJson1ExportJobRequest(input.ExportJobRequest, context);
                }
                if (body === undefined) {
                    body = {};
                }
                body = JSON.stringify(body);
                return [2, new __HttpRequest({
                        protocol: protocol,
                        hostname: hostname,
                        port: port,
                        method: "POST",
                        headers: headers,
                        path: resolvedPath,
                        body: body,
                    })];
        }
    });
}); };
export var serializeAws_restJson1CreateImportJobCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, hostname, _b, protocol, port, basePath, headers, resolvedPath, body;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4, context.endpoint()];
            case 1:
                _a = _c.sent(), hostname = _a.hostname, _b = _a.protocol, protocol = _b === void 0 ? "https" : _b, port = _a.port, basePath = _a.path;
                headers = {
                    "content-type": "application/json",
                };
                resolvedPath = "".concat((basePath === null || basePath === void 0 ? void 0 : basePath.endsWith("/")) ? basePath.slice(0, -1) : basePath || "") + "/v1/apps/{ApplicationId}/jobs/import";
                resolvedPath = __resolvedPath(resolvedPath, input, "ApplicationId", function () { return input.ApplicationId; }, "{ApplicationId}", false);
                if (input.ImportJobRequest !== undefined) {
                    body = serializeAws_restJson1ImportJobRequest(input.ImportJobRequest, context);
                }
                if (body === undefined) {
                    body = {};
                }
                body = JSON.stringify(body);
                return [2, new __HttpRequest({
                        protocol: protocol,
                        hostname: hostname,
                        port: port,
                        method: "POST",
                        headers: headers,
                        path: resolvedPath,
                        body: body,
                    })];
        }
    });
}); };
export var serializeAws_restJson1CreateInAppTemplateCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, hostname, _b, protocol, port, basePath, headers, resolvedPath, body;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4, context.endpoint()];
            case 1:
                _a = _c.sent(), hostname = _a.hostname, _b = _a.protocol, protocol = _b === void 0 ? "https" : _b, port = _a.port, basePath = _a.path;
                headers = {
                    "content-type": "application/json",
                };
                resolvedPath = "".concat((basePath === null || basePath === void 0 ? void 0 : basePath.endsWith("/")) ? basePath.slice(0, -1) : basePath || "") + "/v1/templates/{TemplateName}/inapp";
                resolvedPath = __resolvedPath(resolvedPath, input, "TemplateName", function () { return input.TemplateName; }, "{TemplateName}", false);
                if (input.InAppTemplateRequest !== undefined) {
                    body = serializeAws_restJson1InAppTemplateRequest(input.InAppTemplateRequest, context);
                }
                if (body === undefined) {
                    body = {};
                }
                body = JSON.stringify(body);
                return [2, new __HttpRequest({
                        protocol: protocol,
                        hostname: hostname,
                        port: port,
                        method: "POST",
                        headers: headers,
                        path: resolvedPath,
                        body: body,
                    })];
        }
    });
}); };
export var serializeAws_restJson1CreateJourneyCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, hostname, _b, protocol, port, basePath, headers, resolvedPath, body;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4, context.endpoint()];
            case 1:
                _a = _c.sent(), hostname = _a.hostname, _b = _a.protocol, protocol = _b === void 0 ? "https" : _b, port = _a.port, basePath = _a.path;
                headers = {
                    "content-type": "application/json",
                };
                resolvedPath = "".concat((basePath === null || basePath === void 0 ? void 0 : basePath.endsWith("/")) ? basePath.slice(0, -1) : basePath || "") + "/v1/apps/{ApplicationId}/journeys";
                resolvedPath = __resolvedPath(resolvedPath, input, "ApplicationId", function () { return input.ApplicationId; }, "{ApplicationId}", false);
                if (input.WriteJourneyRequest !== undefined) {
                    body = serializeAws_restJson1WriteJourneyRequest(input.WriteJourneyRequest, context);
                }
                if (body === undefined) {
                    body = {};
                }
                body = JSON.stringify(body);
                return [2, new __HttpRequest({
                        protocol: protocol,
                        hostname: hostname,
                        port: port,
                        method: "POST",
                        headers: headers,
                        path: resolvedPath,
                        body: body,
                    })];
        }
    });
}); };
export var serializeAws_restJson1CreatePushTemplateCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, hostname, _b, protocol, port, basePath, headers, resolvedPath, body;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4, context.endpoint()];
            case 1:
                _a = _c.sent(), hostname = _a.hostname, _b = _a.protocol, protocol = _b === void 0 ? "https" : _b, port = _a.port, basePath = _a.path;
                headers = {
                    "content-type": "application/json",
                };
                resolvedPath = "".concat((basePath === null || basePath === void 0 ? void 0 : basePath.endsWith("/")) ? basePath.slice(0, -1) : basePath || "") + "/v1/templates/{TemplateName}/push";
                resolvedPath = __resolvedPath(resolvedPath, input, "TemplateName", function () { return input.TemplateName; }, "{TemplateName}", false);
                if (input.PushNotificationTemplateRequest !== undefined) {
                    body = serializeAws_restJson1PushNotificationTemplateRequest(input.PushNotificationTemplateRequest, context);
                }
                if (body === undefined) {
                    body = {};
                }
                body = JSON.stringify(body);
                return [2, new __HttpRequest({
                        protocol: protocol,
                        hostname: hostname,
                        port: port,
                        method: "POST",
                        headers: headers,
                        path: resolvedPath,
                        body: body,
                    })];
        }
    });
}); };
export var serializeAws_restJson1CreateRecommenderConfigurationCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, hostname, _b, protocol, port, basePath, headers, resolvedPath, body;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4, context.endpoint()];
            case 1:
                _a = _c.sent(), hostname = _a.hostname, _b = _a.protocol, protocol = _b === void 0 ? "https" : _b, port = _a.port, basePath = _a.path;
                headers = {
                    "content-type": "application/json",
                };
                resolvedPath = "".concat((basePath === null || basePath === void 0 ? void 0 : basePath.endsWith("/")) ? basePath.slice(0, -1) : basePath || "") + "/v1/recommenders";
                if (input.CreateRecommenderConfiguration !== undefined) {
                    body = serializeAws_restJson1CreateRecommenderConfigurationShape(input.CreateRecommenderConfiguration, context);
                }
                if (body === undefined) {
                    body = {};
                }
                body = JSON.stringify(body);
                return [2, new __HttpRequest({
                        protocol: protocol,
                        hostname: hostname,
                        port: port,
                        method: "POST",
                        headers: headers,
                        path: resolvedPath,
                        body: body,
                    })];
        }
    });
}); };
export var serializeAws_restJson1CreateSegmentCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, hostname, _b, protocol, port, basePath, headers, resolvedPath, body;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4, context.endpoint()];
            case 1:
                _a = _c.sent(), hostname = _a.hostname, _b = _a.protocol, protocol = _b === void 0 ? "https" : _b, port = _a.port, basePath = _a.path;
                headers = {
                    "content-type": "application/json",
                };
                resolvedPath = "".concat((basePath === null || basePath === void 0 ? void 0 : basePath.endsWith("/")) ? basePath.slice(0, -1) : basePath || "") + "/v1/apps/{ApplicationId}/segments";
                resolvedPath = __resolvedPath(resolvedPath, input, "ApplicationId", function () { return input.ApplicationId; }, "{ApplicationId}", false);
                if (input.WriteSegmentRequest !== undefined) {
                    body = serializeAws_restJson1WriteSegmentRequest(input.WriteSegmentRequest, context);
                }
                if (body === undefined) {
                    body = {};
                }
                body = JSON.stringify(body);
                return [2, new __HttpRequest({
                        protocol: protocol,
                        hostname: hostname,
                        port: port,
                        method: "POST",
                        headers: headers,
                        path: resolvedPath,
                        body: body,
                    })];
        }
    });
}); };
export var serializeAws_restJson1CreateSmsTemplateCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, hostname, _b, protocol, port, basePath, headers, resolvedPath, body;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4, context.endpoint()];
            case 1:
                _a = _c.sent(), hostname = _a.hostname, _b = _a.protocol, protocol = _b === void 0 ? "https" : _b, port = _a.port, basePath = _a.path;
                headers = {
                    "content-type": "application/json",
                };
                resolvedPath = "".concat((basePath === null || basePath === void 0 ? void 0 : basePath.endsWith("/")) ? basePath.slice(0, -1) : basePath || "") + "/v1/templates/{TemplateName}/sms";
                resolvedPath = __resolvedPath(resolvedPath, input, "TemplateName", function () { return input.TemplateName; }, "{TemplateName}", false);
                if (input.SMSTemplateRequest !== undefined) {
                    body = serializeAws_restJson1SMSTemplateRequest(input.SMSTemplateRequest, context);
                }
                if (body === undefined) {
                    body = {};
                }
                body = JSON.stringify(body);
                return [2, new __HttpRequest({
                        protocol: protocol,
                        hostname: hostname,
                        port: port,
                        method: "POST",
                        headers: headers,
                        path: resolvedPath,
                        body: body,
                    })];
        }
    });
}); };
export var serializeAws_restJson1CreateVoiceTemplateCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, hostname, _b, protocol, port, basePath, headers, resolvedPath, body;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4, context.endpoint()];
            case 1:
                _a = _c.sent(), hostname = _a.hostname, _b = _a.protocol, protocol = _b === void 0 ? "https" : _b, port = _a.port, basePath = _a.path;
                headers = {
                    "content-type": "application/json",
                };
                resolvedPath = "".concat((basePath === null || basePath === void 0 ? void 0 : basePath.endsWith("/")) ? basePath.slice(0, -1) : basePath || "") + "/v1/templates/{TemplateName}/voice";
                resolvedPath = __resolvedPath(resolvedPath, input, "TemplateName", function () { return input.TemplateName; }, "{TemplateName}", false);
                if (input.VoiceTemplateRequest !== undefined) {
                    body = serializeAws_restJson1VoiceTemplateRequest(input.VoiceTemplateRequest, context);
                }
                if (body === undefined) {
                    body = {};
                }
                body = JSON.stringify(body);
                return [2, new __HttpRequest({
                        protocol: protocol,
                        hostname: hostname,
                        port: port,
                        method: "POST",
                        headers: headers,
                        path: resolvedPath,
                        body: body,
                    })];
        }
    });
}); };
export var serializeAws_restJson1DeleteAdmChannelCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, hostname, _b, protocol, port, basePath, headers, resolvedPath, body;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4, context.endpoint()];
            case 1:
                _a = _c.sent(), hostname = _a.hostname, _b = _a.protocol, protocol = _b === void 0 ? "https" : _b, port = _a.port, basePath = _a.path;
                headers = {};
                resolvedPath = "".concat((basePath === null || basePath === void 0 ? void 0 : basePath.endsWith("/")) ? basePath.slice(0, -1) : basePath || "") + "/v1/apps/{ApplicationId}/channels/adm";
                resolvedPath = __resolvedPath(resolvedPath, input, "ApplicationId", function () { return input.ApplicationId; }, "{ApplicationId}", false);
                return [2, new __HttpRequest({
                        protocol: protocol,
                        hostname: hostname,
                        port: port,
                        method: "DELETE",
                        headers: headers,
                        path: resolvedPath,
                        body: body,
                    })];
        }
    });
}); };
export var serializeAws_restJson1DeleteApnsChannelCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, hostname, _b, protocol, port, basePath, headers, resolvedPath, body;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4, context.endpoint()];
            case 1:
                _a = _c.sent(), hostname = _a.hostname, _b = _a.protocol, protocol = _b === void 0 ? "https" : _b, port = _a.port, basePath = _a.path;
                headers = {};
                resolvedPath = "".concat((basePath === null || basePath === void 0 ? void 0 : basePath.endsWith("/")) ? basePath.slice(0, -1) : basePath || "") + "/v1/apps/{ApplicationId}/channels/apns";
                resolvedPath = __resolvedPath(resolvedPath, input, "ApplicationId", function () { return input.ApplicationId; }, "{ApplicationId}", false);
                return [2, new __HttpRequest({
                        protocol: protocol,
                        hostname: hostname,
                        port: port,
                        method: "DELETE",
                        headers: headers,
                        path: resolvedPath,
                        body: body,
                    })];
        }
    });
}); };
export var serializeAws_restJson1DeleteApnsSandboxChannelCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, hostname, _b, protocol, port, basePath, headers, resolvedPath, body;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4, context.endpoint()];
            case 1:
                _a = _c.sent(), hostname = _a.hostname, _b = _a.protocol, protocol = _b === void 0 ? "https" : _b, port = _a.port, basePath = _a.path;
                headers = {};
                resolvedPath = "".concat((basePath === null || basePath === void 0 ? void 0 : basePath.endsWith("/")) ? basePath.slice(0, -1) : basePath || "") +
                    "/v1/apps/{ApplicationId}/channels/apns_sandbox";
                resolvedPath = __resolvedPath(resolvedPath, input, "ApplicationId", function () { return input.ApplicationId; }, "{ApplicationId}", false);
                return [2, new __HttpRequest({
                        protocol: protocol,
                        hostname: hostname,
                        port: port,
                        method: "DELETE",
                        headers: headers,
                        path: resolvedPath,
                        body: body,
                    })];
        }
    });
}); };
export var serializeAws_restJson1DeleteApnsVoipChannelCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, hostname, _b, protocol, port, basePath, headers, resolvedPath, body;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4, context.endpoint()];
            case 1:
                _a = _c.sent(), hostname = _a.hostname, _b = _a.protocol, protocol = _b === void 0 ? "https" : _b, port = _a.port, basePath = _a.path;
                headers = {};
                resolvedPath = "".concat((basePath === null || basePath === void 0 ? void 0 : basePath.endsWith("/")) ? basePath.slice(0, -1) : basePath || "") +
                    "/v1/apps/{ApplicationId}/channels/apns_voip";
                resolvedPath = __resolvedPath(resolvedPath, input, "ApplicationId", function () { return input.ApplicationId; }, "{ApplicationId}", false);
                return [2, new __HttpRequest({
                        protocol: protocol,
                        hostname: hostname,
                        port: port,
                        method: "DELETE",
                        headers: headers,
                        path: resolvedPath,
                        body: body,
                    })];
        }
    });
}); };
export var serializeAws_restJson1DeleteApnsVoipSandboxChannelCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, hostname, _b, protocol, port, basePath, headers, resolvedPath, body;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4, context.endpoint()];
            case 1:
                _a = _c.sent(), hostname = _a.hostname, _b = _a.protocol, protocol = _b === void 0 ? "https" : _b, port = _a.port, basePath = _a.path;
                headers = {};
                resolvedPath = "".concat((basePath === null || basePath === void 0 ? void 0 : basePath.endsWith("/")) ? basePath.slice(0, -1) : basePath || "") +
                    "/v1/apps/{ApplicationId}/channels/apns_voip_sandbox";
                resolvedPath = __resolvedPath(resolvedPath, input, "ApplicationId", function () { return input.ApplicationId; }, "{ApplicationId}", false);
                return [2, new __HttpRequest({
                        protocol: protocol,
                        hostname: hostname,
                        port: port,
                        method: "DELETE",
                        headers: headers,
                        path: resolvedPath,
                        body: body,
                    })];
        }
    });
}); };
export var serializeAws_restJson1DeleteAppCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, hostname, _b, protocol, port, basePath, headers, resolvedPath, body;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4, context.endpoint()];
            case 1:
                _a = _c.sent(), hostname = _a.hostname, _b = _a.protocol, protocol = _b === void 0 ? "https" : _b, port = _a.port, basePath = _a.path;
                headers = {};
                resolvedPath = "".concat((basePath === null || basePath === void 0 ? void 0 : basePath.endsWith("/")) ? basePath.slice(0, -1) : basePath || "") + "/v1/apps/{ApplicationId}";
                resolvedPath = __resolvedPath(resolvedPath, input, "ApplicationId", function () { return input.ApplicationId; }, "{ApplicationId}", false);
                return [2, new __HttpRequest({
                        protocol: protocol,
                        hostname: hostname,
                        port: port,
                        method: "DELETE",
                        headers: headers,
                        path: resolvedPath,
                        body: body,
                    })];
        }
    });
}); };
export var serializeAws_restJson1DeleteBaiduChannelCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, hostname, _b, protocol, port, basePath, headers, resolvedPath, body;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4, context.endpoint()];
            case 1:
                _a = _c.sent(), hostname = _a.hostname, _b = _a.protocol, protocol = _b === void 0 ? "https" : _b, port = _a.port, basePath = _a.path;
                headers = {};
                resolvedPath = "".concat((basePath === null || basePath === void 0 ? void 0 : basePath.endsWith("/")) ? basePath.slice(0, -1) : basePath || "") + "/v1/apps/{ApplicationId}/channels/baidu";
                resolvedPath = __resolvedPath(resolvedPath, input, "ApplicationId", function () { return input.ApplicationId; }, "{ApplicationId}", false);
                return [2, new __HttpRequest({
                        protocol: protocol,
                        hostname: hostname,
                        port: port,
                        method: "DELETE",
                        headers: headers,
                        path: resolvedPath,
                        body: body,
                    })];
        }
    });
}); };
export var serializeAws_restJson1DeleteCampaignCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, hostname, _b, protocol, port, basePath, headers, resolvedPath, body;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4, context.endpoint()];
            case 1:
                _a = _c.sent(), hostname = _a.hostname, _b = _a.protocol, protocol = _b === void 0 ? "https" : _b, port = _a.port, basePath = _a.path;
                headers = {};
                resolvedPath = "".concat((basePath === null || basePath === void 0 ? void 0 : basePath.endsWith("/")) ? basePath.slice(0, -1) : basePath || "") +
                    "/v1/apps/{ApplicationId}/campaigns/{CampaignId}";
                resolvedPath = __resolvedPath(resolvedPath, input, "ApplicationId", function () { return input.ApplicationId; }, "{ApplicationId}", false);
                resolvedPath = __resolvedPath(resolvedPath, input, "CampaignId", function () { return input.CampaignId; }, "{CampaignId}", false);
                return [2, new __HttpRequest({
                        protocol: protocol,
                        hostname: hostname,
                        port: port,
                        method: "DELETE",
                        headers: headers,
                        path: resolvedPath,
                        body: body,
                    })];
        }
    });
}); };
export var serializeAws_restJson1DeleteEmailChannelCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, hostname, _b, protocol, port, basePath, headers, resolvedPath, body;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4, context.endpoint()];
            case 1:
                _a = _c.sent(), hostname = _a.hostname, _b = _a.protocol, protocol = _b === void 0 ? "https" : _b, port = _a.port, basePath = _a.path;
                headers = {};
                resolvedPath = "".concat((basePath === null || basePath === void 0 ? void 0 : basePath.endsWith("/")) ? basePath.slice(0, -1) : basePath || "") + "/v1/apps/{ApplicationId}/channels/email";
                resolvedPath = __resolvedPath(resolvedPath, input, "ApplicationId", function () { return input.ApplicationId; }, "{ApplicationId}", false);
                return [2, new __HttpRequest({
                        protocol: protocol,
                        hostname: hostname,
                        port: port,
                        method: "DELETE",
                        headers: headers,
                        path: resolvedPath,
                        body: body,
                    })];
        }
    });
}); };
export var serializeAws_restJson1DeleteEmailTemplateCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, hostname, _b, protocol, port, basePath, headers, resolvedPath, query, body;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4, context.endpoint()];
            case 1:
                _a = _c.sent(), hostname = _a.hostname, _b = _a.protocol, protocol = _b === void 0 ? "https" : _b, port = _a.port, basePath = _a.path;
                headers = {};
                resolvedPath = "".concat((basePath === null || basePath === void 0 ? void 0 : basePath.endsWith("/")) ? basePath.slice(0, -1) : basePath || "") + "/v1/templates/{TemplateName}/email";
                resolvedPath = __resolvedPath(resolvedPath, input, "TemplateName", function () { return input.TemplateName; }, "{TemplateName}", false);
                query = map({
                    version: [, input.Version],
                });
                return [2, new __HttpRequest({
                        protocol: protocol,
                        hostname: hostname,
                        port: port,
                        method: "DELETE",
                        headers: headers,
                        path: resolvedPath,
                        query: query,
                        body: body,
                    })];
        }
    });
}); };
export var serializeAws_restJson1DeleteEndpointCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, hostname, _b, protocol, port, basePath, headers, resolvedPath, body;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4, context.endpoint()];
            case 1:
                _a = _c.sent(), hostname = _a.hostname, _b = _a.protocol, protocol = _b === void 0 ? "https" : _b, port = _a.port, basePath = _a.path;
                headers = {};
                resolvedPath = "".concat((basePath === null || basePath === void 0 ? void 0 : basePath.endsWith("/")) ? basePath.slice(0, -1) : basePath || "") +
                    "/v1/apps/{ApplicationId}/endpoints/{EndpointId}";
                resolvedPath = __resolvedPath(resolvedPath, input, "ApplicationId", function () { return input.ApplicationId; }, "{ApplicationId}", false);
                resolvedPath = __resolvedPath(resolvedPath, input, "EndpointId", function () { return input.EndpointId; }, "{EndpointId}", false);
                return [2, new __HttpRequest({
                        protocol: protocol,
                        hostname: hostname,
                        port: port,
                        method: "DELETE",
                        headers: headers,
                        path: resolvedPath,
                        body: body,
                    })];
        }
    });
}); };
export var serializeAws_restJson1DeleteEventStreamCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, hostname, _b, protocol, port, basePath, headers, resolvedPath, body;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4, context.endpoint()];
            case 1:
                _a = _c.sent(), hostname = _a.hostname, _b = _a.protocol, protocol = _b === void 0 ? "https" : _b, port = _a.port, basePath = _a.path;
                headers = {};
                resolvedPath = "".concat((basePath === null || basePath === void 0 ? void 0 : basePath.endsWith("/")) ? basePath.slice(0, -1) : basePath || "") + "/v1/apps/{ApplicationId}/eventstream";
                resolvedPath = __resolvedPath(resolvedPath, input, "ApplicationId", function () { return input.ApplicationId; }, "{ApplicationId}", false);
                return [2, new __HttpRequest({
                        protocol: protocol,
                        hostname: hostname,
                        port: port,
                        method: "DELETE",
                        headers: headers,
                        path: resolvedPath,
                        body: body,
                    })];
        }
    });
}); };
export var serializeAws_restJson1DeleteGcmChannelCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, hostname, _b, protocol, port, basePath, headers, resolvedPath, body;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4, context.endpoint()];
            case 1:
                _a = _c.sent(), hostname = _a.hostname, _b = _a.protocol, protocol = _b === void 0 ? "https" : _b, port = _a.port, basePath = _a.path;
                headers = {};
                resolvedPath = "".concat((basePath === null || basePath === void 0 ? void 0 : basePath.endsWith("/")) ? basePath.slice(0, -1) : basePath || "") + "/v1/apps/{ApplicationId}/channels/gcm";
                resolvedPath = __resolvedPath(resolvedPath, input, "ApplicationId", function () { return input.ApplicationId; }, "{ApplicationId}", false);
                return [2, new __HttpRequest({
                        protocol: protocol,
                        hostname: hostname,
                        port: port,
                        method: "DELETE",
                        headers: headers,
                        path: resolvedPath,
                        body: body,
                    })];
        }
    });
}); };
export var serializeAws_restJson1DeleteInAppTemplateCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, hostname, _b, protocol, port, basePath, headers, resolvedPath, query, body;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4, context.endpoint()];
            case 1:
                _a = _c.sent(), hostname = _a.hostname, _b = _a.protocol, protocol = _b === void 0 ? "https" : _b, port = _a.port, basePath = _a.path;
                headers = {};
                resolvedPath = "".concat((basePath === null || basePath === void 0 ? void 0 : basePath.endsWith("/")) ? basePath.slice(0, -1) : basePath || "") + "/v1/templates/{TemplateName}/inapp";
                resolvedPath = __resolvedPath(resolvedPath, input, "TemplateName", function () { return input.TemplateName; }, "{TemplateName}", false);
                query = map({
                    version: [, input.Version],
                });
                return [2, new __HttpRequest({
                        protocol: protocol,
                        hostname: hostname,
                        port: port,
                        method: "DELETE",
                        headers: headers,
                        path: resolvedPath,
                        query: query,
                        body: body,
                    })];
        }
    });
}); };
export var serializeAws_restJson1DeleteJourneyCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, hostname, _b, protocol, port, basePath, headers, resolvedPath, body;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4, context.endpoint()];
            case 1:
                _a = _c.sent(), hostname = _a.hostname, _b = _a.protocol, protocol = _b === void 0 ? "https" : _b, port = _a.port, basePath = _a.path;
                headers = {};
                resolvedPath = "".concat((basePath === null || basePath === void 0 ? void 0 : basePath.endsWith("/")) ? basePath.slice(0, -1) : basePath || "") +
                    "/v1/apps/{ApplicationId}/journeys/{JourneyId}";
                resolvedPath = __resolvedPath(resolvedPath, input, "ApplicationId", function () { return input.ApplicationId; }, "{ApplicationId}", false);
                resolvedPath = __resolvedPath(resolvedPath, input, "JourneyId", function () { return input.JourneyId; }, "{JourneyId}", false);
                return [2, new __HttpRequest({
                        protocol: protocol,
                        hostname: hostname,
                        port: port,
                        method: "DELETE",
                        headers: headers,
                        path: resolvedPath,
                        body: body,
                    })];
        }
    });
}); };
export var serializeAws_restJson1DeletePushTemplateCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, hostname, _b, protocol, port, basePath, headers, resolvedPath, query, body;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4, context.endpoint()];
            case 1:
                _a = _c.sent(), hostname = _a.hostname, _b = _a.protocol, protocol = _b === void 0 ? "https" : _b, port = _a.port, basePath = _a.path;
                headers = {};
                resolvedPath = "".concat((basePath === null || basePath === void 0 ? void 0 : basePath.endsWith("/")) ? basePath.slice(0, -1) : basePath || "") + "/v1/templates/{TemplateName}/push";
                resolvedPath = __resolvedPath(resolvedPath, input, "TemplateName", function () { return input.TemplateName; }, "{TemplateName}", false);
                query = map({
                    version: [, input.Version],
                });
                return [2, new __HttpRequest({
                        protocol: protocol,
                        hostname: hostname,
                        port: port,
                        method: "DELETE",
                        headers: headers,
                        path: resolvedPath,
                        query: query,
                        body: body,
                    })];
        }
    });
}); };
export var serializeAws_restJson1DeleteRecommenderConfigurationCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, hostname, _b, protocol, port, basePath, headers, resolvedPath, body;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4, context.endpoint()];
            case 1:
                _a = _c.sent(), hostname = _a.hostname, _b = _a.protocol, protocol = _b === void 0 ? "https" : _b, port = _a.port, basePath = _a.path;
                headers = {};
                resolvedPath = "".concat((basePath === null || basePath === void 0 ? void 0 : basePath.endsWith("/")) ? basePath.slice(0, -1) : basePath || "") + "/v1/recommenders/{RecommenderId}";
                resolvedPath = __resolvedPath(resolvedPath, input, "RecommenderId", function () { return input.RecommenderId; }, "{RecommenderId}", false);
                return [2, new __HttpRequest({
                        protocol: protocol,
                        hostname: hostname,
                        port: port,
                        method: "DELETE",
                        headers: headers,
                        path: resolvedPath,
                        body: body,
                    })];
        }
    });
}); };
export var serializeAws_restJson1DeleteSegmentCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, hostname, _b, protocol, port, basePath, headers, resolvedPath, body;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4, context.endpoint()];
            case 1:
                _a = _c.sent(), hostname = _a.hostname, _b = _a.protocol, protocol = _b === void 0 ? "https" : _b, port = _a.port, basePath = _a.path;
                headers = {};
                resolvedPath = "".concat((basePath === null || basePath === void 0 ? void 0 : basePath.endsWith("/")) ? basePath.slice(0, -1) : basePath || "") +
                    "/v1/apps/{ApplicationId}/segments/{SegmentId}";
                resolvedPath = __resolvedPath(resolvedPath, input, "ApplicationId", function () { return input.ApplicationId; }, "{ApplicationId}", false);
                resolvedPath = __resolvedPath(resolvedPath, input, "SegmentId", function () { return input.SegmentId; }, "{SegmentId}", false);
                return [2, new __HttpRequest({
                        protocol: protocol,
                        hostname: hostname,
                        port: port,
                        method: "DELETE",
                        headers: headers,
                        path: resolvedPath,
                        body: body,
                    })];
        }
    });
}); };
export var serializeAws_restJson1DeleteSmsChannelCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, hostname, _b, protocol, port, basePath, headers, resolvedPath, body;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4, context.endpoint()];
            case 1:
                _a = _c.sent(), hostname = _a.hostname, _b = _a.protocol, protocol = _b === void 0 ? "https" : _b, port = _a.port, basePath = _a.path;
                headers = {};
                resolvedPath = "".concat((basePath === null || basePath === void 0 ? void 0 : basePath.endsWith("/")) ? basePath.slice(0, -1) : basePath || "") + "/v1/apps/{ApplicationId}/channels/sms";
                resolvedPath = __resolvedPath(resolvedPath, input, "ApplicationId", function () { return input.ApplicationId; }, "{ApplicationId}", false);
                return [2, new __HttpRequest({
                        protocol: protocol,
                        hostname: hostname,
                        port: port,
                        method: "DELETE",
                        headers: headers,
                        path: resolvedPath,
                        body: body,
                    })];
        }
    });
}); };
export var serializeAws_restJson1DeleteSmsTemplateCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, hostname, _b, protocol, port, basePath, headers, resolvedPath, query, body;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4, context.endpoint()];
            case 1:
                _a = _c.sent(), hostname = _a.hostname, _b = _a.protocol, protocol = _b === void 0 ? "https" : _b, port = _a.port, basePath = _a.path;
                headers = {};
                resolvedPath = "".concat((basePath === null || basePath === void 0 ? void 0 : basePath.endsWith("/")) ? basePath.slice(0, -1) : basePath || "") + "/v1/templates/{TemplateName}/sms";
                resolvedPath = __resolvedPath(resolvedPath, input, "TemplateName", function () { return input.TemplateName; }, "{TemplateName}", false);
                query = map({
                    version: [, input.Version],
                });
                return [2, new __HttpRequest({
                        protocol: protocol,
                        hostname: hostname,
                        port: port,
                        method: "DELETE",
                        headers: headers,
                        path: resolvedPath,
                        query: query,
                        body: body,
                    })];
        }
    });
}); };
export var serializeAws_restJson1DeleteUserEndpointsCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, hostname, _b, protocol, port, basePath, headers, resolvedPath, body;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4, context.endpoint()];
            case 1:
                _a = _c.sent(), hostname = _a.hostname, _b = _a.protocol, protocol = _b === void 0 ? "https" : _b, port = _a.port, basePath = _a.path;
                headers = {};
                resolvedPath = "".concat((basePath === null || basePath === void 0 ? void 0 : basePath.endsWith("/")) ? basePath.slice(0, -1) : basePath || "") + "/v1/apps/{ApplicationId}/users/{UserId}";
                resolvedPath = __resolvedPath(resolvedPath, input, "ApplicationId", function () { return input.ApplicationId; }, "{ApplicationId}", false);
                resolvedPath = __resolvedPath(resolvedPath, input, "UserId", function () { return input.UserId; }, "{UserId}", false);
                return [2, new __HttpRequest({
                        protocol: protocol,
                        hostname: hostname,
                        port: port,
                        method: "DELETE",
                        headers: headers,
                        path: resolvedPath,
                        body: body,
                    })];
        }
    });
}); };
export var serializeAws_restJson1DeleteVoiceChannelCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, hostname, _b, protocol, port, basePath, headers, resolvedPath, body;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4, context.endpoint()];
            case 1:
                _a = _c.sent(), hostname = _a.hostname, _b = _a.protocol, protocol = _b === void 0 ? "https" : _b, port = _a.port, basePath = _a.path;
                headers = {};
                resolvedPath = "".concat((basePath === null || basePath === void 0 ? void 0 : basePath.endsWith("/")) ? basePath.slice(0, -1) : basePath || "") + "/v1/apps/{ApplicationId}/channels/voice";
                resolvedPath = __resolvedPath(resolvedPath, input, "ApplicationId", function () { return input.ApplicationId; }, "{ApplicationId}", false);
                return [2, new __HttpRequest({
                        protocol: protocol,
                        hostname: hostname,
                        port: port,
                        method: "DELETE",
                        headers: headers,
                        path: resolvedPath,
                        body: body,
                    })];
        }
    });
}); };
export var serializeAws_restJson1DeleteVoiceTemplateCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, hostname, _b, protocol, port, basePath, headers, resolvedPath, query, body;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4, context.endpoint()];
            case 1:
                _a = _c.sent(), hostname = _a.hostname, _b = _a.protocol, protocol = _b === void 0 ? "https" : _b, port = _a.port, basePath = _a.path;
                headers = {};
                resolvedPath = "".concat((basePath === null || basePath === void 0 ? void 0 : basePath.endsWith("/")) ? basePath.slice(0, -1) : basePath || "") + "/v1/templates/{TemplateName}/voice";
                resolvedPath = __resolvedPath(resolvedPath, input, "TemplateName", function () { return input.TemplateName; }, "{TemplateName}", false);
                query = map({
                    version: [, input.Version],
                });
                return [2, new __HttpRequest({
                        protocol: protocol,
                        hostname: hostname,
                        port: port,
                        method: "DELETE",
                        headers: headers,
                        path: resolvedPath,
                        query: query,
                        body: body,
                    })];
        }
    });
}); };
export var serializeAws_restJson1GetAdmChannelCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, hostname, _b, protocol, port, basePath, headers, resolvedPath, body;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4, context.endpoint()];
            case 1:
                _a = _c.sent(), hostname = _a.hostname, _b = _a.protocol, protocol = _b === void 0 ? "https" : _b, port = _a.port, basePath = _a.path;
                headers = {};
                resolvedPath = "".concat((basePath === null || basePath === void 0 ? void 0 : basePath.endsWith("/")) ? basePath.slice(0, -1) : basePath || "") + "/v1/apps/{ApplicationId}/channels/adm";
                resolvedPath = __resolvedPath(resolvedPath, input, "ApplicationId", function () { return input.ApplicationId; }, "{ApplicationId}", false);
                return [2, new __HttpRequest({
                        protocol: protocol,
                        hostname: hostname,
                        port: port,
                        method: "GET",
                        headers: headers,
                        path: resolvedPath,
                        body: body,
                    })];
        }
    });
}); };
export var serializeAws_restJson1GetApnsChannelCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, hostname, _b, protocol, port, basePath, headers, resolvedPath, body;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4, context.endpoint()];
            case 1:
                _a = _c.sent(), hostname = _a.hostname, _b = _a.protocol, protocol = _b === void 0 ? "https" : _b, port = _a.port, basePath = _a.path;
                headers = {};
                resolvedPath = "".concat((basePath === null || basePath === void 0 ? void 0 : basePath.endsWith("/")) ? basePath.slice(0, -1) : basePath || "") + "/v1/apps/{ApplicationId}/channels/apns";
                resolvedPath = __resolvedPath(resolvedPath, input, "ApplicationId", function () { return input.ApplicationId; }, "{ApplicationId}", false);
                return [2, new __HttpRequest({
                        protocol: protocol,
                        hostname: hostname,
                        port: port,
                        method: "GET",
                        headers: headers,
                        path: resolvedPath,
                        body: body,
                    })];
        }
    });
}); };
export var serializeAws_restJson1GetApnsSandboxChannelCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, hostname, _b, protocol, port, basePath, headers, resolvedPath, body;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4, context.endpoint()];
            case 1:
                _a = _c.sent(), hostname = _a.hostname, _b = _a.protocol, protocol = _b === void 0 ? "https" : _b, port = _a.port, basePath = _a.path;
                headers = {};
                resolvedPath = "".concat((basePath === null || basePath === void 0 ? void 0 : basePath.endsWith("/")) ? basePath.slice(0, -1) : basePath || "") +
                    "/v1/apps/{ApplicationId}/channels/apns_sandbox";
                resolvedPath = __resolvedPath(resolvedPath, input, "ApplicationId", function () { return input.ApplicationId; }, "{ApplicationId}", false);
                return [2, new __HttpRequest({
                        protocol: protocol,
                        hostname: hostname,
                        port: port,
                        method: "GET",
                        headers: headers,
                        path: resolvedPath,
                        body: body,
                    })];
        }
    });
}); };
export var serializeAws_restJson1GetApnsVoipChannelCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, hostname, _b, protocol, port, basePath, headers, resolvedPath, body;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4, context.endpoint()];
            case 1:
                _a = _c.sent(), hostname = _a.hostname, _b = _a.protocol, protocol = _b === void 0 ? "https" : _b, port = _a.port, basePath = _a.path;
                headers = {};
                resolvedPath = "".concat((basePath === null || basePath === void 0 ? void 0 : basePath.endsWith("/")) ? basePath.slice(0, -1) : basePath || "") +
                    "/v1/apps/{ApplicationId}/channels/apns_voip";
                resolvedPath = __resolvedPath(resolvedPath, input, "ApplicationId", function () { return input.ApplicationId; }, "{ApplicationId}", false);
                return [2, new __HttpRequest({
                        protocol: protocol,
                        hostname: hostname,
                        port: port,
                        method: "GET",
                        headers: headers,
                        path: resolvedPath,
                        body: body,
                    })];
        }
    });
}); };
export var serializeAws_restJson1GetApnsVoipSandboxChannelCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, hostname, _b, protocol, port, basePath, headers, resolvedPath, body;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4, context.endpoint()];
            case 1:
                _a = _c.sent(), hostname = _a.hostname, _b = _a.protocol, protocol = _b === void 0 ? "https" : _b, port = _a.port, basePath = _a.path;
                headers = {};
                resolvedPath = "".concat((basePath === null || basePath === void 0 ? void 0 : basePath.endsWith("/")) ? basePath.slice(0, -1) : basePath || "") +
                    "/v1/apps/{ApplicationId}/channels/apns_voip_sandbox";
                resolvedPath = __resolvedPath(resolvedPath, input, "ApplicationId", function () { return input.ApplicationId; }, "{ApplicationId}", false);
                return [2, new __HttpRequest({
                        protocol: protocol,
                        hostname: hostname,
                        port: port,
                        method: "GET",
                        headers: headers,
                        path: resolvedPath,
                        body: body,
                    })];
        }
    });
}); };
export var serializeAws_restJson1GetAppCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, hostname, _b, protocol, port, basePath, headers, resolvedPath, body;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4, context.endpoint()];
            case 1:
                _a = _c.sent(), hostname = _a.hostname, _b = _a.protocol, protocol = _b === void 0 ? "https" : _b, port = _a.port, basePath = _a.path;
                headers = {};
                resolvedPath = "".concat((basePath === null || basePath === void 0 ? void 0 : basePath.endsWith("/")) ? basePath.slice(0, -1) : basePath || "") + "/v1/apps/{ApplicationId}";
                resolvedPath = __resolvedPath(resolvedPath, input, "ApplicationId", function () { return input.ApplicationId; }, "{ApplicationId}", false);
                return [2, new __HttpRequest({
                        protocol: protocol,
                        hostname: hostname,
                        port: port,
                        method: "GET",
                        headers: headers,
                        path: resolvedPath,
                        body: body,
                    })];
        }
    });
}); };
export var serializeAws_restJson1GetApplicationDateRangeKpiCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, hostname, _b, protocol, port, basePath, headers, resolvedPath, query, body;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4, context.endpoint()];
            case 1:
                _a = _c.sent(), hostname = _a.hostname, _b = _a.protocol, protocol = _b === void 0 ? "https" : _b, port = _a.port, basePath = _a.path;
                headers = {};
                resolvedPath = "".concat((basePath === null || basePath === void 0 ? void 0 : basePath.endsWith("/")) ? basePath.slice(0, -1) : basePath || "") +
                    "/v1/apps/{ApplicationId}/kpis/daterange/{KpiName}";
                resolvedPath = __resolvedPath(resolvedPath, input, "ApplicationId", function () { return input.ApplicationId; }, "{ApplicationId}", false);
                resolvedPath = __resolvedPath(resolvedPath, input, "KpiName", function () { return input.KpiName; }, "{KpiName}", false);
                query = map({
                    "end-time": [function () { return input.EndTime !== void 0; }, function () { return (input.EndTime.toISOString().split(".")[0] + "Z").toString(); }],
                    "next-token": [, input.NextToken],
                    "page-size": [, input.PageSize],
                    "start-time": [
                        function () { return input.StartTime !== void 0; },
                        function () { return (input.StartTime.toISOString().split(".")[0] + "Z").toString(); },
                    ],
                });
                return [2, new __HttpRequest({
                        protocol: protocol,
                        hostname: hostname,
                        port: port,
                        method: "GET",
                        headers: headers,
                        path: resolvedPath,
                        query: query,
                        body: body,
                    })];
        }
    });
}); };
export var serializeAws_restJson1GetApplicationSettingsCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, hostname, _b, protocol, port, basePath, headers, resolvedPath, body;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4, context.endpoint()];
            case 1:
                _a = _c.sent(), hostname = _a.hostname, _b = _a.protocol, protocol = _b === void 0 ? "https" : _b, port = _a.port, basePath = _a.path;
                headers = {};
                resolvedPath = "".concat((basePath === null || basePath === void 0 ? void 0 : basePath.endsWith("/")) ? basePath.slice(0, -1) : basePath || "") + "/v1/apps/{ApplicationId}/settings";
                resolvedPath = __resolvedPath(resolvedPath, input, "ApplicationId", function () { return input.ApplicationId; }, "{ApplicationId}", false);
                return [2, new __HttpRequest({
                        protocol: protocol,
                        hostname: hostname,
                        port: port,
                        method: "GET",
                        headers: headers,
                        path: resolvedPath,
                        body: body,
                    })];
        }
    });
}); };
export var serializeAws_restJson1GetAppsCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, hostname, _b, protocol, port, basePath, headers, resolvedPath, query, body;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4, context.endpoint()];
            case 1:
                _a = _c.sent(), hostname = _a.hostname, _b = _a.protocol, protocol = _b === void 0 ? "https" : _b, port = _a.port, basePath = _a.path;
                headers = {};
                resolvedPath = "".concat((basePath === null || basePath === void 0 ? void 0 : basePath.endsWith("/")) ? basePath.slice(0, -1) : basePath || "") + "/v1/apps";
                query = map({
                    "page-size": [, input.PageSize],
                    token: [, input.Token],
                });
                return [2, new __HttpRequest({
                        protocol: protocol,
                        hostname: hostname,
                        port: port,
                        method: "GET",
                        headers: headers,
                        path: resolvedPath,
                        query: query,
                        body: body,
                    })];
        }
    });
}); };
export var serializeAws_restJson1GetBaiduChannelCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, hostname, _b, protocol, port, basePath, headers, resolvedPath, body;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4, context.endpoint()];
            case 1:
                _a = _c.sent(), hostname = _a.hostname, _b = _a.protocol, protocol = _b === void 0 ? "https" : _b, port = _a.port, basePath = _a.path;
                headers = {};
                resolvedPath = "".concat((basePath === null || basePath === void 0 ? void 0 : basePath.endsWith("/")) ? basePath.slice(0, -1) : basePath || "") + "/v1/apps/{ApplicationId}/channels/baidu";
                resolvedPath = __resolvedPath(resolvedPath, input, "ApplicationId", function () { return input.ApplicationId; }, "{ApplicationId}", false);
                return [2, new __HttpRequest({
                        protocol: protocol,
                        hostname: hostname,
                        port: port,
                        method: "GET",
                        headers: headers,
                        path: resolvedPath,
                        body: body,
                    })];
        }
    });
}); };
export var serializeAws_restJson1GetCampaignCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, hostname, _b, protocol, port, basePath, headers, resolvedPath, body;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4, context.endpoint()];
            case 1:
                _a = _c.sent(), hostname = _a.hostname, _b = _a.protocol, protocol = _b === void 0 ? "https" : _b, port = _a.port, basePath = _a.path;
                headers = {};
                resolvedPath = "".concat((basePath === null || basePath === void 0 ? void 0 : basePath.endsWith("/")) ? basePath.slice(0, -1) : basePath || "") +
                    "/v1/apps/{ApplicationId}/campaigns/{CampaignId}";
                resolvedPath = __resolvedPath(resolvedPath, input, "ApplicationId", function () { return input.ApplicationId; }, "{ApplicationId}", false);
                resolvedPath = __resolvedPath(resolvedPath, input, "CampaignId", function () { return input.CampaignId; }, "{CampaignId}", false);
                return [2, new __HttpRequest({
                        protocol: protocol,
                        hostname: hostname,
                        port: port,
                        method: "GET",
                        headers: headers,
                        path: resolvedPath,
                        body: body,
                    })];
        }
    });
}); };
export var serializeAws_restJson1GetCampaignActivitiesCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, hostname, _b, protocol, port, basePath, headers, resolvedPath, query, body;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4, context.endpoint()];
            case 1:
                _a = _c.sent(), hostname = _a.hostname, _b = _a.protocol, protocol = _b === void 0 ? "https" : _b, port = _a.port, basePath = _a.path;
                headers = {};
                resolvedPath = "".concat((basePath === null || basePath === void 0 ? void 0 : basePath.endsWith("/")) ? basePath.slice(0, -1) : basePath || "") +
                    "/v1/apps/{ApplicationId}/campaigns/{CampaignId}/activities";
                resolvedPath = __resolvedPath(resolvedPath, input, "ApplicationId", function () { return input.ApplicationId; }, "{ApplicationId}", false);
                resolvedPath = __resolvedPath(resolvedPath, input, "CampaignId", function () { return input.CampaignId; }, "{CampaignId}", false);
                query = map({
                    "page-size": [, input.PageSize],
                    token: [, input.Token],
                });
                return [2, new __HttpRequest({
                        protocol: protocol,
                        hostname: hostname,
                        port: port,
                        method: "GET",
                        headers: headers,
                        path: resolvedPath,
                        query: query,
                        body: body,
                    })];
        }
    });
}); };
export var serializeAws_restJson1GetCampaignDateRangeKpiCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, hostname, _b, protocol, port, basePath, headers, resolvedPath, query, body;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4, context.endpoint()];
            case 1:
                _a = _c.sent(), hostname = _a.hostname, _b = _a.protocol, protocol = _b === void 0 ? "https" : _b, port = _a.port, basePath = _a.path;
                headers = {};
                resolvedPath = "".concat((basePath === null || basePath === void 0 ? void 0 : basePath.endsWith("/")) ? basePath.slice(0, -1) : basePath || "") +
                    "/v1/apps/{ApplicationId}/campaigns/{CampaignId}/kpis/daterange/{KpiName}";
                resolvedPath = __resolvedPath(resolvedPath, input, "ApplicationId", function () { return input.ApplicationId; }, "{ApplicationId}", false);
                resolvedPath = __resolvedPath(resolvedPath, input, "CampaignId", function () { return input.CampaignId; }, "{CampaignId}", false);
                resolvedPath = __resolvedPath(resolvedPath, input, "KpiName", function () { return input.KpiName; }, "{KpiName}", false);
                query = map({
                    "end-time": [function () { return input.EndTime !== void 0; }, function () { return (input.EndTime.toISOString().split(".")[0] + "Z").toString(); }],
                    "next-token": [, input.NextToken],
                    "page-size": [, input.PageSize],
                    "start-time": [
                        function () { return input.StartTime !== void 0; },
                        function () { return (input.StartTime.toISOString().split(".")[0] + "Z").toString(); },
                    ],
                });
                return [2, new __HttpRequest({
                        protocol: protocol,
                        hostname: hostname,
                        port: port,
                        method: "GET",
                        headers: headers,
                        path: resolvedPath,
                        query: query,
                        body: body,
                    })];
        }
    });
}); };
export var serializeAws_restJson1GetCampaignsCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, hostname, _b, protocol, port, basePath, headers, resolvedPath, query, body;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4, context.endpoint()];
            case 1:
                _a = _c.sent(), hostname = _a.hostname, _b = _a.protocol, protocol = _b === void 0 ? "https" : _b, port = _a.port, basePath = _a.path;
                headers = {};
                resolvedPath = "".concat((basePath === null || basePath === void 0 ? void 0 : basePath.endsWith("/")) ? basePath.slice(0, -1) : basePath || "") + "/v1/apps/{ApplicationId}/campaigns";
                resolvedPath = __resolvedPath(resolvedPath, input, "ApplicationId", function () { return input.ApplicationId; }, "{ApplicationId}", false);
                query = map({
                    "page-size": [, input.PageSize],
                    token: [, input.Token],
                });
                return [2, new __HttpRequest({
                        protocol: protocol,
                        hostname: hostname,
                        port: port,
                        method: "GET",
                        headers: headers,
                        path: resolvedPath,
                        query: query,
                        body: body,
                    })];
        }
    });
}); };
export var serializeAws_restJson1GetCampaignVersionCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, hostname, _b, protocol, port, basePath, headers, resolvedPath, body;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4, context.endpoint()];
            case 1:
                _a = _c.sent(), hostname = _a.hostname, _b = _a.protocol, protocol = _b === void 0 ? "https" : _b, port = _a.port, basePath = _a.path;
                headers = {};
                resolvedPath = "".concat((basePath === null || basePath === void 0 ? void 0 : basePath.endsWith("/")) ? basePath.slice(0, -1) : basePath || "") +
                    "/v1/apps/{ApplicationId}/campaigns/{CampaignId}/versions/{Version}";
                resolvedPath = __resolvedPath(resolvedPath, input, "ApplicationId", function () { return input.ApplicationId; }, "{ApplicationId}", false);
                resolvedPath = __resolvedPath(resolvedPath, input, "CampaignId", function () { return input.CampaignId; }, "{CampaignId}", false);
                resolvedPath = __resolvedPath(resolvedPath, input, "Version", function () { return input.Version; }, "{Version}", false);
                return [2, new __HttpRequest({
                        protocol: protocol,
                        hostname: hostname,
                        port: port,
                        method: "GET",
                        headers: headers,
                        path: resolvedPath,
                        body: body,
                    })];
        }
    });
}); };
export var serializeAws_restJson1GetCampaignVersionsCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, hostname, _b, protocol, port, basePath, headers, resolvedPath, query, body;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4, context.endpoint()];
            case 1:
                _a = _c.sent(), hostname = _a.hostname, _b = _a.protocol, protocol = _b === void 0 ? "https" : _b, port = _a.port, basePath = _a.path;
                headers = {};
                resolvedPath = "".concat((basePath === null || basePath === void 0 ? void 0 : basePath.endsWith("/")) ? basePath.slice(0, -1) : basePath || "") +
                    "/v1/apps/{ApplicationId}/campaigns/{CampaignId}/versions";
                resolvedPath = __resolvedPath(resolvedPath, input, "ApplicationId", function () { return input.ApplicationId; }, "{ApplicationId}", false);
                resolvedPath = __resolvedPath(resolvedPath, input, "CampaignId", function () { return input.CampaignId; }, "{CampaignId}", false);
                query = map({
                    "page-size": [, input.PageSize],
                    token: [, input.Token],
                });
                return [2, new __HttpRequest({
                        protocol: protocol,
                        hostname: hostname,
                        port: port,
                        method: "GET",
                        headers: headers,
                        path: resolvedPath,
                        query: query,
                        body: body,
                    })];
        }
    });
}); };
export var serializeAws_restJson1GetChannelsCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, hostname, _b, protocol, port, basePath, headers, resolvedPath, body;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4, context.endpoint()];
            case 1:
                _a = _c.sent(), hostname = _a.hostname, _b = _a.protocol, protocol = _b === void 0 ? "https" : _b, port = _a.port, basePath = _a.path;
                headers = {};
                resolvedPath = "".concat((basePath === null || basePath === void 0 ? void 0 : basePath.endsWith("/")) ? basePath.slice(0, -1) : basePath || "") + "/v1/apps/{ApplicationId}/channels";
                resolvedPath = __resolvedPath(resolvedPath, input, "ApplicationId", function () { return input.ApplicationId; }, "{ApplicationId}", false);
                return [2, new __HttpRequest({
                        protocol: protocol,
                        hostname: hostname,
                        port: port,
                        method: "GET",
                        headers: headers,
                        path: resolvedPath,
                        body: body,
                    })];
        }
    });
}); };
export var serializeAws_restJson1GetEmailChannelCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, hostname, _b, protocol, port, basePath, headers, resolvedPath, body;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4, context.endpoint()];
            case 1:
                _a = _c.sent(), hostname = _a.hostname, _b = _a.protocol, protocol = _b === void 0 ? "https" : _b, port = _a.port, basePath = _a.path;
                headers = {};
                resolvedPath = "".concat((basePath === null || basePath === void 0 ? void 0 : basePath.endsWith("/")) ? basePath.slice(0, -1) : basePath || "") + "/v1/apps/{ApplicationId}/channels/email";
                resolvedPath = __resolvedPath(resolvedPath, input, "ApplicationId", function () { return input.ApplicationId; }, "{ApplicationId}", false);
                return [2, new __HttpRequest({
                        protocol: protocol,
                        hostname: hostname,
                        port: port,
                        method: "GET",
                        headers: headers,
                        path: resolvedPath,
                        body: body,
                    })];
        }
    });
}); };
export var serializeAws_restJson1GetEmailTemplateCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, hostname, _b, protocol, port, basePath, headers, resolvedPath, query, body;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4, context.endpoint()];
            case 1:
                _a = _c.sent(), hostname = _a.hostname, _b = _a.protocol, protocol = _b === void 0 ? "https" : _b, port = _a.port, basePath = _a.path;
                headers = {};
                resolvedPath = "".concat((basePath === null || basePath === void 0 ? void 0 : basePath.endsWith("/")) ? basePath.slice(0, -1) : basePath || "") + "/v1/templates/{TemplateName}/email";
                resolvedPath = __resolvedPath(resolvedPath, input, "TemplateName", function () { return input.TemplateName; }, "{TemplateName}", false);
                query = map({
                    version: [, input.Version],
                });
                return [2, new __HttpRequest({
                        protocol: protocol,
                        hostname: hostname,
                        port: port,
                        method: "GET",
                        headers: headers,
                        path: resolvedPath,
                        query: query,
                        body: body,
                    })];
        }
    });
}); };
export var serializeAws_restJson1GetEndpointCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, hostname, _b, protocol, port, basePath, headers, resolvedPath, body;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4, context.endpoint()];
            case 1:
                _a = _c.sent(), hostname = _a.hostname, _b = _a.protocol, protocol = _b === void 0 ? "https" : _b, port = _a.port, basePath = _a.path;
                headers = {};
                resolvedPath = "".concat((basePath === null || basePath === void 0 ? void 0 : basePath.endsWith("/")) ? basePath.slice(0, -1) : basePath || "") +
                    "/v1/apps/{ApplicationId}/endpoints/{EndpointId}";
                resolvedPath = __resolvedPath(resolvedPath, input, "ApplicationId", function () { return input.ApplicationId; }, "{ApplicationId}", false);
                resolvedPath = __resolvedPath(resolvedPath, input, "EndpointId", function () { return input.EndpointId; }, "{EndpointId}", false);
                return [2, new __HttpRequest({
                        protocol: protocol,
                        hostname: hostname,
                        port: port,
                        method: "GET",
                        headers: headers,
                        path: resolvedPath,
                        body: body,
                    })];
        }
    });
}); };
export var serializeAws_restJson1GetEventStreamCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, hostname, _b, protocol, port, basePath, headers, resolvedPath, body;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4, context.endpoint()];
            case 1:
                _a = _c.sent(), hostname = _a.hostname, _b = _a.protocol, protocol = _b === void 0 ? "https" : _b, port = _a.port, basePath = _a.path;
                headers = {};
                resolvedPath = "".concat((basePath === null || basePath === void 0 ? void 0 : basePath.endsWith("/")) ? basePath.slice(0, -1) : basePath || "") + "/v1/apps/{ApplicationId}/eventstream";
                resolvedPath = __resolvedPath(resolvedPath, input, "ApplicationId", function () { return input.ApplicationId; }, "{ApplicationId}", false);
                return [2, new __HttpRequest({
                        protocol: protocol,
                        hostname: hostname,
                        port: port,
                        method: "GET",
                        headers: headers,
                        path: resolvedPath,
                        body: body,
                    })];
        }
    });
}); };
export var serializeAws_restJson1GetExportJobCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, hostname, _b, protocol, port, basePath, headers, resolvedPath, body;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4, context.endpoint()];
            case 1:
                _a = _c.sent(), hostname = _a.hostname, _b = _a.protocol, protocol = _b === void 0 ? "https" : _b, port = _a.port, basePath = _a.path;
                headers = {};
                resolvedPath = "".concat((basePath === null || basePath === void 0 ? void 0 : basePath.endsWith("/")) ? basePath.slice(0, -1) : basePath || "") +
                    "/v1/apps/{ApplicationId}/jobs/export/{JobId}";
                resolvedPath = __resolvedPath(resolvedPath, input, "ApplicationId", function () { return input.ApplicationId; }, "{ApplicationId}", false);
                resolvedPath = __resolvedPath(resolvedPath, input, "JobId", function () { return input.JobId; }, "{JobId}", false);
                return [2, new __HttpRequest({
                        protocol: protocol,
                        hostname: hostname,
                        port: port,
                        method: "GET",
                        headers: headers,
                        path: resolvedPath,
                        body: body,
                    })];
        }
    });
}); };
export var serializeAws_restJson1GetExportJobsCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, hostname, _b, protocol, port, basePath, headers, resolvedPath, query, body;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4, context.endpoint()];
            case 1:
                _a = _c.sent(), hostname = _a.hostname, _b = _a.protocol, protocol = _b === void 0 ? "https" : _b, port = _a.port, basePath = _a.path;
                headers = {};
                resolvedPath = "".concat((basePath === null || basePath === void 0 ? void 0 : basePath.endsWith("/")) ? basePath.slice(0, -1) : basePath || "") + "/v1/apps/{ApplicationId}/jobs/export";
                resolvedPath = __resolvedPath(resolvedPath, input, "ApplicationId", function () { return input.ApplicationId; }, "{ApplicationId}", false);
                query = map({
                    "page-size": [, input.PageSize],
                    token: [, input.Token],
                });
                return [2, new __HttpRequest({
                        protocol: protocol,
                        hostname: hostname,
                        port: port,
                        method: "GET",
                        headers: headers,
                        path: resolvedPath,
                        query: query,
                        body: body,
                    })];
        }
    });
}); };
export var serializeAws_restJson1GetGcmChannelCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, hostname, _b, protocol, port, basePath, headers, resolvedPath, body;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4, context.endpoint()];
            case 1:
                _a = _c.sent(), hostname = _a.hostname, _b = _a.protocol, protocol = _b === void 0 ? "https" : _b, port = _a.port, basePath = _a.path;
                headers = {};
                resolvedPath = "".concat((basePath === null || basePath === void 0 ? void 0 : basePath.endsWith("/")) ? basePath.slice(0, -1) : basePath || "") + "/v1/apps/{ApplicationId}/channels/gcm";
                resolvedPath = __resolvedPath(resolvedPath, input, "ApplicationId", function () { return input.ApplicationId; }, "{ApplicationId}", false);
                return [2, new __HttpRequest({
                        protocol: protocol,
                        hostname: hostname,
                        port: port,
                        method: "GET",
                        headers: headers,
                        path: resolvedPath,
                        body: body,
                    })];
        }
    });
}); };
export var serializeAws_restJson1GetImportJobCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, hostname, _b, protocol, port, basePath, headers, resolvedPath, body;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4, context.endpoint()];
            case 1:
                _a = _c.sent(), hostname = _a.hostname, _b = _a.protocol, protocol = _b === void 0 ? "https" : _b, port = _a.port, basePath = _a.path;
                headers = {};
                resolvedPath = "".concat((basePath === null || basePath === void 0 ? void 0 : basePath.endsWith("/")) ? basePath.slice(0, -1) : basePath || "") +
                    "/v1/apps/{ApplicationId}/jobs/import/{JobId}";
                resolvedPath = __resolvedPath(resolvedPath, input, "ApplicationId", function () { return input.ApplicationId; }, "{ApplicationId}", false);
                resolvedPath = __resolvedPath(resolvedPath, input, "JobId", function () { return input.JobId; }, "{JobId}", false);
                return [2, new __HttpRequest({
                        protocol: protocol,
                        hostname: hostname,
                        port: port,
                        method: "GET",
                        headers: headers,
                        path: resolvedPath,
                        body: body,
                    })];
        }
    });
}); };
export var serializeAws_restJson1GetImportJobsCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, hostname, _b, protocol, port, basePath, headers, resolvedPath, query, body;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4, context.endpoint()];
            case 1:
                _a = _c.sent(), hostname = _a.hostname, _b = _a.protocol, protocol = _b === void 0 ? "https" : _b, port = _a.port, basePath = _a.path;
                headers = {};
                resolvedPath = "".concat((basePath === null || basePath === void 0 ? void 0 : basePath.endsWith("/")) ? basePath.slice(0, -1) : basePath || "") + "/v1/apps/{ApplicationId}/jobs/import";
                resolvedPath = __resolvedPath(resolvedPath, input, "ApplicationId", function () { return input.ApplicationId; }, "{ApplicationId}", false);
                query = map({
                    "page-size": [, input.PageSize],
                    token: [, input.Token],
                });
                return [2, new __HttpRequest({
                        protocol: protocol,
                        hostname: hostname,
                        port: port,
                        method: "GET",
                        headers: headers,
                        path: resolvedPath,
                        query: query,
                        body: body,
                    })];
        }
    });
}); };
export var serializeAws_restJson1GetInAppMessagesCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, hostname, _b, protocol, port, basePath, headers, resolvedPath, body;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4, context.endpoint()];
            case 1:
                _a = _c.sent(), hostname = _a.hostname, _b = _a.protocol, protocol = _b === void 0 ? "https" : _b, port = _a.port, basePath = _a.path;
                headers = {};
                resolvedPath = "".concat((basePath === null || basePath === void 0 ? void 0 : basePath.endsWith("/")) ? basePath.slice(0, -1) : basePath || "") +
                    "/v1/apps/{ApplicationId}/endpoints/{EndpointId}/inappmessages";
                resolvedPath = __resolvedPath(resolvedPath, input, "ApplicationId", function () { return input.ApplicationId; }, "{ApplicationId}", false);
                resolvedPath = __resolvedPath(resolvedPath, input, "EndpointId", function () { return input.EndpointId; }, "{EndpointId}", false);
                return [2, new __HttpRequest({
                        protocol: protocol,
                        hostname: hostname,
                        port: port,
                        method: "GET",
                        headers: headers,
                        path: resolvedPath,
                        body: body,
                    })];
        }
    });
}); };
export var serializeAws_restJson1GetInAppTemplateCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, hostname, _b, protocol, port, basePath, headers, resolvedPath, query, body;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4, context.endpoint()];
            case 1:
                _a = _c.sent(), hostname = _a.hostname, _b = _a.protocol, protocol = _b === void 0 ? "https" : _b, port = _a.port, basePath = _a.path;
                headers = {};
                resolvedPath = "".concat((basePath === null || basePath === void 0 ? void 0 : basePath.endsWith("/")) ? basePath.slice(0, -1) : basePath || "") + "/v1/templates/{TemplateName}/inapp";
                resolvedPath = __resolvedPath(resolvedPath, input, "TemplateName", function () { return input.TemplateName; }, "{TemplateName}", false);
                query = map({
                    version: [, input.Version],
                });
                return [2, new __HttpRequest({
                        protocol: protocol,
                        hostname: hostname,
                        port: port,
                        method: "GET",
                        headers: headers,
                        path: resolvedPath,
                        query: query,
                        body: body,
                    })];
        }
    });
}); };
export var serializeAws_restJson1GetJourneyCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, hostname, _b, protocol, port, basePath, headers, resolvedPath, body;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4, context.endpoint()];
            case 1:
                _a = _c.sent(), hostname = _a.hostname, _b = _a.protocol, protocol = _b === void 0 ? "https" : _b, port = _a.port, basePath = _a.path;
                headers = {};
                resolvedPath = "".concat((basePath === null || basePath === void 0 ? void 0 : basePath.endsWith("/")) ? basePath.slice(0, -1) : basePath || "") +
                    "/v1/apps/{ApplicationId}/journeys/{JourneyId}";
                resolvedPath = __resolvedPath(resolvedPath, input, "ApplicationId", function () { return input.ApplicationId; }, "{ApplicationId}", false);
                resolvedPath = __resolvedPath(resolvedPath, input, "JourneyId", function () { return input.JourneyId; }, "{JourneyId}", false);
                return [2, new __HttpRequest({
                        protocol: protocol,
                        hostname: hostname,
                        port: port,
                        method: "GET",
                        headers: headers,
                        path: resolvedPath,
                        body: body,
                    })];
        }
    });
}); };
export var serializeAws_restJson1GetJourneyDateRangeKpiCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, hostname, _b, protocol, port, basePath, headers, resolvedPath, query, body;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4, context.endpoint()];
            case 1:
                _a = _c.sent(), hostname = _a.hostname, _b = _a.protocol, protocol = _b === void 0 ? "https" : _b, port = _a.port, basePath = _a.path;
                headers = {};
                resolvedPath = "".concat((basePath === null || basePath === void 0 ? void 0 : basePath.endsWith("/")) ? basePath.slice(0, -1) : basePath || "") +
                    "/v1/apps/{ApplicationId}/journeys/{JourneyId}/kpis/daterange/{KpiName}";
                resolvedPath = __resolvedPath(resolvedPath, input, "ApplicationId", function () { return input.ApplicationId; }, "{ApplicationId}", false);
                resolvedPath = __resolvedPath(resolvedPath, input, "JourneyId", function () { return input.JourneyId; }, "{JourneyId}", false);
                resolvedPath = __resolvedPath(resolvedPath, input, "KpiName", function () { return input.KpiName; }, "{KpiName}", false);
                query = map({
                    "end-time": [function () { return input.EndTime !== void 0; }, function () { return (input.EndTime.toISOString().split(".")[0] + "Z").toString(); }],
                    "next-token": [, input.NextToken],
                    "page-size": [, input.PageSize],
                    "start-time": [
                        function () { return input.StartTime !== void 0; },
                        function () { return (input.StartTime.toISOString().split(".")[0] + "Z").toString(); },
                    ],
                });
                return [2, new __HttpRequest({
                        protocol: protocol,
                        hostname: hostname,
                        port: port,
                        method: "GET",
                        headers: headers,
                        path: resolvedPath,
                        query: query,
                        body: body,
                    })];
        }
    });
}); };
export var serializeAws_restJson1GetJourneyExecutionActivityMetricsCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, hostname, _b, protocol, port, basePath, headers, resolvedPath, query, body;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4, context.endpoint()];
            case 1:
                _a = _c.sent(), hostname = _a.hostname, _b = _a.protocol, protocol = _b === void 0 ? "https" : _b, port = _a.port, basePath = _a.path;
                headers = {};
                resolvedPath = "".concat((basePath === null || basePath === void 0 ? void 0 : basePath.endsWith("/")) ? basePath.slice(0, -1) : basePath || "") +
                    "/v1/apps/{ApplicationId}/journeys/{JourneyId}/activities/{JourneyActivityId}/execution-metrics";
                resolvedPath = __resolvedPath(resolvedPath, input, "ApplicationId", function () { return input.ApplicationId; }, "{ApplicationId}", false);
                resolvedPath = __resolvedPath(resolvedPath, input, "JourneyActivityId", function () { return input.JourneyActivityId; }, "{JourneyActivityId}", false);
                resolvedPath = __resolvedPath(resolvedPath, input, "JourneyId", function () { return input.JourneyId; }, "{JourneyId}", false);
                query = map({
                    "next-token": [, input.NextToken],
                    "page-size": [, input.PageSize],
                });
                return [2, new __HttpRequest({
                        protocol: protocol,
                        hostname: hostname,
                        port: port,
                        method: "GET",
                        headers: headers,
                        path: resolvedPath,
                        query: query,
                        body: body,
                    })];
        }
    });
}); };
export var serializeAws_restJson1GetJourneyExecutionMetricsCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, hostname, _b, protocol, port, basePath, headers, resolvedPath, query, body;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4, context.endpoint()];
            case 1:
                _a = _c.sent(), hostname = _a.hostname, _b = _a.protocol, protocol = _b === void 0 ? "https" : _b, port = _a.port, basePath = _a.path;
                headers = {};
                resolvedPath = "".concat((basePath === null || basePath === void 0 ? void 0 : basePath.endsWith("/")) ? basePath.slice(0, -1) : basePath || "") +
                    "/v1/apps/{ApplicationId}/journeys/{JourneyId}/execution-metrics";
                resolvedPath = __resolvedPath(resolvedPath, input, "ApplicationId", function () { return input.ApplicationId; }, "{ApplicationId}", false);
                resolvedPath = __resolvedPath(resolvedPath, input, "JourneyId", function () { return input.JourneyId; }, "{JourneyId}", false);
                query = map({
                    "next-token": [, input.NextToken],
                    "page-size": [, input.PageSize],
                });
                return [2, new __HttpRequest({
                        protocol: protocol,
                        hostname: hostname,
                        port: port,
                        method: "GET",
                        headers: headers,
                        path: resolvedPath,
                        query: query,
                        body: body,
                    })];
        }
    });
}); };
export var serializeAws_restJson1GetPushTemplateCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, hostname, _b, protocol, port, basePath, headers, resolvedPath, query, body;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4, context.endpoint()];
            case 1:
                _a = _c.sent(), hostname = _a.hostname, _b = _a.protocol, protocol = _b === void 0 ? "https" : _b, port = _a.port, basePath = _a.path;
                headers = {};
                resolvedPath = "".concat((basePath === null || basePath === void 0 ? void 0 : basePath.endsWith("/")) ? basePath.slice(0, -1) : basePath || "") + "/v1/templates/{TemplateName}/push";
                resolvedPath = __resolvedPath(resolvedPath, input, "TemplateName", function () { return input.TemplateName; }, "{TemplateName}", false);
                query = map({
                    version: [, input.Version],
                });
                return [2, new __HttpRequest({
                        protocol: protocol,
                        hostname: hostname,
                        port: port,
                        method: "GET",
                        headers: headers,
                        path: resolvedPath,
                        query: query,
                        body: body,
                    })];
        }
    });
}); };
export var serializeAws_restJson1GetRecommenderConfigurationCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, hostname, _b, protocol, port, basePath, headers, resolvedPath, body;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4, context.endpoint()];
            case 1:
                _a = _c.sent(), hostname = _a.hostname, _b = _a.protocol, protocol = _b === void 0 ? "https" : _b, port = _a.port, basePath = _a.path;
                headers = {};
                resolvedPath = "".concat((basePath === null || basePath === void 0 ? void 0 : basePath.endsWith("/")) ? basePath.slice(0, -1) : basePath || "") + "/v1/recommenders/{RecommenderId}";
                resolvedPath = __resolvedPath(resolvedPath, input, "RecommenderId", function () { return input.RecommenderId; }, "{RecommenderId}", false);
                return [2, new __HttpRequest({
                        protocol: protocol,
                        hostname: hostname,
                        port: port,
                        method: "GET",
                        headers: headers,
                        path: resolvedPath,
                        body: body,
                    })];
        }
    });
}); };
export var serializeAws_restJson1GetRecommenderConfigurationsCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, hostname, _b, protocol, port, basePath, headers, resolvedPath, query, body;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4, context.endpoint()];
            case 1:
                _a = _c.sent(), hostname = _a.hostname, _b = _a.protocol, protocol = _b === void 0 ? "https" : _b, port = _a.port, basePath = _a.path;
                headers = {};
                resolvedPath = "".concat((basePath === null || basePath === void 0 ? void 0 : basePath.endsWith("/")) ? basePath.slice(0, -1) : basePath || "") + "/v1/recommenders";
                query = map({
                    "page-size": [, input.PageSize],
                    token: [, input.Token],
                });
                return [2, new __HttpRequest({
                        protocol: protocol,
                        hostname: hostname,
                        port: port,
                        method: "GET",
                        headers: headers,
                        path: resolvedPath,
                        query: query,
                        body: body,
                    })];
        }
    });
}); };
export var serializeAws_restJson1GetSegmentCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, hostname, _b, protocol, port, basePath, headers, resolvedPath, body;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4, context.endpoint()];
            case 1:
                _a = _c.sent(), hostname = _a.hostname, _b = _a.protocol, protocol = _b === void 0 ? "https" : _b, port = _a.port, basePath = _a.path;
                headers = {};
                resolvedPath = "".concat((basePath === null || basePath === void 0 ? void 0 : basePath.endsWith("/")) ? basePath.slice(0, -1) : basePath || "") +
                    "/v1/apps/{ApplicationId}/segments/{SegmentId}";
                resolvedPath = __resolvedPath(resolvedPath, input, "ApplicationId", function () { return input.ApplicationId; }, "{ApplicationId}", false);
                resolvedPath = __resolvedPath(resolvedPath, input, "SegmentId", function () { return input.SegmentId; }, "{SegmentId}", false);
                return [2, new __HttpRequest({
                        protocol: protocol,
                        hostname: hostname,
                        port: port,
                        method: "GET",
                        headers: headers,
                        path: resolvedPath,
                        body: body,
                    })];
        }
    });
}); };
export var serializeAws_restJson1GetSegmentExportJobsCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, hostname, _b, protocol, port, basePath, headers, resolvedPath, query, body;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4, context.endpoint()];
            case 1:
                _a = _c.sent(), hostname = _a.hostname, _b = _a.protocol, protocol = _b === void 0 ? "https" : _b, port = _a.port, basePath = _a.path;
                headers = {};
                resolvedPath = "".concat((basePath === null || basePath === void 0 ? void 0 : basePath.endsWith("/")) ? basePath.slice(0, -1) : basePath || "") +
                    "/v1/apps/{ApplicationId}/segments/{SegmentId}/jobs/export";
                resolvedPath = __resolvedPath(resolvedPath, input, "ApplicationId", function () { return input.ApplicationId; }, "{ApplicationId}", false);
                resolvedPath = __resolvedPath(resolvedPath, input, "SegmentId", function () { return input.SegmentId; }, "{SegmentId}", false);
                query = map({
                    "page-size": [, input.PageSize],
                    token: [, input.Token],
                });
                return [2, new __HttpRequest({
                        protocol: protocol,
                        hostname: hostname,
                        port: port,
                        method: "GET",
                        headers: headers,
                        path: resolvedPath,
                        query: query,
                        body: body,
                    })];
        }
    });
}); };
export var serializeAws_restJson1GetSegmentImportJobsCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, hostname, _b, protocol, port, basePath, headers, resolvedPath, query, body;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4, context.endpoint()];
            case 1:
                _a = _c.sent(), hostname = _a.hostname, _b = _a.protocol, protocol = _b === void 0 ? "https" : _b, port = _a.port, basePath = _a.path;
                headers = {};
                resolvedPath = "".concat((basePath === null || basePath === void 0 ? void 0 : basePath.endsWith("/")) ? basePath.slice(0, -1) : basePath || "") +
                    "/v1/apps/{ApplicationId}/segments/{SegmentId}/jobs/import";
                resolvedPath = __resolvedPath(resolvedPath, input, "ApplicationId", function () { return input.ApplicationId; }, "{ApplicationId}", false);
                resolvedPath = __resolvedPath(resolvedPath, input, "SegmentId", function () { return input.SegmentId; }, "{SegmentId}", false);
                query = map({
                    "page-size": [, input.PageSize],
                    token: [, input.Token],
                });
                return [2, new __HttpRequest({
                        protocol: protocol,
                        hostname: hostname,
                        port: port,
                        method: "GET",
                        headers: headers,
                        path: resolvedPath,
                        query: query,
                        body: body,
                    })];
        }
    });
}); };
export var serializeAws_restJson1GetSegmentsCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, hostname, _b, protocol, port, basePath, headers, resolvedPath, query, body;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4, context.endpoint()];
            case 1:
                _a = _c.sent(), hostname = _a.hostname, _b = _a.protocol, protocol = _b === void 0 ? "https" : _b, port = _a.port, basePath = _a.path;
                headers = {};
                resolvedPath = "".concat((basePath === null || basePath === void 0 ? void 0 : basePath.endsWith("/")) ? basePath.slice(0, -1) : basePath || "") + "/v1/apps/{ApplicationId}/segments";
                resolvedPath = __resolvedPath(resolvedPath, input, "ApplicationId", function () { return input.ApplicationId; }, "{ApplicationId}", false);
                query = map({
                    "page-size": [, input.PageSize],
                    token: [, input.Token],
                });
                return [2, new __HttpRequest({
                        protocol: protocol,
                        hostname: hostname,
                        port: port,
                        method: "GET",
                        headers: headers,
                        path: resolvedPath,
                        query: query,
                        body: body,
                    })];
        }
    });
}); };
export var serializeAws_restJson1GetSegmentVersionCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, hostname, _b, protocol, port, basePath, headers, resolvedPath, body;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4, context.endpoint()];
            case 1:
                _a = _c.sent(), hostname = _a.hostname, _b = _a.protocol, protocol = _b === void 0 ? "https" : _b, port = _a.port, basePath = _a.path;
                headers = {};
                resolvedPath = "".concat((basePath === null || basePath === void 0 ? void 0 : basePath.endsWith("/")) ? basePath.slice(0, -1) : basePath || "") +
                    "/v1/apps/{ApplicationId}/segments/{SegmentId}/versions/{Version}";
                resolvedPath = __resolvedPath(resolvedPath, input, "ApplicationId", function () { return input.ApplicationId; }, "{ApplicationId}", false);
                resolvedPath = __resolvedPath(resolvedPath, input, "SegmentId", function () { return input.SegmentId; }, "{SegmentId}", false);
                resolvedPath = __resolvedPath(resolvedPath, input, "Version", function () { return input.Version; }, "{Version}", false);
                return [2, new __HttpRequest({
                        protocol: protocol,
                        hostname: hostname,
                        port: port,
                        method: "GET",
                        headers: headers,
                        path: resolvedPath,
                        body: body,
                    })];
        }
    });
}); };
export var serializeAws_restJson1GetSegmentVersionsCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, hostname, _b, protocol, port, basePath, headers, resolvedPath, query, body;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4, context.endpoint()];
            case 1:
                _a = _c.sent(), hostname = _a.hostname, _b = _a.protocol, protocol = _b === void 0 ? "https" : _b, port = _a.port, basePath = _a.path;
                headers = {};
                resolvedPath = "".concat((basePath === null || basePath === void 0 ? void 0 : basePath.endsWith("/")) ? basePath.slice(0, -1) : basePath || "") +
                    "/v1/apps/{ApplicationId}/segments/{SegmentId}/versions";
                resolvedPath = __resolvedPath(resolvedPath, input, "ApplicationId", function () { return input.ApplicationId; }, "{ApplicationId}", false);
                resolvedPath = __resolvedPath(resolvedPath, input, "SegmentId", function () { return input.SegmentId; }, "{SegmentId}", false);
                query = map({
                    "page-size": [, input.PageSize],
                    token: [, input.Token],
                });
                return [2, new __HttpRequest({
                        protocol: protocol,
                        hostname: hostname,
                        port: port,
                        method: "GET",
                        headers: headers,
                        path: resolvedPath,
                        query: query,
                        body: body,
                    })];
        }
    });
}); };
export var serializeAws_restJson1GetSmsChannelCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, hostname, _b, protocol, port, basePath, headers, resolvedPath, body;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4, context.endpoint()];
            case 1:
                _a = _c.sent(), hostname = _a.hostname, _b = _a.protocol, protocol = _b === void 0 ? "https" : _b, port = _a.port, basePath = _a.path;
                headers = {};
                resolvedPath = "".concat((basePath === null || basePath === void 0 ? void 0 : basePath.endsWith("/")) ? basePath.slice(0, -1) : basePath || "") + "/v1/apps/{ApplicationId}/channels/sms";
                resolvedPath = __resolvedPath(resolvedPath, input, "ApplicationId", function () { return input.ApplicationId; }, "{ApplicationId}", false);
                return [2, new __HttpRequest({
                        protocol: protocol,
                        hostname: hostname,
                        port: port,
                        method: "GET",
                        headers: headers,
                        path: resolvedPath,
                        body: body,
                    })];
        }
    });
}); };
export var serializeAws_restJson1GetSmsTemplateCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, hostname, _b, protocol, port, basePath, headers, resolvedPath, query, body;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4, context.endpoint()];
            case 1:
                _a = _c.sent(), hostname = _a.hostname, _b = _a.protocol, protocol = _b === void 0 ? "https" : _b, port = _a.port, basePath = _a.path;
                headers = {};
                resolvedPath = "".concat((basePath === null || basePath === void 0 ? void 0 : basePath.endsWith("/")) ? basePath.slice(0, -1) : basePath || "") + "/v1/templates/{TemplateName}/sms";
                resolvedPath = __resolvedPath(resolvedPath, input, "TemplateName", function () { return input.TemplateName; }, "{TemplateName}", false);
                query = map({
                    version: [, input.Version],
                });
                return [2, new __HttpRequest({
                        protocol: protocol,
                        hostname: hostname,
                        port: port,
                        method: "GET",
                        headers: headers,
                        path: resolvedPath,
                        query: query,
                        body: body,
                    })];
        }
    });
}); };
export var serializeAws_restJson1GetUserEndpointsCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, hostname, _b, protocol, port, basePath, headers, resolvedPath, body;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4, context.endpoint()];
            case 1:
                _a = _c.sent(), hostname = _a.hostname, _b = _a.protocol, protocol = _b === void 0 ? "https" : _b, port = _a.port, basePath = _a.path;
                headers = {};
                resolvedPath = "".concat((basePath === null || basePath === void 0 ? void 0 : basePath.endsWith("/")) ? basePath.slice(0, -1) : basePath || "") + "/v1/apps/{ApplicationId}/users/{UserId}";
                resolvedPath = __resolvedPath(resolvedPath, input, "ApplicationId", function () { return input.ApplicationId; }, "{ApplicationId}", false);
                resolvedPath = __resolvedPath(resolvedPath, input, "UserId", function () { return input.UserId; }, "{UserId}", false);
                return [2, new __HttpRequest({
                        protocol: protocol,
                        hostname: hostname,
                        port: port,
                        method: "GET",
                        headers: headers,
                        path: resolvedPath,
                        body: body,
                    })];
        }
    });
}); };
export var serializeAws_restJson1GetVoiceChannelCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, hostname, _b, protocol, port, basePath, headers, resolvedPath, body;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4, context.endpoint()];
            case 1:
                _a = _c.sent(), hostname = _a.hostname, _b = _a.protocol, protocol = _b === void 0 ? "https" : _b, port = _a.port, basePath = _a.path;
                headers = {};
                resolvedPath = "".concat((basePath === null || basePath === void 0 ? void 0 : basePath.endsWith("/")) ? basePath.slice(0, -1) : basePath || "") + "/v1/apps/{ApplicationId}/channels/voice";
                resolvedPath = __resolvedPath(resolvedPath, input, "ApplicationId", function () { return input.ApplicationId; }, "{ApplicationId}", false);
                return [2, new __HttpRequest({
                        protocol: protocol,
                        hostname: hostname,
                        port: port,
                        method: "GET",
                        headers: headers,
                        path: resolvedPath,
                        body: body,
                    })];
        }
    });
}); };
export var serializeAws_restJson1GetVoiceTemplateCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, hostname, _b, protocol, port, basePath, headers, resolvedPath, query, body;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4, context.endpoint()];
            case 1:
                _a = _c.sent(), hostname = _a.hostname, _b = _a.protocol, protocol = _b === void 0 ? "https" : _b, port = _a.port, basePath = _a.path;
                headers = {};
                resolvedPath = "".concat((basePath === null || basePath === void 0 ? void 0 : basePath.endsWith("/")) ? basePath.slice(0, -1) : basePath || "") + "/v1/templates/{TemplateName}/voice";
                resolvedPath = __resolvedPath(resolvedPath, input, "TemplateName", function () { return input.TemplateName; }, "{TemplateName}", false);
                query = map({
                    version: [, input.Version],
                });
                return [2, new __HttpRequest({
                        protocol: protocol,
                        hostname: hostname,
                        port: port,
                        method: "GET",
                        headers: headers,
                        path: resolvedPath,
                        query: query,
                        body: body,
                    })];
        }
    });
}); };
export var serializeAws_restJson1ListJourneysCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, hostname, _b, protocol, port, basePath, headers, resolvedPath, query, body;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4, context.endpoint()];
            case 1:
                _a = _c.sent(), hostname = _a.hostname, _b = _a.protocol, protocol = _b === void 0 ? "https" : _b, port = _a.port, basePath = _a.path;
                headers = {};
                resolvedPath = "".concat((basePath === null || basePath === void 0 ? void 0 : basePath.endsWith("/")) ? basePath.slice(0, -1) : basePath || "") + "/v1/apps/{ApplicationId}/journeys";
                resolvedPath = __resolvedPath(resolvedPath, input, "ApplicationId", function () { return input.ApplicationId; }, "{ApplicationId}", false);
                query = map({
                    "page-size": [, input.PageSize],
                    token: [, input.Token],
                });
                return [2, new __HttpRequest({
                        protocol: protocol,
                        hostname: hostname,
                        port: port,
                        method: "GET",
                        headers: headers,
                        path: resolvedPath,
                        query: query,
                        body: body,
                    })];
        }
    });
}); };
export var serializeAws_restJson1ListTagsForResourceCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, hostname, _b, protocol, port, basePath, headers, resolvedPath, body;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4, context.endpoint()];
            case 1:
                _a = _c.sent(), hostname = _a.hostname, _b = _a.protocol, protocol = _b === void 0 ? "https" : _b, port = _a.port, basePath = _a.path;
                headers = {};
                resolvedPath = "".concat((basePath === null || basePath === void 0 ? void 0 : basePath.endsWith("/")) ? basePath.slice(0, -1) : basePath || "") + "/v1/tags/{ResourceArn}";
                resolvedPath = __resolvedPath(resolvedPath, input, "ResourceArn", function () { return input.ResourceArn; }, "{ResourceArn}", false);
                return [2, new __HttpRequest({
                        protocol: protocol,
                        hostname: hostname,
                        port: port,
                        method: "GET",
                        headers: headers,
                        path: resolvedPath,
                        body: body,
                    })];
        }
    });
}); };
export var serializeAws_restJson1ListTemplatesCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, hostname, _b, protocol, port, basePath, headers, resolvedPath, query, body;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4, context.endpoint()];
            case 1:
                _a = _c.sent(), hostname = _a.hostname, _b = _a.protocol, protocol = _b === void 0 ? "https" : _b, port = _a.port, basePath = _a.path;
                headers = {};
                resolvedPath = "".concat((basePath === null || basePath === void 0 ? void 0 : basePath.endsWith("/")) ? basePath.slice(0, -1) : basePath || "") + "/v1/templates";
                query = map({
                    "next-token": [, input.NextToken],
                    "page-size": [, input.PageSize],
                    prefix: [, input.Prefix],
                    "template-type": [, input.TemplateType],
                });
                return [2, new __HttpRequest({
                        protocol: protocol,
                        hostname: hostname,
                        port: port,
                        method: "GET",
                        headers: headers,
                        path: resolvedPath,
                        query: query,
                        body: body,
                    })];
        }
    });
}); };
export var serializeAws_restJson1ListTemplateVersionsCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, hostname, _b, protocol, port, basePath, headers, resolvedPath, query, body;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4, context.endpoint()];
            case 1:
                _a = _c.sent(), hostname = _a.hostname, _b = _a.protocol, protocol = _b === void 0 ? "https" : _b, port = _a.port, basePath = _a.path;
                headers = {};
                resolvedPath = "".concat((basePath === null || basePath === void 0 ? void 0 : basePath.endsWith("/")) ? basePath.slice(0, -1) : basePath || "") +
                    "/v1/templates/{TemplateName}/{TemplateType}/versions";
                resolvedPath = __resolvedPath(resolvedPath, input, "TemplateName", function () { return input.TemplateName; }, "{TemplateName}", false);
                resolvedPath = __resolvedPath(resolvedPath, input, "TemplateType", function () { return input.TemplateType; }, "{TemplateType}", false);
                query = map({
                    "next-token": [, input.NextToken],
                    "page-size": [, input.PageSize],
                });
                return [2, new __HttpRequest({
                        protocol: protocol,
                        hostname: hostname,
                        port: port,
                        method: "GET",
                        headers: headers,
                        path: resolvedPath,
                        query: query,
                        body: body,
                    })];
        }
    });
}); };
export var serializeAws_restJson1PhoneNumberValidateCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, hostname, _b, protocol, port, basePath, headers, resolvedPath, body;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4, context.endpoint()];
            case 1:
                _a = _c.sent(), hostname = _a.hostname, _b = _a.protocol, protocol = _b === void 0 ? "https" : _b, port = _a.port, basePath = _a.path;
                headers = {
                    "content-type": "application/json",
                };
                resolvedPath = "".concat((basePath === null || basePath === void 0 ? void 0 : basePath.endsWith("/")) ? basePath.slice(0, -1) : basePath || "") + "/v1/phone/number/validate";
                if (input.NumberValidateRequest !== undefined) {
                    body = serializeAws_restJson1NumberValidateRequest(input.NumberValidateRequest, context);
                }
                if (body === undefined) {
                    body = {};
                }
                body = JSON.stringify(body);
                return [2, new __HttpRequest({
                        protocol: protocol,
                        hostname: hostname,
                        port: port,
                        method: "POST",
                        headers: headers,
                        path: resolvedPath,
                        body: body,
                    })];
        }
    });
}); };
export var serializeAws_restJson1PutEventsCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, hostname, _b, protocol, port, basePath, headers, resolvedPath, body;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4, context.endpoint()];
            case 1:
                _a = _c.sent(), hostname = _a.hostname, _b = _a.protocol, protocol = _b === void 0 ? "https" : _b, port = _a.port, basePath = _a.path;
                headers = {
                    "content-type": "application/json",
                };
                resolvedPath = "".concat((basePath === null || basePath === void 0 ? void 0 : basePath.endsWith("/")) ? basePath.slice(0, -1) : basePath || "") + "/v1/apps/{ApplicationId}/events";
                resolvedPath = __resolvedPath(resolvedPath, input, "ApplicationId", function () { return input.ApplicationId; }, "{ApplicationId}", false);
                if (input.EventsRequest !== undefined) {
                    body = serializeAws_restJson1EventsRequest(input.EventsRequest, context);
                }
                if (body === undefined) {
                    body = {};
                }
                body = JSON.stringify(body);
                return [2, new __HttpRequest({
                        protocol: protocol,
                        hostname: hostname,
                        port: port,
                        method: "POST",
                        headers: headers,
                        path: resolvedPath,
                        body: body,
                    })];
        }
    });
}); };
export var serializeAws_restJson1PutEventStreamCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, hostname, _b, protocol, port, basePath, headers, resolvedPath, body;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4, context.endpoint()];
            case 1:
                _a = _c.sent(), hostname = _a.hostname, _b = _a.protocol, protocol = _b === void 0 ? "https" : _b, port = _a.port, basePath = _a.path;
                headers = {
                    "content-type": "application/json",
                };
                resolvedPath = "".concat((basePath === null || basePath === void 0 ? void 0 : basePath.endsWith("/")) ? basePath.slice(0, -1) : basePath || "") + "/v1/apps/{ApplicationId}/eventstream";
                resolvedPath = __resolvedPath(resolvedPath, input, "ApplicationId", function () { return input.ApplicationId; }, "{ApplicationId}", false);
                if (input.WriteEventStream !== undefined) {
                    body = serializeAws_restJson1WriteEventStream(input.WriteEventStream, context);
                }
                if (body === undefined) {
                    body = {};
                }
                body = JSON.stringify(body);
                return [2, new __HttpRequest({
                        protocol: protocol,
                        hostname: hostname,
                        port: port,
                        method: "POST",
                        headers: headers,
                        path: resolvedPath,
                        body: body,
                    })];
        }
    });
}); };
export var serializeAws_restJson1RemoveAttributesCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, hostname, _b, protocol, port, basePath, headers, resolvedPath, body;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4, context.endpoint()];
            case 1:
                _a = _c.sent(), hostname = _a.hostname, _b = _a.protocol, protocol = _b === void 0 ? "https" : _b, port = _a.port, basePath = _a.path;
                headers = {
                    "content-type": "application/json",
                };
                resolvedPath = "".concat((basePath === null || basePath === void 0 ? void 0 : basePath.endsWith("/")) ? basePath.slice(0, -1) : basePath || "") +
                    "/v1/apps/{ApplicationId}/attributes/{AttributeType}";
                resolvedPath = __resolvedPath(resolvedPath, input, "ApplicationId", function () { return input.ApplicationId; }, "{ApplicationId}", false);
                resolvedPath = __resolvedPath(resolvedPath, input, "AttributeType", function () { return input.AttributeType; }, "{AttributeType}", false);
                if (input.UpdateAttributesRequest !== undefined) {
                    body = serializeAws_restJson1UpdateAttributesRequest(input.UpdateAttributesRequest, context);
                }
                if (body === undefined) {
                    body = {};
                }
                body = JSON.stringify(body);
                return [2, new __HttpRequest({
                        protocol: protocol,
                        hostname: hostname,
                        port: port,
                        method: "PUT",
                        headers: headers,
                        path: resolvedPath,
                        body: body,
                    })];
        }
    });
}); };
export var serializeAws_restJson1SendMessagesCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, hostname, _b, protocol, port, basePath, headers, resolvedPath, body;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4, context.endpoint()];
            case 1:
                _a = _c.sent(), hostname = _a.hostname, _b = _a.protocol, protocol = _b === void 0 ? "https" : _b, port = _a.port, basePath = _a.path;
                headers = {
                    "content-type": "application/json",
                };
                resolvedPath = "".concat((basePath === null || basePath === void 0 ? void 0 : basePath.endsWith("/")) ? basePath.slice(0, -1) : basePath || "") + "/v1/apps/{ApplicationId}/messages";
                resolvedPath = __resolvedPath(resolvedPath, input, "ApplicationId", function () { return input.ApplicationId; }, "{ApplicationId}", false);
                if (input.MessageRequest !== undefined) {
                    body = serializeAws_restJson1MessageRequest(input.MessageRequest, context);
                }
                if (body === undefined) {
                    body = {};
                }
                body = JSON.stringify(body);
                return [2, new __HttpRequest({
                        protocol: protocol,
                        hostname: hostname,
                        port: port,
                        method: "POST",
                        headers: headers,
                        path: resolvedPath,
                        body: body,
                    })];
        }
    });
}); };
export var serializeAws_restJson1SendOTPMessageCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, hostname, _b, protocol, port, basePath, headers, resolvedPath, body;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4, context.endpoint()];
            case 1:
                _a = _c.sent(), hostname = _a.hostname, _b = _a.protocol, protocol = _b === void 0 ? "https" : _b, port = _a.port, basePath = _a.path;
                headers = {
                    "content-type": "application/json",
                };
                resolvedPath = "".concat((basePath === null || basePath === void 0 ? void 0 : basePath.endsWith("/")) ? basePath.slice(0, -1) : basePath || "") + "/v1/apps/{ApplicationId}/otp";
                resolvedPath = __resolvedPath(resolvedPath, input, "ApplicationId", function () { return input.ApplicationId; }, "{ApplicationId}", false);
                if (input.SendOTPMessageRequestParameters !== undefined) {
                    body = serializeAws_restJson1SendOTPMessageRequestParameters(input.SendOTPMessageRequestParameters, context);
                }
                if (body === undefined) {
                    body = {};
                }
                body = JSON.stringify(body);
                return [2, new __HttpRequest({
                        protocol: protocol,
                        hostname: hostname,
                        port: port,
                        method: "POST",
                        headers: headers,
                        path: resolvedPath,
                        body: body,
                    })];
        }
    });
}); };
export var serializeAws_restJson1SendUsersMessagesCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, hostname, _b, protocol, port, basePath, headers, resolvedPath, body;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4, context.endpoint()];
            case 1:
                _a = _c.sent(), hostname = _a.hostname, _b = _a.protocol, protocol = _b === void 0 ? "https" : _b, port = _a.port, basePath = _a.path;
                headers = {
                    "content-type": "application/json",
                };
                resolvedPath = "".concat((basePath === null || basePath === void 0 ? void 0 : basePath.endsWith("/")) ? basePath.slice(0, -1) : basePath || "") + "/v1/apps/{ApplicationId}/users-messages";
                resolvedPath = __resolvedPath(resolvedPath, input, "ApplicationId", function () { return input.ApplicationId; }, "{ApplicationId}", false);
                if (input.SendUsersMessageRequest !== undefined) {
                    body = serializeAws_restJson1SendUsersMessageRequest(input.SendUsersMessageRequest, context);
                }
                if (body === undefined) {
                    body = {};
                }
                body = JSON.stringify(body);
                return [2, new __HttpRequest({
                        protocol: protocol,
                        hostname: hostname,
                        port: port,
                        method: "POST",
                        headers: headers,
                        path: resolvedPath,
                        body: body,
                    })];
        }
    });
}); };
export var serializeAws_restJson1TagResourceCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, hostname, _b, protocol, port, basePath, headers, resolvedPath, body;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4, context.endpoint()];
            case 1:
                _a = _c.sent(), hostname = _a.hostname, _b = _a.protocol, protocol = _b === void 0 ? "https" : _b, port = _a.port, basePath = _a.path;
                headers = {
                    "content-type": "application/json",
                };
                resolvedPath = "".concat((basePath === null || basePath === void 0 ? void 0 : basePath.endsWith("/")) ? basePath.slice(0, -1) : basePath || "") + "/v1/tags/{ResourceArn}";
                resolvedPath = __resolvedPath(resolvedPath, input, "ResourceArn", function () { return input.ResourceArn; }, "{ResourceArn}", false);
                if (input.TagsModel !== undefined) {
                    body = serializeAws_restJson1TagsModel(input.TagsModel, context);
                }
                if (body === undefined) {
                    body = {};
                }
                body = JSON.stringify(body);
                return [2, new __HttpRequest({
                        protocol: protocol,
                        hostname: hostname,
                        port: port,
                        method: "POST",
                        headers: headers,
                        path: resolvedPath,
                        body: body,
                    })];
        }
    });
}); };
export var serializeAws_restJson1UntagResourceCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, hostname, _b, protocol, port, basePath, headers, resolvedPath, query, body;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4, context.endpoint()];
            case 1:
                _a = _c.sent(), hostname = _a.hostname, _b = _a.protocol, protocol = _b === void 0 ? "https" : _b, port = _a.port, basePath = _a.path;
                headers = {};
                resolvedPath = "".concat((basePath === null || basePath === void 0 ? void 0 : basePath.endsWith("/")) ? basePath.slice(0, -1) : basePath || "") + "/v1/tags/{ResourceArn}";
                resolvedPath = __resolvedPath(resolvedPath, input, "ResourceArn", function () { return input.ResourceArn; }, "{ResourceArn}", false);
                query = map({
                    tagKeys: [function () { return input.TagKeys !== void 0; }, function () { return (input.TagKeys || []).map(function (_entry) { return _entry; }); }],
                });
                return [2, new __HttpRequest({
                        protocol: protocol,
                        hostname: hostname,
                        port: port,
                        method: "DELETE",
                        headers: headers,
                        path: resolvedPath,
                        query: query,
                        body: body,
                    })];
        }
    });
}); };
export var serializeAws_restJson1UpdateAdmChannelCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, hostname, _b, protocol, port, basePath, headers, resolvedPath, body;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4, context.endpoint()];
            case 1:
                _a = _c.sent(), hostname = _a.hostname, _b = _a.protocol, protocol = _b === void 0 ? "https" : _b, port = _a.port, basePath = _a.path;
                headers = {
                    "content-type": "application/json",
                };
                resolvedPath = "".concat((basePath === null || basePath === void 0 ? void 0 : basePath.endsWith("/")) ? basePath.slice(0, -1) : basePath || "") + "/v1/apps/{ApplicationId}/channels/adm";
                resolvedPath = __resolvedPath(resolvedPath, input, "ApplicationId", function () { return input.ApplicationId; }, "{ApplicationId}", false);
                if (input.ADMChannelRequest !== undefined) {
                    body = serializeAws_restJson1ADMChannelRequest(input.ADMChannelRequest, context);
                }
                if (body === undefined) {
                    body = {};
                }
                body = JSON.stringify(body);
                return [2, new __HttpRequest({
                        protocol: protocol,
                        hostname: hostname,
                        port: port,
                        method: "PUT",
                        headers: headers,
                        path: resolvedPath,
                        body: body,
                    })];
        }
    });
}); };
export var serializeAws_restJson1UpdateApnsChannelCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, hostname, _b, protocol, port, basePath, headers, resolvedPath, body;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4, context.endpoint()];
            case 1:
                _a = _c.sent(), hostname = _a.hostname, _b = _a.protocol, protocol = _b === void 0 ? "https" : _b, port = _a.port, basePath = _a.path;
                headers = {
                    "content-type": "application/json",
                };
                resolvedPath = "".concat((basePath === null || basePath === void 0 ? void 0 : basePath.endsWith("/")) ? basePath.slice(0, -1) : basePath || "") + "/v1/apps/{ApplicationId}/channels/apns";
                resolvedPath = __resolvedPath(resolvedPath, input, "ApplicationId", function () { return input.ApplicationId; }, "{ApplicationId}", false);
                if (input.APNSChannelRequest !== undefined) {
                    body = serializeAws_restJson1APNSChannelRequest(input.APNSChannelRequest, context);
                }
                if (body === undefined) {
                    body = {};
                }
                body = JSON.stringify(body);
                return [2, new __HttpRequest({
                        protocol: protocol,
                        hostname: hostname,
                        port: port,
                        method: "PUT",
                        headers: headers,
                        path: resolvedPath,
                        body: body,
                    })];
        }
    });
}); };
export var serializeAws_restJson1UpdateApnsSandboxChannelCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, hostname, _b, protocol, port, basePath, headers, resolvedPath, body;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4, context.endpoint()];
            case 1:
                _a = _c.sent(), hostname = _a.hostname, _b = _a.protocol, protocol = _b === void 0 ? "https" : _b, port = _a.port, basePath = _a.path;
                headers = {
                    "content-type": "application/json",
                };
                resolvedPath = "".concat((basePath === null || basePath === void 0 ? void 0 : basePath.endsWith("/")) ? basePath.slice(0, -1) : basePath || "") +
                    "/v1/apps/{ApplicationId}/channels/apns_sandbox";
                resolvedPath = __resolvedPath(resolvedPath, input, "ApplicationId", function () { return input.ApplicationId; }, "{ApplicationId}", false);
                if (input.APNSSandboxChannelRequest !== undefined) {
                    body = serializeAws_restJson1APNSSandboxChannelRequest(input.APNSSandboxChannelRequest, context);
                }
                if (body === undefined) {
                    body = {};
                }
                body = JSON.stringify(body);
                return [2, new __HttpRequest({
                        protocol: protocol,
                        hostname: hostname,
                        port: port,
                        method: "PUT",
                        headers: headers,
                        path: resolvedPath,
                        body: body,
                    })];
        }
    });
}); };
export var serializeAws_restJson1UpdateApnsVoipChannelCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, hostname, _b, protocol, port, basePath, headers, resolvedPath, body;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4, context.endpoint()];
            case 1:
                _a = _c.sent(), hostname = _a.hostname, _b = _a.protocol, protocol = _b === void 0 ? "https" : _b, port = _a.port, basePath = _a.path;
                headers = {
                    "content-type": "application/json",
                };
                resolvedPath = "".concat((basePath === null || basePath === void 0 ? void 0 : basePath.endsWith("/")) ? basePath.slice(0, -1) : basePath || "") +
                    "/v1/apps/{ApplicationId}/channels/apns_voip";
                resolvedPath = __resolvedPath(resolvedPath, input, "ApplicationId", function () { return input.ApplicationId; }, "{ApplicationId}", false);
                if (input.APNSVoipChannelRequest !== undefined) {
                    body = serializeAws_restJson1APNSVoipChannelRequest(input.APNSVoipChannelRequest, context);
                }
                if (body === undefined) {
                    body = {};
                }
                body = JSON.stringify(body);
                return [2, new __HttpRequest({
                        protocol: protocol,
                        hostname: hostname,
                        port: port,
                        method: "PUT",
                        headers: headers,
                        path: resolvedPath,
                        body: body,
                    })];
        }
    });
}); };
export var serializeAws_restJson1UpdateApnsVoipSandboxChannelCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, hostname, _b, protocol, port, basePath, headers, resolvedPath, body;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4, context.endpoint()];
            case 1:
                _a = _c.sent(), hostname = _a.hostname, _b = _a.protocol, protocol = _b === void 0 ? "https" : _b, port = _a.port, basePath = _a.path;
                headers = {
                    "content-type": "application/json",
                };
                resolvedPath = "".concat((basePath === null || basePath === void 0 ? void 0 : basePath.endsWith("/")) ? basePath.slice(0, -1) : basePath || "") +
                    "/v1/apps/{ApplicationId}/channels/apns_voip_sandbox";
                resolvedPath = __resolvedPath(resolvedPath, input, "ApplicationId", function () { return input.ApplicationId; }, "{ApplicationId}", false);
                if (input.APNSVoipSandboxChannelRequest !== undefined) {
                    body = serializeAws_restJson1APNSVoipSandboxChannelRequest(input.APNSVoipSandboxChannelRequest, context);
                }
                if (body === undefined) {
                    body = {};
                }
                body = JSON.stringify(body);
                return [2, new __HttpRequest({
                        protocol: protocol,
                        hostname: hostname,
                        port: port,
                        method: "PUT",
                        headers: headers,
                        path: resolvedPath,
                        body: body,
                    })];
        }
    });
}); };
export var serializeAws_restJson1UpdateApplicationSettingsCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, hostname, _b, protocol, port, basePath, headers, resolvedPath, body;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4, context.endpoint()];
            case 1:
                _a = _c.sent(), hostname = _a.hostname, _b = _a.protocol, protocol = _b === void 0 ? "https" : _b, port = _a.port, basePath = _a.path;
                headers = {
                    "content-type": "application/json",
                };
                resolvedPath = "".concat((basePath === null || basePath === void 0 ? void 0 : basePath.endsWith("/")) ? basePath.slice(0, -1) : basePath || "") + "/v1/apps/{ApplicationId}/settings";
                resolvedPath = __resolvedPath(resolvedPath, input, "ApplicationId", function () { return input.ApplicationId; }, "{ApplicationId}", false);
                if (input.WriteApplicationSettingsRequest !== undefined) {
                    body = serializeAws_restJson1WriteApplicationSettingsRequest(input.WriteApplicationSettingsRequest, context);
                }
                if (body === undefined) {
                    body = {};
                }
                body = JSON.stringify(body);
                return [2, new __HttpRequest({
                        protocol: protocol,
                        hostname: hostname,
                        port: port,
                        method: "PUT",
                        headers: headers,
                        path: resolvedPath,
                        body: body,
                    })];
        }
    });
}); };
export var serializeAws_restJson1UpdateBaiduChannelCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, hostname, _b, protocol, port, basePath, headers, resolvedPath, body;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4, context.endpoint()];
            case 1:
                _a = _c.sent(), hostname = _a.hostname, _b = _a.protocol, protocol = _b === void 0 ? "https" : _b, port = _a.port, basePath = _a.path;
                headers = {
                    "content-type": "application/json",
                };
                resolvedPath = "".concat((basePath === null || basePath === void 0 ? void 0 : basePath.endsWith("/")) ? basePath.slice(0, -1) : basePath || "") + "/v1/apps/{ApplicationId}/channels/baidu";
                resolvedPath = __resolvedPath(resolvedPath, input, "ApplicationId", function () { return input.ApplicationId; }, "{ApplicationId}", false);
                if (input.BaiduChannelRequest !== undefined) {
                    body = serializeAws_restJson1BaiduChannelRequest(input.BaiduChannelRequest, context);
                }
                if (body === undefined) {
                    body = {};
                }
                body = JSON.stringify(body);
                return [2, new __HttpRequest({
                        protocol: protocol,
                        hostname: hostname,
                        port: port,
                        method: "PUT",
                        headers: headers,
                        path: resolvedPath,
                        body: body,
                    })];
        }
    });
}); };
export var serializeAws_restJson1UpdateCampaignCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, hostname, _b, protocol, port, basePath, headers, resolvedPath, body;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4, context.endpoint()];
            case 1:
                _a = _c.sent(), hostname = _a.hostname, _b = _a.protocol, protocol = _b === void 0 ? "https" : _b, port = _a.port, basePath = _a.path;
                headers = {
                    "content-type": "application/json",
                };
                resolvedPath = "".concat((basePath === null || basePath === void 0 ? void 0 : basePath.endsWith("/")) ? basePath.slice(0, -1) : basePath || "") +
                    "/v1/apps/{ApplicationId}/campaigns/{CampaignId}";
                resolvedPath = __resolvedPath(resolvedPath, input, "ApplicationId", function () { return input.ApplicationId; }, "{ApplicationId}", false);
                resolvedPath = __resolvedPath(resolvedPath, input, "CampaignId", function () { return input.CampaignId; }, "{CampaignId}", false);
                if (input.WriteCampaignRequest !== undefined) {
                    body = serializeAws_restJson1WriteCampaignRequest(input.WriteCampaignRequest, context);
                }
                if (body === undefined) {
                    body = {};
                }
                body = JSON.stringify(body);
                return [2, new __HttpRequest({
                        protocol: protocol,
                        hostname: hostname,
                        port: port,
                        method: "PUT",
                        headers: headers,
                        path: resolvedPath,
                        body: body,
                    })];
        }
    });
}); };
export var serializeAws_restJson1UpdateEmailChannelCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, hostname, _b, protocol, port, basePath, headers, resolvedPath, body;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4, context.endpoint()];
            case 1:
                _a = _c.sent(), hostname = _a.hostname, _b = _a.protocol, protocol = _b === void 0 ? "https" : _b, port = _a.port, basePath = _a.path;
                headers = {
                    "content-type": "application/json",
                };
                resolvedPath = "".concat((basePath === null || basePath === void 0 ? void 0 : basePath.endsWith("/")) ? basePath.slice(0, -1) : basePath || "") + "/v1/apps/{ApplicationId}/channels/email";
                resolvedPath = __resolvedPath(resolvedPath, input, "ApplicationId", function () { return input.ApplicationId; }, "{ApplicationId}", false);
                if (input.EmailChannelRequest !== undefined) {
                    body = serializeAws_restJson1EmailChannelRequest(input.EmailChannelRequest, context);
                }
                if (body === undefined) {
                    body = {};
                }
                body = JSON.stringify(body);
                return [2, new __HttpRequest({
                        protocol: protocol,
                        hostname: hostname,
                        port: port,
                        method: "PUT",
                        headers: headers,
                        path: resolvedPath,
                        body: body,
                    })];
        }
    });
}); };
export var serializeAws_restJson1UpdateEmailTemplateCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, hostname, _b, protocol, port, basePath, headers, resolvedPath, query, body;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4, context.endpoint()];
            case 1:
                _a = _c.sent(), hostname = _a.hostname, _b = _a.protocol, protocol = _b === void 0 ? "https" : _b, port = _a.port, basePath = _a.path;
                headers = {
                    "content-type": "application/json",
                };
                resolvedPath = "".concat((basePath === null || basePath === void 0 ? void 0 : basePath.endsWith("/")) ? basePath.slice(0, -1) : basePath || "") + "/v1/templates/{TemplateName}/email";
                resolvedPath = __resolvedPath(resolvedPath, input, "TemplateName", function () { return input.TemplateName; }, "{TemplateName}", false);
                query = map({
                    "create-new-version": [function () { return input.CreateNewVersion !== void 0; }, function () { return input.CreateNewVersion.toString(); }],
                    version: [, input.Version],
                });
                if (input.EmailTemplateRequest !== undefined) {
                    body = serializeAws_restJson1EmailTemplateRequest(input.EmailTemplateRequest, context);
                }
                if (body === undefined) {
                    body = {};
                }
                body = JSON.stringify(body);
                return [2, new __HttpRequest({
                        protocol: protocol,
                        hostname: hostname,
                        port: port,
                        method: "PUT",
                        headers: headers,
                        path: resolvedPath,
                        query: query,
                        body: body,
                    })];
        }
    });
}); };
export var serializeAws_restJson1UpdateEndpointCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, hostname, _b, protocol, port, basePath, headers, resolvedPath, body;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4, context.endpoint()];
            case 1:
                _a = _c.sent(), hostname = _a.hostname, _b = _a.protocol, protocol = _b === void 0 ? "https" : _b, port = _a.port, basePath = _a.path;
                headers = {
                    "content-type": "application/json",
                };
                resolvedPath = "".concat((basePath === null || basePath === void 0 ? void 0 : basePath.endsWith("/")) ? basePath.slice(0, -1) : basePath || "") +
                    "/v1/apps/{ApplicationId}/endpoints/{EndpointId}";
                resolvedPath = __resolvedPath(resolvedPath, input, "ApplicationId", function () { return input.ApplicationId; }, "{ApplicationId}", false);
                resolvedPath = __resolvedPath(resolvedPath, input, "EndpointId", function () { return input.EndpointId; }, "{EndpointId}", false);
                if (input.EndpointRequest !== undefined) {
                    body = serializeAws_restJson1EndpointRequest(input.EndpointRequest, context);
                }
                if (body === undefined) {
                    body = {};
                }
                body = JSON.stringify(body);
                return [2, new __HttpRequest({
                        protocol: protocol,
                        hostname: hostname,
                        port: port,
                        method: "PUT",
                        headers: headers,
                        path: resolvedPath,
                        body: body,
                    })];
        }
    });
}); };
export var serializeAws_restJson1UpdateEndpointsBatchCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, hostname, _b, protocol, port, basePath, headers, resolvedPath, body;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4, context.endpoint()];
            case 1:
                _a = _c.sent(), hostname = _a.hostname, _b = _a.protocol, protocol = _b === void 0 ? "https" : _b, port = _a.port, basePath = _a.path;
                headers = {
                    "content-type": "application/json",
                };
                resolvedPath = "".concat((basePath === null || basePath === void 0 ? void 0 : basePath.endsWith("/")) ? basePath.slice(0, -1) : basePath || "") + "/v1/apps/{ApplicationId}/endpoints";
                resolvedPath = __resolvedPath(resolvedPath, input, "ApplicationId", function () { return input.ApplicationId; }, "{ApplicationId}", false);
                if (input.EndpointBatchRequest !== undefined) {
                    body = serializeAws_restJson1EndpointBatchRequest(input.EndpointBatchRequest, context);
                }
                if (body === undefined) {
                    body = {};
                }
                body = JSON.stringify(body);
                return [2, new __HttpRequest({
                        protocol: protocol,
                        hostname: hostname,
                        port: port,
                        method: "PUT",
                        headers: headers,
                        path: resolvedPath,
                        body: body,
                    })];
        }
    });
}); };
export var serializeAws_restJson1UpdateGcmChannelCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, hostname, _b, protocol, port, basePath, headers, resolvedPath, body;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4, context.endpoint()];
            case 1:
                _a = _c.sent(), hostname = _a.hostname, _b = _a.protocol, protocol = _b === void 0 ? "https" : _b, port = _a.port, basePath = _a.path;
                headers = {
                    "content-type": "application/json",
                };
                resolvedPath = "".concat((basePath === null || basePath === void 0 ? void 0 : basePath.endsWith("/")) ? basePath.slice(0, -1) : basePath || "") + "/v1/apps/{ApplicationId}/channels/gcm";
                resolvedPath = __resolvedPath(resolvedPath, input, "ApplicationId", function () { return input.ApplicationId; }, "{ApplicationId}", false);
                if (input.GCMChannelRequest !== undefined) {
                    body = serializeAws_restJson1GCMChannelRequest(input.GCMChannelRequest, context);
                }
                if (body === undefined) {
                    body = {};
                }
                body = JSON.stringify(body);
                return [2, new __HttpRequest({
                        protocol: protocol,
                        hostname: hostname,
                        port: port,
                        method: "PUT",
                        headers: headers,
                        path: resolvedPath,
                        body: body,
                    })];
        }
    });
}); };
export var serializeAws_restJson1UpdateInAppTemplateCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, hostname, _b, protocol, port, basePath, headers, resolvedPath, query, body;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4, context.endpoint()];
            case 1:
                _a = _c.sent(), hostname = _a.hostname, _b = _a.protocol, protocol = _b === void 0 ? "https" : _b, port = _a.port, basePath = _a.path;
                headers = {
                    "content-type": "application/json",
                };
                resolvedPath = "".concat((basePath === null || basePath === void 0 ? void 0 : basePath.endsWith("/")) ? basePath.slice(0, -1) : basePath || "") + "/v1/templates/{TemplateName}/inapp";
                resolvedPath = __resolvedPath(resolvedPath, input, "TemplateName", function () { return input.TemplateName; }, "{TemplateName}", false);
                query = map({
                    "create-new-version": [function () { return input.CreateNewVersion !== void 0; }, function () { return input.CreateNewVersion.toString(); }],
                    version: [, input.Version],
                });
                if (input.InAppTemplateRequest !== undefined) {
                    body = serializeAws_restJson1InAppTemplateRequest(input.InAppTemplateRequest, context);
                }
                if (body === undefined) {
                    body = {};
                }
                body = JSON.stringify(body);
                return [2, new __HttpRequest({
                        protocol: protocol,
                        hostname: hostname,
                        port: port,
                        method: "PUT",
                        headers: headers,
                        path: resolvedPath,
                        query: query,
                        body: body,
                    })];
        }
    });
}); };
export var serializeAws_restJson1UpdateJourneyCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, hostname, _b, protocol, port, basePath, headers, resolvedPath, body;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4, context.endpoint()];
            case 1:
                _a = _c.sent(), hostname = _a.hostname, _b = _a.protocol, protocol = _b === void 0 ? "https" : _b, port = _a.port, basePath = _a.path;
                headers = {
                    "content-type": "application/json",
                };
                resolvedPath = "".concat((basePath === null || basePath === void 0 ? void 0 : basePath.endsWith("/")) ? basePath.slice(0, -1) : basePath || "") +
                    "/v1/apps/{ApplicationId}/journeys/{JourneyId}";
                resolvedPath = __resolvedPath(resolvedPath, input, "ApplicationId", function () { return input.ApplicationId; }, "{ApplicationId}", false);
                resolvedPath = __resolvedPath(resolvedPath, input, "JourneyId", function () { return input.JourneyId; }, "{JourneyId}", false);
                if (input.WriteJourneyRequest !== undefined) {
                    body = serializeAws_restJson1WriteJourneyRequest(input.WriteJourneyRequest, context);
                }
                if (body === undefined) {
                    body = {};
                }
                body = JSON.stringify(body);
                return [2, new __HttpRequest({
                        protocol: protocol,
                        hostname: hostname,
                        port: port,
                        method: "PUT",
                        headers: headers,
                        path: resolvedPath,
                        body: body,
                    })];
        }
    });
}); };
export var serializeAws_restJson1UpdateJourneyStateCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, hostname, _b, protocol, port, basePath, headers, resolvedPath, body;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4, context.endpoint()];
            case 1:
                _a = _c.sent(), hostname = _a.hostname, _b = _a.protocol, protocol = _b === void 0 ? "https" : _b, port = _a.port, basePath = _a.path;
                headers = {
                    "content-type": "application/json",
                };
                resolvedPath = "".concat((basePath === null || basePath === void 0 ? void 0 : basePath.endsWith("/")) ? basePath.slice(0, -1) : basePath || "") +
                    "/v1/apps/{ApplicationId}/journeys/{JourneyId}/state";
                resolvedPath = __resolvedPath(resolvedPath, input, "ApplicationId", function () { return input.ApplicationId; }, "{ApplicationId}", false);
                resolvedPath = __resolvedPath(resolvedPath, input, "JourneyId", function () { return input.JourneyId; }, "{JourneyId}", false);
                if (input.JourneyStateRequest !== undefined) {
                    body = serializeAws_restJson1JourneyStateRequest(input.JourneyStateRequest, context);
                }
                if (body === undefined) {
                    body = {};
                }
                body = JSON.stringify(body);
                return [2, new __HttpRequest({
                        protocol: protocol,
                        hostname: hostname,
                        port: port,
                        method: "PUT",
                        headers: headers,
                        path: resolvedPath,
                        body: body,
                    })];
        }
    });
}); };
export var serializeAws_restJson1UpdatePushTemplateCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, hostname, _b, protocol, port, basePath, headers, resolvedPath, query, body;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4, context.endpoint()];
            case 1:
                _a = _c.sent(), hostname = _a.hostname, _b = _a.protocol, protocol = _b === void 0 ? "https" : _b, port = _a.port, basePath = _a.path;
                headers = {
                    "content-type": "application/json",
                };
                resolvedPath = "".concat((basePath === null || basePath === void 0 ? void 0 : basePath.endsWith("/")) ? basePath.slice(0, -1) : basePath || "") + "/v1/templates/{TemplateName}/push";
                resolvedPath = __resolvedPath(resolvedPath, input, "TemplateName", function () { return input.TemplateName; }, "{TemplateName}", false);
                query = map({
                    "create-new-version": [function () { return input.CreateNewVersion !== void 0; }, function () { return input.CreateNewVersion.toString(); }],
                    version: [, input.Version],
                });
                if (input.PushNotificationTemplateRequest !== undefined) {
                    body = serializeAws_restJson1PushNotificationTemplateRequest(input.PushNotificationTemplateRequest, context);
                }
                if (body === undefined) {
                    body = {};
                }
                body = JSON.stringify(body);
                return [2, new __HttpRequest({
                        protocol: protocol,
                        hostname: hostname,
                        port: port,
                        method: "PUT",
                        headers: headers,
                        path: resolvedPath,
                        query: query,
                        body: body,
                    })];
        }
    });
}); };
export var serializeAws_restJson1UpdateRecommenderConfigurationCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, hostname, _b, protocol, port, basePath, headers, resolvedPath, body;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4, context.endpoint()];
            case 1:
                _a = _c.sent(), hostname = _a.hostname, _b = _a.protocol, protocol = _b === void 0 ? "https" : _b, port = _a.port, basePath = _a.path;
                headers = {
                    "content-type": "application/json",
                };
                resolvedPath = "".concat((basePath === null || basePath === void 0 ? void 0 : basePath.endsWith("/")) ? basePath.slice(0, -1) : basePath || "") + "/v1/recommenders/{RecommenderId}";
                resolvedPath = __resolvedPath(resolvedPath, input, "RecommenderId", function () { return input.RecommenderId; }, "{RecommenderId}", false);
                if (input.UpdateRecommenderConfiguration !== undefined) {
                    body = serializeAws_restJson1UpdateRecommenderConfigurationShape(input.UpdateRecommenderConfiguration, context);
                }
                if (body === undefined) {
                    body = {};
                }
                body = JSON.stringify(body);
                return [2, new __HttpRequest({
                        protocol: protocol,
                        hostname: hostname,
                        port: port,
                        method: "PUT",
                        headers: headers,
                        path: resolvedPath,
                        body: body,
                    })];
        }
    });
}); };
export var serializeAws_restJson1UpdateSegmentCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, hostname, _b, protocol, port, basePath, headers, resolvedPath, body;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4, context.endpoint()];
            case 1:
                _a = _c.sent(), hostname = _a.hostname, _b = _a.protocol, protocol = _b === void 0 ? "https" : _b, port = _a.port, basePath = _a.path;
                headers = {
                    "content-type": "application/json",
                };
                resolvedPath = "".concat((basePath === null || basePath === void 0 ? void 0 : basePath.endsWith("/")) ? basePath.slice(0, -1) : basePath || "") +
                    "/v1/apps/{ApplicationId}/segments/{SegmentId}";
                resolvedPath = __resolvedPath(resolvedPath, input, "ApplicationId", function () { return input.ApplicationId; }, "{ApplicationId}", false);
                resolvedPath = __resolvedPath(resolvedPath, input, "SegmentId", function () { return input.SegmentId; }, "{SegmentId}", false);
                if (input.WriteSegmentRequest !== undefined) {
                    body = serializeAws_restJson1WriteSegmentRequest(input.WriteSegmentRequest, context);
                }
                if (body === undefined) {
                    body = {};
                }
                body = JSON.stringify(body);
                return [2, new __HttpRequest({
                        protocol: protocol,
                        hostname: hostname,
                        port: port,
                        method: "PUT",
                        headers: headers,
                        path: resolvedPath,
                        body: body,
                    })];
        }
    });
}); };
export var serializeAws_restJson1UpdateSmsChannelCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, hostname, _b, protocol, port, basePath, headers, resolvedPath, body;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4, context.endpoint()];
            case 1:
                _a = _c.sent(), hostname = _a.hostname, _b = _a.protocol, protocol = _b === void 0 ? "https" : _b, port = _a.port, basePath = _a.path;
                headers = {
                    "content-type": "application/json",
                };
                resolvedPath = "".concat((basePath === null || basePath === void 0 ? void 0 : basePath.endsWith("/")) ? basePath.slice(0, -1) : basePath || "") + "/v1/apps/{ApplicationId}/channels/sms";
                resolvedPath = __resolvedPath(resolvedPath, input, "ApplicationId", function () { return input.ApplicationId; }, "{ApplicationId}", false);
                if (input.SMSChannelRequest !== undefined) {
                    body = serializeAws_restJson1SMSChannelRequest(input.SMSChannelRequest, context);
                }
                if (body === undefined) {
                    body = {};
                }
                body = JSON.stringify(body);
                return [2, new __HttpRequest({
                        protocol: protocol,
                        hostname: hostname,
                        port: port,
                        method: "PUT",
                        headers: headers,
                        path: resolvedPath,
                        body: body,
                    })];
        }
    });
}); };
export var serializeAws_restJson1UpdateSmsTemplateCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, hostname, _b, protocol, port, basePath, headers, resolvedPath, query, body;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4, context.endpoint()];
            case 1:
                _a = _c.sent(), hostname = _a.hostname, _b = _a.protocol, protocol = _b === void 0 ? "https" : _b, port = _a.port, basePath = _a.path;
                headers = {
                    "content-type": "application/json",
                };
                resolvedPath = "".concat((basePath === null || basePath === void 0 ? void 0 : basePath.endsWith("/")) ? basePath.slice(0, -1) : basePath || "") + "/v1/templates/{TemplateName}/sms";
                resolvedPath = __resolvedPath(resolvedPath, input, "TemplateName", function () { return input.TemplateName; }, "{TemplateName}", false);
                query = map({
                    "create-new-version": [function () { return input.CreateNewVersion !== void 0; }, function () { return input.CreateNewVersion.toString(); }],
                    version: [, input.Version],
                });
                if (input.SMSTemplateRequest !== undefined) {
                    body = serializeAws_restJson1SMSTemplateRequest(input.SMSTemplateRequest, context);
                }
                if (body === undefined) {
                    body = {};
                }
                body = JSON.stringify(body);
                return [2, new __HttpRequest({
                        protocol: protocol,
                        hostname: hostname,
                        port: port,
                        method: "PUT",
                        headers: headers,
                        path: resolvedPath,
                        query: query,
                        body: body,
                    })];
        }
    });
}); };
export var serializeAws_restJson1UpdateTemplateActiveVersionCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, hostname, _b, protocol, port, basePath, headers, resolvedPath, body;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4, context.endpoint()];
            case 1:
                _a = _c.sent(), hostname = _a.hostname, _b = _a.protocol, protocol = _b === void 0 ? "https" : _b, port = _a.port, basePath = _a.path;
                headers = {
                    "content-type": "application/json",
                };
                resolvedPath = "".concat((basePath === null || basePath === void 0 ? void 0 : basePath.endsWith("/")) ? basePath.slice(0, -1) : basePath || "") +
                    "/v1/templates/{TemplateName}/{TemplateType}/active-version";
                resolvedPath = __resolvedPath(resolvedPath, input, "TemplateName", function () { return input.TemplateName; }, "{TemplateName}", false);
                resolvedPath = __resolvedPath(resolvedPath, input, "TemplateType", function () { return input.TemplateType; }, "{TemplateType}", false);
                if (input.TemplateActiveVersionRequest !== undefined) {
                    body = serializeAws_restJson1TemplateActiveVersionRequest(input.TemplateActiveVersionRequest, context);
                }
                if (body === undefined) {
                    body = {};
                }
                body = JSON.stringify(body);
                return [2, new __HttpRequest({
                        protocol: protocol,
                        hostname: hostname,
                        port: port,
                        method: "PUT",
                        headers: headers,
                        path: resolvedPath,
                        body: body,
                    })];
        }
    });
}); };
export var serializeAws_restJson1UpdateVoiceChannelCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, hostname, _b, protocol, port, basePath, headers, resolvedPath, body;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4, context.endpoint()];
            case 1:
                _a = _c.sent(), hostname = _a.hostname, _b = _a.protocol, protocol = _b === void 0 ? "https" : _b, port = _a.port, basePath = _a.path;
                headers = {
                    "content-type": "application/json",
                };
                resolvedPath = "".concat((basePath === null || basePath === void 0 ? void 0 : basePath.endsWith("/")) ? basePath.slice(0, -1) : basePath || "") + "/v1/apps/{ApplicationId}/channels/voice";
                resolvedPath = __resolvedPath(resolvedPath, input, "ApplicationId", function () { return input.ApplicationId; }, "{ApplicationId}", false);
                if (input.VoiceChannelRequest !== undefined) {
                    body = serializeAws_restJson1VoiceChannelRequest(input.VoiceChannelRequest, context);
                }
                if (body === undefined) {
                    body = {};
                }
                body = JSON.stringify(body);
                return [2, new __HttpRequest({
                        protocol: protocol,
                        hostname: hostname,
                        port: port,
                        method: "PUT",
                        headers: headers,
                        path: resolvedPath,
                        body: body,
                    })];
        }
    });
}); };
export var serializeAws_restJson1UpdateVoiceTemplateCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, hostname, _b, protocol, port, basePath, headers, resolvedPath, query, body;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4, context.endpoint()];
            case 1:
                _a = _c.sent(), hostname = _a.hostname, _b = _a.protocol, protocol = _b === void 0 ? "https" : _b, port = _a.port, basePath = _a.path;
                headers = {
                    "content-type": "application/json",
                };
                resolvedPath = "".concat((basePath === null || basePath === void 0 ? void 0 : basePath.endsWith("/")) ? basePath.slice(0, -1) : basePath || "") + "/v1/templates/{TemplateName}/voice";
                resolvedPath = __resolvedPath(resolvedPath, input, "TemplateName", function () { return input.TemplateName; }, "{TemplateName}", false);
                query = map({
                    "create-new-version": [function () { return input.CreateNewVersion !== void 0; }, function () { return input.CreateNewVersion.toString(); }],
                    version: [, input.Version],
                });
                if (input.VoiceTemplateRequest !== undefined) {
                    body = serializeAws_restJson1VoiceTemplateRequest(input.VoiceTemplateRequest, context);
                }
                if (body === undefined) {
                    body = {};
                }
                body = JSON.stringify(body);
                return [2, new __HttpRequest({
                        protocol: protocol,
                        hostname: hostname,
                        port: port,
                        method: "PUT",
                        headers: headers,
                        path: resolvedPath,
                        query: query,
                        body: body,
                    })];
        }
    });
}); };
export var serializeAws_restJson1VerifyOTPMessageCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, hostname, _b, protocol, port, basePath, headers, resolvedPath, body;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4, context.endpoint()];
            case 1:
                _a = _c.sent(), hostname = _a.hostname, _b = _a.protocol, protocol = _b === void 0 ? "https" : _b, port = _a.port, basePath = _a.path;
                headers = {
                    "content-type": "application/json",
                };
                resolvedPath = "".concat((basePath === null || basePath === void 0 ? void 0 : basePath.endsWith("/")) ? basePath.slice(0, -1) : basePath || "") + "/v1/apps/{ApplicationId}/verify-otp";
                resolvedPath = __resolvedPath(resolvedPath, input, "ApplicationId", function () { return input.ApplicationId; }, "{ApplicationId}", false);
                if (input.VerifyOTPMessageRequestParameters !== undefined) {
                    body = serializeAws_restJson1VerifyOTPMessageRequestParameters(input.VerifyOTPMessageRequestParameters, context);
                }
                if (body === undefined) {
                    body = {};
                }
                body = JSON.stringify(body);
                return [2, new __HttpRequest({
                        protocol: protocol,
                        hostname: hostname,
                        port: port,
                        method: "POST",
                        headers: headers,
                        path: resolvedPath,
                        body: body,
                    })];
        }
    });
}); };
export var deserializeAws_restJson1CreateAppCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var contents, data, _a;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                if (output.statusCode !== 201 && output.statusCode >= 300) {
                    return [2, deserializeAws_restJson1CreateAppCommandError(output, context)];
                }
                contents = map({
                    $metadata: deserializeMetadata(output),
                });
                _a = __expectObject;
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.apply(void 0, [_b.sent()]);
                contents.ApplicationResponse = deserializeAws_restJson1ApplicationResponse(data, context);
                return [2, contents];
        }
    });
}); };
var deserializeAws_restJson1CreateAppCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, errorCode, _b, parsedBody;
    var _c;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                _a = [__assign({}, output)];
                _c = {};
                return [4, parseErrorBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_c.body = _d.sent(), _c)]));
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "BadRequestException": return [3, 2];
                    case "com.amazonaws.pinpoint#BadRequestException": return [3, 2];
                    case "ForbiddenException": return [3, 4];
                    case "com.amazonaws.pinpoint#ForbiddenException": return [3, 4];
                    case "InternalServerErrorException": return [3, 6];
                    case "com.amazonaws.pinpoint#InternalServerErrorException": return [3, 6];
                    case "MethodNotAllowedException": return [3, 8];
                    case "com.amazonaws.pinpoint#MethodNotAllowedException": return [3, 8];
                    case "NotFoundException": return [3, 10];
                    case "com.amazonaws.pinpoint#NotFoundException": return [3, 10];
                    case "PayloadTooLargeException": return [3, 12];
                    case "com.amazonaws.pinpoint#PayloadTooLargeException": return [3, 12];
                    case "TooManyRequestsException": return [3, 14];
                    case "com.amazonaws.pinpoint#TooManyRequestsException": return [3, 14];
                }
                return [3, 16];
            case 2: return [4, deserializeAws_restJson1BadRequestExceptionResponse(parsedOutput, context)];
            case 3: throw _d.sent();
            case 4: return [4, deserializeAws_restJson1ForbiddenExceptionResponse(parsedOutput, context)];
            case 5: throw _d.sent();
            case 6: return [4, deserializeAws_restJson1InternalServerErrorExceptionResponse(parsedOutput, context)];
            case 7: throw _d.sent();
            case 8: return [4, deserializeAws_restJson1MethodNotAllowedExceptionResponse(parsedOutput, context)];
            case 9: throw _d.sent();
            case 10: return [4, deserializeAws_restJson1NotFoundExceptionResponse(parsedOutput, context)];
            case 11: throw _d.sent();
            case 12: return [4, deserializeAws_restJson1PayloadTooLargeExceptionResponse(parsedOutput, context)];
            case 13: throw _d.sent();
            case 14: return [4, deserializeAws_restJson1TooManyRequestsExceptionResponse(parsedOutput, context)];
            case 15: throw _d.sent();
            case 16:
                parsedBody = parsedOutput.body;
                throwDefaultError({
                    output: output,
                    parsedBody: parsedBody,
                    exceptionCtor: __BaseException,
                    errorCode: errorCode,
                });
                _d.label = 17;
            case 17: return [2];
        }
    });
}); };
export var deserializeAws_restJson1CreateCampaignCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var contents, data, _a;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                if (output.statusCode !== 201 && output.statusCode >= 300) {
                    return [2, deserializeAws_restJson1CreateCampaignCommandError(output, context)];
                }
                contents = map({
                    $metadata: deserializeMetadata(output),
                });
                _a = __expectObject;
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.apply(void 0, [_b.sent()]);
                contents.CampaignResponse = deserializeAws_restJson1CampaignResponse(data, context);
                return [2, contents];
        }
    });
}); };
var deserializeAws_restJson1CreateCampaignCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, errorCode, _b, parsedBody;
    var _c;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                _a = [__assign({}, output)];
                _c = {};
                return [4, parseErrorBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_c.body = _d.sent(), _c)]));
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "BadRequestException": return [3, 2];
                    case "com.amazonaws.pinpoint#BadRequestException": return [3, 2];
                    case "ForbiddenException": return [3, 4];
                    case "com.amazonaws.pinpoint#ForbiddenException": return [3, 4];
                    case "InternalServerErrorException": return [3, 6];
                    case "com.amazonaws.pinpoint#InternalServerErrorException": return [3, 6];
                    case "MethodNotAllowedException": return [3, 8];
                    case "com.amazonaws.pinpoint#MethodNotAllowedException": return [3, 8];
                    case "NotFoundException": return [3, 10];
                    case "com.amazonaws.pinpoint#NotFoundException": return [3, 10];
                    case "PayloadTooLargeException": return [3, 12];
                    case "com.amazonaws.pinpoint#PayloadTooLargeException": return [3, 12];
                    case "TooManyRequestsException": return [3, 14];
                    case "com.amazonaws.pinpoint#TooManyRequestsException": return [3, 14];
                }
                return [3, 16];
            case 2: return [4, deserializeAws_restJson1BadRequestExceptionResponse(parsedOutput, context)];
            case 3: throw _d.sent();
            case 4: return [4, deserializeAws_restJson1ForbiddenExceptionResponse(parsedOutput, context)];
            case 5: throw _d.sent();
            case 6: return [4, deserializeAws_restJson1InternalServerErrorExceptionResponse(parsedOutput, context)];
            case 7: throw _d.sent();
            case 8: return [4, deserializeAws_restJson1MethodNotAllowedExceptionResponse(parsedOutput, context)];
            case 9: throw _d.sent();
            case 10: return [4, deserializeAws_restJson1NotFoundExceptionResponse(parsedOutput, context)];
            case 11: throw _d.sent();
            case 12: return [4, deserializeAws_restJson1PayloadTooLargeExceptionResponse(parsedOutput, context)];
            case 13: throw _d.sent();
            case 14: return [4, deserializeAws_restJson1TooManyRequestsExceptionResponse(parsedOutput, context)];
            case 15: throw _d.sent();
            case 16:
                parsedBody = parsedOutput.body;
                throwDefaultError({
                    output: output,
                    parsedBody: parsedBody,
                    exceptionCtor: __BaseException,
                    errorCode: errorCode,
                });
                _d.label = 17;
            case 17: return [2];
        }
    });
}); };
export var deserializeAws_restJson1CreateEmailTemplateCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var contents, data, _a;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                if (output.statusCode !== 201 && output.statusCode >= 300) {
                    return [2, deserializeAws_restJson1CreateEmailTemplateCommandError(output, context)];
                }
                contents = map({
                    $metadata: deserializeMetadata(output),
                });
                _a = __expectObject;
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.apply(void 0, [_b.sent()]);
                contents.CreateTemplateMessageBody = deserializeAws_restJson1CreateTemplateMessageBody(data, context);
                return [2, contents];
        }
    });
}); };
var deserializeAws_restJson1CreateEmailTemplateCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, errorCode, _b, parsedBody;
    var _c;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                _a = [__assign({}, output)];
                _c = {};
                return [4, parseErrorBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_c.body = _d.sent(), _c)]));
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "BadRequestException": return [3, 2];
                    case "com.amazonaws.pinpoint#BadRequestException": return [3, 2];
                    case "ForbiddenException": return [3, 4];
                    case "com.amazonaws.pinpoint#ForbiddenException": return [3, 4];
                    case "InternalServerErrorException": return [3, 6];
                    case "com.amazonaws.pinpoint#InternalServerErrorException": return [3, 6];
                    case "MethodNotAllowedException": return [3, 8];
                    case "com.amazonaws.pinpoint#MethodNotAllowedException": return [3, 8];
                    case "TooManyRequestsException": return [3, 10];
                    case "com.amazonaws.pinpoint#TooManyRequestsException": return [3, 10];
                }
                return [3, 12];
            case 2: return [4, deserializeAws_restJson1BadRequestExceptionResponse(parsedOutput, context)];
            case 3: throw _d.sent();
            case 4: return [4, deserializeAws_restJson1ForbiddenExceptionResponse(parsedOutput, context)];
            case 5: throw _d.sent();
            case 6: return [4, deserializeAws_restJson1InternalServerErrorExceptionResponse(parsedOutput, context)];
            case 7: throw _d.sent();
            case 8: return [4, deserializeAws_restJson1MethodNotAllowedExceptionResponse(parsedOutput, context)];
            case 9: throw _d.sent();
            case 10: return [4, deserializeAws_restJson1TooManyRequestsExceptionResponse(parsedOutput, context)];
            case 11: throw _d.sent();
            case 12:
                parsedBody = parsedOutput.body;
                throwDefaultError({
                    output: output,
                    parsedBody: parsedBody,
                    exceptionCtor: __BaseException,
                    errorCode: errorCode,
                });
                _d.label = 13;
            case 13: return [2];
        }
    });
}); };
export var deserializeAws_restJson1CreateExportJobCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var contents, data, _a;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                if (output.statusCode !== 202 && output.statusCode >= 300) {
                    return [2, deserializeAws_restJson1CreateExportJobCommandError(output, context)];
                }
                contents = map({
                    $metadata: deserializeMetadata(output),
                });
                _a = __expectObject;
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.apply(void 0, [_b.sent()]);
                contents.ExportJobResponse = deserializeAws_restJson1ExportJobResponse(data, context);
                return [2, contents];
        }
    });
}); };
var deserializeAws_restJson1CreateExportJobCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, errorCode, _b, parsedBody;
    var _c;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                _a = [__assign({}, output)];
                _c = {};
                return [4, parseErrorBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_c.body = _d.sent(), _c)]));
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "BadRequestException": return [3, 2];
                    case "com.amazonaws.pinpoint#BadRequestException": return [3, 2];
                    case "ForbiddenException": return [3, 4];
                    case "com.amazonaws.pinpoint#ForbiddenException": return [3, 4];
                    case "InternalServerErrorException": return [3, 6];
                    case "com.amazonaws.pinpoint#InternalServerErrorException": return [3, 6];
                    case "MethodNotAllowedException": return [3, 8];
                    case "com.amazonaws.pinpoint#MethodNotAllowedException": return [3, 8];
                    case "NotFoundException": return [3, 10];
                    case "com.amazonaws.pinpoint#NotFoundException": return [3, 10];
                    case "PayloadTooLargeException": return [3, 12];
                    case "com.amazonaws.pinpoint#PayloadTooLargeException": return [3, 12];
                    case "TooManyRequestsException": return [3, 14];
                    case "com.amazonaws.pinpoint#TooManyRequestsException": return [3, 14];
                }
                return [3, 16];
            case 2: return [4, deserializeAws_restJson1BadRequestExceptionResponse(parsedOutput, context)];
            case 3: throw _d.sent();
            case 4: return [4, deserializeAws_restJson1ForbiddenExceptionResponse(parsedOutput, context)];
            case 5: throw _d.sent();
            case 6: return [4, deserializeAws_restJson1InternalServerErrorExceptionResponse(parsedOutput, context)];
            case 7: throw _d.sent();
            case 8: return [4, deserializeAws_restJson1MethodNotAllowedExceptionResponse(parsedOutput, context)];
            case 9: throw _d.sent();
            case 10: return [4, deserializeAws_restJson1NotFoundExceptionResponse(parsedOutput, context)];
            case 11: throw _d.sent();
            case 12: return [4, deserializeAws_restJson1PayloadTooLargeExceptionResponse(parsedOutput, context)];
            case 13: throw _d.sent();
            case 14: return [4, deserializeAws_restJson1TooManyRequestsExceptionResponse(parsedOutput, context)];
            case 15: throw _d.sent();
            case 16:
                parsedBody = parsedOutput.body;
                throwDefaultError({
                    output: output,
                    parsedBody: parsedBody,
                    exceptionCtor: __BaseException,
                    errorCode: errorCode,
                });
                _d.label = 17;
            case 17: return [2];
        }
    });
}); };
export var deserializeAws_restJson1CreateImportJobCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var contents, data, _a;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                if (output.statusCode !== 201 && output.statusCode >= 300) {
                    return [2, deserializeAws_restJson1CreateImportJobCommandError(output, context)];
                }
                contents = map({
                    $metadata: deserializeMetadata(output),
                });
                _a = __expectObject;
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.apply(void 0, [_b.sent()]);
                contents.ImportJobResponse = deserializeAws_restJson1ImportJobResponse(data, context);
                return [2, contents];
        }
    });
}); };
var deserializeAws_restJson1CreateImportJobCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, errorCode, _b, parsedBody;
    var _c;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                _a = [__assign({}, output)];
                _c = {};
                return [4, parseErrorBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_c.body = _d.sent(), _c)]));
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "BadRequestException": return [3, 2];
                    case "com.amazonaws.pinpoint#BadRequestException": return [3, 2];
                    case "ForbiddenException": return [3, 4];
                    case "com.amazonaws.pinpoint#ForbiddenException": return [3, 4];
                    case "InternalServerErrorException": return [3, 6];
                    case "com.amazonaws.pinpoint#InternalServerErrorException": return [3, 6];
                    case "MethodNotAllowedException": return [3, 8];
                    case "com.amazonaws.pinpoint#MethodNotAllowedException": return [3, 8];
                    case "NotFoundException": return [3, 10];
                    case "com.amazonaws.pinpoint#NotFoundException": return [3, 10];
                    case "PayloadTooLargeException": return [3, 12];
                    case "com.amazonaws.pinpoint#PayloadTooLargeException": return [3, 12];
                    case "TooManyRequestsException": return [3, 14];
                    case "com.amazonaws.pinpoint#TooManyRequestsException": return [3, 14];
                }
                return [3, 16];
            case 2: return [4, deserializeAws_restJson1BadRequestExceptionResponse(parsedOutput, context)];
            case 3: throw _d.sent();
            case 4: return [4, deserializeAws_restJson1ForbiddenExceptionResponse(parsedOutput, context)];
            case 5: throw _d.sent();
            case 6: return [4, deserializeAws_restJson1InternalServerErrorExceptionResponse(parsedOutput, context)];
            case 7: throw _d.sent();
            case 8: return [4, deserializeAws_restJson1MethodNotAllowedExceptionResponse(parsedOutput, context)];
            case 9: throw _d.sent();
            case 10: return [4, deserializeAws_restJson1NotFoundExceptionResponse(parsedOutput, context)];
            case 11: throw _d.sent();
            case 12: return [4, deserializeAws_restJson1PayloadTooLargeExceptionResponse(parsedOutput, context)];
            case 13: throw _d.sent();
            case 14: return [4, deserializeAws_restJson1TooManyRequestsExceptionResponse(parsedOutput, context)];
            case 15: throw _d.sent();
            case 16:
                parsedBody = parsedOutput.body;
                throwDefaultError({
                    output: output,
                    parsedBody: parsedBody,
                    exceptionCtor: __BaseException,
                    errorCode: errorCode,
                });
                _d.label = 17;
            case 17: return [2];
        }
    });
}); };
export var deserializeAws_restJson1CreateInAppTemplateCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var contents, data, _a;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                if (output.statusCode !== 201 && output.statusCode >= 300) {
                    return [2, deserializeAws_restJson1CreateInAppTemplateCommandError(output, context)];
                }
                contents = map({
                    $metadata: deserializeMetadata(output),
                });
                _a = __expectObject;
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.apply(void 0, [_b.sent()]);
                contents.TemplateCreateMessageBody = deserializeAws_restJson1TemplateCreateMessageBody(data, context);
                return [2, contents];
        }
    });
}); };
var deserializeAws_restJson1CreateInAppTemplateCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, errorCode, _b, parsedBody;
    var _c;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                _a = [__assign({}, output)];
                _c = {};
                return [4, parseErrorBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_c.body = _d.sent(), _c)]));
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "BadRequestException": return [3, 2];
                    case "com.amazonaws.pinpoint#BadRequestException": return [3, 2];
                    case "ForbiddenException": return [3, 4];
                    case "com.amazonaws.pinpoint#ForbiddenException": return [3, 4];
                    case "InternalServerErrorException": return [3, 6];
                    case "com.amazonaws.pinpoint#InternalServerErrorException": return [3, 6];
                    case "MethodNotAllowedException": return [3, 8];
                    case "com.amazonaws.pinpoint#MethodNotAllowedException": return [3, 8];
                    case "TooManyRequestsException": return [3, 10];
                    case "com.amazonaws.pinpoint#TooManyRequestsException": return [3, 10];
                }
                return [3, 12];
            case 2: return [4, deserializeAws_restJson1BadRequestExceptionResponse(parsedOutput, context)];
            case 3: throw _d.sent();
            case 4: return [4, deserializeAws_restJson1ForbiddenExceptionResponse(parsedOutput, context)];
            case 5: throw _d.sent();
            case 6: return [4, deserializeAws_restJson1InternalServerErrorExceptionResponse(parsedOutput, context)];
            case 7: throw _d.sent();
            case 8: return [4, deserializeAws_restJson1MethodNotAllowedExceptionResponse(parsedOutput, context)];
            case 9: throw _d.sent();
            case 10: return [4, deserializeAws_restJson1TooManyRequestsExceptionResponse(parsedOutput, context)];
            case 11: throw _d.sent();
            case 12:
                parsedBody = parsedOutput.body;
                throwDefaultError({
                    output: output,
                    parsedBody: parsedBody,
                    exceptionCtor: __BaseException,
                    errorCode: errorCode,
                });
                _d.label = 13;
            case 13: return [2];
        }
    });
}); };
export var deserializeAws_restJson1CreateJourneyCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var contents, data, _a;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                if (output.statusCode !== 201 && output.statusCode >= 300) {
                    return [2, deserializeAws_restJson1CreateJourneyCommandError(output, context)];
                }
                contents = map({
                    $metadata: deserializeMetadata(output),
                });
                _a = __expectObject;
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.apply(void 0, [_b.sent()]);
                contents.JourneyResponse = deserializeAws_restJson1JourneyResponse(data, context);
                return [2, contents];
        }
    });
}); };
var deserializeAws_restJson1CreateJourneyCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, errorCode, _b, parsedBody;
    var _c;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                _a = [__assign({}, output)];
                _c = {};
                return [4, parseErrorBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_c.body = _d.sent(), _c)]));
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "BadRequestException": return [3, 2];
                    case "com.amazonaws.pinpoint#BadRequestException": return [3, 2];
                    case "ForbiddenException": return [3, 4];
                    case "com.amazonaws.pinpoint#ForbiddenException": return [3, 4];
                    case "InternalServerErrorException": return [3, 6];
                    case "com.amazonaws.pinpoint#InternalServerErrorException": return [3, 6];
                    case "MethodNotAllowedException": return [3, 8];
                    case "com.amazonaws.pinpoint#MethodNotAllowedException": return [3, 8];
                    case "NotFoundException": return [3, 10];
                    case "com.amazonaws.pinpoint#NotFoundException": return [3, 10];
                    case "PayloadTooLargeException": return [3, 12];
                    case "com.amazonaws.pinpoint#PayloadTooLargeException": return [3, 12];
                    case "TooManyRequestsException": return [3, 14];
                    case "com.amazonaws.pinpoint#TooManyRequestsException": return [3, 14];
                }
                return [3, 16];
            case 2: return [4, deserializeAws_restJson1BadRequestExceptionResponse(parsedOutput, context)];
            case 3: throw _d.sent();
            case 4: return [4, deserializeAws_restJson1ForbiddenExceptionResponse(parsedOutput, context)];
            case 5: throw _d.sent();
            case 6: return [4, deserializeAws_restJson1InternalServerErrorExceptionResponse(parsedOutput, context)];
            case 7: throw _d.sent();
            case 8: return [4, deserializeAws_restJson1MethodNotAllowedExceptionResponse(parsedOutput, context)];
            case 9: throw _d.sent();
            case 10: return [4, deserializeAws_restJson1NotFoundExceptionResponse(parsedOutput, context)];
            case 11: throw _d.sent();
            case 12: return [4, deserializeAws_restJson1PayloadTooLargeExceptionResponse(parsedOutput, context)];
            case 13: throw _d.sent();
            case 14: return [4, deserializeAws_restJson1TooManyRequestsExceptionResponse(parsedOutput, context)];
            case 15: throw _d.sent();
            case 16:
                parsedBody = parsedOutput.body;
                throwDefaultError({
                    output: output,
                    parsedBody: parsedBody,
                    exceptionCtor: __BaseException,
                    errorCode: errorCode,
                });
                _d.label = 17;
            case 17: return [2];
        }
    });
}); };
export var deserializeAws_restJson1CreatePushTemplateCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var contents, data, _a;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                if (output.statusCode !== 201 && output.statusCode >= 300) {
                    return [2, deserializeAws_restJson1CreatePushTemplateCommandError(output, context)];
                }
                contents = map({
                    $metadata: deserializeMetadata(output),
                });
                _a = __expectObject;
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.apply(void 0, [_b.sent()]);
                contents.CreateTemplateMessageBody = deserializeAws_restJson1CreateTemplateMessageBody(data, context);
                return [2, contents];
        }
    });
}); };
var deserializeAws_restJson1CreatePushTemplateCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, errorCode, _b, parsedBody;
    var _c;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                _a = [__assign({}, output)];
                _c = {};
                return [4, parseErrorBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_c.body = _d.sent(), _c)]));
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "BadRequestException": return [3, 2];
                    case "com.amazonaws.pinpoint#BadRequestException": return [3, 2];
                    case "ForbiddenException": return [3, 4];
                    case "com.amazonaws.pinpoint#ForbiddenException": return [3, 4];
                    case "InternalServerErrorException": return [3, 6];
                    case "com.amazonaws.pinpoint#InternalServerErrorException": return [3, 6];
                    case "MethodNotAllowedException": return [3, 8];
                    case "com.amazonaws.pinpoint#MethodNotAllowedException": return [3, 8];
                    case "TooManyRequestsException": return [3, 10];
                    case "com.amazonaws.pinpoint#TooManyRequestsException": return [3, 10];
                }
                return [3, 12];
            case 2: return [4, deserializeAws_restJson1BadRequestExceptionResponse(parsedOutput, context)];
            case 3: throw _d.sent();
            case 4: return [4, deserializeAws_restJson1ForbiddenExceptionResponse(parsedOutput, context)];
            case 5: throw _d.sent();
            case 6: return [4, deserializeAws_restJson1InternalServerErrorExceptionResponse(parsedOutput, context)];
            case 7: throw _d.sent();
            case 8: return [4, deserializeAws_restJson1MethodNotAllowedExceptionResponse(parsedOutput, context)];
            case 9: throw _d.sent();
            case 10: return [4, deserializeAws_restJson1TooManyRequestsExceptionResponse(parsedOutput, context)];
            case 11: throw _d.sent();
            case 12:
                parsedBody = parsedOutput.body;
                throwDefaultError({
                    output: output,
                    parsedBody: parsedBody,
                    exceptionCtor: __BaseException,
                    errorCode: errorCode,
                });
                _d.label = 13;
            case 13: return [2];
        }
    });
}); };
export var deserializeAws_restJson1CreateRecommenderConfigurationCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var contents, data, _a;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                if (output.statusCode !== 201 && output.statusCode >= 300) {
                    return [2, deserializeAws_restJson1CreateRecommenderConfigurationCommandError(output, context)];
                }
                contents = map({
                    $metadata: deserializeMetadata(output),
                });
                _a = __expectObject;
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.apply(void 0, [_b.sent()]);
                contents.RecommenderConfigurationResponse = deserializeAws_restJson1RecommenderConfigurationResponse(data, context);
                return [2, contents];
        }
    });
}); };
var deserializeAws_restJson1CreateRecommenderConfigurationCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, errorCode, _b, parsedBody;
    var _c;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                _a = [__assign({}, output)];
                _c = {};
                return [4, parseErrorBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_c.body = _d.sent(), _c)]));
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "BadRequestException": return [3, 2];
                    case "com.amazonaws.pinpoint#BadRequestException": return [3, 2];
                    case "ForbiddenException": return [3, 4];
                    case "com.amazonaws.pinpoint#ForbiddenException": return [3, 4];
                    case "InternalServerErrorException": return [3, 6];
                    case "com.amazonaws.pinpoint#InternalServerErrorException": return [3, 6];
                    case "MethodNotAllowedException": return [3, 8];
                    case "com.amazonaws.pinpoint#MethodNotAllowedException": return [3, 8];
                    case "NotFoundException": return [3, 10];
                    case "com.amazonaws.pinpoint#NotFoundException": return [3, 10];
                    case "PayloadTooLargeException": return [3, 12];
                    case "com.amazonaws.pinpoint#PayloadTooLargeException": return [3, 12];
                    case "TooManyRequestsException": return [3, 14];
                    case "com.amazonaws.pinpoint#TooManyRequestsException": return [3, 14];
                }
                return [3, 16];
            case 2: return [4, deserializeAws_restJson1BadRequestExceptionResponse(parsedOutput, context)];
            case 3: throw _d.sent();
            case 4: return [4, deserializeAws_restJson1ForbiddenExceptionResponse(parsedOutput, context)];
            case 5: throw _d.sent();
            case 6: return [4, deserializeAws_restJson1InternalServerErrorExceptionResponse(parsedOutput, context)];
            case 7: throw _d.sent();
            case 8: return [4, deserializeAws_restJson1MethodNotAllowedExceptionResponse(parsedOutput, context)];
            case 9: throw _d.sent();
            case 10: return [4, deserializeAws_restJson1NotFoundExceptionResponse(parsedOutput, context)];
            case 11: throw _d.sent();
            case 12: return [4, deserializeAws_restJson1PayloadTooLargeExceptionResponse(parsedOutput, context)];
            case 13: throw _d.sent();
            case 14: return [4, deserializeAws_restJson1TooManyRequestsExceptionResponse(parsedOutput, context)];
            case 15: throw _d.sent();
            case 16:
                parsedBody = parsedOutput.body;
                throwDefaultError({
                    output: output,
                    parsedBody: parsedBody,
                    exceptionCtor: __BaseException,
                    errorCode: errorCode,
                });
                _d.label = 17;
            case 17: return [2];
        }
    });
}); };
export var deserializeAws_restJson1CreateSegmentCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var contents, data, _a;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                if (output.statusCode !== 201 && output.statusCode >= 300) {
                    return [2, deserializeAws_restJson1CreateSegmentCommandError(output, context)];
                }
                contents = map({
                    $metadata: deserializeMetadata(output),
                });
                _a = __expectObject;
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.apply(void 0, [_b.sent()]);
                contents.SegmentResponse = deserializeAws_restJson1SegmentResponse(data, context);
                return [2, contents];
        }
    });
}); };
var deserializeAws_restJson1CreateSegmentCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, errorCode, _b, parsedBody;
    var _c;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                _a = [__assign({}, output)];
                _c = {};
                return [4, parseErrorBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_c.body = _d.sent(), _c)]));
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "BadRequestException": return [3, 2];
                    case "com.amazonaws.pinpoint#BadRequestException": return [3, 2];
                    case "ForbiddenException": return [3, 4];
                    case "com.amazonaws.pinpoint#ForbiddenException": return [3, 4];
                    case "InternalServerErrorException": return [3, 6];
                    case "com.amazonaws.pinpoint#InternalServerErrorException": return [3, 6];
                    case "MethodNotAllowedException": return [3, 8];
                    case "com.amazonaws.pinpoint#MethodNotAllowedException": return [3, 8];
                    case "NotFoundException": return [3, 10];
                    case "com.amazonaws.pinpoint#NotFoundException": return [3, 10];
                    case "PayloadTooLargeException": return [3, 12];
                    case "com.amazonaws.pinpoint#PayloadTooLargeException": return [3, 12];
                    case "TooManyRequestsException": return [3, 14];
                    case "com.amazonaws.pinpoint#TooManyRequestsException": return [3, 14];
                }
                return [3, 16];
            case 2: return [4, deserializeAws_restJson1BadRequestExceptionResponse(parsedOutput, context)];
            case 3: throw _d.sent();
            case 4: return [4, deserializeAws_restJson1ForbiddenExceptionResponse(parsedOutput, context)];
            case 5: throw _d.sent();
            case 6: return [4, deserializeAws_restJson1InternalServerErrorExceptionResponse(parsedOutput, context)];
            case 7: throw _d.sent();
            case 8: return [4, deserializeAws_restJson1MethodNotAllowedExceptionResponse(parsedOutput, context)];
            case 9: throw _d.sent();
            case 10: return [4, deserializeAws_restJson1NotFoundExceptionResponse(parsedOutput, context)];
            case 11: throw _d.sent();
            case 12: return [4, deserializeAws_restJson1PayloadTooLargeExceptionResponse(parsedOutput, context)];
            case 13: throw _d.sent();
            case 14: return [4, deserializeAws_restJson1TooManyRequestsExceptionResponse(parsedOutput, context)];
            case 15: throw _d.sent();
            case 16:
                parsedBody = parsedOutput.body;
                throwDefaultError({
                    output: output,
                    parsedBody: parsedBody,
                    exceptionCtor: __BaseException,
                    errorCode: errorCode,
                });
                _d.label = 17;
            case 17: return [2];
        }
    });
}); };
export var deserializeAws_restJson1CreateSmsTemplateCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var contents, data, _a;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                if (output.statusCode !== 201 && output.statusCode >= 300) {
                    return [2, deserializeAws_restJson1CreateSmsTemplateCommandError(output, context)];
                }
                contents = map({
                    $metadata: deserializeMetadata(output),
                });
                _a = __expectObject;
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.apply(void 0, [_b.sent()]);
                contents.CreateTemplateMessageBody = deserializeAws_restJson1CreateTemplateMessageBody(data, context);
                return [2, contents];
        }
    });
}); };
var deserializeAws_restJson1CreateSmsTemplateCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, errorCode, _b, parsedBody;
    var _c;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                _a = [__assign({}, output)];
                _c = {};
                return [4, parseErrorBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_c.body = _d.sent(), _c)]));
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "BadRequestException": return [3, 2];
                    case "com.amazonaws.pinpoint#BadRequestException": return [3, 2];
                    case "ForbiddenException": return [3, 4];
                    case "com.amazonaws.pinpoint#ForbiddenException": return [3, 4];
                    case "InternalServerErrorException": return [3, 6];
                    case "com.amazonaws.pinpoint#InternalServerErrorException": return [3, 6];
                    case "MethodNotAllowedException": return [3, 8];
                    case "com.amazonaws.pinpoint#MethodNotAllowedException": return [3, 8];
                    case "TooManyRequestsException": return [3, 10];
                    case "com.amazonaws.pinpoint#TooManyRequestsException": return [3, 10];
                }
                return [3, 12];
            case 2: return [4, deserializeAws_restJson1BadRequestExceptionResponse(parsedOutput, context)];
            case 3: throw _d.sent();
            case 4: return [4, deserializeAws_restJson1ForbiddenExceptionResponse(parsedOutput, context)];
            case 5: throw _d.sent();
            case 6: return [4, deserializeAws_restJson1InternalServerErrorExceptionResponse(parsedOutput, context)];
            case 7: throw _d.sent();
            case 8: return [4, deserializeAws_restJson1MethodNotAllowedExceptionResponse(parsedOutput, context)];
            case 9: throw _d.sent();
            case 10: return [4, deserializeAws_restJson1TooManyRequestsExceptionResponse(parsedOutput, context)];
            case 11: throw _d.sent();
            case 12:
                parsedBody = parsedOutput.body;
                throwDefaultError({
                    output: output,
                    parsedBody: parsedBody,
                    exceptionCtor: __BaseException,
                    errorCode: errorCode,
                });
                _d.label = 13;
            case 13: return [2];
        }
    });
}); };
export var deserializeAws_restJson1CreateVoiceTemplateCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var contents, data, _a;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                if (output.statusCode !== 201 && output.statusCode >= 300) {
                    return [2, deserializeAws_restJson1CreateVoiceTemplateCommandError(output, context)];
                }
                contents = map({
                    $metadata: deserializeMetadata(output),
                });
                _a = __expectObject;
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.apply(void 0, [_b.sent()]);
                contents.CreateTemplateMessageBody = deserializeAws_restJson1CreateTemplateMessageBody(data, context);
                return [2, contents];
        }
    });
}); };
var deserializeAws_restJson1CreateVoiceTemplateCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, errorCode, _b, parsedBody;
    var _c;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                _a = [__assign({}, output)];
                _c = {};
                return [4, parseErrorBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_c.body = _d.sent(), _c)]));
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "BadRequestException": return [3, 2];
                    case "com.amazonaws.pinpoint#BadRequestException": return [3, 2];
                    case "ForbiddenException": return [3, 4];
                    case "com.amazonaws.pinpoint#ForbiddenException": return [3, 4];
                    case "InternalServerErrorException": return [3, 6];
                    case "com.amazonaws.pinpoint#InternalServerErrorException": return [3, 6];
                    case "MethodNotAllowedException": return [3, 8];
                    case "com.amazonaws.pinpoint#MethodNotAllowedException": return [3, 8];
                    case "TooManyRequestsException": return [3, 10];
                    case "com.amazonaws.pinpoint#TooManyRequestsException": return [3, 10];
                }
                return [3, 12];
            case 2: return [4, deserializeAws_restJson1BadRequestExceptionResponse(parsedOutput, context)];
            case 3: throw _d.sent();
            case 4: return [4, deserializeAws_restJson1ForbiddenExceptionResponse(parsedOutput, context)];
            case 5: throw _d.sent();
            case 6: return [4, deserializeAws_restJson1InternalServerErrorExceptionResponse(parsedOutput, context)];
            case 7: throw _d.sent();
            case 8: return [4, deserializeAws_restJson1MethodNotAllowedExceptionResponse(parsedOutput, context)];
            case 9: throw _d.sent();
            case 10: return [4, deserializeAws_restJson1TooManyRequestsExceptionResponse(parsedOutput, context)];
            case 11: throw _d.sent();
            case 12:
                parsedBody = parsedOutput.body;
                throwDefaultError({
                    output: output,
                    parsedBody: parsedBody,
                    exceptionCtor: __BaseException,
                    errorCode: errorCode,
                });
                _d.label = 13;
            case 13: return [2];
        }
    });
}); };
export var deserializeAws_restJson1DeleteAdmChannelCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var contents, data, _a;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                if (output.statusCode !== 200 && output.statusCode >= 300) {
                    return [2, deserializeAws_restJson1DeleteAdmChannelCommandError(output, context)];
                }
                contents = map({
                    $metadata: deserializeMetadata(output),
                });
                _a = __expectObject;
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.apply(void 0, [_b.sent()]);
                contents.ADMChannelResponse = deserializeAws_restJson1ADMChannelResponse(data, context);
                return [2, contents];
        }
    });
}); };
var deserializeAws_restJson1DeleteAdmChannelCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, errorCode, _b, parsedBody;
    var _c;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                _a = [__assign({}, output)];
                _c = {};
                return [4, parseErrorBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_c.body = _d.sent(), _c)]));
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "BadRequestException": return [3, 2];
                    case "com.amazonaws.pinpoint#BadRequestException": return [3, 2];
                    case "ForbiddenException": return [3, 4];
                    case "com.amazonaws.pinpoint#ForbiddenException": return [3, 4];
                    case "InternalServerErrorException": return [3, 6];
                    case "com.amazonaws.pinpoint#InternalServerErrorException": return [3, 6];
                    case "MethodNotAllowedException": return [3, 8];
                    case "com.amazonaws.pinpoint#MethodNotAllowedException": return [3, 8];
                    case "NotFoundException": return [3, 10];
                    case "com.amazonaws.pinpoint#NotFoundException": return [3, 10];
                    case "PayloadTooLargeException": return [3, 12];
                    case "com.amazonaws.pinpoint#PayloadTooLargeException": return [3, 12];
                    case "TooManyRequestsException": return [3, 14];
                    case "com.amazonaws.pinpoint#TooManyRequestsException": return [3, 14];
                }
                return [3, 16];
            case 2: return [4, deserializeAws_restJson1BadRequestExceptionResponse(parsedOutput, context)];
            case 3: throw _d.sent();
            case 4: return [4, deserializeAws_restJson1ForbiddenExceptionResponse(parsedOutput, context)];
            case 5: throw _d.sent();
            case 6: return [4, deserializeAws_restJson1InternalServerErrorExceptionResponse(parsedOutput, context)];
            case 7: throw _d.sent();
            case 8: return [4, deserializeAws_restJson1MethodNotAllowedExceptionResponse(parsedOutput, context)];
            case 9: throw _d.sent();
            case 10: return [4, deserializeAws_restJson1NotFoundExceptionResponse(parsedOutput, context)];
            case 11: throw _d.sent();
            case 12: return [4, deserializeAws_restJson1PayloadTooLargeExceptionResponse(parsedOutput, context)];
            case 13: throw _d.sent();
            case 14: return [4, deserializeAws_restJson1TooManyRequestsExceptionResponse(parsedOutput, context)];
            case 15: throw _d.sent();
            case 16:
                parsedBody = parsedOutput.body;
                throwDefaultError({
                    output: output,
                    parsedBody: parsedBody,
                    exceptionCtor: __BaseException,
                    errorCode: errorCode,
                });
                _d.label = 17;
            case 17: return [2];
        }
    });
}); };
export var deserializeAws_restJson1DeleteApnsChannelCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var contents, data, _a;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                if (output.statusCode !== 200 && output.statusCode >= 300) {
                    return [2, deserializeAws_restJson1DeleteApnsChannelCommandError(output, context)];
                }
                contents = map({
                    $metadata: deserializeMetadata(output),
                });
                _a = __expectObject;
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.apply(void 0, [_b.sent()]);
                contents.APNSChannelResponse = deserializeAws_restJson1APNSChannelResponse(data, context);
                return [2, contents];
        }
    });
}); };
var deserializeAws_restJson1DeleteApnsChannelCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, errorCode, _b, parsedBody;
    var _c;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                _a = [__assign({}, output)];
                _c = {};
                return [4, parseErrorBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_c.body = _d.sent(), _c)]));
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "BadRequestException": return [3, 2];
                    case "com.amazonaws.pinpoint#BadRequestException": return [3, 2];
                    case "ForbiddenException": return [3, 4];
                    case "com.amazonaws.pinpoint#ForbiddenException": return [3, 4];
                    case "InternalServerErrorException": return [3, 6];
                    case "com.amazonaws.pinpoint#InternalServerErrorException": return [3, 6];
                    case "MethodNotAllowedException": return [3, 8];
                    case "com.amazonaws.pinpoint#MethodNotAllowedException": return [3, 8];
                    case "NotFoundException": return [3, 10];
                    case "com.amazonaws.pinpoint#NotFoundException": return [3, 10];
                    case "PayloadTooLargeException": return [3, 12];
                    case "com.amazonaws.pinpoint#PayloadTooLargeException": return [3, 12];
                    case "TooManyRequestsException": return [3, 14];
                    case "com.amazonaws.pinpoint#TooManyRequestsException": return [3, 14];
                }
                return [3, 16];
            case 2: return [4, deserializeAws_restJson1BadRequestExceptionResponse(parsedOutput, context)];
            case 3: throw _d.sent();
            case 4: return [4, deserializeAws_restJson1ForbiddenExceptionResponse(parsedOutput, context)];
            case 5: throw _d.sent();
            case 6: return [4, deserializeAws_restJson1InternalServerErrorExceptionResponse(parsedOutput, context)];
            case 7: throw _d.sent();
            case 8: return [4, deserializeAws_restJson1MethodNotAllowedExceptionResponse(parsedOutput, context)];
            case 9: throw _d.sent();
            case 10: return [4, deserializeAws_restJson1NotFoundExceptionResponse(parsedOutput, context)];
            case 11: throw _d.sent();
            case 12: return [4, deserializeAws_restJson1PayloadTooLargeExceptionResponse(parsedOutput, context)];
            case 13: throw _d.sent();
            case 14: return [4, deserializeAws_restJson1TooManyRequestsExceptionResponse(parsedOutput, context)];
            case 15: throw _d.sent();
            case 16:
                parsedBody = parsedOutput.body;
                throwDefaultError({
                    output: output,
                    parsedBody: parsedBody,
                    exceptionCtor: __BaseException,
                    errorCode: errorCode,
                });
                _d.label = 17;
            case 17: return [2];
        }
    });
}); };
export var deserializeAws_restJson1DeleteApnsSandboxChannelCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var contents, data, _a;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                if (output.statusCode !== 200 && output.statusCode >= 300) {
                    return [2, deserializeAws_restJson1DeleteApnsSandboxChannelCommandError(output, context)];
                }
                contents = map({
                    $metadata: deserializeMetadata(output),
                });
                _a = __expectObject;
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.apply(void 0, [_b.sent()]);
                contents.APNSSandboxChannelResponse = deserializeAws_restJson1APNSSandboxChannelResponse(data, context);
                return [2, contents];
        }
    });
}); };
var deserializeAws_restJson1DeleteApnsSandboxChannelCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, errorCode, _b, parsedBody;
    var _c;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                _a = [__assign({}, output)];
                _c = {};
                return [4, parseErrorBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_c.body = _d.sent(), _c)]));
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "BadRequestException": return [3, 2];
                    case "com.amazonaws.pinpoint#BadRequestException": return [3, 2];
                    case "ForbiddenException": return [3, 4];
                    case "com.amazonaws.pinpoint#ForbiddenException": return [3, 4];
                    case "InternalServerErrorException": return [3, 6];
                    case "com.amazonaws.pinpoint#InternalServerErrorException": return [3, 6];
                    case "MethodNotAllowedException": return [3, 8];
                    case "com.amazonaws.pinpoint#MethodNotAllowedException": return [3, 8];
                    case "NotFoundException": return [3, 10];
                    case "com.amazonaws.pinpoint#NotFoundException": return [3, 10];
                    case "PayloadTooLargeException": return [3, 12];
                    case "com.amazonaws.pinpoint#PayloadTooLargeException": return [3, 12];
                    case "TooManyRequestsException": return [3, 14];
                    case "com.amazonaws.pinpoint#TooManyRequestsException": return [3, 14];
                }
                return [3, 16];
            case 2: return [4, deserializeAws_restJson1BadRequestExceptionResponse(parsedOutput, context)];
            case 3: throw _d.sent();
            case 4: return [4, deserializeAws_restJson1ForbiddenExceptionResponse(parsedOutput, context)];
            case 5: throw _d.sent();
            case 6: return [4, deserializeAws_restJson1InternalServerErrorExceptionResponse(parsedOutput, context)];
            case 7: throw _d.sent();
            case 8: return [4, deserializeAws_restJson1MethodNotAllowedExceptionResponse(parsedOutput, context)];
            case 9: throw _d.sent();
            case 10: return [4, deserializeAws_restJson1NotFoundExceptionResponse(parsedOutput, context)];
            case 11: throw _d.sent();
            case 12: return [4, deserializeAws_restJson1PayloadTooLargeExceptionResponse(parsedOutput, context)];
            case 13: throw _d.sent();
            case 14: return [4, deserializeAws_restJson1TooManyRequestsExceptionResponse(parsedOutput, context)];
            case 15: throw _d.sent();
            case 16:
                parsedBody = parsedOutput.body;
                throwDefaultError({
                    output: output,
                    parsedBody: parsedBody,
                    exceptionCtor: __BaseException,
                    errorCode: errorCode,
                });
                _d.label = 17;
            case 17: return [2];
        }
    });
}); };
export var deserializeAws_restJson1DeleteApnsVoipChannelCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var contents, data, _a;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                if (output.statusCode !== 200 && output.statusCode >= 300) {
                    return [2, deserializeAws_restJson1DeleteApnsVoipChannelCommandError(output, context)];
                }
                contents = map({
                    $metadata: deserializeMetadata(output),
                });
                _a = __expectObject;
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.apply(void 0, [_b.sent()]);
                contents.APNSVoipChannelResponse = deserializeAws_restJson1APNSVoipChannelResponse(data, context);
                return [2, contents];
        }
    });
}); };
var deserializeAws_restJson1DeleteApnsVoipChannelCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, errorCode, _b, parsedBody;
    var _c;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                _a = [__assign({}, output)];
                _c = {};
                return [4, parseErrorBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_c.body = _d.sent(), _c)]));
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "BadRequestException": return [3, 2];
                    case "com.amazonaws.pinpoint#BadRequestException": return [3, 2];
                    case "ForbiddenException": return [3, 4];
                    case "com.amazonaws.pinpoint#ForbiddenException": return [3, 4];
                    case "InternalServerErrorException": return [3, 6];
                    case "com.amazonaws.pinpoint#InternalServerErrorException": return [3, 6];
                    case "MethodNotAllowedException": return [3, 8];
                    case "com.amazonaws.pinpoint#MethodNotAllowedException": return [3, 8];
                    case "NotFoundException": return [3, 10];
                    case "com.amazonaws.pinpoint#NotFoundException": return [3, 10];
                    case "PayloadTooLargeException": return [3, 12];
                    case "com.amazonaws.pinpoint#PayloadTooLargeException": return [3, 12];
                    case "TooManyRequestsException": return [3, 14];
                    case "com.amazonaws.pinpoint#TooManyRequestsException": return [3, 14];
                }
                return [3, 16];
            case 2: return [4, deserializeAws_restJson1BadRequestExceptionResponse(parsedOutput, context)];
            case 3: throw _d.sent();
            case 4: return [4, deserializeAws_restJson1ForbiddenExceptionResponse(parsedOutput, context)];
            case 5: throw _d.sent();
            case 6: return [4, deserializeAws_restJson1InternalServerErrorExceptionResponse(parsedOutput, context)];
            case 7: throw _d.sent();
            case 8: return [4, deserializeAws_restJson1MethodNotAllowedExceptionResponse(parsedOutput, context)];
            case 9: throw _d.sent();
            case 10: return [4, deserializeAws_restJson1NotFoundExceptionResponse(parsedOutput, context)];
            case 11: throw _d.sent();
            case 12: return [4, deserializeAws_restJson1PayloadTooLargeExceptionResponse(parsedOutput, context)];
            case 13: throw _d.sent();
            case 14: return [4, deserializeAws_restJson1TooManyRequestsExceptionResponse(parsedOutput, context)];
            case 15: throw _d.sent();
            case 16:
                parsedBody = parsedOutput.body;
                throwDefaultError({
                    output: output,
                    parsedBody: parsedBody,
                    exceptionCtor: __BaseException,
                    errorCode: errorCode,
                });
                _d.label = 17;
            case 17: return [2];
        }
    });
}); };
export var deserializeAws_restJson1DeleteApnsVoipSandboxChannelCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var contents, data, _a;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                if (output.statusCode !== 200 && output.statusCode >= 300) {
                    return [2, deserializeAws_restJson1DeleteApnsVoipSandboxChannelCommandError(output, context)];
                }
                contents = map({
                    $metadata: deserializeMetadata(output),
                });
                _a = __expectObject;
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.apply(void 0, [_b.sent()]);
                contents.APNSVoipSandboxChannelResponse = deserializeAws_restJson1APNSVoipSandboxChannelResponse(data, context);
                return [2, contents];
        }
    });
}); };
var deserializeAws_restJson1DeleteApnsVoipSandboxChannelCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, errorCode, _b, parsedBody;
    var _c;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                _a = [__assign({}, output)];
                _c = {};
                return [4, parseErrorBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_c.body = _d.sent(), _c)]));
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "BadRequestException": return [3, 2];
                    case "com.amazonaws.pinpoint#BadRequestException": return [3, 2];
                    case "ForbiddenException": return [3, 4];
                    case "com.amazonaws.pinpoint#ForbiddenException": return [3, 4];
                    case "InternalServerErrorException": return [3, 6];
                    case "com.amazonaws.pinpoint#InternalServerErrorException": return [3, 6];
                    case "MethodNotAllowedException": return [3, 8];
                    case "com.amazonaws.pinpoint#MethodNotAllowedException": return [3, 8];
                    case "NotFoundException": return [3, 10];
                    case "com.amazonaws.pinpoint#NotFoundException": return [3, 10];
                    case "PayloadTooLargeException": return [3, 12];
                    case "com.amazonaws.pinpoint#PayloadTooLargeException": return [3, 12];
                    case "TooManyRequestsException": return [3, 14];
                    case "com.amazonaws.pinpoint#TooManyRequestsException": return [3, 14];
                }
                return [3, 16];
            case 2: return [4, deserializeAws_restJson1BadRequestExceptionResponse(parsedOutput, context)];
            case 3: throw _d.sent();
            case 4: return [4, deserializeAws_restJson1ForbiddenExceptionResponse(parsedOutput, context)];
            case 5: throw _d.sent();
            case 6: return [4, deserializeAws_restJson1InternalServerErrorExceptionResponse(parsedOutput, context)];
            case 7: throw _d.sent();
            case 8: return [4, deserializeAws_restJson1MethodNotAllowedExceptionResponse(parsedOutput, context)];
            case 9: throw _d.sent();
            case 10: return [4, deserializeAws_restJson1NotFoundExceptionResponse(parsedOutput, context)];
            case 11: throw _d.sent();
            case 12: return [4, deserializeAws_restJson1PayloadTooLargeExceptionResponse(parsedOutput, context)];
            case 13: throw _d.sent();
            case 14: return [4, deserializeAws_restJson1TooManyRequestsExceptionResponse(parsedOutput, context)];
            case 15: throw _d.sent();
            case 16:
                parsedBody = parsedOutput.body;
                throwDefaultError({
                    output: output,
                    parsedBody: parsedBody,
                    exceptionCtor: __BaseException,
                    errorCode: errorCode,
                });
                _d.label = 17;
            case 17: return [2];
        }
    });
}); };
export var deserializeAws_restJson1DeleteAppCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var contents, data, _a;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                if (output.statusCode !== 200 && output.statusCode >= 300) {
                    return [2, deserializeAws_restJson1DeleteAppCommandError(output, context)];
                }
                contents = map({
                    $metadata: deserializeMetadata(output),
                });
                _a = __expectObject;
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.apply(void 0, [_b.sent()]);
                contents.ApplicationResponse = deserializeAws_restJson1ApplicationResponse(data, context);
                return [2, contents];
        }
    });
}); };
var deserializeAws_restJson1DeleteAppCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, errorCode, _b, parsedBody;
    var _c;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                _a = [__assign({}, output)];
                _c = {};
                return [4, parseErrorBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_c.body = _d.sent(), _c)]));
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "BadRequestException": return [3, 2];
                    case "com.amazonaws.pinpoint#BadRequestException": return [3, 2];
                    case "ForbiddenException": return [3, 4];
                    case "com.amazonaws.pinpoint#ForbiddenException": return [3, 4];
                    case "InternalServerErrorException": return [3, 6];
                    case "com.amazonaws.pinpoint#InternalServerErrorException": return [3, 6];
                    case "MethodNotAllowedException": return [3, 8];
                    case "com.amazonaws.pinpoint#MethodNotAllowedException": return [3, 8];
                    case "NotFoundException": return [3, 10];
                    case "com.amazonaws.pinpoint#NotFoundException": return [3, 10];
                    case "PayloadTooLargeException": return [3, 12];
                    case "com.amazonaws.pinpoint#PayloadTooLargeException": return [3, 12];
                    case "TooManyRequestsException": return [3, 14];
                    case "com.amazonaws.pinpoint#TooManyRequestsException": return [3, 14];
                }
                return [3, 16];
            case 2: return [4, deserializeAws_restJson1BadRequestExceptionResponse(parsedOutput, context)];
            case 3: throw _d.sent();
            case 4: return [4, deserializeAws_restJson1ForbiddenExceptionResponse(parsedOutput, context)];
            case 5: throw _d.sent();
            case 6: return [4, deserializeAws_restJson1InternalServerErrorExceptionResponse(parsedOutput, context)];
            case 7: throw _d.sent();
            case 8: return [4, deserializeAws_restJson1MethodNotAllowedExceptionResponse(parsedOutput, context)];
            case 9: throw _d.sent();
            case 10: return [4, deserializeAws_restJson1NotFoundExceptionResponse(parsedOutput, context)];
            case 11: throw _d.sent();
            case 12: return [4, deserializeAws_restJson1PayloadTooLargeExceptionResponse(parsedOutput, context)];
            case 13: throw _d.sent();
            case 14: return [4, deserializeAws_restJson1TooManyRequestsExceptionResponse(parsedOutput, context)];
            case 15: throw _d.sent();
            case 16:
                parsedBody = parsedOutput.body;
                throwDefaultError({
                    output: output,
                    parsedBody: parsedBody,
                    exceptionCtor: __BaseException,
                    errorCode: errorCode,
                });
                _d.label = 17;
            case 17: return [2];
        }
    });
}); };
export var deserializeAws_restJson1DeleteBaiduChannelCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var contents, data, _a;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                if (output.statusCode !== 200 && output.statusCode >= 300) {
                    return [2, deserializeAws_restJson1DeleteBaiduChannelCommandError(output, context)];
                }
                contents = map({
                    $metadata: deserializeMetadata(output),
                });
                _a = __expectObject;
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.apply(void 0, [_b.sent()]);
                contents.BaiduChannelResponse = deserializeAws_restJson1BaiduChannelResponse(data, context);
                return [2, contents];
        }
    });
}); };
var deserializeAws_restJson1DeleteBaiduChannelCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, errorCode, _b, parsedBody;
    var _c;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                _a = [__assign({}, output)];
                _c = {};
                return [4, parseErrorBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_c.body = _d.sent(), _c)]));
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "BadRequestException": return [3, 2];
                    case "com.amazonaws.pinpoint#BadRequestException": return [3, 2];
                    case "ForbiddenException": return [3, 4];
                    case "com.amazonaws.pinpoint#ForbiddenException": return [3, 4];
                    case "InternalServerErrorException": return [3, 6];
                    case "com.amazonaws.pinpoint#InternalServerErrorException": return [3, 6];
                    case "MethodNotAllowedException": return [3, 8];
                    case "com.amazonaws.pinpoint#MethodNotAllowedException": return [3, 8];
                    case "NotFoundException": return [3, 10];
                    case "com.amazonaws.pinpoint#NotFoundException": return [3, 10];
                    case "PayloadTooLargeException": return [3, 12];
                    case "com.amazonaws.pinpoint#PayloadTooLargeException": return [3, 12];
                    case "TooManyRequestsException": return [3, 14];
                    case "com.amazonaws.pinpoint#TooManyRequestsException": return [3, 14];
                }
                return [3, 16];
            case 2: return [4, deserializeAws_restJson1BadRequestExceptionResponse(parsedOutput, context)];
            case 3: throw _d.sent();
            case 4: return [4, deserializeAws_restJson1ForbiddenExceptionResponse(parsedOutput, context)];
            case 5: throw _d.sent();
            case 6: return [4, deserializeAws_restJson1InternalServerErrorExceptionResponse(parsedOutput, context)];
            case 7: throw _d.sent();
            case 8: return [4, deserializeAws_restJson1MethodNotAllowedExceptionResponse(parsedOutput, context)];
            case 9: throw _d.sent();
            case 10: return [4, deserializeAws_restJson1NotFoundExceptionResponse(parsedOutput, context)];
            case 11: throw _d.sent();
            case 12: return [4, deserializeAws_restJson1PayloadTooLargeExceptionResponse(parsedOutput, context)];
            case 13: throw _d.sent();
            case 14: return [4, deserializeAws_restJson1TooManyRequestsExceptionResponse(parsedOutput, context)];
            case 15: throw _d.sent();
            case 16:
                parsedBody = parsedOutput.body;
                throwDefaultError({
                    output: output,
                    parsedBody: parsedBody,
                    exceptionCtor: __BaseException,
                    errorCode: errorCode,
                });
                _d.label = 17;
            case 17: return [2];
        }
    });
}); };
export var deserializeAws_restJson1DeleteCampaignCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var contents, data, _a;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                if (output.statusCode !== 200 && output.statusCode >= 300) {
                    return [2, deserializeAws_restJson1DeleteCampaignCommandError(output, context)];
                }
                contents = map({
                    $metadata: deserializeMetadata(output),
                });
                _a = __expectObject;
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.apply(void 0, [_b.sent()]);
                contents.CampaignResponse = deserializeAws_restJson1CampaignResponse(data, context);
                return [2, contents];
        }
    });
}); };
var deserializeAws_restJson1DeleteCampaignCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, errorCode, _b, parsedBody;
    var _c;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                _a = [__assign({}, output)];
                _c = {};
                return [4, parseErrorBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_c.body = _d.sent(), _c)]));
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "BadRequestException": return [3, 2];
                    case "com.amazonaws.pinpoint#BadRequestException": return [3, 2];
                    case "ForbiddenException": return [3, 4];
                    case "com.amazonaws.pinpoint#ForbiddenException": return [3, 4];
                    case "InternalServerErrorException": return [3, 6];
                    case "com.amazonaws.pinpoint#InternalServerErrorException": return [3, 6];
                    case "MethodNotAllowedException": return [3, 8];
                    case "com.amazonaws.pinpoint#MethodNotAllowedException": return [3, 8];
                    case "NotFoundException": return [3, 10];
                    case "com.amazonaws.pinpoint#NotFoundException": return [3, 10];
                    case "PayloadTooLargeException": return [3, 12];
                    case "com.amazonaws.pinpoint#PayloadTooLargeException": return [3, 12];
                    case "TooManyRequestsException": return [3, 14];
                    case "com.amazonaws.pinpoint#TooManyRequestsException": return [3, 14];
                }
                return [3, 16];
            case 2: return [4, deserializeAws_restJson1BadRequestExceptionResponse(parsedOutput, context)];
            case 3: throw _d.sent();
            case 4: return [4, deserializeAws_restJson1ForbiddenExceptionResponse(parsedOutput, context)];
            case 5: throw _d.sent();
            case 6: return [4, deserializeAws_restJson1InternalServerErrorExceptionResponse(parsedOutput, context)];
            case 7: throw _d.sent();
            case 8: return [4, deserializeAws_restJson1MethodNotAllowedExceptionResponse(parsedOutput, context)];
            case 9: throw _d.sent();
            case 10: return [4, deserializeAws_restJson1NotFoundExceptionResponse(parsedOutput, context)];
            case 11: throw _d.sent();
            case 12: return [4, deserializeAws_restJson1PayloadTooLargeExceptionResponse(parsedOutput, context)];
            case 13: throw _d.sent();
            case 14: return [4, deserializeAws_restJson1TooManyRequestsExceptionResponse(parsedOutput, context)];
            case 15: throw _d.sent();
            case 16:
                parsedBody = parsedOutput.body;
                throwDefaultError({
                    output: output,
                    parsedBody: parsedBody,
                    exceptionCtor: __BaseException,
                    errorCode: errorCode,
                });
                _d.label = 17;
            case 17: return [2];
        }
    });
}); };
export var deserializeAws_restJson1DeleteEmailChannelCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var contents, data, _a;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                if (output.statusCode !== 200 && output.statusCode >= 300) {
                    return [2, deserializeAws_restJson1DeleteEmailChannelCommandError(output, context)];
                }
                contents = map({
                    $metadata: deserializeMetadata(output),
                });
                _a = __expectObject;
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.apply(void 0, [_b.sent()]);
                contents.EmailChannelResponse = deserializeAws_restJson1EmailChannelResponse(data, context);
                return [2, contents];
        }
    });
}); };
var deserializeAws_restJson1DeleteEmailChannelCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, errorCode, _b, parsedBody;
    var _c;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                _a = [__assign({}, output)];
                _c = {};
                return [4, parseErrorBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_c.body = _d.sent(), _c)]));
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "BadRequestException": return [3, 2];
                    case "com.amazonaws.pinpoint#BadRequestException": return [3, 2];
                    case "ForbiddenException": return [3, 4];
                    case "com.amazonaws.pinpoint#ForbiddenException": return [3, 4];
                    case "InternalServerErrorException": return [3, 6];
                    case "com.amazonaws.pinpoint#InternalServerErrorException": return [3, 6];
                    case "MethodNotAllowedException": return [3, 8];
                    case "com.amazonaws.pinpoint#MethodNotAllowedException": return [3, 8];
                    case "NotFoundException": return [3, 10];
                    case "com.amazonaws.pinpoint#NotFoundException": return [3, 10];
                    case "PayloadTooLargeException": return [3, 12];
                    case "com.amazonaws.pinpoint#PayloadTooLargeException": return [3, 12];
                    case "TooManyRequestsException": return [3, 14];
                    case "com.amazonaws.pinpoint#TooManyRequestsException": return [3, 14];
                }
                return [3, 16];
            case 2: return [4, deserializeAws_restJson1BadRequestExceptionResponse(parsedOutput, context)];
            case 3: throw _d.sent();
            case 4: return [4, deserializeAws_restJson1ForbiddenExceptionResponse(parsedOutput, context)];
            case 5: throw _d.sent();
            case 6: return [4, deserializeAws_restJson1InternalServerErrorExceptionResponse(parsedOutput, context)];
            case 7: throw _d.sent();
            case 8: return [4, deserializeAws_restJson1MethodNotAllowedExceptionResponse(parsedOutput, context)];
            case 9: throw _d.sent();
            case 10: return [4, deserializeAws_restJson1NotFoundExceptionResponse(parsedOutput, context)];
            case 11: throw _d.sent();
            case 12: return [4, deserializeAws_restJson1PayloadTooLargeExceptionResponse(parsedOutput, context)];
            case 13: throw _d.sent();
            case 14: return [4, deserializeAws_restJson1TooManyRequestsExceptionResponse(parsedOutput, context)];
            case 15: throw _d.sent();
            case 16:
                parsedBody = parsedOutput.body;
                throwDefaultError({
                    output: output,
                    parsedBody: parsedBody,
                    exceptionCtor: __BaseException,
                    errorCode: errorCode,
                });
                _d.label = 17;
            case 17: return [2];
        }
    });
}); };
export var deserializeAws_restJson1DeleteEmailTemplateCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var contents, data, _a;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                if (output.statusCode !== 202 && output.statusCode >= 300) {
                    return [2, deserializeAws_restJson1DeleteEmailTemplateCommandError(output, context)];
                }
                contents = map({
                    $metadata: deserializeMetadata(output),
                });
                _a = __expectObject;
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.apply(void 0, [_b.sent()]);
                contents.MessageBody = deserializeAws_restJson1MessageBody(data, context);
                return [2, contents];
        }
    });
}); };
var deserializeAws_restJson1DeleteEmailTemplateCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, errorCode, _b, parsedBody;
    var _c;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                _a = [__assign({}, output)];
                _c = {};
                return [4, parseErrorBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_c.body = _d.sent(), _c)]));
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "BadRequestException": return [3, 2];
                    case "com.amazonaws.pinpoint#BadRequestException": return [3, 2];
                    case "ForbiddenException": return [3, 4];
                    case "com.amazonaws.pinpoint#ForbiddenException": return [3, 4];
                    case "InternalServerErrorException": return [3, 6];
                    case "com.amazonaws.pinpoint#InternalServerErrorException": return [3, 6];
                    case "MethodNotAllowedException": return [3, 8];
                    case "com.amazonaws.pinpoint#MethodNotAllowedException": return [3, 8];
                    case "NotFoundException": return [3, 10];
                    case "com.amazonaws.pinpoint#NotFoundException": return [3, 10];
                    case "PayloadTooLargeException": return [3, 12];
                    case "com.amazonaws.pinpoint#PayloadTooLargeException": return [3, 12];
                    case "TooManyRequestsException": return [3, 14];
                    case "com.amazonaws.pinpoint#TooManyRequestsException": return [3, 14];
                }
                return [3, 16];
            case 2: return [4, deserializeAws_restJson1BadRequestExceptionResponse(parsedOutput, context)];
            case 3: throw _d.sent();
            case 4: return [4, deserializeAws_restJson1ForbiddenExceptionResponse(parsedOutput, context)];
            case 5: throw _d.sent();
            case 6: return [4, deserializeAws_restJson1InternalServerErrorExceptionResponse(parsedOutput, context)];
            case 7: throw _d.sent();
            case 8: return [4, deserializeAws_restJson1MethodNotAllowedExceptionResponse(parsedOutput, context)];
            case 9: throw _d.sent();
            case 10: return [4, deserializeAws_restJson1NotFoundExceptionResponse(parsedOutput, context)];
            case 11: throw _d.sent();
            case 12: return [4, deserializeAws_restJson1PayloadTooLargeExceptionResponse(parsedOutput, context)];
            case 13: throw _d.sent();
            case 14: return [4, deserializeAws_restJson1TooManyRequestsExceptionResponse(parsedOutput, context)];
            case 15: throw _d.sent();
            case 16:
                parsedBody = parsedOutput.body;
                throwDefaultError({
                    output: output,
                    parsedBody: parsedBody,
                    exceptionCtor: __BaseException,
                    errorCode: errorCode,
                });
                _d.label = 17;
            case 17: return [2];
        }
    });
}); };
export var deserializeAws_restJson1DeleteEndpointCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var contents, data, _a;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                if (output.statusCode !== 202 && output.statusCode >= 300) {
                    return [2, deserializeAws_restJson1DeleteEndpointCommandError(output, context)];
                }
                contents = map({
                    $metadata: deserializeMetadata(output),
                });
                _a = __expectObject;
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.apply(void 0, [_b.sent()]);
                contents.EndpointResponse = deserializeAws_restJson1EndpointResponse(data, context);
                return [2, contents];
        }
    });
}); };
var deserializeAws_restJson1DeleteEndpointCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, errorCode, _b, parsedBody;
    var _c;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                _a = [__assign({}, output)];
                _c = {};
                return [4, parseErrorBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_c.body = _d.sent(), _c)]));
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "BadRequestException": return [3, 2];
                    case "com.amazonaws.pinpoint#BadRequestException": return [3, 2];
                    case "ForbiddenException": return [3, 4];
                    case "com.amazonaws.pinpoint#ForbiddenException": return [3, 4];
                    case "InternalServerErrorException": return [3, 6];
                    case "com.amazonaws.pinpoint#InternalServerErrorException": return [3, 6];
                    case "MethodNotAllowedException": return [3, 8];
                    case "com.amazonaws.pinpoint#MethodNotAllowedException": return [3, 8];
                    case "NotFoundException": return [3, 10];
                    case "com.amazonaws.pinpoint#NotFoundException": return [3, 10];
                    case "PayloadTooLargeException": return [3, 12];
                    case "com.amazonaws.pinpoint#PayloadTooLargeException": return [3, 12];
                    case "TooManyRequestsException": return [3, 14];
                    case "com.amazonaws.pinpoint#TooManyRequestsException": return [3, 14];
                }
                return [3, 16];
            case 2: return [4, deserializeAws_restJson1BadRequestExceptionResponse(parsedOutput, context)];
            case 3: throw _d.sent();
            case 4: return [4, deserializeAws_restJson1ForbiddenExceptionResponse(parsedOutput, context)];
            case 5: throw _d.sent();
            case 6: return [4, deserializeAws_restJson1InternalServerErrorExceptionResponse(parsedOutput, context)];
            case 7: throw _d.sent();
            case 8: return [4, deserializeAws_restJson1MethodNotAllowedExceptionResponse(parsedOutput, context)];
            case 9: throw _d.sent();
            case 10: return [4, deserializeAws_restJson1NotFoundExceptionResponse(parsedOutput, context)];
            case 11: throw _d.sent();
            case 12: return [4, deserializeAws_restJson1PayloadTooLargeExceptionResponse(parsedOutput, context)];
            case 13: throw _d.sent();
            case 14: return [4, deserializeAws_restJson1TooManyRequestsExceptionResponse(parsedOutput, context)];
            case 15: throw _d.sent();
            case 16:
                parsedBody = parsedOutput.body;
                throwDefaultError({
                    output: output,
                    parsedBody: parsedBody,
                    exceptionCtor: __BaseException,
                    errorCode: errorCode,
                });
                _d.label = 17;
            case 17: return [2];
        }
    });
}); };
export var deserializeAws_restJson1DeleteEventStreamCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var contents, data, _a;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                if (output.statusCode !== 200 && output.statusCode >= 300) {
                    return [2, deserializeAws_restJson1DeleteEventStreamCommandError(output, context)];
                }
                contents = map({
                    $metadata: deserializeMetadata(output),
                });
                _a = __expectObject;
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.apply(void 0, [_b.sent()]);
                contents.EventStream = deserializeAws_restJson1EventStream(data, context);
                return [2, contents];
        }
    });
}); };
var deserializeAws_restJson1DeleteEventStreamCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, errorCode, _b, parsedBody;
    var _c;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                _a = [__assign({}, output)];
                _c = {};
                return [4, parseErrorBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_c.body = _d.sent(), _c)]));
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "BadRequestException": return [3, 2];
                    case "com.amazonaws.pinpoint#BadRequestException": return [3, 2];
                    case "ForbiddenException": return [3, 4];
                    case "com.amazonaws.pinpoint#ForbiddenException": return [3, 4];
                    case "InternalServerErrorException": return [3, 6];
                    case "com.amazonaws.pinpoint#InternalServerErrorException": return [3, 6];
                    case "MethodNotAllowedException": return [3, 8];
                    case "com.amazonaws.pinpoint#MethodNotAllowedException": return [3, 8];
                    case "NotFoundException": return [3, 10];
                    case "com.amazonaws.pinpoint#NotFoundException": return [3, 10];
                    case "PayloadTooLargeException": return [3, 12];
                    case "com.amazonaws.pinpoint#PayloadTooLargeException": return [3, 12];
                    case "TooManyRequestsException": return [3, 14];
                    case "com.amazonaws.pinpoint#TooManyRequestsException": return [3, 14];
                }
                return [3, 16];
            case 2: return [4, deserializeAws_restJson1BadRequestExceptionResponse(parsedOutput, context)];
            case 3: throw _d.sent();
            case 4: return [4, deserializeAws_restJson1ForbiddenExceptionResponse(parsedOutput, context)];
            case 5: throw _d.sent();
            case 6: return [4, deserializeAws_restJson1InternalServerErrorExceptionResponse(parsedOutput, context)];
            case 7: throw _d.sent();
            case 8: return [4, deserializeAws_restJson1MethodNotAllowedExceptionResponse(parsedOutput, context)];
            case 9: throw _d.sent();
            case 10: return [4, deserializeAws_restJson1NotFoundExceptionResponse(parsedOutput, context)];
            case 11: throw _d.sent();
            case 12: return [4, deserializeAws_restJson1PayloadTooLargeExceptionResponse(parsedOutput, context)];
            case 13: throw _d.sent();
            case 14: return [4, deserializeAws_restJson1TooManyRequestsExceptionResponse(parsedOutput, context)];
            case 15: throw _d.sent();
            case 16:
                parsedBody = parsedOutput.body;
                throwDefaultError({
                    output: output,
                    parsedBody: parsedBody,
                    exceptionCtor: __BaseException,
                    errorCode: errorCode,
                });
                _d.label = 17;
            case 17: return [2];
        }
    });
}); };
export var deserializeAws_restJson1DeleteGcmChannelCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var contents, data, _a;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                if (output.statusCode !== 200 && output.statusCode >= 300) {
                    return [2, deserializeAws_restJson1DeleteGcmChannelCommandError(output, context)];
                }
                contents = map({
                    $metadata: deserializeMetadata(output),
                });
                _a = __expectObject;
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.apply(void 0, [_b.sent()]);
                contents.GCMChannelResponse = deserializeAws_restJson1GCMChannelResponse(data, context);
                return [2, contents];
        }
    });
}); };
var deserializeAws_restJson1DeleteGcmChannelCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, errorCode, _b, parsedBody;
    var _c;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                _a = [__assign({}, output)];
                _c = {};
                return [4, parseErrorBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_c.body = _d.sent(), _c)]));
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "BadRequestException": return [3, 2];
                    case "com.amazonaws.pinpoint#BadRequestException": return [3, 2];
                    case "ForbiddenException": return [3, 4];
                    case "com.amazonaws.pinpoint#ForbiddenException": return [3, 4];
                    case "InternalServerErrorException": return [3, 6];
                    case "com.amazonaws.pinpoint#InternalServerErrorException": return [3, 6];
                    case "MethodNotAllowedException": return [3, 8];
                    case "com.amazonaws.pinpoint#MethodNotAllowedException": return [3, 8];
                    case "NotFoundException": return [3, 10];
                    case "com.amazonaws.pinpoint#NotFoundException": return [3, 10];
                    case "PayloadTooLargeException": return [3, 12];
                    case "com.amazonaws.pinpoint#PayloadTooLargeException": return [3, 12];
                    case "TooManyRequestsException": return [3, 14];
                    case "com.amazonaws.pinpoint#TooManyRequestsException": return [3, 14];
                }
                return [3, 16];
            case 2: return [4, deserializeAws_restJson1BadRequestExceptionResponse(parsedOutput, context)];
            case 3: throw _d.sent();
            case 4: return [4, deserializeAws_restJson1ForbiddenExceptionResponse(parsedOutput, context)];
            case 5: throw _d.sent();
            case 6: return [4, deserializeAws_restJson1InternalServerErrorExceptionResponse(parsedOutput, context)];
            case 7: throw _d.sent();
            case 8: return [4, deserializeAws_restJson1MethodNotAllowedExceptionResponse(parsedOutput, context)];
            case 9: throw _d.sent();
            case 10: return [4, deserializeAws_restJson1NotFoundExceptionResponse(parsedOutput, context)];
            case 11: throw _d.sent();
            case 12: return [4, deserializeAws_restJson1PayloadTooLargeExceptionResponse(parsedOutput, context)];
            case 13: throw _d.sent();
            case 14: return [4, deserializeAws_restJson1TooManyRequestsExceptionResponse(parsedOutput, context)];
            case 15: throw _d.sent();
            case 16:
                parsedBody = parsedOutput.body;
                throwDefaultError({
                    output: output,
                    parsedBody: parsedBody,
                    exceptionCtor: __BaseException,
                    errorCode: errorCode,
                });
                _d.label = 17;
            case 17: return [2];
        }
    });
}); };
export var deserializeAws_restJson1DeleteInAppTemplateCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var contents, data, _a;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                if (output.statusCode !== 202 && output.statusCode >= 300) {
                    return [2, deserializeAws_restJson1DeleteInAppTemplateCommandError(output, context)];
                }
                contents = map({
                    $metadata: deserializeMetadata(output),
                });
                _a = __expectObject;
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.apply(void 0, [_b.sent()]);
                contents.MessageBody = deserializeAws_restJson1MessageBody(data, context);
                return [2, contents];
        }
    });
}); };
var deserializeAws_restJson1DeleteInAppTemplateCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, errorCode, _b, parsedBody;
    var _c;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                _a = [__assign({}, output)];
                _c = {};
                return [4, parseErrorBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_c.body = _d.sent(), _c)]));
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "BadRequestException": return [3, 2];
                    case "com.amazonaws.pinpoint#BadRequestException": return [3, 2];
                    case "ForbiddenException": return [3, 4];
                    case "com.amazonaws.pinpoint#ForbiddenException": return [3, 4];
                    case "InternalServerErrorException": return [3, 6];
                    case "com.amazonaws.pinpoint#InternalServerErrorException": return [3, 6];
                    case "MethodNotAllowedException": return [3, 8];
                    case "com.amazonaws.pinpoint#MethodNotAllowedException": return [3, 8];
                    case "NotFoundException": return [3, 10];
                    case "com.amazonaws.pinpoint#NotFoundException": return [3, 10];
                    case "PayloadTooLargeException": return [3, 12];
                    case "com.amazonaws.pinpoint#PayloadTooLargeException": return [3, 12];
                    case "TooManyRequestsException": return [3, 14];
                    case "com.amazonaws.pinpoint#TooManyRequestsException": return [3, 14];
                }
                return [3, 16];
            case 2: return [4, deserializeAws_restJson1BadRequestExceptionResponse(parsedOutput, context)];
            case 3: throw _d.sent();
            case 4: return [4, deserializeAws_restJson1ForbiddenExceptionResponse(parsedOutput, context)];
            case 5: throw _d.sent();
            case 6: return [4, deserializeAws_restJson1InternalServerErrorExceptionResponse(parsedOutput, context)];
            case 7: throw _d.sent();
            case 8: return [4, deserializeAws_restJson1MethodNotAllowedExceptionResponse(parsedOutput, context)];
            case 9: throw _d.sent();
            case 10: return [4, deserializeAws_restJson1NotFoundExceptionResponse(parsedOutput, context)];
            case 11: throw _d.sent();
            case 12: return [4, deserializeAws_restJson1PayloadTooLargeExceptionResponse(parsedOutput, context)];
            case 13: throw _d.sent();
            case 14: return [4, deserializeAws_restJson1TooManyRequestsExceptionResponse(parsedOutput, context)];
            case 15: throw _d.sent();
            case 16:
                parsedBody = parsedOutput.body;
                throwDefaultError({
                    output: output,
                    parsedBody: parsedBody,
                    exceptionCtor: __BaseException,
                    errorCode: errorCode,
                });
                _d.label = 17;
            case 17: return [2];
        }
    });
}); };
export var deserializeAws_restJson1DeleteJourneyCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var contents, data, _a;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                if (output.statusCode !== 200 && output.statusCode >= 300) {
                    return [2, deserializeAws_restJson1DeleteJourneyCommandError(output, context)];
                }
                contents = map({
                    $metadata: deserializeMetadata(output),
                });
                _a = __expectObject;
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.apply(void 0, [_b.sent()]);
                contents.JourneyResponse = deserializeAws_restJson1JourneyResponse(data, context);
                return [2, contents];
        }
    });
}); };
var deserializeAws_restJson1DeleteJourneyCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, errorCode, _b, parsedBody;
    var _c;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                _a = [__assign({}, output)];
                _c = {};
                return [4, parseErrorBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_c.body = _d.sent(), _c)]));
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "BadRequestException": return [3, 2];
                    case "com.amazonaws.pinpoint#BadRequestException": return [3, 2];
                    case "ForbiddenException": return [3, 4];
                    case "com.amazonaws.pinpoint#ForbiddenException": return [3, 4];
                    case "InternalServerErrorException": return [3, 6];
                    case "com.amazonaws.pinpoint#InternalServerErrorException": return [3, 6];
                    case "MethodNotAllowedException": return [3, 8];
                    case "com.amazonaws.pinpoint#MethodNotAllowedException": return [3, 8];
                    case "NotFoundException": return [3, 10];
                    case "com.amazonaws.pinpoint#NotFoundException": return [3, 10];
                    case "PayloadTooLargeException": return [3, 12];
                    case "com.amazonaws.pinpoint#PayloadTooLargeException": return [3, 12];
                    case "TooManyRequestsException": return [3, 14];
                    case "com.amazonaws.pinpoint#TooManyRequestsException": return [3, 14];
                }
                return [3, 16];
            case 2: return [4, deserializeAws_restJson1BadRequestExceptionResponse(parsedOutput, context)];
            case 3: throw _d.sent();
            case 4: return [4, deserializeAws_restJson1ForbiddenExceptionResponse(parsedOutput, context)];
            case 5: throw _d.sent();
            case 6: return [4, deserializeAws_restJson1InternalServerErrorExceptionResponse(parsedOutput, context)];
            case 7: throw _d.sent();
            case 8: return [4, deserializeAws_restJson1MethodNotAllowedExceptionResponse(parsedOutput, context)];
            case 9: throw _d.sent();
            case 10: return [4, deserializeAws_restJson1NotFoundExceptionResponse(parsedOutput, context)];
            case 11: throw _d.sent();
            case 12: return [4, deserializeAws_restJson1PayloadTooLargeExceptionResponse(parsedOutput, context)];
            case 13: throw _d.sent();
            case 14: return [4, deserializeAws_restJson1TooManyRequestsExceptionResponse(parsedOutput, context)];
            case 15: throw _d.sent();
            case 16:
                parsedBody = parsedOutput.body;
                throwDefaultError({
                    output: output,
                    parsedBody: parsedBody,
                    exceptionCtor: __BaseException,
                    errorCode: errorCode,
                });
                _d.label = 17;
            case 17: return [2];
        }
    });
}); };
export var deserializeAws_restJson1DeletePushTemplateCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var contents, data, _a;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                if (output.statusCode !== 202 && output.statusCode >= 300) {
                    return [2, deserializeAws_restJson1DeletePushTemplateCommandError(output, context)];
                }
                contents = map({
                    $metadata: deserializeMetadata(output),
                });
                _a = __expectObject;
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.apply(void 0, [_b.sent()]);
                contents.MessageBody = deserializeAws_restJson1MessageBody(data, context);
                return [2, contents];
        }
    });
}); };
var deserializeAws_restJson1DeletePushTemplateCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, errorCode, _b, parsedBody;
    var _c;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                _a = [__assign({}, output)];
                _c = {};
                return [4, parseErrorBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_c.body = _d.sent(), _c)]));
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "BadRequestException": return [3, 2];
                    case "com.amazonaws.pinpoint#BadRequestException": return [3, 2];
                    case "ForbiddenException": return [3, 4];
                    case "com.amazonaws.pinpoint#ForbiddenException": return [3, 4];
                    case "InternalServerErrorException": return [3, 6];
                    case "com.amazonaws.pinpoint#InternalServerErrorException": return [3, 6];
                    case "MethodNotAllowedException": return [3, 8];
                    case "com.amazonaws.pinpoint#MethodNotAllowedException": return [3, 8];
                    case "NotFoundException": return [3, 10];
                    case "com.amazonaws.pinpoint#NotFoundException": return [3, 10];
                    case "PayloadTooLargeException": return [3, 12];
                    case "com.amazonaws.pinpoint#PayloadTooLargeException": return [3, 12];
                    case "TooManyRequestsException": return [3, 14];
                    case "com.amazonaws.pinpoint#TooManyRequestsException": return [3, 14];
                }
                return [3, 16];
            case 2: return [4, deserializeAws_restJson1BadRequestExceptionResponse(parsedOutput, context)];
            case 3: throw _d.sent();
            case 4: return [4, deserializeAws_restJson1ForbiddenExceptionResponse(parsedOutput, context)];
            case 5: throw _d.sent();
            case 6: return [4, deserializeAws_restJson1InternalServerErrorExceptionResponse(parsedOutput, context)];
            case 7: throw _d.sent();
            case 8: return [4, deserializeAws_restJson1MethodNotAllowedExceptionResponse(parsedOutput, context)];
            case 9: throw _d.sent();
            case 10: return [4, deserializeAws_restJson1NotFoundExceptionResponse(parsedOutput, context)];
            case 11: throw _d.sent();
            case 12: return [4, deserializeAws_restJson1PayloadTooLargeExceptionResponse(parsedOutput, context)];
            case 13: throw _d.sent();
            case 14: return [4, deserializeAws_restJson1TooManyRequestsExceptionResponse(parsedOutput, context)];
            case 15: throw _d.sent();
            case 16:
                parsedBody = parsedOutput.body;
                throwDefaultError({
                    output: output,
                    parsedBody: parsedBody,
                    exceptionCtor: __BaseException,
                    errorCode: errorCode,
                });
                _d.label = 17;
            case 17: return [2];
        }
    });
}); };
export var deserializeAws_restJson1DeleteRecommenderConfigurationCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var contents, data, _a;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                if (output.statusCode !== 200 && output.statusCode >= 300) {
                    return [2, deserializeAws_restJson1DeleteRecommenderConfigurationCommandError(output, context)];
                }
                contents = map({
                    $metadata: deserializeMetadata(output),
                });
                _a = __expectObject;
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.apply(void 0, [_b.sent()]);
                contents.RecommenderConfigurationResponse = deserializeAws_restJson1RecommenderConfigurationResponse(data, context);
                return [2, contents];
        }
    });
}); };
var deserializeAws_restJson1DeleteRecommenderConfigurationCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, errorCode, _b, parsedBody;
    var _c;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                _a = [__assign({}, output)];
                _c = {};
                return [4, parseErrorBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_c.body = _d.sent(), _c)]));
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "BadRequestException": return [3, 2];
                    case "com.amazonaws.pinpoint#BadRequestException": return [3, 2];
                    case "ForbiddenException": return [3, 4];
                    case "com.amazonaws.pinpoint#ForbiddenException": return [3, 4];
                    case "InternalServerErrorException": return [3, 6];
                    case "com.amazonaws.pinpoint#InternalServerErrorException": return [3, 6];
                    case "MethodNotAllowedException": return [3, 8];
                    case "com.amazonaws.pinpoint#MethodNotAllowedException": return [3, 8];
                    case "NotFoundException": return [3, 10];
                    case "com.amazonaws.pinpoint#NotFoundException": return [3, 10];
                    case "PayloadTooLargeException": return [3, 12];
                    case "com.amazonaws.pinpoint#PayloadTooLargeException": return [3, 12];
                    case "TooManyRequestsException": return [3, 14];
                    case "com.amazonaws.pinpoint#TooManyRequestsException": return [3, 14];
                }
                return [3, 16];
            case 2: return [4, deserializeAws_restJson1BadRequestExceptionResponse(parsedOutput, context)];
            case 3: throw _d.sent();
            case 4: return [4, deserializeAws_restJson1ForbiddenExceptionResponse(parsedOutput, context)];
            case 5: throw _d.sent();
            case 6: return [4, deserializeAws_restJson1InternalServerErrorExceptionResponse(parsedOutput, context)];
            case 7: throw _d.sent();
            case 8: return [4, deserializeAws_restJson1MethodNotAllowedExceptionResponse(parsedOutput, context)];
            case 9: throw _d.sent();
            case 10: return [4, deserializeAws_restJson1NotFoundExceptionResponse(parsedOutput, context)];
            case 11: throw _d.sent();
            case 12: return [4, deserializeAws_restJson1PayloadTooLargeExceptionResponse(parsedOutput, context)];
            case 13: throw _d.sent();
            case 14: return [4, deserializeAws_restJson1TooManyRequestsExceptionResponse(parsedOutput, context)];
            case 15: throw _d.sent();
            case 16:
                parsedBody = parsedOutput.body;
                throwDefaultError({
                    output: output,
                    parsedBody: parsedBody,
                    exceptionCtor: __BaseException,
                    errorCode: errorCode,
                });
                _d.label = 17;
            case 17: return [2];
        }
    });
}); };
export var deserializeAws_restJson1DeleteSegmentCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var contents, data, _a;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                if (output.statusCode !== 200 && output.statusCode >= 300) {
                    return [2, deserializeAws_restJson1DeleteSegmentCommandError(output, context)];
                }
                contents = map({
                    $metadata: deserializeMetadata(output),
                });
                _a = __expectObject;
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.apply(void 0, [_b.sent()]);
                contents.SegmentResponse = deserializeAws_restJson1SegmentResponse(data, context);
                return [2, contents];
        }
    });
}); };
var deserializeAws_restJson1DeleteSegmentCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, errorCode, _b, parsedBody;
    var _c;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                _a = [__assign({}, output)];
                _c = {};
                return [4, parseErrorBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_c.body = _d.sent(), _c)]));
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "BadRequestException": return [3, 2];
                    case "com.amazonaws.pinpoint#BadRequestException": return [3, 2];
                    case "ForbiddenException": return [3, 4];
                    case "com.amazonaws.pinpoint#ForbiddenException": return [3, 4];
                    case "InternalServerErrorException": return [3, 6];
                    case "com.amazonaws.pinpoint#InternalServerErrorException": return [3, 6];
                    case "MethodNotAllowedException": return [3, 8];
                    case "com.amazonaws.pinpoint#MethodNotAllowedException": return [3, 8];
                    case "NotFoundException": return [3, 10];
                    case "com.amazonaws.pinpoint#NotFoundException": return [3, 10];
                    case "PayloadTooLargeException": return [3, 12];
                    case "com.amazonaws.pinpoint#PayloadTooLargeException": return [3, 12];
                    case "TooManyRequestsException": return [3, 14];
                    case "com.amazonaws.pinpoint#TooManyRequestsException": return [3, 14];
                }
                return [3, 16];
            case 2: return [4, deserializeAws_restJson1BadRequestExceptionResponse(parsedOutput, context)];
            case 3: throw _d.sent();
            case 4: return [4, deserializeAws_restJson1ForbiddenExceptionResponse(parsedOutput, context)];
            case 5: throw _d.sent();
            case 6: return [4, deserializeAws_restJson1InternalServerErrorExceptionResponse(parsedOutput, context)];
            case 7: throw _d.sent();
            case 8: return [4, deserializeAws_restJson1MethodNotAllowedExceptionResponse(parsedOutput, context)];
            case 9: throw _d.sent();
            case 10: return [4, deserializeAws_restJson1NotFoundExceptionResponse(parsedOutput, context)];
            case 11: throw _d.sent();
            case 12: return [4, deserializeAws_restJson1PayloadTooLargeExceptionResponse(parsedOutput, context)];
            case 13: throw _d.sent();
            case 14: return [4, deserializeAws_restJson1TooManyRequestsExceptionResponse(parsedOutput, context)];
            case 15: throw _d.sent();
            case 16:
                parsedBody = parsedOutput.body;
                throwDefaultError({
                    output: output,
                    parsedBody: parsedBody,
                    exceptionCtor: __BaseException,
                    errorCode: errorCode,
                });
                _d.label = 17;
            case 17: return [2];
        }
    });
}); };
export var deserializeAws_restJson1DeleteSmsChannelCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var contents, data, _a;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                if (output.statusCode !== 200 && output.statusCode >= 300) {
                    return [2, deserializeAws_restJson1DeleteSmsChannelCommandError(output, context)];
                }
                contents = map({
                    $metadata: deserializeMetadata(output),
                });
                _a = __expectObject;
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.apply(void 0, [_b.sent()]);
                contents.SMSChannelResponse = deserializeAws_restJson1SMSChannelResponse(data, context);
                return [2, contents];
        }
    });
}); };
var deserializeAws_restJson1DeleteSmsChannelCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, errorCode, _b, parsedBody;
    var _c;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                _a = [__assign({}, output)];
                _c = {};
                return [4, parseErrorBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_c.body = _d.sent(), _c)]));
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "BadRequestException": return [3, 2];
                    case "com.amazonaws.pinpoint#BadRequestException": return [3, 2];
                    case "ForbiddenException": return [3, 4];
                    case "com.amazonaws.pinpoint#ForbiddenException": return [3, 4];
                    case "InternalServerErrorException": return [3, 6];
                    case "com.amazonaws.pinpoint#InternalServerErrorException": return [3, 6];
                    case "MethodNotAllowedException": return [3, 8];
                    case "com.amazonaws.pinpoint#MethodNotAllowedException": return [3, 8];
                    case "NotFoundException": return [3, 10];
                    case "com.amazonaws.pinpoint#NotFoundException": return [3, 10];
                    case "PayloadTooLargeException": return [3, 12];
                    case "com.amazonaws.pinpoint#PayloadTooLargeException": return [3, 12];
                    case "TooManyRequestsException": return [3, 14];
                    case "com.amazonaws.pinpoint#TooManyRequestsException": return [3, 14];
                }
                return [3, 16];
            case 2: return [4, deserializeAws_restJson1BadRequestExceptionResponse(parsedOutput, context)];
            case 3: throw _d.sent();
            case 4: return [4, deserializeAws_restJson1ForbiddenExceptionResponse(parsedOutput, context)];
            case 5: throw _d.sent();
            case 6: return [4, deserializeAws_restJson1InternalServerErrorExceptionResponse(parsedOutput, context)];
            case 7: throw _d.sent();
            case 8: return [4, deserializeAws_restJson1MethodNotAllowedExceptionResponse(parsedOutput, context)];
            case 9: throw _d.sent();
            case 10: return [4, deserializeAws_restJson1NotFoundExceptionResponse(parsedOutput, context)];
            case 11: throw _d.sent();
            case 12: return [4, deserializeAws_restJson1PayloadTooLargeExceptionResponse(parsedOutput, context)];
            case 13: throw _d.sent();
            case 14: return [4, deserializeAws_restJson1TooManyRequestsExceptionResponse(parsedOutput, context)];
            case 15: throw _d.sent();
            case 16:
                parsedBody = parsedOutput.body;
                throwDefaultError({
                    output: output,
                    parsedBody: parsedBody,
                    exceptionCtor: __BaseException,
                    errorCode: errorCode,
                });
                _d.label = 17;
            case 17: return [2];
        }
    });
}); };
export var deserializeAws_restJson1DeleteSmsTemplateCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var contents, data, _a;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                if (output.statusCode !== 202 && output.statusCode >= 300) {
                    return [2, deserializeAws_restJson1DeleteSmsTemplateCommandError(output, context)];
                }
                contents = map({
                    $metadata: deserializeMetadata(output),
                });
                _a = __expectObject;
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.apply(void 0, [_b.sent()]);
                contents.MessageBody = deserializeAws_restJson1MessageBody(data, context);
                return [2, contents];
        }
    });
}); };
var deserializeAws_restJson1DeleteSmsTemplateCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, errorCode, _b, parsedBody;
    var _c;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                _a = [__assign({}, output)];
                _c = {};
                return [4, parseErrorBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_c.body = _d.sent(), _c)]));
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "BadRequestException": return [3, 2];
                    case "com.amazonaws.pinpoint#BadRequestException": return [3, 2];
                    case "ForbiddenException": return [3, 4];
                    case "com.amazonaws.pinpoint#ForbiddenException": return [3, 4];
                    case "InternalServerErrorException": return [3, 6];
                    case "com.amazonaws.pinpoint#InternalServerErrorException": return [3, 6];
                    case "MethodNotAllowedException": return [3, 8];
                    case "com.amazonaws.pinpoint#MethodNotAllowedException": return [3, 8];
                    case "NotFoundException": return [3, 10];
                    case "com.amazonaws.pinpoint#NotFoundException": return [3, 10];
                    case "PayloadTooLargeException": return [3, 12];
                    case "com.amazonaws.pinpoint#PayloadTooLargeException": return [3, 12];
                    case "TooManyRequestsException": return [3, 14];
                    case "com.amazonaws.pinpoint#TooManyRequestsException": return [3, 14];
                }
                return [3, 16];
            case 2: return [4, deserializeAws_restJson1BadRequestExceptionResponse(parsedOutput, context)];
            case 3: throw _d.sent();
            case 4: return [4, deserializeAws_restJson1ForbiddenExceptionResponse(parsedOutput, context)];
            case 5: throw _d.sent();
            case 6: return [4, deserializeAws_restJson1InternalServerErrorExceptionResponse(parsedOutput, context)];
            case 7: throw _d.sent();
            case 8: return [4, deserializeAws_restJson1MethodNotAllowedExceptionResponse(parsedOutput, context)];
            case 9: throw _d.sent();
            case 10: return [4, deserializeAws_restJson1NotFoundExceptionResponse(parsedOutput, context)];
            case 11: throw _d.sent();
            case 12: return [4, deserializeAws_restJson1PayloadTooLargeExceptionResponse(parsedOutput, context)];
            case 13: throw _d.sent();
            case 14: return [4, deserializeAws_restJson1TooManyRequestsExceptionResponse(parsedOutput, context)];
            case 15: throw _d.sent();
            case 16:
                parsedBody = parsedOutput.body;
                throwDefaultError({
                    output: output,
                    parsedBody: parsedBody,
                    exceptionCtor: __BaseException,
                    errorCode: errorCode,
                });
                _d.label = 17;
            case 17: return [2];
        }
    });
}); };
export var deserializeAws_restJson1DeleteUserEndpointsCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var contents, data, _a;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                if (output.statusCode !== 202 && output.statusCode >= 300) {
                    return [2, deserializeAws_restJson1DeleteUserEndpointsCommandError(output, context)];
                }
                contents = map({
                    $metadata: deserializeMetadata(output),
                });
                _a = __expectObject;
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.apply(void 0, [_b.sent()]);
                contents.EndpointsResponse = deserializeAws_restJson1EndpointsResponse(data, context);
                return [2, contents];
        }
    });
}); };
var deserializeAws_restJson1DeleteUserEndpointsCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, errorCode, _b, parsedBody;
    var _c;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                _a = [__assign({}, output)];
                _c = {};
                return [4, parseErrorBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_c.body = _d.sent(), _c)]));
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "BadRequestException": return [3, 2];
                    case "com.amazonaws.pinpoint#BadRequestException": return [3, 2];
                    case "ForbiddenException": return [3, 4];
                    case "com.amazonaws.pinpoint#ForbiddenException": return [3, 4];
                    case "InternalServerErrorException": return [3, 6];
                    case "com.amazonaws.pinpoint#InternalServerErrorException": return [3, 6];
                    case "MethodNotAllowedException": return [3, 8];
                    case "com.amazonaws.pinpoint#MethodNotAllowedException": return [3, 8];
                    case "NotFoundException": return [3, 10];
                    case "com.amazonaws.pinpoint#NotFoundException": return [3, 10];
                    case "PayloadTooLargeException": return [3, 12];
                    case "com.amazonaws.pinpoint#PayloadTooLargeException": return [3, 12];
                    case "TooManyRequestsException": return [3, 14];
                    case "com.amazonaws.pinpoint#TooManyRequestsException": return [3, 14];
                }
                return [3, 16];
            case 2: return [4, deserializeAws_restJson1BadRequestExceptionResponse(parsedOutput, context)];
            case 3: throw _d.sent();
            case 4: return [4, deserializeAws_restJson1ForbiddenExceptionResponse(parsedOutput, context)];
            case 5: throw _d.sent();
            case 6: return [4, deserializeAws_restJson1InternalServerErrorExceptionResponse(parsedOutput, context)];
            case 7: throw _d.sent();
            case 8: return [4, deserializeAws_restJson1MethodNotAllowedExceptionResponse(parsedOutput, context)];
            case 9: throw _d.sent();
            case 10: return [4, deserializeAws_restJson1NotFoundExceptionResponse(parsedOutput, context)];
            case 11: throw _d.sent();
            case 12: return [4, deserializeAws_restJson1PayloadTooLargeExceptionResponse(parsedOutput, context)];
            case 13: throw _d.sent();
            case 14: return [4, deserializeAws_restJson1TooManyRequestsExceptionResponse(parsedOutput, context)];
            case 15: throw _d.sent();
            case 16:
                parsedBody = parsedOutput.body;
                throwDefaultError({
                    output: output,
                    parsedBody: parsedBody,
                    exceptionCtor: __BaseException,
                    errorCode: errorCode,
                });
                _d.label = 17;
            case 17: return [2];
        }
    });
}); };
export var deserializeAws_restJson1DeleteVoiceChannelCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var contents, data, _a;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                if (output.statusCode !== 200 && output.statusCode >= 300) {
                    return [2, deserializeAws_restJson1DeleteVoiceChannelCommandError(output, context)];
                }
                contents = map({
                    $metadata: deserializeMetadata(output),
                });
                _a = __expectObject;
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.apply(void 0, [_b.sent()]);
                contents.VoiceChannelResponse = deserializeAws_restJson1VoiceChannelResponse(data, context);
                return [2, contents];
        }
    });
}); };
var deserializeAws_restJson1DeleteVoiceChannelCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, errorCode, _b, parsedBody;
    var _c;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                _a = [__assign({}, output)];
                _c = {};
                return [4, parseErrorBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_c.body = _d.sent(), _c)]));
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "BadRequestException": return [3, 2];
                    case "com.amazonaws.pinpoint#BadRequestException": return [3, 2];
                    case "ForbiddenException": return [3, 4];
                    case "com.amazonaws.pinpoint#ForbiddenException": return [3, 4];
                    case "InternalServerErrorException": return [3, 6];
                    case "com.amazonaws.pinpoint#InternalServerErrorException": return [3, 6];
                    case "MethodNotAllowedException": return [3, 8];
                    case "com.amazonaws.pinpoint#MethodNotAllowedException": return [3, 8];
                    case "NotFoundException": return [3, 10];
                    case "com.amazonaws.pinpoint#NotFoundException": return [3, 10];
                    case "PayloadTooLargeException": return [3, 12];
                    case "com.amazonaws.pinpoint#PayloadTooLargeException": return [3, 12];
                    case "TooManyRequestsException": return [3, 14];
                    case "com.amazonaws.pinpoint#TooManyRequestsException": return [3, 14];
                }
                return [3, 16];
            case 2: return [4, deserializeAws_restJson1BadRequestExceptionResponse(parsedOutput, context)];
            case 3: throw _d.sent();
            case 4: return [4, deserializeAws_restJson1ForbiddenExceptionResponse(parsedOutput, context)];
            case 5: throw _d.sent();
            case 6: return [4, deserializeAws_restJson1InternalServerErrorExceptionResponse(parsedOutput, context)];
            case 7: throw _d.sent();
            case 8: return [4, deserializeAws_restJson1MethodNotAllowedExceptionResponse(parsedOutput, context)];
            case 9: throw _d.sent();
            case 10: return [4, deserializeAws_restJson1NotFoundExceptionResponse(parsedOutput, context)];
            case 11: throw _d.sent();
            case 12: return [4, deserializeAws_restJson1PayloadTooLargeExceptionResponse(parsedOutput, context)];
            case 13: throw _d.sent();
            case 14: return [4, deserializeAws_restJson1TooManyRequestsExceptionResponse(parsedOutput, context)];
            case 15: throw _d.sent();
            case 16:
                parsedBody = parsedOutput.body;
                throwDefaultError({
                    output: output,
                    parsedBody: parsedBody,
                    exceptionCtor: __BaseException,
                    errorCode: errorCode,
                });
                _d.label = 17;
            case 17: return [2];
        }
    });
}); };
export var deserializeAws_restJson1DeleteVoiceTemplateCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var contents, data, _a;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                if (output.statusCode !== 202 && output.statusCode >= 300) {
                    return [2, deserializeAws_restJson1DeleteVoiceTemplateCommandError(output, context)];
                }
                contents = map({
                    $metadata: deserializeMetadata(output),
                });
                _a = __expectObject;
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.apply(void 0, [_b.sent()]);
                contents.MessageBody = deserializeAws_restJson1MessageBody(data, context);
                return [2, contents];
        }
    });
}); };
var deserializeAws_restJson1DeleteVoiceTemplateCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, errorCode, _b, parsedBody;
    var _c;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                _a = [__assign({}, output)];
                _c = {};
                return [4, parseErrorBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_c.body = _d.sent(), _c)]));
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "BadRequestException": return [3, 2];
                    case "com.amazonaws.pinpoint#BadRequestException": return [3, 2];
                    case "ForbiddenException": return [3, 4];
                    case "com.amazonaws.pinpoint#ForbiddenException": return [3, 4];
                    case "InternalServerErrorException": return [3, 6];
                    case "com.amazonaws.pinpoint#InternalServerErrorException": return [3, 6];
                    case "MethodNotAllowedException": return [3, 8];
                    case "com.amazonaws.pinpoint#MethodNotAllowedException": return [3, 8];
                    case "NotFoundException": return [3, 10];
                    case "com.amazonaws.pinpoint#NotFoundException": return [3, 10];
                    case "PayloadTooLargeException": return [3, 12];
                    case "com.amazonaws.pinpoint#PayloadTooLargeException": return [3, 12];
                    case "TooManyRequestsException": return [3, 14];
                    case "com.amazonaws.pinpoint#TooManyRequestsException": return [3, 14];
                }
                return [3, 16];
            case 2: return [4, deserializeAws_restJson1BadRequestExceptionResponse(parsedOutput, context)];
            case 3: throw _d.sent();
            case 4: return [4, deserializeAws_restJson1ForbiddenExceptionResponse(parsedOutput, context)];
            case 5: throw _d.sent();
            case 6: return [4, deserializeAws_restJson1InternalServerErrorExceptionResponse(parsedOutput, context)];
            case 7: throw _d.sent();
            case 8: return [4, deserializeAws_restJson1MethodNotAllowedExceptionResponse(parsedOutput, context)];
            case 9: throw _d.sent();
            case 10: return [4, deserializeAws_restJson1NotFoundExceptionResponse(parsedOutput, context)];
            case 11: throw _d.sent();
            case 12: return [4, deserializeAws_restJson1PayloadTooLargeExceptionResponse(parsedOutput, context)];
            case 13: throw _d.sent();
            case 14: return [4, deserializeAws_restJson1TooManyRequestsExceptionResponse(parsedOutput, context)];
            case 15: throw _d.sent();
            case 16:
                parsedBody = parsedOutput.body;
                throwDefaultError({
                    output: output,
                    parsedBody: parsedBody,
                    exceptionCtor: __BaseException,
                    errorCode: errorCode,
                });
                _d.label = 17;
            case 17: return [2];
        }
    });
}); };
export var deserializeAws_restJson1GetAdmChannelCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var contents, data, _a;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                if (output.statusCode !== 200 && output.statusCode >= 300) {
                    return [2, deserializeAws_restJson1GetAdmChannelCommandError(output, context)];
                }
                contents = map({
                    $metadata: deserializeMetadata(output),
                });
                _a = __expectObject;
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.apply(void 0, [_b.sent()]);
                contents.ADMChannelResponse = deserializeAws_restJson1ADMChannelResponse(data, context);
                return [2, contents];
        }
    });
}); };
var deserializeAws_restJson1GetAdmChannelCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, errorCode, _b, parsedBody;
    var _c;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                _a = [__assign({}, output)];
                _c = {};
                return [4, parseErrorBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_c.body = _d.sent(), _c)]));
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "BadRequestException": return [3, 2];
                    case "com.amazonaws.pinpoint#BadRequestException": return [3, 2];
                    case "ForbiddenException": return [3, 4];
                    case "com.amazonaws.pinpoint#ForbiddenException": return [3, 4];
                    case "InternalServerErrorException": return [3, 6];
                    case "com.amazonaws.pinpoint#InternalServerErrorException": return [3, 6];
                    case "MethodNotAllowedException": return [3, 8];
                    case "com.amazonaws.pinpoint#MethodNotAllowedException": return [3, 8];
                    case "NotFoundException": return [3, 10];
                    case "com.amazonaws.pinpoint#NotFoundException": return [3, 10];
                    case "PayloadTooLargeException": return [3, 12];
                    case "com.amazonaws.pinpoint#PayloadTooLargeException": return [3, 12];
                    case "TooManyRequestsException": return [3, 14];
                    case "com.amazonaws.pinpoint#TooManyRequestsException": return [3, 14];
                }
                return [3, 16];
            case 2: return [4, deserializeAws_restJson1BadRequestExceptionResponse(parsedOutput, context)];
            case 3: throw _d.sent();
            case 4: return [4, deserializeAws_restJson1ForbiddenExceptionResponse(parsedOutput, context)];
            case 5: throw _d.sent();
            case 6: return [4, deserializeAws_restJson1InternalServerErrorExceptionResponse(parsedOutput, context)];
            case 7: throw _d.sent();
            case 8: return [4, deserializeAws_restJson1MethodNotAllowedExceptionResponse(parsedOutput, context)];
            case 9: throw _d.sent();
            case 10: return [4, deserializeAws_restJson1NotFoundExceptionResponse(parsedOutput, context)];
            case 11: throw _d.sent();
            case 12: return [4, deserializeAws_restJson1PayloadTooLargeExceptionResponse(parsedOutput, context)];
            case 13: throw _d.sent();
            case 14: return [4, deserializeAws_restJson1TooManyRequestsExceptionResponse(parsedOutput, context)];
            case 15: throw _d.sent();
            case 16:
                parsedBody = parsedOutput.body;
                throwDefaultError({
                    output: output,
                    parsedBody: parsedBody,
                    exceptionCtor: __BaseException,
                    errorCode: errorCode,
                });
                _d.label = 17;
            case 17: return [2];
        }
    });
}); };
export var deserializeAws_restJson1GetApnsChannelCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var contents, data, _a;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                if (output.statusCode !== 200 && output.statusCode >= 300) {
                    return [2, deserializeAws_restJson1GetApnsChannelCommandError(output, context)];
                }
                contents = map({
                    $metadata: deserializeMetadata(output),
                });
                _a = __expectObject;
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.apply(void 0, [_b.sent()]);
                contents.APNSChannelResponse = deserializeAws_restJson1APNSChannelResponse(data, context);
                return [2, contents];
        }
    });
}); };
var deserializeAws_restJson1GetApnsChannelCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, errorCode, _b, parsedBody;
    var _c;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                _a = [__assign({}, output)];
                _c = {};
                return [4, parseErrorBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_c.body = _d.sent(), _c)]));
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "BadRequestException": return [3, 2];
                    case "com.amazonaws.pinpoint#BadRequestException": return [3, 2];
                    case "ForbiddenException": return [3, 4];
                    case "com.amazonaws.pinpoint#ForbiddenException": return [3, 4];
                    case "InternalServerErrorException": return [3, 6];
                    case "com.amazonaws.pinpoint#InternalServerErrorException": return [3, 6];
                    case "MethodNotAllowedException": return [3, 8];
                    case "com.amazonaws.pinpoint#MethodNotAllowedException": return [3, 8];
                    case "NotFoundException": return [3, 10];
                    case "com.amazonaws.pinpoint#NotFoundException": return [3, 10];
                    case "PayloadTooLargeException": return [3, 12];
                    case "com.amazonaws.pinpoint#PayloadTooLargeException": return [3, 12];
                    case "TooManyRequestsException": return [3, 14];
                    case "com.amazonaws.pinpoint#TooManyRequestsException": return [3, 14];
                }
                return [3, 16];
            case 2: return [4, deserializeAws_restJson1BadRequestExceptionResponse(parsedOutput, context)];
            case 3: throw _d.sent();
            case 4: return [4, deserializeAws_restJson1ForbiddenExceptionResponse(parsedOutput, context)];
            case 5: throw _d.sent();
            case 6: return [4, deserializeAws_restJson1InternalServerErrorExceptionResponse(parsedOutput, context)];
            case 7: throw _d.sent();
            case 8: return [4, deserializeAws_restJson1MethodNotAllowedExceptionResponse(parsedOutput, context)];
            case 9: throw _d.sent();
            case 10: return [4, deserializeAws_restJson1NotFoundExceptionResponse(parsedOutput, context)];
            case 11: throw _d.sent();
            case 12: return [4, deserializeAws_restJson1PayloadTooLargeExceptionResponse(parsedOutput, context)];
            case 13: throw _d.sent();
            case 14: return [4, deserializeAws_restJson1TooManyRequestsExceptionResponse(parsedOutput, context)];
            case 15: throw _d.sent();
            case 16:
                parsedBody = parsedOutput.body;
                throwDefaultError({
                    output: output,
                    parsedBody: parsedBody,
                    exceptionCtor: __BaseException,
                    errorCode: errorCode,
                });
                _d.label = 17;
            case 17: return [2];
        }
    });
}); };
export var deserializeAws_restJson1GetApnsSandboxChannelCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var contents, data, _a;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                if (output.statusCode !== 200 && output.statusCode >= 300) {
                    return [2, deserializeAws_restJson1GetApnsSandboxChannelCommandError(output, context)];
                }
                contents = map({
                    $metadata: deserializeMetadata(output),
                });
                _a = __expectObject;
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.apply(void 0, [_b.sent()]);
                contents.APNSSandboxChannelResponse = deserializeAws_restJson1APNSSandboxChannelResponse(data, context);
                return [2, contents];
        }
    });
}); };
var deserializeAws_restJson1GetApnsSandboxChannelCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, errorCode, _b, parsedBody;
    var _c;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                _a = [__assign({}, output)];
                _c = {};
                return [4, parseErrorBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_c.body = _d.sent(), _c)]));
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "BadRequestException": return [3, 2];
                    case "com.amazonaws.pinpoint#BadRequestException": return [3, 2];
                    case "ForbiddenException": return [3, 4];
                    case "com.amazonaws.pinpoint#ForbiddenException": return [3, 4];
                    case "InternalServerErrorException": return [3, 6];
                    case "com.amazonaws.pinpoint#InternalServerErrorException": return [3, 6];
                    case "MethodNotAllowedException": return [3, 8];
                    case "com.amazonaws.pinpoint#MethodNotAllowedException": return [3, 8];
                    case "NotFoundException": return [3, 10];
                    case "com.amazonaws.pinpoint#NotFoundException": return [3, 10];
                    case "PayloadTooLargeException": return [3, 12];
                    case "com.amazonaws.pinpoint#PayloadTooLargeException": return [3, 12];
                    case "TooManyRequestsException": return [3, 14];
                    case "com.amazonaws.pinpoint#TooManyRequestsException": return [3, 14];
                }
                return [3, 16];
            case 2: return [4, deserializeAws_restJson1BadRequestExceptionResponse(parsedOutput, context)];
            case 3: throw _d.sent();
            case 4: return [4, deserializeAws_restJson1ForbiddenExceptionResponse(parsedOutput, context)];
            case 5: throw _d.sent();
            case 6: return [4, deserializeAws_restJson1InternalServerErrorExceptionResponse(parsedOutput, context)];
            case 7: throw _d.sent();
            case 8: return [4, deserializeAws_restJson1MethodNotAllowedExceptionResponse(parsedOutput, context)];
            case 9: throw _d.sent();
            case 10: return [4, deserializeAws_restJson1NotFoundExceptionResponse(parsedOutput, context)];
            case 11: throw _d.sent();
            case 12: return [4, deserializeAws_restJson1PayloadTooLargeExceptionResponse(parsedOutput, context)];
            case 13: throw _d.sent();
            case 14: return [4, deserializeAws_restJson1TooManyRequestsExceptionResponse(parsedOutput, context)];
            case 15: throw _d.sent();
            case 16:
                parsedBody = parsedOutput.body;
                throwDefaultError({
                    output: output,
                    parsedBody: parsedBody,
                    exceptionCtor: __BaseException,
                    errorCode: errorCode,
                });
                _d.label = 17;
            case 17: return [2];
        }
    });
}); };
export var deserializeAws_restJson1GetApnsVoipChannelCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var contents, data, _a;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                if (output.statusCode !== 200 && output.statusCode >= 300) {
                    return [2, deserializeAws_restJson1GetApnsVoipChannelCommandError(output, context)];
                }
                contents = map({
                    $metadata: deserializeMetadata(output),
                });
                _a = __expectObject;
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.apply(void 0, [_b.sent()]);
                contents.APNSVoipChannelResponse = deserializeAws_restJson1APNSVoipChannelResponse(data, context);
                return [2, contents];
        }
    });
}); };
var deserializeAws_restJson1GetApnsVoipChannelCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, errorCode, _b, parsedBody;
    var _c;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                _a = [__assign({}, output)];
                _c = {};
                return [4, parseErrorBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_c.body = _d.sent(), _c)]));
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "BadRequestException": return [3, 2];
                    case "com.amazonaws.pinpoint#BadRequestException": return [3, 2];
                    case "ForbiddenException": return [3, 4];
                    case "com.amazonaws.pinpoint#ForbiddenException": return [3, 4];
                    case "InternalServerErrorException": return [3, 6];
                    case "com.amazonaws.pinpoint#InternalServerErrorException": return [3, 6];
                    case "MethodNotAllowedException": return [3, 8];
                    case "com.amazonaws.pinpoint#MethodNotAllowedException": return [3, 8];
                    case "NotFoundException": return [3, 10];
                    case "com.amazonaws.pinpoint#NotFoundException": return [3, 10];
                    case "PayloadTooLargeException": return [3, 12];
                    case "com.amazonaws.pinpoint#PayloadTooLargeException": return [3, 12];
                    case "TooManyRequestsException": return [3, 14];
                    case "com.amazonaws.pinpoint#TooManyRequestsException": return [3, 14];
                }
                return [3, 16];
            case 2: return [4, deserializeAws_restJson1BadRequestExceptionResponse(parsedOutput, context)];
            case 3: throw _d.sent();
            case 4: return [4, deserializeAws_restJson1ForbiddenExceptionResponse(parsedOutput, context)];
            case 5: throw _d.sent();
            case 6: return [4, deserializeAws_restJson1InternalServerErrorExceptionResponse(parsedOutput, context)];
            case 7: throw _d.sent();
            case 8: return [4, deserializeAws_restJson1MethodNotAllowedExceptionResponse(parsedOutput, context)];
            case 9: throw _d.sent();
            case 10: return [4, deserializeAws_restJson1NotFoundExceptionResponse(parsedOutput, context)];
            case 11: throw _d.sent();
            case 12: return [4, deserializeAws_restJson1PayloadTooLargeExceptionResponse(parsedOutput, context)];
            case 13: throw _d.sent();
            case 14: return [4, deserializeAws_restJson1TooManyRequestsExceptionResponse(parsedOutput, context)];
            case 15: throw _d.sent();
            case 16:
                parsedBody = parsedOutput.body;
                throwDefaultError({
                    output: output,
                    parsedBody: parsedBody,
                    exceptionCtor: __BaseException,
                    errorCode: errorCode,
                });
                _d.label = 17;
            case 17: return [2];
        }
    });
}); };
export var deserializeAws_restJson1GetApnsVoipSandboxChannelCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var contents, data, _a;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                if (output.statusCode !== 200 && output.statusCode >= 300) {
                    return [2, deserializeAws_restJson1GetApnsVoipSandboxChannelCommandError(output, context)];
                }
                contents = map({
                    $metadata: deserializeMetadata(output),
                });
                _a = __expectObject;
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.apply(void 0, [_b.sent()]);
                contents.APNSVoipSandboxChannelResponse = deserializeAws_restJson1APNSVoipSandboxChannelResponse(data, context);
                return [2, contents];
        }
    });
}); };
var deserializeAws_restJson1GetApnsVoipSandboxChannelCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, errorCode, _b, parsedBody;
    var _c;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                _a = [__assign({}, output)];
                _c = {};
                return [4, parseErrorBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_c.body = _d.sent(), _c)]));
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "BadRequestException": return [3, 2];
                    case "com.amazonaws.pinpoint#BadRequestException": return [3, 2];
                    case "ForbiddenException": return [3, 4];
                    case "com.amazonaws.pinpoint#ForbiddenException": return [3, 4];
                    case "InternalServerErrorException": return [3, 6];
                    case "com.amazonaws.pinpoint#InternalServerErrorException": return [3, 6];
                    case "MethodNotAllowedException": return [3, 8];
                    case "com.amazonaws.pinpoint#MethodNotAllowedException": return [3, 8];
                    case "NotFoundException": return [3, 10];
                    case "com.amazonaws.pinpoint#NotFoundException": return [3, 10];
                    case "PayloadTooLargeException": return [3, 12];
                    case "com.amazonaws.pinpoint#PayloadTooLargeException": return [3, 12];
                    case "TooManyRequestsException": return [3, 14];
                    case "com.amazonaws.pinpoint#TooManyRequestsException": return [3, 14];
                }
                return [3, 16];
            case 2: return [4, deserializeAws_restJson1BadRequestExceptionResponse(parsedOutput, context)];
            case 3: throw _d.sent();
            case 4: return [4, deserializeAws_restJson1ForbiddenExceptionResponse(parsedOutput, context)];
            case 5: throw _d.sent();
            case 6: return [4, deserializeAws_restJson1InternalServerErrorExceptionResponse(parsedOutput, context)];
            case 7: throw _d.sent();
            case 8: return [4, deserializeAws_restJson1MethodNotAllowedExceptionResponse(parsedOutput, context)];
            case 9: throw _d.sent();
            case 10: return [4, deserializeAws_restJson1NotFoundExceptionResponse(parsedOutput, context)];
            case 11: throw _d.sent();
            case 12: return [4, deserializeAws_restJson1PayloadTooLargeExceptionResponse(parsedOutput, context)];
            case 13: throw _d.sent();
            case 14: return [4, deserializeAws_restJson1TooManyRequestsExceptionResponse(parsedOutput, context)];
            case 15: throw _d.sent();
            case 16:
                parsedBody = parsedOutput.body;
                throwDefaultError({
                    output: output,
                    parsedBody: parsedBody,
                    exceptionCtor: __BaseException,
                    errorCode: errorCode,
                });
                _d.label = 17;
            case 17: return [2];
        }
    });
}); };
export var deserializeAws_restJson1GetAppCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var contents, data, _a;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                if (output.statusCode !== 200 && output.statusCode >= 300) {
                    return [2, deserializeAws_restJson1GetAppCommandError(output, context)];
                }
                contents = map({
                    $metadata: deserializeMetadata(output),
                });
                _a = __expectObject;
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.apply(void 0, [_b.sent()]);
                contents.ApplicationResponse = deserializeAws_restJson1ApplicationResponse(data, context);
                return [2, contents];
        }
    });
}); };
var deserializeAws_restJson1GetAppCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, errorCode, _b, parsedBody;
    var _c;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                _a = [__assign({}, output)];
                _c = {};
                return [4, parseErrorBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_c.body = _d.sent(), _c)]));
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "BadRequestException": return [3, 2];
                    case "com.amazonaws.pinpoint#BadRequestException": return [3, 2];
                    case "ForbiddenException": return [3, 4];
                    case "com.amazonaws.pinpoint#ForbiddenException": return [3, 4];
                    case "InternalServerErrorException": return [3, 6];
                    case "com.amazonaws.pinpoint#InternalServerErrorException": return [3, 6];
                    case "MethodNotAllowedException": return [3, 8];
                    case "com.amazonaws.pinpoint#MethodNotAllowedException": return [3, 8];
                    case "NotFoundException": return [3, 10];
                    case "com.amazonaws.pinpoint#NotFoundException": return [3, 10];
                    case "PayloadTooLargeException": return [3, 12];
                    case "com.amazonaws.pinpoint#PayloadTooLargeException": return [3, 12];
                    case "TooManyRequestsException": return [3, 14];
                    case "com.amazonaws.pinpoint#TooManyRequestsException": return [3, 14];
                }
                return [3, 16];
            case 2: return [4, deserializeAws_restJson1BadRequestExceptionResponse(parsedOutput, context)];
            case 3: throw _d.sent();
            case 4: return [4, deserializeAws_restJson1ForbiddenExceptionResponse(parsedOutput, context)];
            case 5: throw _d.sent();
            case 6: return [4, deserializeAws_restJson1InternalServerErrorExceptionResponse(parsedOutput, context)];
            case 7: throw _d.sent();
            case 8: return [4, deserializeAws_restJson1MethodNotAllowedExceptionResponse(parsedOutput, context)];
            case 9: throw _d.sent();
            case 10: return [4, deserializeAws_restJson1NotFoundExceptionResponse(parsedOutput, context)];
            case 11: throw _d.sent();
            case 12: return [4, deserializeAws_restJson1PayloadTooLargeExceptionResponse(parsedOutput, context)];
            case 13: throw _d.sent();
            case 14: return [4, deserializeAws_restJson1TooManyRequestsExceptionResponse(parsedOutput, context)];
            case 15: throw _d.sent();
            case 16:
                parsedBody = parsedOutput.body;
                throwDefaultError({
                    output: output,
                    parsedBody: parsedBody,
                    exceptionCtor: __BaseException,
                    errorCode: errorCode,
                });
                _d.label = 17;
            case 17: return [2];
        }
    });
}); };
export var deserializeAws_restJson1GetApplicationDateRangeKpiCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var contents, data, _a;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                if (output.statusCode !== 200 && output.statusCode >= 300) {
                    return [2, deserializeAws_restJson1GetApplicationDateRangeKpiCommandError(output, context)];
                }
                contents = map({
                    $metadata: deserializeMetadata(output),
                });
                _a = __expectObject;
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.apply(void 0, [_b.sent()]);
                contents.ApplicationDateRangeKpiResponse = deserializeAws_restJson1ApplicationDateRangeKpiResponse(data, context);
                return [2, contents];
        }
    });
}); };
var deserializeAws_restJson1GetApplicationDateRangeKpiCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, errorCode, _b, parsedBody;
    var _c;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                _a = [__assign({}, output)];
                _c = {};
                return [4, parseErrorBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_c.body = _d.sent(), _c)]));
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "BadRequestException": return [3, 2];
                    case "com.amazonaws.pinpoint#BadRequestException": return [3, 2];
                    case "ForbiddenException": return [3, 4];
                    case "com.amazonaws.pinpoint#ForbiddenException": return [3, 4];
                    case "InternalServerErrorException": return [3, 6];
                    case "com.amazonaws.pinpoint#InternalServerErrorException": return [3, 6];
                    case "MethodNotAllowedException": return [3, 8];
                    case "com.amazonaws.pinpoint#MethodNotAllowedException": return [3, 8];
                    case "NotFoundException": return [3, 10];
                    case "com.amazonaws.pinpoint#NotFoundException": return [3, 10];
                    case "PayloadTooLargeException": return [3, 12];
                    case "com.amazonaws.pinpoint#PayloadTooLargeException": return [3, 12];
                    case "TooManyRequestsException": return [3, 14];
                    case "com.amazonaws.pinpoint#TooManyRequestsException": return [3, 14];
                }
                return [3, 16];
            case 2: return [4, deserializeAws_restJson1BadRequestExceptionResponse(parsedOutput, context)];
            case 3: throw _d.sent();
            case 4: return [4, deserializeAws_restJson1ForbiddenExceptionResponse(parsedOutput, context)];
            case 5: throw _d.sent();
            case 6: return [4, deserializeAws_restJson1InternalServerErrorExceptionResponse(parsedOutput, context)];
            case 7: throw _d.sent();
            case 8: return [4, deserializeAws_restJson1MethodNotAllowedExceptionResponse(parsedOutput, context)];
            case 9: throw _d.sent();
            case 10: return [4, deserializeAws_restJson1NotFoundExceptionResponse(parsedOutput, context)];
            case 11: throw _d.sent();
            case 12: return [4, deserializeAws_restJson1PayloadTooLargeExceptionResponse(parsedOutput, context)];
            case 13: throw _d.sent();
            case 14: return [4, deserializeAws_restJson1TooManyRequestsExceptionResponse(parsedOutput, context)];
            case 15: throw _d.sent();
            case 16:
                parsedBody = parsedOutput.body;
                throwDefaultError({
                    output: output,
                    parsedBody: parsedBody,
                    exceptionCtor: __BaseException,
                    errorCode: errorCode,
                });
                _d.label = 17;
            case 17: return [2];
        }
    });
}); };
export var deserializeAws_restJson1GetApplicationSettingsCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var contents, data, _a;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                if (output.statusCode !== 200 && output.statusCode >= 300) {
                    return [2, deserializeAws_restJson1GetApplicationSettingsCommandError(output, context)];
                }
                contents = map({
                    $metadata: deserializeMetadata(output),
                });
                _a = __expectObject;
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.apply(void 0, [_b.sent()]);
                contents.ApplicationSettingsResource = deserializeAws_restJson1ApplicationSettingsResource(data, context);
                return [2, contents];
        }
    });
}); };
var deserializeAws_restJson1GetApplicationSettingsCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, errorCode, _b, parsedBody;
    var _c;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                _a = [__assign({}, output)];
                _c = {};
                return [4, parseErrorBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_c.body = _d.sent(), _c)]));
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "BadRequestException": return [3, 2];
                    case "com.amazonaws.pinpoint#BadRequestException": return [3, 2];
                    case "ForbiddenException": return [3, 4];
                    case "com.amazonaws.pinpoint#ForbiddenException": return [3, 4];
                    case "InternalServerErrorException": return [3, 6];
                    case "com.amazonaws.pinpoint#InternalServerErrorException": return [3, 6];
                    case "MethodNotAllowedException": return [3, 8];
                    case "com.amazonaws.pinpoint#MethodNotAllowedException": return [3, 8];
                    case "NotFoundException": return [3, 10];
                    case "com.amazonaws.pinpoint#NotFoundException": return [3, 10];
                    case "PayloadTooLargeException": return [3, 12];
                    case "com.amazonaws.pinpoint#PayloadTooLargeException": return [3, 12];
                    case "TooManyRequestsException": return [3, 14];
                    case "com.amazonaws.pinpoint#TooManyRequestsException": return [3, 14];
                }
                return [3, 16];
            case 2: return [4, deserializeAws_restJson1BadRequestExceptionResponse(parsedOutput, context)];
            case 3: throw _d.sent();
            case 4: return [4, deserializeAws_restJson1ForbiddenExceptionResponse(parsedOutput, context)];
            case 5: throw _d.sent();
            case 6: return [4, deserializeAws_restJson1InternalServerErrorExceptionResponse(parsedOutput, context)];
            case 7: throw _d.sent();
            case 8: return [4, deserializeAws_restJson1MethodNotAllowedExceptionResponse(parsedOutput, context)];
            case 9: throw _d.sent();
            case 10: return [4, deserializeAws_restJson1NotFoundExceptionResponse(parsedOutput, context)];
            case 11: throw _d.sent();
            case 12: return [4, deserializeAws_restJson1PayloadTooLargeExceptionResponse(parsedOutput, context)];
            case 13: throw _d.sent();
            case 14: return [4, deserializeAws_restJson1TooManyRequestsExceptionResponse(parsedOutput, context)];
            case 15: throw _d.sent();
            case 16:
                parsedBody = parsedOutput.body;
                throwDefaultError({
                    output: output,
                    parsedBody: parsedBody,
                    exceptionCtor: __BaseException,
                    errorCode: errorCode,
                });
                _d.label = 17;
            case 17: return [2];
        }
    });
}); };
export var deserializeAws_restJson1GetAppsCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var contents, data, _a;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                if (output.statusCode !== 200 && output.statusCode >= 300) {
                    return [2, deserializeAws_restJson1GetAppsCommandError(output, context)];
                }
                contents = map({
                    $metadata: deserializeMetadata(output),
                });
                _a = __expectObject;
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.apply(void 0, [_b.sent()]);
                contents.ApplicationsResponse = deserializeAws_restJson1ApplicationsResponse(data, context);
                return [2, contents];
        }
    });
}); };
var deserializeAws_restJson1GetAppsCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, errorCode, _b, parsedBody;
    var _c;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                _a = [__assign({}, output)];
                _c = {};
                return [4, parseErrorBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_c.body = _d.sent(), _c)]));
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "BadRequestException": return [3, 2];
                    case "com.amazonaws.pinpoint#BadRequestException": return [3, 2];
                    case "ForbiddenException": return [3, 4];
                    case "com.amazonaws.pinpoint#ForbiddenException": return [3, 4];
                    case "InternalServerErrorException": return [3, 6];
                    case "com.amazonaws.pinpoint#InternalServerErrorException": return [3, 6];
                    case "MethodNotAllowedException": return [3, 8];
                    case "com.amazonaws.pinpoint#MethodNotAllowedException": return [3, 8];
                    case "NotFoundException": return [3, 10];
                    case "com.amazonaws.pinpoint#NotFoundException": return [3, 10];
                    case "PayloadTooLargeException": return [3, 12];
                    case "com.amazonaws.pinpoint#PayloadTooLargeException": return [3, 12];
                    case "TooManyRequestsException": return [3, 14];
                    case "com.amazonaws.pinpoint#TooManyRequestsException": return [3, 14];
                }
                return [3, 16];
            case 2: return [4, deserializeAws_restJson1BadRequestExceptionResponse(parsedOutput, context)];
            case 3: throw _d.sent();
            case 4: return [4, deserializeAws_restJson1ForbiddenExceptionResponse(parsedOutput, context)];
            case 5: throw _d.sent();
            case 6: return [4, deserializeAws_restJson1InternalServerErrorExceptionResponse(parsedOutput, context)];
            case 7: throw _d.sent();
            case 8: return [4, deserializeAws_restJson1MethodNotAllowedExceptionResponse(parsedOutput, context)];
            case 9: throw _d.sent();
            case 10: return [4, deserializeAws_restJson1NotFoundExceptionResponse(parsedOutput, context)];
            case 11: throw _d.sent();
            case 12: return [4, deserializeAws_restJson1PayloadTooLargeExceptionResponse(parsedOutput, context)];
            case 13: throw _d.sent();
            case 14: return [4, deserializeAws_restJson1TooManyRequestsExceptionResponse(parsedOutput, context)];
            case 15: throw _d.sent();
            case 16:
                parsedBody = parsedOutput.body;
                throwDefaultError({
                    output: output,
                    parsedBody: parsedBody,
                    exceptionCtor: __BaseException,
                    errorCode: errorCode,
                });
                _d.label = 17;
            case 17: return [2];
        }
    });
}); };
export var deserializeAws_restJson1GetBaiduChannelCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var contents, data, _a;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                if (output.statusCode !== 200 && output.statusCode >= 300) {
                    return [2, deserializeAws_restJson1GetBaiduChannelCommandError(output, context)];
                }
                contents = map({
                    $metadata: deserializeMetadata(output),
                });
                _a = __expectObject;
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.apply(void 0, [_b.sent()]);
                contents.BaiduChannelResponse = deserializeAws_restJson1BaiduChannelResponse(data, context);
                return [2, contents];
        }
    });
}); };
var deserializeAws_restJson1GetBaiduChannelCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, errorCode, _b, parsedBody;
    var _c;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                _a = [__assign({}, output)];
                _c = {};
                return [4, parseErrorBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_c.body = _d.sent(), _c)]));
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "BadRequestException": return [3, 2];
                    case "com.amazonaws.pinpoint#BadRequestException": return [3, 2];
                    case "ForbiddenException": return [3, 4];
                    case "com.amazonaws.pinpoint#ForbiddenException": return [3, 4];
                    case "InternalServerErrorException": return [3, 6];
                    case "com.amazonaws.pinpoint#InternalServerErrorException": return [3, 6];
                    case "MethodNotAllowedException": return [3, 8];
                    case "com.amazonaws.pinpoint#MethodNotAllowedException": return [3, 8];
                    case "NotFoundException": return [3, 10];
                    case "com.amazonaws.pinpoint#NotFoundException": return [3, 10];
                    case "PayloadTooLargeException": return [3, 12];
                    case "com.amazonaws.pinpoint#PayloadTooLargeException": return [3, 12];
                    case "TooManyRequestsException": return [3, 14];
                    case "com.amazonaws.pinpoint#TooManyRequestsException": return [3, 14];
                }
                return [3, 16];
            case 2: return [4, deserializeAws_restJson1BadRequestExceptionResponse(parsedOutput, context)];
            case 3: throw _d.sent();
            case 4: return [4, deserializeAws_restJson1ForbiddenExceptionResponse(parsedOutput, context)];
            case 5: throw _d.sent();
            case 6: return [4, deserializeAws_restJson1InternalServerErrorExceptionResponse(parsedOutput, context)];
            case 7: throw _d.sent();
            case 8: return [4, deserializeAws_restJson1MethodNotAllowedExceptionResponse(parsedOutput, context)];
            case 9: throw _d.sent();
            case 10: return [4, deserializeAws_restJson1NotFoundExceptionResponse(parsedOutput, context)];
            case 11: throw _d.sent();
            case 12: return [4, deserializeAws_restJson1PayloadTooLargeExceptionResponse(parsedOutput, context)];
            case 13: throw _d.sent();
            case 14: return [4, deserializeAws_restJson1TooManyRequestsExceptionResponse(parsedOutput, context)];
            case 15: throw _d.sent();
            case 16:
                parsedBody = parsedOutput.body;
                throwDefaultError({
                    output: output,
                    parsedBody: parsedBody,
                    exceptionCtor: __BaseException,
                    errorCode: errorCode,
                });
                _d.label = 17;
            case 17: return [2];
        }
    });
}); };
export var deserializeAws_restJson1GetCampaignCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var contents, data, _a;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                if (output.statusCode !== 200 && output.statusCode >= 300) {
                    return [2, deserializeAws_restJson1GetCampaignCommandError(output, context)];
                }
                contents = map({
                    $metadata: deserializeMetadata(output),
                });
                _a = __expectObject;
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.apply(void 0, [_b.sent()]);
                contents.CampaignResponse = deserializeAws_restJson1CampaignResponse(data, context);
                return [2, contents];
        }
    });
}); };
var deserializeAws_restJson1GetCampaignCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, errorCode, _b, parsedBody;
    var _c;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                _a = [__assign({}, output)];
                _c = {};
                return [4, parseErrorBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_c.body = _d.sent(), _c)]));
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "BadRequestException": return [3, 2];
                    case "com.amazonaws.pinpoint#BadRequestException": return [3, 2];
                    case "ForbiddenException": return [3, 4];
                    case "com.amazonaws.pinpoint#ForbiddenException": return [3, 4];
                    case "InternalServerErrorException": return [3, 6];
                    case "com.amazonaws.pinpoint#InternalServerErrorException": return [3, 6];
                    case "MethodNotAllowedException": return [3, 8];
                    case "com.amazonaws.pinpoint#MethodNotAllowedException": return [3, 8];
                    case "NotFoundException": return [3, 10];
                    case "com.amazonaws.pinpoint#NotFoundException": return [3, 10];
                    case "PayloadTooLargeException": return [3, 12];
                    case "com.amazonaws.pinpoint#PayloadTooLargeException": return [3, 12];
                    case "TooManyRequestsException": return [3, 14];
                    case "com.amazonaws.pinpoint#TooManyRequestsException": return [3, 14];
                }
                return [3, 16];
            case 2: return [4, deserializeAws_restJson1BadRequestExceptionResponse(parsedOutput, context)];
            case 3: throw _d.sent();
            case 4: return [4, deserializeAws_restJson1ForbiddenExceptionResponse(parsedOutput, context)];
            case 5: throw _d.sent();
            case 6: return [4, deserializeAws_restJson1InternalServerErrorExceptionResponse(parsedOutput, context)];
            case 7: throw _d.sent();
            case 8: return [4, deserializeAws_restJson1MethodNotAllowedExceptionResponse(parsedOutput, context)];
            case 9: throw _d.sent();
            case 10: return [4, deserializeAws_restJson1NotFoundExceptionResponse(parsedOutput, context)];
            case 11: throw _d.sent();
            case 12: return [4, deserializeAws_restJson1PayloadTooLargeExceptionResponse(parsedOutput, context)];
            case 13: throw _d.sent();
            case 14: return [4, deserializeAws_restJson1TooManyRequestsExceptionResponse(parsedOutput, context)];
            case 15: throw _d.sent();
            case 16:
                parsedBody = parsedOutput.body;
                throwDefaultError({
                    output: output,
                    parsedBody: parsedBody,
                    exceptionCtor: __BaseException,
                    errorCode: errorCode,
                });
                _d.label = 17;
            case 17: return [2];
        }
    });
}); };
export var deserializeAws_restJson1GetCampaignActivitiesCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var contents, data, _a;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                if (output.statusCode !== 200 && output.statusCode >= 300) {
                    return [2, deserializeAws_restJson1GetCampaignActivitiesCommandError(output, context)];
                }
                contents = map({
                    $metadata: deserializeMetadata(output),
                });
                _a = __expectObject;
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.apply(void 0, [_b.sent()]);
                contents.ActivitiesResponse = deserializeAws_restJson1ActivitiesResponse(data, context);
                return [2, contents];
        }
    });
}); };
var deserializeAws_restJson1GetCampaignActivitiesCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, errorCode, _b, parsedBody;
    var _c;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                _a = [__assign({}, output)];
                _c = {};
                return [4, parseErrorBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_c.body = _d.sent(), _c)]));
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "BadRequestException": return [3, 2];
                    case "com.amazonaws.pinpoint#BadRequestException": return [3, 2];
                    case "ForbiddenException": return [3, 4];
                    case "com.amazonaws.pinpoint#ForbiddenException": return [3, 4];
                    case "InternalServerErrorException": return [3, 6];
                    case "com.amazonaws.pinpoint#InternalServerErrorException": return [3, 6];
                    case "MethodNotAllowedException": return [3, 8];
                    case "com.amazonaws.pinpoint#MethodNotAllowedException": return [3, 8];
                    case "NotFoundException": return [3, 10];
                    case "com.amazonaws.pinpoint#NotFoundException": return [3, 10];
                    case "PayloadTooLargeException": return [3, 12];
                    case "com.amazonaws.pinpoint#PayloadTooLargeException": return [3, 12];
                    case "TooManyRequestsException": return [3, 14];
                    case "com.amazonaws.pinpoint#TooManyRequestsException": return [3, 14];
                }
                return [3, 16];
            case 2: return [4, deserializeAws_restJson1BadRequestExceptionResponse(parsedOutput, context)];
            case 3: throw _d.sent();
            case 4: return [4, deserializeAws_restJson1ForbiddenExceptionResponse(parsedOutput, context)];
            case 5: throw _d.sent();
            case 6: return [4, deserializeAws_restJson1InternalServerErrorExceptionResponse(parsedOutput, context)];
            case 7: throw _d.sent();
            case 8: return [4, deserializeAws_restJson1MethodNotAllowedExceptionResponse(parsedOutput, context)];
            case 9: throw _d.sent();
            case 10: return [4, deserializeAws_restJson1NotFoundExceptionResponse(parsedOutput, context)];
            case 11: throw _d.sent();
            case 12: return [4, deserializeAws_restJson1PayloadTooLargeExceptionResponse(parsedOutput, context)];
            case 13: throw _d.sent();
            case 14: return [4, deserializeAws_restJson1TooManyRequestsExceptionResponse(parsedOutput, context)];
            case 15: throw _d.sent();
            case 16:
                parsedBody = parsedOutput.body;
                throwDefaultError({
                    output: output,
                    parsedBody: parsedBody,
                    exceptionCtor: __BaseException,
                    errorCode: errorCode,
                });
                _d.label = 17;
            case 17: return [2];
        }
    });
}); };
export var deserializeAws_restJson1GetCampaignDateRangeKpiCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var contents, data, _a;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                if (output.statusCode !== 200 && output.statusCode >= 300) {
                    return [2, deserializeAws_restJson1GetCampaignDateRangeKpiCommandError(output, context)];
                }
                contents = map({
                    $metadata: deserializeMetadata(output),
                });
                _a = __expectObject;
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.apply(void 0, [_b.sent()]);
                contents.CampaignDateRangeKpiResponse = deserializeAws_restJson1CampaignDateRangeKpiResponse(data, context);
                return [2, contents];
        }
    });
}); };
var deserializeAws_restJson1GetCampaignDateRangeKpiCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, errorCode, _b, parsedBody;
    var _c;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                _a = [__assign({}, output)];
                _c = {};
                return [4, parseErrorBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_c.body = _d.sent(), _c)]));
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "BadRequestException": return [3, 2];
                    case "com.amazonaws.pinpoint#BadRequestException": return [3, 2];
                    case "ForbiddenException": return [3, 4];
                    case "com.amazonaws.pinpoint#ForbiddenException": return [3, 4];
                    case "InternalServerErrorException": return [3, 6];
                    case "com.amazonaws.pinpoint#InternalServerErrorException": return [3, 6];
                    case "MethodNotAllowedException": return [3, 8];
                    case "com.amazonaws.pinpoint#MethodNotAllowedException": return [3, 8];
                    case "NotFoundException": return [3, 10];
                    case "com.amazonaws.pinpoint#NotFoundException": return [3, 10];
                    case "PayloadTooLargeException": return [3, 12];
                    case "com.amazonaws.pinpoint#PayloadTooLargeException": return [3, 12];
                    case "TooManyRequestsException": return [3, 14];
                    case "com.amazonaws.pinpoint#TooManyRequestsException": return [3, 14];
                }
                return [3, 16];
            case 2: return [4, deserializeAws_restJson1BadRequestExceptionResponse(parsedOutput, context)];
            case 3: throw _d.sent();
            case 4: return [4, deserializeAws_restJson1ForbiddenExceptionResponse(parsedOutput, context)];
            case 5: throw _d.sent();
            case 6: return [4, deserializeAws_restJson1InternalServerErrorExceptionResponse(parsedOutput, context)];
            case 7: throw _d.sent();
            case 8: return [4, deserializeAws_restJson1MethodNotAllowedExceptionResponse(parsedOutput, context)];
            case 9: throw _d.sent();
            case 10: return [4, deserializeAws_restJson1NotFoundExceptionResponse(parsedOutput, context)];
            case 11: throw _d.sent();
            case 12: return [4, deserializeAws_restJson1PayloadTooLargeExceptionResponse(parsedOutput, context)];
            case 13: throw _d.sent();
            case 14: return [4, deserializeAws_restJson1TooManyRequestsExceptionResponse(parsedOutput, context)];
            case 15: throw _d.sent();
            case 16:
                parsedBody = parsedOutput.body;
                throwDefaultError({
                    output: output,
                    parsedBody: parsedBody,
                    exceptionCtor: __BaseException,
                    errorCode: errorCode,
                });
                _d.label = 17;
            case 17: return [2];
        }
    });
}); };
export var deserializeAws_restJson1GetCampaignsCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var contents, data, _a;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                if (output.statusCode !== 200 && output.statusCode >= 300) {
                    return [2, deserializeAws_restJson1GetCampaignsCommandError(output, context)];
                }
                contents = map({
                    $metadata: deserializeMetadata(output),
                });
                _a = __expectObject;
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.apply(void 0, [_b.sent()]);
                contents.CampaignsResponse = deserializeAws_restJson1CampaignsResponse(data, context);
                return [2, contents];
        }
    });
}); };
var deserializeAws_restJson1GetCampaignsCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, errorCode, _b, parsedBody;
    var _c;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                _a = [__assign({}, output)];
                _c = {};
                return [4, parseErrorBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_c.body = _d.sent(), _c)]));
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "BadRequestException": return [3, 2];
                    case "com.amazonaws.pinpoint#BadRequestException": return [3, 2];
                    case "ForbiddenException": return [3, 4];
                    case "com.amazonaws.pinpoint#ForbiddenException": return [3, 4];
                    case "InternalServerErrorException": return [3, 6];
                    case "com.amazonaws.pinpoint#InternalServerErrorException": return [3, 6];
                    case "MethodNotAllowedException": return [3, 8];
                    case "com.amazonaws.pinpoint#MethodNotAllowedException": return [3, 8];
                    case "NotFoundException": return [3, 10];
                    case "com.amazonaws.pinpoint#NotFoundException": return [3, 10];
                    case "PayloadTooLargeException": return [3, 12];
                    case "com.amazonaws.pinpoint#PayloadTooLargeException": return [3, 12];
                    case "TooManyRequestsException": return [3, 14];
                    case "com.amazonaws.pinpoint#TooManyRequestsException": return [3, 14];
                }
                return [3, 16];
            case 2: return [4, deserializeAws_restJson1BadRequestExceptionResponse(parsedOutput, context)];
            case 3: throw _d.sent();
            case 4: return [4, deserializeAws_restJson1ForbiddenExceptionResponse(parsedOutput, context)];
            case 5: throw _d.sent();
            case 6: return [4, deserializeAws_restJson1InternalServerErrorExceptionResponse(parsedOutput, context)];
            case 7: throw _d.sent();
            case 8: return [4, deserializeAws_restJson1MethodNotAllowedExceptionResponse(parsedOutput, context)];
            case 9: throw _d.sent();
            case 10: return [4, deserializeAws_restJson1NotFoundExceptionResponse(parsedOutput, context)];
            case 11: throw _d.sent();
            case 12: return [4, deserializeAws_restJson1PayloadTooLargeExceptionResponse(parsedOutput, context)];
            case 13: throw _d.sent();
            case 14: return [4, deserializeAws_restJson1TooManyRequestsExceptionResponse(parsedOutput, context)];
            case 15: throw _d.sent();
            case 16:
                parsedBody = parsedOutput.body;
                throwDefaultError({
                    output: output,
                    parsedBody: parsedBody,
                    exceptionCtor: __BaseException,
                    errorCode: errorCode,
                });
                _d.label = 17;
            case 17: return [2];
        }
    });
}); };
export var deserializeAws_restJson1GetCampaignVersionCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var contents, data, _a;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                if (output.statusCode !== 200 && output.statusCode >= 300) {
                    return [2, deserializeAws_restJson1GetCampaignVersionCommandError(output, context)];
                }
                contents = map({
                    $metadata: deserializeMetadata(output),
                });
                _a = __expectObject;
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.apply(void 0, [_b.sent()]);
                contents.CampaignResponse = deserializeAws_restJson1CampaignResponse(data, context);
                return [2, contents];
        }
    });
}); };
var deserializeAws_restJson1GetCampaignVersionCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, errorCode, _b, parsedBody;
    var _c;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                _a = [__assign({}, output)];
                _c = {};
                return [4, parseErrorBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_c.body = _d.sent(), _c)]));
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "BadRequestException": return [3, 2];
                    case "com.amazonaws.pinpoint#BadRequestException": return [3, 2];
                    case "ForbiddenException": return [3, 4];
                    case "com.amazonaws.pinpoint#ForbiddenException": return [3, 4];
                    case "InternalServerErrorException": return [3, 6];
                    case "com.amazonaws.pinpoint#InternalServerErrorException": return [3, 6];
                    case "MethodNotAllowedException": return [3, 8];
                    case "com.amazonaws.pinpoint#MethodNotAllowedException": return [3, 8];
                    case "NotFoundException": return [3, 10];
                    case "com.amazonaws.pinpoint#NotFoundException": return [3, 10];
                    case "PayloadTooLargeException": return [3, 12];
                    case "com.amazonaws.pinpoint#PayloadTooLargeException": return [3, 12];
                    case "TooManyRequestsException": return [3, 14];
                    case "com.amazonaws.pinpoint#TooManyRequestsException": return [3, 14];
                }
                return [3, 16];
            case 2: return [4, deserializeAws_restJson1BadRequestExceptionResponse(parsedOutput, context)];
            case 3: throw _d.sent();
            case 4: return [4, deserializeAws_restJson1ForbiddenExceptionResponse(parsedOutput, context)];
            case 5: throw _d.sent();
            case 6: return [4, deserializeAws_restJson1InternalServerErrorExceptionResponse(parsedOutput, context)];
            case 7: throw _d.sent();
            case 8: return [4, deserializeAws_restJson1MethodNotAllowedExceptionResponse(parsedOutput, context)];
            case 9: throw _d.sent();
            case 10: return [4, deserializeAws_restJson1NotFoundExceptionResponse(parsedOutput, context)];
            case 11: throw _d.sent();
            case 12: return [4, deserializeAws_restJson1PayloadTooLargeExceptionResponse(parsedOutput, context)];
            case 13: throw _d.sent();
            case 14: return [4, deserializeAws_restJson1TooManyRequestsExceptionResponse(parsedOutput, context)];
            case 15: throw _d.sent();
            case 16:
                parsedBody = parsedOutput.body;
                throwDefaultError({
                    output: output,
                    parsedBody: parsedBody,
                    exceptionCtor: __BaseException,
                    errorCode: errorCode,
                });
                _d.label = 17;
            case 17: return [2];
        }
    });
}); };
export var deserializeAws_restJson1GetCampaignVersionsCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var contents, data, _a;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                if (output.statusCode !== 200 && output.statusCode >= 300) {
                    return [2, deserializeAws_restJson1GetCampaignVersionsCommandError(output, context)];
                }
                contents = map({
                    $metadata: deserializeMetadata(output),
                });
                _a = __expectObject;
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.apply(void 0, [_b.sent()]);
                contents.CampaignsResponse = deserializeAws_restJson1CampaignsResponse(data, context);
                return [2, contents];
        }
    });
}); };
var deserializeAws_restJson1GetCampaignVersionsCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, errorCode, _b, parsedBody;
    var _c;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                _a = [__assign({}, output)];
                _c = {};
                return [4, parseErrorBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_c.body = _d.sent(), _c)]));
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "BadRequestException": return [3, 2];
                    case "com.amazonaws.pinpoint#BadRequestException": return [3, 2];
                    case "ForbiddenException": return [3, 4];
                    case "com.amazonaws.pinpoint#ForbiddenException": return [3, 4];
                    case "InternalServerErrorException": return [3, 6];
                    case "com.amazonaws.pinpoint#InternalServerErrorException": return [3, 6];
                    case "MethodNotAllowedException": return [3, 8];
                    case "com.amazonaws.pinpoint#MethodNotAllowedException": return [3, 8];
                    case "NotFoundException": return [3, 10];
                    case "com.amazonaws.pinpoint#NotFoundException": return [3, 10];
                    case "PayloadTooLargeException": return [3, 12];
                    case "com.amazonaws.pinpoint#PayloadTooLargeException": return [3, 12];
                    case "TooManyRequestsException": return [3, 14];
                    case "com.amazonaws.pinpoint#TooManyRequestsException": return [3, 14];
                }
                return [3, 16];
            case 2: return [4, deserializeAws_restJson1BadRequestExceptionResponse(parsedOutput, context)];
            case 3: throw _d.sent();
            case 4: return [4, deserializeAws_restJson1ForbiddenExceptionResponse(parsedOutput, context)];
            case 5: throw _d.sent();
            case 6: return [4, deserializeAws_restJson1InternalServerErrorExceptionResponse(parsedOutput, context)];
            case 7: throw _d.sent();
            case 8: return [4, deserializeAws_restJson1MethodNotAllowedExceptionResponse(parsedOutput, context)];
            case 9: throw _d.sent();
            case 10: return [4, deserializeAws_restJson1NotFoundExceptionResponse(parsedOutput, context)];
            case 11: throw _d.sent();
            case 12: return [4, deserializeAws_restJson1PayloadTooLargeExceptionResponse(parsedOutput, context)];
            case 13: throw _d.sent();
            case 14: return [4, deserializeAws_restJson1TooManyRequestsExceptionResponse(parsedOutput, context)];
            case 15: throw _d.sent();
            case 16:
                parsedBody = parsedOutput.body;
                throwDefaultError({
                    output: output,
                    parsedBody: parsedBody,
                    exceptionCtor: __BaseException,
                    errorCode: errorCode,
                });
                _d.label = 17;
            case 17: return [2];
        }
    });
}); };
export var deserializeAws_restJson1GetChannelsCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var contents, data, _a;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                if (output.statusCode !== 200 && output.statusCode >= 300) {
                    return [2, deserializeAws_restJson1GetChannelsCommandError(output, context)];
                }
                contents = map({
                    $metadata: deserializeMetadata(output),
                });
                _a = __expectObject;
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.apply(void 0, [_b.sent()]);
                contents.ChannelsResponse = deserializeAws_restJson1ChannelsResponse(data, context);
                return [2, contents];
        }
    });
}); };
var deserializeAws_restJson1GetChannelsCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, errorCode, _b, parsedBody;
    var _c;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                _a = [__assign({}, output)];
                _c = {};
                return [4, parseErrorBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_c.body = _d.sent(), _c)]));
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "BadRequestException": return [3, 2];
                    case "com.amazonaws.pinpoint#BadRequestException": return [3, 2];
                    case "ForbiddenException": return [3, 4];
                    case "com.amazonaws.pinpoint#ForbiddenException": return [3, 4];
                    case "InternalServerErrorException": return [3, 6];
                    case "com.amazonaws.pinpoint#InternalServerErrorException": return [3, 6];
                    case "MethodNotAllowedException": return [3, 8];
                    case "com.amazonaws.pinpoint#MethodNotAllowedException": return [3, 8];
                    case "NotFoundException": return [3, 10];
                    case "com.amazonaws.pinpoint#NotFoundException": return [3, 10];
                    case "PayloadTooLargeException": return [3, 12];
                    case "com.amazonaws.pinpoint#PayloadTooLargeException": return [3, 12];
                    case "TooManyRequestsException": return [3, 14];
                    case "com.amazonaws.pinpoint#TooManyRequestsException": return [3, 14];
                }
                return [3, 16];
            case 2: return [4, deserializeAws_restJson1BadRequestExceptionResponse(parsedOutput, context)];
            case 3: throw _d.sent();
            case 4: return [4, deserializeAws_restJson1ForbiddenExceptionResponse(parsedOutput, context)];
            case 5: throw _d.sent();
            case 6: return [4, deserializeAws_restJson1InternalServerErrorExceptionResponse(parsedOutput, context)];
            case 7: throw _d.sent();
            case 8: return [4, deserializeAws_restJson1MethodNotAllowedExceptionResponse(parsedOutput, context)];
            case 9: throw _d.sent();
            case 10: return [4, deserializeAws_restJson1NotFoundExceptionResponse(parsedOutput, context)];
            case 11: throw _d.sent();
            case 12: return [4, deserializeAws_restJson1PayloadTooLargeExceptionResponse(parsedOutput, context)];
            case 13: throw _d.sent();
            case 14: return [4, deserializeAws_restJson1TooManyRequestsExceptionResponse(parsedOutput, context)];
            case 15: throw _d.sent();
            case 16:
                parsedBody = parsedOutput.body;
                throwDefaultError({
                    output: output,
                    parsedBody: parsedBody,
                    exceptionCtor: __BaseException,
                    errorCode: errorCode,
                });
                _d.label = 17;
            case 17: return [2];
        }
    });
}); };
export var deserializeAws_restJson1GetEmailChannelCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var contents, data, _a;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                if (output.statusCode !== 200 && output.statusCode >= 300) {
                    return [2, deserializeAws_restJson1GetEmailChannelCommandError(output, context)];
                }
                contents = map({
                    $metadata: deserializeMetadata(output),
                });
                _a = __expectObject;
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.apply(void 0, [_b.sent()]);
                contents.EmailChannelResponse = deserializeAws_restJson1EmailChannelResponse(data, context);
                return [2, contents];
        }
    });
}); };
var deserializeAws_restJson1GetEmailChannelCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, errorCode, _b, parsedBody;
    var _c;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                _a = [__assign({}, output)];
                _c = {};
                return [4, parseErrorBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_c.body = _d.sent(), _c)]));
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "BadRequestException": return [3, 2];
                    case "com.amazonaws.pinpoint#BadRequestException": return [3, 2];
                    case "ForbiddenException": return [3, 4];
                    case "com.amazonaws.pinpoint#ForbiddenException": return [3, 4];
                    case "InternalServerErrorException": return [3, 6];
                    case "com.amazonaws.pinpoint#InternalServerErrorException": return [3, 6];
                    case "MethodNotAllowedException": return [3, 8];
                    case "com.amazonaws.pinpoint#MethodNotAllowedException": return [3, 8];
                    case "NotFoundException": return [3, 10];
                    case "com.amazonaws.pinpoint#NotFoundException": return [3, 10];
                    case "PayloadTooLargeException": return [3, 12];
                    case "com.amazonaws.pinpoint#PayloadTooLargeException": return [3, 12];
                    case "TooManyRequestsException": return [3, 14];
                    case "com.amazonaws.pinpoint#TooManyRequestsException": return [3, 14];
                }
                return [3, 16];
            case 2: return [4, deserializeAws_restJson1BadRequestExceptionResponse(parsedOutput, context)];
            case 3: throw _d.sent();
            case 4: return [4, deserializeAws_restJson1ForbiddenExceptionResponse(parsedOutput, context)];
            case 5: throw _d.sent();
            case 6: return [4, deserializeAws_restJson1InternalServerErrorExceptionResponse(parsedOutput, context)];
            case 7: throw _d.sent();
            case 8: return [4, deserializeAws_restJson1MethodNotAllowedExceptionResponse(parsedOutput, context)];
            case 9: throw _d.sent();
            case 10: return [4, deserializeAws_restJson1NotFoundExceptionResponse(parsedOutput, context)];
            case 11: throw _d.sent();
            case 12: return [4, deserializeAws_restJson1PayloadTooLargeExceptionResponse(parsedOutput, context)];
            case 13: throw _d.sent();
            case 14: return [4, deserializeAws_restJson1TooManyRequestsExceptionResponse(parsedOutput, context)];
            case 15: throw _d.sent();
            case 16:
                parsedBody = parsedOutput.body;
                throwDefaultError({
                    output: output,
                    parsedBody: parsedBody,
                    exceptionCtor: __BaseException,
                    errorCode: errorCode,
                });
                _d.label = 17;
            case 17: return [2];
        }
    });
}); };
export var deserializeAws_restJson1GetEmailTemplateCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var contents, data, _a;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                if (output.statusCode !== 200 && output.statusCode >= 300) {
                    return [2, deserializeAws_restJson1GetEmailTemplateCommandError(output, context)];
                }
                contents = map({
                    $metadata: deserializeMetadata(output),
                });
                _a = __expectObject;
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.apply(void 0, [_b.sent()]);
                contents.EmailTemplateResponse = deserializeAws_restJson1EmailTemplateResponse(data, context);
                return [2, contents];
        }
    });
}); };
var deserializeAws_restJson1GetEmailTemplateCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, errorCode, _b, parsedBody;
    var _c;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                _a = [__assign({}, output)];
                _c = {};
                return [4, parseErrorBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_c.body = _d.sent(), _c)]));
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "BadRequestException": return [3, 2];
                    case "com.amazonaws.pinpoint#BadRequestException": return [3, 2];
                    case "ForbiddenException": return [3, 4];
                    case "com.amazonaws.pinpoint#ForbiddenException": return [3, 4];
                    case "InternalServerErrorException": return [3, 6];
                    case "com.amazonaws.pinpoint#InternalServerErrorException": return [3, 6];
                    case "MethodNotAllowedException": return [3, 8];
                    case "com.amazonaws.pinpoint#MethodNotAllowedException": return [3, 8];
                    case "NotFoundException": return [3, 10];
                    case "com.amazonaws.pinpoint#NotFoundException": return [3, 10];
                    case "PayloadTooLargeException": return [3, 12];
                    case "com.amazonaws.pinpoint#PayloadTooLargeException": return [3, 12];
                    case "TooManyRequestsException": return [3, 14];
                    case "com.amazonaws.pinpoint#TooManyRequestsException": return [3, 14];
                }
                return [3, 16];
            case 2: return [4, deserializeAws_restJson1BadRequestExceptionResponse(parsedOutput, context)];
            case 3: throw _d.sent();
            case 4: return [4, deserializeAws_restJson1ForbiddenExceptionResponse(parsedOutput, context)];
            case 5: throw _d.sent();
            case 6: return [4, deserializeAws_restJson1InternalServerErrorExceptionResponse(parsedOutput, context)];
            case 7: throw _d.sent();
            case 8: return [4, deserializeAws_restJson1MethodNotAllowedExceptionResponse(parsedOutput, context)];
            case 9: throw _d.sent();
            case 10: return [4, deserializeAws_restJson1NotFoundExceptionResponse(parsedOutput, context)];
            case 11: throw _d.sent();
            case 12: return [4, deserializeAws_restJson1PayloadTooLargeExceptionResponse(parsedOutput, context)];
            case 13: throw _d.sent();
            case 14: return [4, deserializeAws_restJson1TooManyRequestsExceptionResponse(parsedOutput, context)];
            case 15: throw _d.sent();
            case 16:
                parsedBody = parsedOutput.body;
                throwDefaultError({
                    output: output,
                    parsedBody: parsedBody,
                    exceptionCtor: __BaseException,
                    errorCode: errorCode,
                });
                _d.label = 17;
            case 17: return [2];
        }
    });
}); };
export var deserializeAws_restJson1GetEndpointCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var contents, data, _a;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                if (output.statusCode !== 200 && output.statusCode >= 300) {
                    return [2, deserializeAws_restJson1GetEndpointCommandError(output, context)];
                }
                contents = map({
                    $metadata: deserializeMetadata(output),
                });
                _a = __expectObject;
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.apply(void 0, [_b.sent()]);
                contents.EndpointResponse = deserializeAws_restJson1EndpointResponse(data, context);
                return [2, contents];
        }
    });
}); };
var deserializeAws_restJson1GetEndpointCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, errorCode, _b, parsedBody;
    var _c;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                _a = [__assign({}, output)];
                _c = {};
                return [4, parseErrorBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_c.body = _d.sent(), _c)]));
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "BadRequestException": return [3, 2];
                    case "com.amazonaws.pinpoint#BadRequestException": return [3, 2];
                    case "ForbiddenException": return [3, 4];
                    case "com.amazonaws.pinpoint#ForbiddenException": return [3, 4];
                    case "InternalServerErrorException": return [3, 6];
                    case "com.amazonaws.pinpoint#InternalServerErrorException": return [3, 6];
                    case "MethodNotAllowedException": return [3, 8];
                    case "com.amazonaws.pinpoint#MethodNotAllowedException": return [3, 8];
                    case "NotFoundException": return [3, 10];
                    case "com.amazonaws.pinpoint#NotFoundException": return [3, 10];
                    case "PayloadTooLargeException": return [3, 12];
                    case "com.amazonaws.pinpoint#PayloadTooLargeException": return [3, 12];
                    case "TooManyRequestsException": return [3, 14];
                    case "com.amazonaws.pinpoint#TooManyRequestsException": return [3, 14];
                }
                return [3, 16];
            case 2: return [4, deserializeAws_restJson1BadRequestExceptionResponse(parsedOutput, context)];
            case 3: throw _d.sent();
            case 4: return [4, deserializeAws_restJson1ForbiddenExceptionResponse(parsedOutput, context)];
            case 5: throw _d.sent();
            case 6: return [4, deserializeAws_restJson1InternalServerErrorExceptionResponse(parsedOutput, context)];
            case 7: throw _d.sent();
            case 8: return [4, deserializeAws_restJson1MethodNotAllowedExceptionResponse(parsedOutput, context)];
            case 9: throw _d.sent();
            case 10: return [4, deserializeAws_restJson1NotFoundExceptionResponse(parsedOutput, context)];
            case 11: throw _d.sent();
            case 12: return [4, deserializeAws_restJson1PayloadTooLargeExceptionResponse(parsedOutput, context)];
            case 13: throw _d.sent();
            case 14: return [4, deserializeAws_restJson1TooManyRequestsExceptionResponse(parsedOutput, context)];
            case 15: throw _d.sent();
            case 16:
                parsedBody = parsedOutput.body;
                throwDefaultError({
                    output: output,
                    parsedBody: parsedBody,
                    exceptionCtor: __BaseException,
                    errorCode: errorCode,
                });
                _d.label = 17;
            case 17: return [2];
        }
    });
}); };
export var deserializeAws_restJson1GetEventStreamCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var contents, data, _a;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                if (output.statusCode !== 200 && output.statusCode >= 300) {
                    return [2, deserializeAws_restJson1GetEventStreamCommandError(output, context)];
                }
                contents = map({
                    $metadata: deserializeMetadata(output),
                });
                _a = __expectObject;
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.apply(void 0, [_b.sent()]);
                contents.EventStream = deserializeAws_restJson1EventStream(data, context);
                return [2, contents];
        }
    });
}); };
var deserializeAws_restJson1GetEventStreamCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, errorCode, _b, parsedBody;
    var _c;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                _a = [__assign({}, output)];
                _c = {};
                return [4, parseErrorBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_c.body = _d.sent(), _c)]));
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "BadRequestException": return [3, 2];
                    case "com.amazonaws.pinpoint#BadRequestException": return [3, 2];
                    case "ForbiddenException": return [3, 4];
                    case "com.amazonaws.pinpoint#ForbiddenException": return [3, 4];
                    case "InternalServerErrorException": return [3, 6];
                    case "com.amazonaws.pinpoint#InternalServerErrorException": return [3, 6];
                    case "MethodNotAllowedException": return [3, 8];
                    case "com.amazonaws.pinpoint#MethodNotAllowedException": return [3, 8];
                    case "NotFoundException": return [3, 10];
                    case "com.amazonaws.pinpoint#NotFoundException": return [3, 10];
                    case "PayloadTooLargeException": return [3, 12];
                    case "com.amazonaws.pinpoint#PayloadTooLargeException": return [3, 12];
                    case "TooManyRequestsException": return [3, 14];
                    case "com.amazonaws.pinpoint#TooManyRequestsException": return [3, 14];
                }
                return [3, 16];
            case 2: return [4, deserializeAws_restJson1BadRequestExceptionResponse(parsedOutput, context)];
            case 3: throw _d.sent();
            case 4: return [4, deserializeAws_restJson1ForbiddenExceptionResponse(parsedOutput, context)];
            case 5: throw _d.sent();
            case 6: return [4, deserializeAws_restJson1InternalServerErrorExceptionResponse(parsedOutput, context)];
            case 7: throw _d.sent();
            case 8: return [4, deserializeAws_restJson1MethodNotAllowedExceptionResponse(parsedOutput, context)];
            case 9: throw _d.sent();
            case 10: return [4, deserializeAws_restJson1NotFoundExceptionResponse(parsedOutput, context)];
            case 11: throw _d.sent();
            case 12: return [4, deserializeAws_restJson1PayloadTooLargeExceptionResponse(parsedOutput, context)];
            case 13: throw _d.sent();
            case 14: return [4, deserializeAws_restJson1TooManyRequestsExceptionResponse(parsedOutput, context)];
            case 15: throw _d.sent();
            case 16:
                parsedBody = parsedOutput.body;
                throwDefaultError({
                    output: output,
                    parsedBody: parsedBody,
                    exceptionCtor: __BaseException,
                    errorCode: errorCode,
                });
                _d.label = 17;
            case 17: return [2];
        }
    });
}); };
export var deserializeAws_restJson1GetExportJobCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var contents, data, _a;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                if (output.statusCode !== 200 && output.statusCode >= 300) {
                    return [2, deserializeAws_restJson1GetExportJobCommandError(output, context)];
                }
                contents = map({
                    $metadata: deserializeMetadata(output),
                });
                _a = __expectObject;
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.apply(void 0, [_b.sent()]);
                contents.ExportJobResponse = deserializeAws_restJson1ExportJobResponse(data, context);
                return [2, contents];
        }
    });
}); };
var deserializeAws_restJson1GetExportJobCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, errorCode, _b, parsedBody;
    var _c;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                _a = [__assign({}, output)];
                _c = {};
                return [4, parseErrorBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_c.body = _d.sent(), _c)]));
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "BadRequestException": return [3, 2];
                    case "com.amazonaws.pinpoint#BadRequestException": return [3, 2];
                    case "ForbiddenException": return [3, 4];
                    case "com.amazonaws.pinpoint#ForbiddenException": return [3, 4];
                    case "InternalServerErrorException": return [3, 6];
                    case "com.amazonaws.pinpoint#InternalServerErrorException": return [3, 6];
                    case "MethodNotAllowedException": return [3, 8];
                    case "com.amazonaws.pinpoint#MethodNotAllowedException": return [3, 8];
                    case "NotFoundException": return [3, 10];
                    case "com.amazonaws.pinpoint#NotFoundException": return [3, 10];
                    case "PayloadTooLargeException": return [3, 12];
                    case "com.amazonaws.pinpoint#PayloadTooLargeException": return [3, 12];
                    case "TooManyRequestsException": return [3, 14];
                    case "com.amazonaws.pinpoint#TooManyRequestsException": return [3, 14];
                }
                return [3, 16];
            case 2: return [4, deserializeAws_restJson1BadRequestExceptionResponse(parsedOutput, context)];
            case 3: throw _d.sent();
            case 4: return [4, deserializeAws_restJson1ForbiddenExceptionResponse(parsedOutput, context)];
            case 5: throw _d.sent();
            case 6: return [4, deserializeAws_restJson1InternalServerErrorExceptionResponse(parsedOutput, context)];
            case 7: throw _d.sent();
            case 8: return [4, deserializeAws_restJson1MethodNotAllowedExceptionResponse(parsedOutput, context)];
            case 9: throw _d.sent();
            case 10: return [4, deserializeAws_restJson1NotFoundExceptionResponse(parsedOutput, context)];
            case 11: throw _d.sent();
            case 12: return [4, deserializeAws_restJson1PayloadTooLargeExceptionResponse(parsedOutput, context)];
            case 13: throw _d.sent();
            case 14: return [4, deserializeAws_restJson1TooManyRequestsExceptionResponse(parsedOutput, context)];
            case 15: throw _d.sent();
            case 16:
                parsedBody = parsedOutput.body;
                throwDefaultError({
                    output: output,
                    parsedBody: parsedBody,
                    exceptionCtor: __BaseException,
                    errorCode: errorCode,
                });
                _d.label = 17;
            case 17: return [2];
        }
    });
}); };
export var deserializeAws_restJson1GetExportJobsCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var contents, data, _a;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                if (output.statusCode !== 200 && output.statusCode >= 300) {
                    return [2, deserializeAws_restJson1GetExportJobsCommandError(output, context)];
                }
                contents = map({
                    $metadata: deserializeMetadata(output),
                });
                _a = __expectObject;
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.apply(void 0, [_b.sent()]);
                contents.ExportJobsResponse = deserializeAws_restJson1ExportJobsResponse(data, context);
                return [2, contents];
        }
    });
}); };
var deserializeAws_restJson1GetExportJobsCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, errorCode, _b, parsedBody;
    var _c;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                _a = [__assign({}, output)];
                _c = {};
                return [4, parseErrorBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_c.body = _d.sent(), _c)]));
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "BadRequestException": return [3, 2];
                    case "com.amazonaws.pinpoint#BadRequestException": return [3, 2];
                    case "ForbiddenException": return [3, 4];
                    case "com.amazonaws.pinpoint#ForbiddenException": return [3, 4];
                    case "InternalServerErrorException": return [3, 6];
                    case "com.amazonaws.pinpoint#InternalServerErrorException": return [3, 6];
                    case "MethodNotAllowedException": return [3, 8];
                    case "com.amazonaws.pinpoint#MethodNotAllowedException": return [3, 8];
                    case "NotFoundException": return [3, 10];
                    case "com.amazonaws.pinpoint#NotFoundException": return [3, 10];
                    case "PayloadTooLargeException": return [3, 12];
                    case "com.amazonaws.pinpoint#PayloadTooLargeException": return [3, 12];
                    case "TooManyRequestsException": return [3, 14];
                    case "com.amazonaws.pinpoint#TooManyRequestsException": return [3, 14];
                }
                return [3, 16];
            case 2: return [4, deserializeAws_restJson1BadRequestExceptionResponse(parsedOutput, context)];
            case 3: throw _d.sent();
            case 4: return [4, deserializeAws_restJson1ForbiddenExceptionResponse(parsedOutput, context)];
            case 5: throw _d.sent();
            case 6: return [4, deserializeAws_restJson1InternalServerErrorExceptionResponse(parsedOutput, context)];
            case 7: throw _d.sent();
            case 8: return [4, deserializeAws_restJson1MethodNotAllowedExceptionResponse(parsedOutput, context)];
            case 9: throw _d.sent();
            case 10: return [4, deserializeAws_restJson1NotFoundExceptionResponse(parsedOutput, context)];
            case 11: throw _d.sent();
            case 12: return [4, deserializeAws_restJson1PayloadTooLargeExceptionResponse(parsedOutput, context)];
            case 13: throw _d.sent();
            case 14: return [4, deserializeAws_restJson1TooManyRequestsExceptionResponse(parsedOutput, context)];
            case 15: throw _d.sent();
            case 16:
                parsedBody = parsedOutput.body;
                throwDefaultError({
                    output: output,
                    parsedBody: parsedBody,
                    exceptionCtor: __BaseException,
                    errorCode: errorCode,
                });
                _d.label = 17;
            case 17: return [2];
        }
    });
}); };
export var deserializeAws_restJson1GetGcmChannelCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var contents, data, _a;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                if (output.statusCode !== 200 && output.statusCode >= 300) {
                    return [2, deserializeAws_restJson1GetGcmChannelCommandError(output, context)];
                }
                contents = map({
                    $metadata: deserializeMetadata(output),
                });
                _a = __expectObject;
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.apply(void 0, [_b.sent()]);
                contents.GCMChannelResponse = deserializeAws_restJson1GCMChannelResponse(data, context);
                return [2, contents];
        }
    });
}); };
var deserializeAws_restJson1GetGcmChannelCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, errorCode, _b, parsedBody;
    var _c;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                _a = [__assign({}, output)];
                _c = {};
                return [4, parseErrorBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_c.body = _d.sent(), _c)]));
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "BadRequestException": return [3, 2];
                    case "com.amazonaws.pinpoint#BadRequestException": return [3, 2];
                    case "ForbiddenException": return [3, 4];
                    case "com.amazonaws.pinpoint#ForbiddenException": return [3, 4];
                    case "InternalServerErrorException": return [3, 6];
                    case "com.amazonaws.pinpoint#InternalServerErrorException": return [3, 6];
                    case "MethodNotAllowedException": return [3, 8];
                    case "com.amazonaws.pinpoint#MethodNotAllowedException": return [3, 8];
                    case "NotFoundException": return [3, 10];
                    case "com.amazonaws.pinpoint#NotFoundException": return [3, 10];
                    case "PayloadTooLargeException": return [3, 12];
                    case "com.amazonaws.pinpoint#PayloadTooLargeException": return [3, 12];
                    case "TooManyRequestsException": return [3, 14];
                    case "com.amazonaws.pinpoint#TooManyRequestsException": return [3, 14];
                }
                return [3, 16];
            case 2: return [4, deserializeAws_restJson1BadRequestExceptionResponse(parsedOutput, context)];
            case 3: throw _d.sent();
            case 4: return [4, deserializeAws_restJson1ForbiddenExceptionResponse(parsedOutput, context)];
            case 5: throw _d.sent();
            case 6: return [4, deserializeAws_restJson1InternalServerErrorExceptionResponse(parsedOutput, context)];
            case 7: throw _d.sent();
            case 8: return [4, deserializeAws_restJson1MethodNotAllowedExceptionResponse(parsedOutput, context)];
            case 9: throw _d.sent();
            case 10: return [4, deserializeAws_restJson1NotFoundExceptionResponse(parsedOutput, context)];
            case 11: throw _d.sent();
            case 12: return [4, deserializeAws_restJson1PayloadTooLargeExceptionResponse(parsedOutput, context)];
            case 13: throw _d.sent();
            case 14: return [4, deserializeAws_restJson1TooManyRequestsExceptionResponse(parsedOutput, context)];
            case 15: throw _d.sent();
            case 16:
                parsedBody = parsedOutput.body;
                throwDefaultError({
                    output: output,
                    parsedBody: parsedBody,
                    exceptionCtor: __BaseException,
                    errorCode: errorCode,
                });
                _d.label = 17;
            case 17: return [2];
        }
    });
}); };
export var deserializeAws_restJson1GetImportJobCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var contents, data, _a;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                if (output.statusCode !== 200 && output.statusCode >= 300) {
                    return [2, deserializeAws_restJson1GetImportJobCommandError(output, context)];
                }
                contents = map({
                    $metadata: deserializeMetadata(output),
                });
                _a = __expectObject;
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.apply(void 0, [_b.sent()]);
                contents.ImportJobResponse = deserializeAws_restJson1ImportJobResponse(data, context);
                return [2, contents];
        }
    });
}); };
var deserializeAws_restJson1GetImportJobCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, errorCode, _b, parsedBody;
    var _c;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                _a = [__assign({}, output)];
                _c = {};
                return [4, parseErrorBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_c.body = _d.sent(), _c)]));
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "BadRequestException": return [3, 2];
                    case "com.amazonaws.pinpoint#BadRequestException": return [3, 2];
                    case "ForbiddenException": return [3, 4];
                    case "com.amazonaws.pinpoint#ForbiddenException": return [3, 4];
                    case "InternalServerErrorException": return [3, 6];
                    case "com.amazonaws.pinpoint#InternalServerErrorException": return [3, 6];
                    case "MethodNotAllowedException": return [3, 8];
                    case "com.amazonaws.pinpoint#MethodNotAllowedException": return [3, 8];
                    case "NotFoundException": return [3, 10];
                    case "com.amazonaws.pinpoint#NotFoundException": return [3, 10];
                    case "PayloadTooLargeException": return [3, 12];
                    case "com.amazonaws.pinpoint#PayloadTooLargeException": return [3, 12];
                    case "TooManyRequestsException": return [3, 14];
                    case "com.amazonaws.pinpoint#TooManyRequestsException": return [3, 14];
                }
                return [3, 16];
            case 2: return [4, deserializeAws_restJson1BadRequestExceptionResponse(parsedOutput, context)];
            case 3: throw _d.sent();
            case 4: return [4, deserializeAws_restJson1ForbiddenExceptionResponse(parsedOutput, context)];
            case 5: throw _d.sent();
            case 6: return [4, deserializeAws_restJson1InternalServerErrorExceptionResponse(parsedOutput, context)];
            case 7: throw _d.sent();
            case 8: return [4, deserializeAws_restJson1MethodNotAllowedExceptionResponse(parsedOutput, context)];
            case 9: throw _d.sent();
            case 10: return [4, deserializeAws_restJson1NotFoundExceptionResponse(parsedOutput, context)];
            case 11: throw _d.sent();
            case 12: return [4, deserializeAws_restJson1PayloadTooLargeExceptionResponse(parsedOutput, context)];
            case 13: throw _d.sent();
            case 14: return [4, deserializeAws_restJson1TooManyRequestsExceptionResponse(parsedOutput, context)];
            case 15: throw _d.sent();
            case 16:
                parsedBody = parsedOutput.body;
                throwDefaultError({
                    output: output,
                    parsedBody: parsedBody,
                    exceptionCtor: __BaseException,
                    errorCode: errorCode,
                });
                _d.label = 17;
            case 17: return [2];
        }
    });
}); };
export var deserializeAws_restJson1GetImportJobsCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var contents, data, _a;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                if (output.statusCode !== 200 && output.statusCode >= 300) {
                    return [2, deserializeAws_restJson1GetImportJobsCommandError(output, context)];
                }
                contents = map({
                    $metadata: deserializeMetadata(output),
                });
                _a = __expectObject;
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.apply(void 0, [_b.sent()]);
                contents.ImportJobsResponse = deserializeAws_restJson1ImportJobsResponse(data, context);
                return [2, contents];
        }
    });
}); };
var deserializeAws_restJson1GetImportJobsCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, errorCode, _b, parsedBody;
    var _c;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                _a = [__assign({}, output)];
                _c = {};
                return [4, parseErrorBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_c.body = _d.sent(), _c)]));
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "BadRequestException": return [3, 2];
                    case "com.amazonaws.pinpoint#BadRequestException": return [3, 2];
                    case "ForbiddenException": return [3, 4];
                    case "com.amazonaws.pinpoint#ForbiddenException": return [3, 4];
                    case "InternalServerErrorException": return [3, 6];
                    case "com.amazonaws.pinpoint#InternalServerErrorException": return [3, 6];
                    case "MethodNotAllowedException": return [3, 8];
                    case "com.amazonaws.pinpoint#MethodNotAllowedException": return [3, 8];
                    case "NotFoundException": return [3, 10];
                    case "com.amazonaws.pinpoint#NotFoundException": return [3, 10];
                    case "PayloadTooLargeException": return [3, 12];
                    case "com.amazonaws.pinpoint#PayloadTooLargeException": return [3, 12];
                    case "TooManyRequestsException": return [3, 14];
                    case "com.amazonaws.pinpoint#TooManyRequestsException": return [3, 14];
                }
                return [3, 16];
            case 2: return [4, deserializeAws_restJson1BadRequestExceptionResponse(parsedOutput, context)];
            case 3: throw _d.sent();
            case 4: return [4, deserializeAws_restJson1ForbiddenExceptionResponse(parsedOutput, context)];
            case 5: throw _d.sent();
            case 6: return [4, deserializeAws_restJson1InternalServerErrorExceptionResponse(parsedOutput, context)];
            case 7: throw _d.sent();
            case 8: return [4, deserializeAws_restJson1MethodNotAllowedExceptionResponse(parsedOutput, context)];
            case 9: throw _d.sent();
            case 10: return [4, deserializeAws_restJson1NotFoundExceptionResponse(parsedOutput, context)];
            case 11: throw _d.sent();
            case 12: return [4, deserializeAws_restJson1PayloadTooLargeExceptionResponse(parsedOutput, context)];
            case 13: throw _d.sent();
            case 14: return [4, deserializeAws_restJson1TooManyRequestsExceptionResponse(parsedOutput, context)];
            case 15: throw _d.sent();
            case 16:
                parsedBody = parsedOutput.body;
                throwDefaultError({
                    output: output,
                    parsedBody: parsedBody,
                    exceptionCtor: __BaseException,
                    errorCode: errorCode,
                });
                _d.label = 17;
            case 17: return [2];
        }
    });
}); };
export var deserializeAws_restJson1GetInAppMessagesCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var contents, data, _a;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                if (output.statusCode !== 200 && output.statusCode >= 300) {
                    return [2, deserializeAws_restJson1GetInAppMessagesCommandError(output, context)];
                }
                contents = map({
                    $metadata: deserializeMetadata(output),
                });
                _a = __expectObject;
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.apply(void 0, [_b.sent()]);
                contents.InAppMessagesResponse = deserializeAws_restJson1InAppMessagesResponse(data, context);
                return [2, contents];
        }
    });
}); };
var deserializeAws_restJson1GetInAppMessagesCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, errorCode, _b, parsedBody;
    var _c;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                _a = [__assign({}, output)];
                _c = {};
                return [4, parseErrorBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_c.body = _d.sent(), _c)]));
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "BadRequestException": return [3, 2];
                    case "com.amazonaws.pinpoint#BadRequestException": return [3, 2];
                    case "ForbiddenException": return [3, 4];
                    case "com.amazonaws.pinpoint#ForbiddenException": return [3, 4];
                    case "InternalServerErrorException": return [3, 6];
                    case "com.amazonaws.pinpoint#InternalServerErrorException": return [3, 6];
                    case "MethodNotAllowedException": return [3, 8];
                    case "com.amazonaws.pinpoint#MethodNotAllowedException": return [3, 8];
                    case "NotFoundException": return [3, 10];
                    case "com.amazonaws.pinpoint#NotFoundException": return [3, 10];
                    case "PayloadTooLargeException": return [3, 12];
                    case "com.amazonaws.pinpoint#PayloadTooLargeException": return [3, 12];
                    case "TooManyRequestsException": return [3, 14];
                    case "com.amazonaws.pinpoint#TooManyRequestsException": return [3, 14];
                }
                return [3, 16];
            case 2: return [4, deserializeAws_restJson1BadRequestExceptionResponse(parsedOutput, context)];
            case 3: throw _d.sent();
            case 4: return [4, deserializeAws_restJson1ForbiddenExceptionResponse(parsedOutput, context)];
            case 5: throw _d.sent();
            case 6: return [4, deserializeAws_restJson1InternalServerErrorExceptionResponse(parsedOutput, context)];
            case 7: throw _d.sent();
            case 8: return [4, deserializeAws_restJson1MethodNotAllowedExceptionResponse(parsedOutput, context)];
            case 9: throw _d.sent();
            case 10: return [4, deserializeAws_restJson1NotFoundExceptionResponse(parsedOutput, context)];
            case 11: throw _d.sent();
            case 12: return [4, deserializeAws_restJson1PayloadTooLargeExceptionResponse(parsedOutput, context)];
            case 13: throw _d.sent();
            case 14: return [4, deserializeAws_restJson1TooManyRequestsExceptionResponse(parsedOutput, context)];
            case 15: throw _d.sent();
            case 16:
                parsedBody = parsedOutput.body;
                throwDefaultError({
                    output: output,
                    parsedBody: parsedBody,
                    exceptionCtor: __BaseException,
                    errorCode: errorCode,
                });
                _d.label = 17;
            case 17: return [2];
        }
    });
}); };
export var deserializeAws_restJson1GetInAppTemplateCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var contents, data, _a;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                if (output.statusCode !== 200 && output.statusCode >= 300) {
                    return [2, deserializeAws_restJson1GetInAppTemplateCommandError(output, context)];
                }
                contents = map({
                    $metadata: deserializeMetadata(output),
                });
                _a = __expectObject;
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.apply(void 0, [_b.sent()]);
                contents.InAppTemplateResponse = deserializeAws_restJson1InAppTemplateResponse(data, context);
                return [2, contents];
        }
    });
}); };
var deserializeAws_restJson1GetInAppTemplateCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, errorCode, _b, parsedBody;
    var _c;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                _a = [__assign({}, output)];
                _c = {};
                return [4, parseErrorBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_c.body = _d.sent(), _c)]));
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "BadRequestException": return [3, 2];
                    case "com.amazonaws.pinpoint#BadRequestException": return [3, 2];
                    case "ForbiddenException": return [3, 4];
                    case "com.amazonaws.pinpoint#ForbiddenException": return [3, 4];
                    case "InternalServerErrorException": return [3, 6];
                    case "com.amazonaws.pinpoint#InternalServerErrorException": return [3, 6];
                    case "MethodNotAllowedException": return [3, 8];
                    case "com.amazonaws.pinpoint#MethodNotAllowedException": return [3, 8];
                    case "NotFoundException": return [3, 10];
                    case "com.amazonaws.pinpoint#NotFoundException": return [3, 10];
                    case "PayloadTooLargeException": return [3, 12];
                    case "com.amazonaws.pinpoint#PayloadTooLargeException": return [3, 12];
                    case "TooManyRequestsException": return [3, 14];
                    case "com.amazonaws.pinpoint#TooManyRequestsException": return [3, 14];
                }
                return [3, 16];
            case 2: return [4, deserializeAws_restJson1BadRequestExceptionResponse(parsedOutput, context)];
            case 3: throw _d.sent();
            case 4: return [4, deserializeAws_restJson1ForbiddenExceptionResponse(parsedOutput, context)];
            case 5: throw _d.sent();
            case 6: return [4, deserializeAws_restJson1InternalServerErrorExceptionResponse(parsedOutput, context)];
            case 7: throw _d.sent();
            case 8: return [4, deserializeAws_restJson1MethodNotAllowedExceptionResponse(parsedOutput, context)];
            case 9: throw _d.sent();
            case 10: return [4, deserializeAws_restJson1NotFoundExceptionResponse(parsedOutput, context)];
            case 11: throw _d.sent();
            case 12: return [4, deserializeAws_restJson1PayloadTooLargeExceptionResponse(parsedOutput, context)];
            case 13: throw _d.sent();
            case 14: return [4, deserializeAws_restJson1TooManyRequestsExceptionResponse(parsedOutput, context)];
            case 15: throw _d.sent();
            case 16:
                parsedBody = parsedOutput.body;
                throwDefaultError({
                    output: output,
                    parsedBody: parsedBody,
                    exceptionCtor: __BaseException,
                    errorCode: errorCode,
                });
                _d.label = 17;
            case 17: return [2];
        }
    });
}); };
export var deserializeAws_restJson1GetJourneyCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var contents, data, _a;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                if (output.statusCode !== 200 && output.statusCode >= 300) {
                    return [2, deserializeAws_restJson1GetJourneyCommandError(output, context)];
                }
                contents = map({
                    $metadata: deserializeMetadata(output),
                });
                _a = __expectObject;
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.apply(void 0, [_b.sent()]);
                contents.JourneyResponse = deserializeAws_restJson1JourneyResponse(data, context);
                return [2, contents];
        }
    });
}); };
var deserializeAws_restJson1GetJourneyCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, errorCode, _b, parsedBody;
    var _c;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                _a = [__assign({}, output)];
                _c = {};
                return [4, parseErrorBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_c.body = _d.sent(), _c)]));
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "BadRequestException": return [3, 2];
                    case "com.amazonaws.pinpoint#BadRequestException": return [3, 2];
                    case "ForbiddenException": return [3, 4];
                    case "com.amazonaws.pinpoint#ForbiddenException": return [3, 4];
                    case "InternalServerErrorException": return [3, 6];
                    case "com.amazonaws.pinpoint#InternalServerErrorException": return [3, 6];
                    case "MethodNotAllowedException": return [3, 8];
                    case "com.amazonaws.pinpoint#MethodNotAllowedException": return [3, 8];
                    case "NotFoundException": return [3, 10];
                    case "com.amazonaws.pinpoint#NotFoundException": return [3, 10];
                    case "PayloadTooLargeException": return [3, 12];
                    case "com.amazonaws.pinpoint#PayloadTooLargeException": return [3, 12];
                    case "TooManyRequestsException": return [3, 14];
                    case "com.amazonaws.pinpoint#TooManyRequestsException": return [3, 14];
                }
                return [3, 16];
            case 2: return [4, deserializeAws_restJson1BadRequestExceptionResponse(parsedOutput, context)];
            case 3: throw _d.sent();
            case 4: return [4, deserializeAws_restJson1ForbiddenExceptionResponse(parsedOutput, context)];
            case 5: throw _d.sent();
            case 6: return [4, deserializeAws_restJson1InternalServerErrorExceptionResponse(parsedOutput, context)];
            case 7: throw _d.sent();
            case 8: return [4, deserializeAws_restJson1MethodNotAllowedExceptionResponse(parsedOutput, context)];
            case 9: throw _d.sent();
            case 10: return [4, deserializeAws_restJson1NotFoundExceptionResponse(parsedOutput, context)];
            case 11: throw _d.sent();
            case 12: return [4, deserializeAws_restJson1PayloadTooLargeExceptionResponse(parsedOutput, context)];
            case 13: throw _d.sent();
            case 14: return [4, deserializeAws_restJson1TooManyRequestsExceptionResponse(parsedOutput, context)];
            case 15: throw _d.sent();
            case 16:
                parsedBody = parsedOutput.body;
                throwDefaultError({
                    output: output,
                    parsedBody: parsedBody,
                    exceptionCtor: __BaseException,
                    errorCode: errorCode,
                });
                _d.label = 17;
            case 17: return [2];
        }
    });
}); };
export var deserializeAws_restJson1GetJourneyDateRangeKpiCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var contents, data, _a;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                if (output.statusCode !== 200 && output.statusCode >= 300) {
                    return [2, deserializeAws_restJson1GetJourneyDateRangeKpiCommandError(output, context)];
                }
                contents = map({
                    $metadata: deserializeMetadata(output),
                });
                _a = __expectObject;
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.apply(void 0, [_b.sent()]);
                contents.JourneyDateRangeKpiResponse = deserializeAws_restJson1JourneyDateRangeKpiResponse(data, context);
                return [2, contents];
        }
    });
}); };
var deserializeAws_restJson1GetJourneyDateRangeKpiCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, errorCode, _b, parsedBody;
    var _c;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                _a = [__assign({}, output)];
                _c = {};
                return [4, parseErrorBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_c.body = _d.sent(), _c)]));
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "BadRequestException": return [3, 2];
                    case "com.amazonaws.pinpoint#BadRequestException": return [3, 2];
                    case "ForbiddenException": return [3, 4];
                    case "com.amazonaws.pinpoint#ForbiddenException": return [3, 4];
                    case "InternalServerErrorException": return [3, 6];
                    case "com.amazonaws.pinpoint#InternalServerErrorException": return [3, 6];
                    case "MethodNotAllowedException": return [3, 8];
                    case "com.amazonaws.pinpoint#MethodNotAllowedException": return [3, 8];
                    case "NotFoundException": return [3, 10];
                    case "com.amazonaws.pinpoint#NotFoundException": return [3, 10];
                    case "PayloadTooLargeException": return [3, 12];
                    case "com.amazonaws.pinpoint#PayloadTooLargeException": return [3, 12];
                    case "TooManyRequestsException": return [3, 14];
                    case "com.amazonaws.pinpoint#TooManyRequestsException": return [3, 14];
                }
                return [3, 16];
            case 2: return [4, deserializeAws_restJson1BadRequestExceptionResponse(parsedOutput, context)];
            case 3: throw _d.sent();
            case 4: return [4, deserializeAws_restJson1ForbiddenExceptionResponse(parsedOutput, context)];
            case 5: throw _d.sent();
            case 6: return [4, deserializeAws_restJson1InternalServerErrorExceptionResponse(parsedOutput, context)];
            case 7: throw _d.sent();
            case 8: return [4, deserializeAws_restJson1MethodNotAllowedExceptionResponse(parsedOutput, context)];
            case 9: throw _d.sent();
            case 10: return [4, deserializeAws_restJson1NotFoundExceptionResponse(parsedOutput, context)];
            case 11: throw _d.sent();
            case 12: return [4, deserializeAws_restJson1PayloadTooLargeExceptionResponse(parsedOutput, context)];
            case 13: throw _d.sent();
            case 14: return [4, deserializeAws_restJson1TooManyRequestsExceptionResponse(parsedOutput, context)];
            case 15: throw _d.sent();
            case 16:
                parsedBody = parsedOutput.body;
                throwDefaultError({
                    output: output,
                    parsedBody: parsedBody,
                    exceptionCtor: __BaseException,
                    errorCode: errorCode,
                });
                _d.label = 17;
            case 17: return [2];
        }
    });
}); };
export var deserializeAws_restJson1GetJourneyExecutionActivityMetricsCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var contents, data, _a;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                if (output.statusCode !== 200 && output.statusCode >= 300) {
                    return [2, deserializeAws_restJson1GetJourneyExecutionActivityMetricsCommandError(output, context)];
                }
                contents = map({
                    $metadata: deserializeMetadata(output),
                });
                _a = __expectObject;
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.apply(void 0, [_b.sent()]);
                contents.JourneyExecutionActivityMetricsResponse = deserializeAws_restJson1JourneyExecutionActivityMetricsResponse(data, context);
                return [2, contents];
        }
    });
}); };
var deserializeAws_restJson1GetJourneyExecutionActivityMetricsCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, errorCode, _b, parsedBody;
    var _c;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                _a = [__assign({}, output)];
                _c = {};
                return [4, parseErrorBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_c.body = _d.sent(), _c)]));
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "BadRequestException": return [3, 2];
                    case "com.amazonaws.pinpoint#BadRequestException": return [3, 2];
                    case "ForbiddenException": return [3, 4];
                    case "com.amazonaws.pinpoint#ForbiddenException": return [3, 4];
                    case "InternalServerErrorException": return [3, 6];
                    case "com.amazonaws.pinpoint#InternalServerErrorException": return [3, 6];
                    case "MethodNotAllowedException": return [3, 8];
                    case "com.amazonaws.pinpoint#MethodNotAllowedException": return [3, 8];
                    case "NotFoundException": return [3, 10];
                    case "com.amazonaws.pinpoint#NotFoundException": return [3, 10];
                    case "PayloadTooLargeException": return [3, 12];
                    case "com.amazonaws.pinpoint#PayloadTooLargeException": return [3, 12];
                    case "TooManyRequestsException": return [3, 14];
                    case "com.amazonaws.pinpoint#TooManyRequestsException": return [3, 14];
                }
                return [3, 16];
            case 2: return [4, deserializeAws_restJson1BadRequestExceptionResponse(parsedOutput, context)];
            case 3: throw _d.sent();
            case 4: return [4, deserializeAws_restJson1ForbiddenExceptionResponse(parsedOutput, context)];
            case 5: throw _d.sent();
            case 6: return [4, deserializeAws_restJson1InternalServerErrorExceptionResponse(parsedOutput, context)];
            case 7: throw _d.sent();
            case 8: return [4, deserializeAws_restJson1MethodNotAllowedExceptionResponse(parsedOutput, context)];
            case 9: throw _d.sent();
            case 10: return [4, deserializeAws_restJson1NotFoundExceptionResponse(parsedOutput, context)];
            case 11: throw _d.sent();
            case 12: return [4, deserializeAws_restJson1PayloadTooLargeExceptionResponse(parsedOutput, context)];
            case 13: throw _d.sent();
            case 14: return [4, deserializeAws_restJson1TooManyRequestsExceptionResponse(parsedOutput, context)];
            case 15: throw _d.sent();
            case 16:
                parsedBody = parsedOutput.body;
                throwDefaultError({
                    output: output,
                    parsedBody: parsedBody,
                    exceptionCtor: __BaseException,
                    errorCode: errorCode,
                });
                _d.label = 17;
            case 17: return [2];
        }
    });
}); };
export var deserializeAws_restJson1GetJourneyExecutionMetricsCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var contents, data, _a;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                if (output.statusCode !== 200 && output.statusCode >= 300) {
                    return [2, deserializeAws_restJson1GetJourneyExecutionMetricsCommandError(output, context)];
                }
                contents = map({
                    $metadata: deserializeMetadata(output),
                });
                _a = __expectObject;
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.apply(void 0, [_b.sent()]);
                contents.JourneyExecutionMetricsResponse = deserializeAws_restJson1JourneyExecutionMetricsResponse(data, context);
                return [2, contents];
        }
    });
}); };
var deserializeAws_restJson1GetJourneyExecutionMetricsCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, errorCode, _b, parsedBody;
    var _c;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                _a = [__assign({}, output)];
                _c = {};
                return [4, parseErrorBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_c.body = _d.sent(), _c)]));
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "BadRequestException": return [3, 2];
                    case "com.amazonaws.pinpoint#BadRequestException": return [3, 2];
                    case "ForbiddenException": return [3, 4];
                    case "com.amazonaws.pinpoint#ForbiddenException": return [3, 4];
                    case "InternalServerErrorException": return [3, 6];
                    case "com.amazonaws.pinpoint#InternalServerErrorException": return [3, 6];
                    case "MethodNotAllowedException": return [3, 8];
                    case "com.amazonaws.pinpoint#MethodNotAllowedException": return [3, 8];
                    case "NotFoundException": return [3, 10];
                    case "com.amazonaws.pinpoint#NotFoundException": return [3, 10];
                    case "PayloadTooLargeException": return [3, 12];
                    case "com.amazonaws.pinpoint#PayloadTooLargeException": return [3, 12];
                    case "TooManyRequestsException": return [3, 14];
                    case "com.amazonaws.pinpoint#TooManyRequestsException": return [3, 14];
                }
                return [3, 16];
            case 2: return [4, deserializeAws_restJson1BadRequestExceptionResponse(parsedOutput, context)];
            case 3: throw _d.sent();
            case 4: return [4, deserializeAws_restJson1ForbiddenExceptionResponse(parsedOutput, context)];
            case 5: throw _d.sent();
            case 6: return [4, deserializeAws_restJson1InternalServerErrorExceptionResponse(parsedOutput, context)];
            case 7: throw _d.sent();
            case 8: return [4, deserializeAws_restJson1MethodNotAllowedExceptionResponse(parsedOutput, context)];
            case 9: throw _d.sent();
            case 10: return [4, deserializeAws_restJson1NotFoundExceptionResponse(parsedOutput, context)];
            case 11: throw _d.sent();
            case 12: return [4, deserializeAws_restJson1PayloadTooLargeExceptionResponse(parsedOutput, context)];
            case 13: throw _d.sent();
            case 14: return [4, deserializeAws_restJson1TooManyRequestsExceptionResponse(parsedOutput, context)];
            case 15: throw _d.sent();
            case 16:
                parsedBody = parsedOutput.body;
                throwDefaultError({
                    output: output,
                    parsedBody: parsedBody,
                    exceptionCtor: __BaseException,
                    errorCode: errorCode,
                });
                _d.label = 17;
            case 17: return [2];
        }
    });
}); };
export var deserializeAws_restJson1GetPushTemplateCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var contents, data, _a;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                if (output.statusCode !== 200 && output.statusCode >= 300) {
                    return [2, deserializeAws_restJson1GetPushTemplateCommandError(output, context)];
                }
                contents = map({
                    $metadata: deserializeMetadata(output),
                });
                _a = __expectObject;
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.apply(void 0, [_b.sent()]);
                contents.PushNotificationTemplateResponse = deserializeAws_restJson1PushNotificationTemplateResponse(data, context);
                return [2, contents];
        }
    });
}); };
var deserializeAws_restJson1GetPushTemplateCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, errorCode, _b, parsedBody;
    var _c;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                _a = [__assign({}, output)];
                _c = {};
                return [4, parseErrorBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_c.body = _d.sent(), _c)]));
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "BadRequestException": return [3, 2];
                    case "com.amazonaws.pinpoint#BadRequestException": return [3, 2];
                    case "ForbiddenException": return [3, 4];
                    case "com.amazonaws.pinpoint#ForbiddenException": return [3, 4];
                    case "InternalServerErrorException": return [3, 6];
                    case "com.amazonaws.pinpoint#InternalServerErrorException": return [3, 6];
                    case "MethodNotAllowedException": return [3, 8];
                    case "com.amazonaws.pinpoint#MethodNotAllowedException": return [3, 8];
                    case "NotFoundException": return [3, 10];
                    case "com.amazonaws.pinpoint#NotFoundException": return [3, 10];
                    case "PayloadTooLargeException": return [3, 12];
                    case "com.amazonaws.pinpoint#PayloadTooLargeException": return [3, 12];
                    case "TooManyRequestsException": return [3, 14];
                    case "com.amazonaws.pinpoint#TooManyRequestsException": return [3, 14];
                }
                return [3, 16];
            case 2: return [4, deserializeAws_restJson1BadRequestExceptionResponse(parsedOutput, context)];
            case 3: throw _d.sent();
            case 4: return [4, deserializeAws_restJson1ForbiddenExceptionResponse(parsedOutput, context)];
            case 5: throw _d.sent();
            case 6: return [4, deserializeAws_restJson1InternalServerErrorExceptionResponse(parsedOutput, context)];
            case 7: throw _d.sent();
            case 8: return [4, deserializeAws_restJson1MethodNotAllowedExceptionResponse(parsedOutput, context)];
            case 9: throw _d.sent();
            case 10: return [4, deserializeAws_restJson1NotFoundExceptionResponse(parsedOutput, context)];
            case 11: throw _d.sent();
            case 12: return [4, deserializeAws_restJson1PayloadTooLargeExceptionResponse(parsedOutput, context)];
            case 13: throw _d.sent();
            case 14: return [4, deserializeAws_restJson1TooManyRequestsExceptionResponse(parsedOutput, context)];
            case 15: throw _d.sent();
            case 16:
                parsedBody = parsedOutput.body;
                throwDefaultError({
                    output: output,
                    parsedBody: parsedBody,
                    exceptionCtor: __BaseException,
                    errorCode: errorCode,
                });
                _d.label = 17;
            case 17: return [2];
        }
    });
}); };
export var deserializeAws_restJson1GetRecommenderConfigurationCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var contents, data, _a;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                if (output.statusCode !== 200 && output.statusCode >= 300) {
                    return [2, deserializeAws_restJson1GetRecommenderConfigurationCommandError(output, context)];
                }
                contents = map({
                    $metadata: deserializeMetadata(output),
                });
                _a = __expectObject;
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.apply(void 0, [_b.sent()]);
                contents.RecommenderConfigurationResponse = deserializeAws_restJson1RecommenderConfigurationResponse(data, context);
                return [2, contents];
        }
    });
}); };
var deserializeAws_restJson1GetRecommenderConfigurationCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, errorCode, _b, parsedBody;
    var _c;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                _a = [__assign({}, output)];
                _c = {};
                return [4, parseErrorBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_c.body = _d.sent(), _c)]));
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "BadRequestException": return [3, 2];
                    case "com.amazonaws.pinpoint#BadRequestException": return [3, 2];
                    case "ForbiddenException": return [3, 4];
                    case "com.amazonaws.pinpoint#ForbiddenException": return [3, 4];
                    case "InternalServerErrorException": return [3, 6];
                    case "com.amazonaws.pinpoint#InternalServerErrorException": return [3, 6];
                    case "MethodNotAllowedException": return [3, 8];
                    case "com.amazonaws.pinpoint#MethodNotAllowedException": return [3, 8];
                    case "NotFoundException": return [3, 10];
                    case "com.amazonaws.pinpoint#NotFoundException": return [3, 10];
                    case "PayloadTooLargeException": return [3, 12];
                    case "com.amazonaws.pinpoint#PayloadTooLargeException": return [3, 12];
                    case "TooManyRequestsException": return [3, 14];
                    case "com.amazonaws.pinpoint#TooManyRequestsException": return [3, 14];
                }
                return [3, 16];
            case 2: return [4, deserializeAws_restJson1BadRequestExceptionResponse(parsedOutput, context)];
            case 3: throw _d.sent();
            case 4: return [4, deserializeAws_restJson1ForbiddenExceptionResponse(parsedOutput, context)];
            case 5: throw _d.sent();
            case 6: return [4, deserializeAws_restJson1InternalServerErrorExceptionResponse(parsedOutput, context)];
            case 7: throw _d.sent();
            case 8: return [4, deserializeAws_restJson1MethodNotAllowedExceptionResponse(parsedOutput, context)];
            case 9: throw _d.sent();
            case 10: return [4, deserializeAws_restJson1NotFoundExceptionResponse(parsedOutput, context)];
            case 11: throw _d.sent();
            case 12: return [4, deserializeAws_restJson1PayloadTooLargeExceptionResponse(parsedOutput, context)];
            case 13: throw _d.sent();
            case 14: return [4, deserializeAws_restJson1TooManyRequestsExceptionResponse(parsedOutput, context)];
            case 15: throw _d.sent();
            case 16:
                parsedBody = parsedOutput.body;
                throwDefaultError({
                    output: output,
                    parsedBody: parsedBody,
                    exceptionCtor: __BaseException,
                    errorCode: errorCode,
                });
                _d.label = 17;
            case 17: return [2];
        }
    });
}); };
export var deserializeAws_restJson1GetRecommenderConfigurationsCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var contents, data, _a;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                if (output.statusCode !== 200 && output.statusCode >= 300) {
                    return [2, deserializeAws_restJson1GetRecommenderConfigurationsCommandError(output, context)];
                }
                contents = map({
                    $metadata: deserializeMetadata(output),
                });
                _a = __expectObject;
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.apply(void 0, [_b.sent()]);
                contents.ListRecommenderConfigurationsResponse = deserializeAws_restJson1ListRecommenderConfigurationsResponse(data, context);
                return [2, contents];
        }
    });
}); };
var deserializeAws_restJson1GetRecommenderConfigurationsCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, errorCode, _b, parsedBody;
    var _c;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                _a = [__assign({}, output)];
                _c = {};
                return [4, parseErrorBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_c.body = _d.sent(), _c)]));
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "BadRequestException": return [3, 2];
                    case "com.amazonaws.pinpoint#BadRequestException": return [3, 2];
                    case "ForbiddenException": return [3, 4];
                    case "com.amazonaws.pinpoint#ForbiddenException": return [3, 4];
                    case "InternalServerErrorException": return [3, 6];
                    case "com.amazonaws.pinpoint#InternalServerErrorException": return [3, 6];
                    case "MethodNotAllowedException": return [3, 8];
                    case "com.amazonaws.pinpoint#MethodNotAllowedException": return [3, 8];
                    case "NotFoundException": return [3, 10];
                    case "com.amazonaws.pinpoint#NotFoundException": return [3, 10];
                    case "PayloadTooLargeException": return [3, 12];
                    case "com.amazonaws.pinpoint#PayloadTooLargeException": return [3, 12];
                    case "TooManyRequestsException": return [3, 14];
                    case "com.amazonaws.pinpoint#TooManyRequestsException": return [3, 14];
                }
                return [3, 16];
            case 2: return [4, deserializeAws_restJson1BadRequestExceptionResponse(parsedOutput, context)];
            case 3: throw _d.sent();
            case 4: return [4, deserializeAws_restJson1ForbiddenExceptionResponse(parsedOutput, context)];
            case 5: throw _d.sent();
            case 6: return [4, deserializeAws_restJson1InternalServerErrorExceptionResponse(parsedOutput, context)];
            case 7: throw _d.sent();
            case 8: return [4, deserializeAws_restJson1MethodNotAllowedExceptionResponse(parsedOutput, context)];
            case 9: throw _d.sent();
            case 10: return [4, deserializeAws_restJson1NotFoundExceptionResponse(parsedOutput, context)];
            case 11: throw _d.sent();
            case 12: return [4, deserializeAws_restJson1PayloadTooLargeExceptionResponse(parsedOutput, context)];
            case 13: throw _d.sent();
            case 14: return [4, deserializeAws_restJson1TooManyRequestsExceptionResponse(parsedOutput, context)];
            case 15: throw _d.sent();
            case 16:
                parsedBody = parsedOutput.body;
                throwDefaultError({
                    output: output,
                    parsedBody: parsedBody,
                    exceptionCtor: __BaseException,
                    errorCode: errorCode,
                });
                _d.label = 17;
            case 17: return [2];
        }
    });
}); };
export var deserializeAws_restJson1GetSegmentCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var contents, data, _a;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                if (output.statusCode !== 200 && output.statusCode >= 300) {
                    return [2, deserializeAws_restJson1GetSegmentCommandError(output, context)];
                }
                contents = map({
                    $metadata: deserializeMetadata(output),
                });
                _a = __expectObject;
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.apply(void 0, [_b.sent()]);
                contents.SegmentResponse = deserializeAws_restJson1SegmentResponse(data, context);
                return [2, contents];
        }
    });
}); };
var deserializeAws_restJson1GetSegmentCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, errorCode, _b, parsedBody;
    var _c;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                _a = [__assign({}, output)];
                _c = {};
                return [4, parseErrorBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_c.body = _d.sent(), _c)]));
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "BadRequestException": return [3, 2];
                    case "com.amazonaws.pinpoint#BadRequestException": return [3, 2];
                    case "ForbiddenException": return [3, 4];
                    case "com.amazonaws.pinpoint#ForbiddenException": return [3, 4];
                    case "InternalServerErrorException": return [3, 6];
                    case "com.amazonaws.pinpoint#InternalServerErrorException": return [3, 6];
                    case "MethodNotAllowedException": return [3, 8];
                    case "com.amazonaws.pinpoint#MethodNotAllowedException": return [3, 8];
                    case "NotFoundException": return [3, 10];
                    case "com.amazonaws.pinpoint#NotFoundException": return [3, 10];
                    case "PayloadTooLargeException": return [3, 12];
                    case "com.amazonaws.pinpoint#PayloadTooLargeException": return [3, 12];
                    case "TooManyRequestsException": return [3, 14];
                    case "com.amazonaws.pinpoint#TooManyRequestsException": return [3, 14];
                }
                return [3, 16];
            case 2: return [4, deserializeAws_restJson1BadRequestExceptionResponse(parsedOutput, context)];
            case 3: throw _d.sent();
            case 4: return [4, deserializeAws_restJson1ForbiddenExceptionResponse(parsedOutput, context)];
            case 5: throw _d.sent();
            case 6: return [4, deserializeAws_restJson1InternalServerErrorExceptionResponse(parsedOutput, context)];
            case 7: throw _d.sent();
            case 8: return [4, deserializeAws_restJson1MethodNotAllowedExceptionResponse(parsedOutput, context)];
            case 9: throw _d.sent();
            case 10: return [4, deserializeAws_restJson1NotFoundExceptionResponse(parsedOutput, context)];
            case 11: throw _d.sent();
            case 12: return [4, deserializeAws_restJson1PayloadTooLargeExceptionResponse(parsedOutput, context)];
            case 13: throw _d.sent();
            case 14: return [4, deserializeAws_restJson1TooManyRequestsExceptionResponse(parsedOutput, context)];
            case 15: throw _d.sent();
            case 16:
                parsedBody = parsedOutput.body;
                throwDefaultError({
                    output: output,
                    parsedBody: parsedBody,
                    exceptionCtor: __BaseException,
                    errorCode: errorCode,
                });
                _d.label = 17;
            case 17: return [2];
        }
    });
}); };
export var deserializeAws_restJson1GetSegmentExportJobsCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var contents, data, _a;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                if (output.statusCode !== 200 && output.statusCode >= 300) {
                    return [2, deserializeAws_restJson1GetSegmentExportJobsCommandError(output, context)];
                }
                contents = map({
                    $metadata: deserializeMetadata(output),
                });
                _a = __expectObject;
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.apply(void 0, [_b.sent()]);
                contents.ExportJobsResponse = deserializeAws_restJson1ExportJobsResponse(data, context);
                return [2, contents];
        }
    });
}); };
var deserializeAws_restJson1GetSegmentExportJobsCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, errorCode, _b, parsedBody;
    var _c;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                _a = [__assign({}, output)];
                _c = {};
                return [4, parseErrorBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_c.body = _d.sent(), _c)]));
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "BadRequestException": return [3, 2];
                    case "com.amazonaws.pinpoint#BadRequestException": return [3, 2];
                    case "ForbiddenException": return [3, 4];
                    case "com.amazonaws.pinpoint#ForbiddenException": return [3, 4];
                    case "InternalServerErrorException": return [3, 6];
                    case "com.amazonaws.pinpoint#InternalServerErrorException": return [3, 6];
                    case "MethodNotAllowedException": return [3, 8];
                    case "com.amazonaws.pinpoint#MethodNotAllowedException": return [3, 8];
                    case "NotFoundException": return [3, 10];
                    case "com.amazonaws.pinpoint#NotFoundException": return [3, 10];
                    case "PayloadTooLargeException": return [3, 12];
                    case "com.amazonaws.pinpoint#PayloadTooLargeException": return [3, 12];
                    case "TooManyRequestsException": return [3, 14];
                    case "com.amazonaws.pinpoint#TooManyRequestsException": return [3, 14];
                }
                return [3, 16];
            case 2: return [4, deserializeAws_restJson1BadRequestExceptionResponse(parsedOutput, context)];
            case 3: throw _d.sent();
            case 4: return [4, deserializeAws_restJson1ForbiddenExceptionResponse(parsedOutput, context)];
            case 5: throw _d.sent();
            case 6: return [4, deserializeAws_restJson1InternalServerErrorExceptionResponse(parsedOutput, context)];
            case 7: throw _d.sent();
            case 8: return [4, deserializeAws_restJson1MethodNotAllowedExceptionResponse(parsedOutput, context)];
            case 9: throw _d.sent();
            case 10: return [4, deserializeAws_restJson1NotFoundExceptionResponse(parsedOutput, context)];
            case 11: throw _d.sent();
            case 12: return [4, deserializeAws_restJson1PayloadTooLargeExceptionResponse(parsedOutput, context)];
            case 13: throw _d.sent();
            case 14: return [4, deserializeAws_restJson1TooManyRequestsExceptionResponse(parsedOutput, context)];
            case 15: throw _d.sent();
            case 16:
                parsedBody = parsedOutput.body;
                throwDefaultError({
                    output: output,
                    parsedBody: parsedBody,
                    exceptionCtor: __BaseException,
                    errorCode: errorCode,
                });
                _d.label = 17;
            case 17: return [2];
        }
    });
}); };
export var deserializeAws_restJson1GetSegmentImportJobsCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var contents, data, _a;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                if (output.statusCode !== 200 && output.statusCode >= 300) {
                    return [2, deserializeAws_restJson1GetSegmentImportJobsCommandError(output, context)];
                }
                contents = map({
                    $metadata: deserializeMetadata(output),
                });
                _a = __expectObject;
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.apply(void 0, [_b.sent()]);
                contents.ImportJobsResponse = deserializeAws_restJson1ImportJobsResponse(data, context);
                return [2, contents];
        }
    });
}); };
var deserializeAws_restJson1GetSegmentImportJobsCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, errorCode, _b, parsedBody;
    var _c;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                _a = [__assign({}, output)];
                _c = {};
                return [4, parseErrorBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_c.body = _d.sent(), _c)]));
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "BadRequestException": return [3, 2];
                    case "com.amazonaws.pinpoint#BadRequestException": return [3, 2];
                    case "ForbiddenException": return [3, 4];
                    case "com.amazonaws.pinpoint#ForbiddenException": return [3, 4];
                    case "InternalServerErrorException": return [3, 6];
                    case "com.amazonaws.pinpoint#InternalServerErrorException": return [3, 6];
                    case "MethodNotAllowedException": return [3, 8];
                    case "com.amazonaws.pinpoint#MethodNotAllowedException": return [3, 8];
                    case "NotFoundException": return [3, 10];
                    case "com.amazonaws.pinpoint#NotFoundException": return [3, 10];
                    case "PayloadTooLargeException": return [3, 12];
                    case "com.amazonaws.pinpoint#PayloadTooLargeException": return [3, 12];
                    case "TooManyRequestsException": return [3, 14];
                    case "com.amazonaws.pinpoint#TooManyRequestsException": return [3, 14];
                }
                return [3, 16];
            case 2: return [4, deserializeAws_restJson1BadRequestExceptionResponse(parsedOutput, context)];
            case 3: throw _d.sent();
            case 4: return [4, deserializeAws_restJson1ForbiddenExceptionResponse(parsedOutput, context)];
            case 5: throw _d.sent();
            case 6: return [4, deserializeAws_restJson1InternalServerErrorExceptionResponse(parsedOutput, context)];
            case 7: throw _d.sent();
            case 8: return [4, deserializeAws_restJson1MethodNotAllowedExceptionResponse(parsedOutput, context)];
            case 9: throw _d.sent();
            case 10: return [4, deserializeAws_restJson1NotFoundExceptionResponse(parsedOutput, context)];
            case 11: throw _d.sent();
            case 12: return [4, deserializeAws_restJson1PayloadTooLargeExceptionResponse(parsedOutput, context)];
            case 13: throw _d.sent();
            case 14: return [4, deserializeAws_restJson1TooManyRequestsExceptionResponse(parsedOutput, context)];
            case 15: throw _d.sent();
            case 16:
                parsedBody = parsedOutput.body;
                throwDefaultError({
                    output: output,
                    parsedBody: parsedBody,
                    exceptionCtor: __BaseException,
                    errorCode: errorCode,
                });
                _d.label = 17;
            case 17: return [2];
        }
    });
}); };
export var deserializeAws_restJson1GetSegmentsCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var contents, data, _a;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                if (output.statusCode !== 200 && output.statusCode >= 300) {
                    return [2, deserializeAws_restJson1GetSegmentsCommandError(output, context)];
                }
                contents = map({
                    $metadata: deserializeMetadata(output),
                });
                _a = __expectObject;
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.apply(void 0, [_b.sent()]);
                contents.SegmentsResponse = deserializeAws_restJson1SegmentsResponse(data, context);
                return [2, contents];
        }
    });
}); };
var deserializeAws_restJson1GetSegmentsCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, errorCode, _b, parsedBody;
    var _c;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                _a = [__assign({}, output)];
                _c = {};
                return [4, parseErrorBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_c.body = _d.sent(), _c)]));
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "BadRequestException": return [3, 2];
                    case "com.amazonaws.pinpoint#BadRequestException": return [3, 2];
                    case "ForbiddenException": return [3, 4];
                    case "com.amazonaws.pinpoint#ForbiddenException": return [3, 4];
                    case "InternalServerErrorException": return [3, 6];
                    case "com.amazonaws.pinpoint#InternalServerErrorException": return [3, 6];
                    case "MethodNotAllowedException": return [3, 8];
                    case "com.amazonaws.pinpoint#MethodNotAllowedException": return [3, 8];
                    case "NotFoundException": return [3, 10];
                    case "com.amazonaws.pinpoint#NotFoundException": return [3, 10];
                    case "PayloadTooLargeException": return [3, 12];
                    case "com.amazonaws.pinpoint#PayloadTooLargeException": return [3, 12];
                    case "TooManyRequestsException": return [3, 14];
                    case "com.amazonaws.pinpoint#TooManyRequestsException": return [3, 14];
                }
                return [3, 16];
            case 2: return [4, deserializeAws_restJson1BadRequestExceptionResponse(parsedOutput, context)];
            case 3: throw _d.sent();
            case 4: return [4, deserializeAws_restJson1ForbiddenExceptionResponse(parsedOutput, context)];
            case 5: throw _d.sent();
            case 6: return [4, deserializeAws_restJson1InternalServerErrorExceptionResponse(parsedOutput, context)];
            case 7: throw _d.sent();
            case 8: return [4, deserializeAws_restJson1MethodNotAllowedExceptionResponse(parsedOutput, context)];
            case 9: throw _d.sent();
            case 10: return [4, deserializeAws_restJson1NotFoundExceptionResponse(parsedOutput, context)];
            case 11: throw _d.sent();
            case 12: return [4, deserializeAws_restJson1PayloadTooLargeExceptionResponse(parsedOutput, context)];
            case 13: throw _d.sent();
            case 14: return [4, deserializeAws_restJson1TooManyRequestsExceptionResponse(parsedOutput, context)];
            case 15: throw _d.sent();
            case 16:
                parsedBody = parsedOutput.body;
                throwDefaultError({
                    output: output,
                    parsedBody: parsedBody,
                    exceptionCtor: __BaseException,
                    errorCode: errorCode,
                });
                _d.label = 17;
            case 17: return [2];
        }
    });
}); };
export var deserializeAws_restJson1GetSegmentVersionCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var contents, data, _a;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                if (output.statusCode !== 200 && output.statusCode >= 300) {
                    return [2, deserializeAws_restJson1GetSegmentVersionCommandError(output, context)];
                }
                contents = map({
                    $metadata: deserializeMetadata(output),
                });
                _a = __expectObject;
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.apply(void 0, [_b.sent()]);
                contents.SegmentResponse = deserializeAws_restJson1SegmentResponse(data, context);
                return [2, contents];
        }
    });
}); };
var deserializeAws_restJson1GetSegmentVersionCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, errorCode, _b, parsedBody;
    var _c;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                _a = [__assign({}, output)];
                _c = {};
                return [4, parseErrorBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_c.body = _d.sent(), _c)]));
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "BadRequestException": return [3, 2];
                    case "com.amazonaws.pinpoint#BadRequestException": return [3, 2];
                    case "ForbiddenException": return [3, 4];
                    case "com.amazonaws.pinpoint#ForbiddenException": return [3, 4];
                    case "InternalServerErrorException": return [3, 6];
                    case "com.amazonaws.pinpoint#InternalServerErrorException": return [3, 6];
                    case "MethodNotAllowedException": return [3, 8];
                    case "com.amazonaws.pinpoint#MethodNotAllowedException": return [3, 8];
                    case "NotFoundException": return [3, 10];
                    case "com.amazonaws.pinpoint#NotFoundException": return [3, 10];
                    case "PayloadTooLargeException": return [3, 12];
                    case "com.amazonaws.pinpoint#PayloadTooLargeException": return [3, 12];
                    case "TooManyRequestsException": return [3, 14];
                    case "com.amazonaws.pinpoint#TooManyRequestsException": return [3, 14];
                }
                return [3, 16];
            case 2: return [4, deserializeAws_restJson1BadRequestExceptionResponse(parsedOutput, context)];
            case 3: throw _d.sent();
            case 4: return [4, deserializeAws_restJson1ForbiddenExceptionResponse(parsedOutput, context)];
            case 5: throw _d.sent();
            case 6: return [4, deserializeAws_restJson1InternalServerErrorExceptionResponse(parsedOutput, context)];
            case 7: throw _d.sent();
            case 8: return [4, deserializeAws_restJson1MethodNotAllowedExceptionResponse(parsedOutput, context)];
            case 9: throw _d.sent();
            case 10: return [4, deserializeAws_restJson1NotFoundExceptionResponse(parsedOutput, context)];
            case 11: throw _d.sent();
            case 12: return [4, deserializeAws_restJson1PayloadTooLargeExceptionResponse(parsedOutput, context)];
            case 13: throw _d.sent();
            case 14: return [4, deserializeAws_restJson1TooManyRequestsExceptionResponse(parsedOutput, context)];
            case 15: throw _d.sent();
            case 16:
                parsedBody = parsedOutput.body;
                throwDefaultError({
                    output: output,
                    parsedBody: parsedBody,
                    exceptionCtor: __BaseException,
                    errorCode: errorCode,
                });
                _d.label = 17;
            case 17: return [2];
        }
    });
}); };
export var deserializeAws_restJson1GetSegmentVersionsCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var contents, data, _a;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                if (output.statusCode !== 200 && output.statusCode >= 300) {
                    return [2, deserializeAws_restJson1GetSegmentVersionsCommandError(output, context)];
                }
                contents = map({
                    $metadata: deserializeMetadata(output),
                });
                _a = __expectObject;
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.apply(void 0, [_b.sent()]);
                contents.SegmentsResponse = deserializeAws_restJson1SegmentsResponse(data, context);
                return [2, contents];
        }
    });
}); };
var deserializeAws_restJson1GetSegmentVersionsCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, errorCode, _b, parsedBody;
    var _c;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                _a = [__assign({}, output)];
                _c = {};
                return [4, parseErrorBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_c.body = _d.sent(), _c)]));
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "BadRequestException": return [3, 2];
                    case "com.amazonaws.pinpoint#BadRequestException": return [3, 2];
                    case "ForbiddenException": return [3, 4];
                    case "com.amazonaws.pinpoint#ForbiddenException": return [3, 4];
                    case "InternalServerErrorException": return [3, 6];
                    case "com.amazonaws.pinpoint#InternalServerErrorException": return [3, 6];
                    case "MethodNotAllowedException": return [3, 8];
                    case "com.amazonaws.pinpoint#MethodNotAllowedException": return [3, 8];
                    case "NotFoundException": return [3, 10];
                    case "com.amazonaws.pinpoint#NotFoundException": return [3, 10];
                    case "PayloadTooLargeException": return [3, 12];
                    case "com.amazonaws.pinpoint#PayloadTooLargeException": return [3, 12];
                    case "TooManyRequestsException": return [3, 14];
                    case "com.amazonaws.pinpoint#TooManyRequestsException": return [3, 14];
                }
                return [3, 16];
            case 2: return [4, deserializeAws_restJson1BadRequestExceptionResponse(parsedOutput, context)];
            case 3: throw _d.sent();
            case 4: return [4, deserializeAws_restJson1ForbiddenExceptionResponse(parsedOutput, context)];
            case 5: throw _d.sent();
            case 6: return [4, deserializeAws_restJson1InternalServerErrorExceptionResponse(parsedOutput, context)];
            case 7: throw _d.sent();
            case 8: return [4, deserializeAws_restJson1MethodNotAllowedExceptionResponse(parsedOutput, context)];
            case 9: throw _d.sent();
            case 10: return [4, deserializeAws_restJson1NotFoundExceptionResponse(parsedOutput, context)];
            case 11: throw _d.sent();
            case 12: return [4, deserializeAws_restJson1PayloadTooLargeExceptionResponse(parsedOutput, context)];
            case 13: throw _d.sent();
            case 14: return [4, deserializeAws_restJson1TooManyRequestsExceptionResponse(parsedOutput, context)];
            case 15: throw _d.sent();
            case 16:
                parsedBody = parsedOutput.body;
                throwDefaultError({
                    output: output,
                    parsedBody: parsedBody,
                    exceptionCtor: __BaseException,
                    errorCode: errorCode,
                });
                _d.label = 17;
            case 17: return [2];
        }
    });
}); };
export var deserializeAws_restJson1GetSmsChannelCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var contents, data, _a;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                if (output.statusCode !== 200 && output.statusCode >= 300) {
                    return [2, deserializeAws_restJson1GetSmsChannelCommandError(output, context)];
                }
                contents = map({
                    $metadata: deserializeMetadata(output),
                });
                _a = __expectObject;
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.apply(void 0, [_b.sent()]);
                contents.SMSChannelResponse = deserializeAws_restJson1SMSChannelResponse(data, context);
                return [2, contents];
        }
    });
}); };
var deserializeAws_restJson1GetSmsChannelCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, errorCode, _b, parsedBody;
    var _c;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                _a = [__assign({}, output)];
                _c = {};
                return [4, parseErrorBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_c.body = _d.sent(), _c)]));
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "BadRequestException": return [3, 2];
                    case "com.amazonaws.pinpoint#BadRequestException": return [3, 2];
                    case "ForbiddenException": return [3, 4];
                    case "com.amazonaws.pinpoint#ForbiddenException": return [3, 4];
                    case "InternalServerErrorException": return [3, 6];
                    case "com.amazonaws.pinpoint#InternalServerErrorException": return [3, 6];
                    case "MethodNotAllowedException": return [3, 8];
                    case "com.amazonaws.pinpoint#MethodNotAllowedException": return [3, 8];
                    case "NotFoundException": return [3, 10];
                    case "com.amazonaws.pinpoint#NotFoundException": return [3, 10];
                    case "PayloadTooLargeException": return [3, 12];
                    case "com.amazonaws.pinpoint#PayloadTooLargeException": return [3, 12];
                    case "TooManyRequestsException": return [3, 14];
                    case "com.amazonaws.pinpoint#TooManyRequestsException": return [3, 14];
                }
                return [3, 16];
            case 2: return [4, deserializeAws_restJson1BadRequestExceptionResponse(parsedOutput, context)];
            case 3: throw _d.sent();
            case 4: return [4, deserializeAws_restJson1ForbiddenExceptionResponse(parsedOutput, context)];
            case 5: throw _d.sent();
            case 6: return [4, deserializeAws_restJson1InternalServerErrorExceptionResponse(parsedOutput, context)];
            case 7: throw _d.sent();
            case 8: return [4, deserializeAws_restJson1MethodNotAllowedExceptionResponse(parsedOutput, context)];
            case 9: throw _d.sent();
            case 10: return [4, deserializeAws_restJson1NotFoundExceptionResponse(parsedOutput, context)];
            case 11: throw _d.sent();
            case 12: return [4, deserializeAws_restJson1PayloadTooLargeExceptionResponse(parsedOutput, context)];
            case 13: throw _d.sent();
            case 14: return [4, deserializeAws_restJson1TooManyRequestsExceptionResponse(parsedOutput, context)];
            case 15: throw _d.sent();
            case 16:
                parsedBody = parsedOutput.body;
                throwDefaultError({
                    output: output,
                    parsedBody: parsedBody,
                    exceptionCtor: __BaseException,
                    errorCode: errorCode,
                });
                _d.label = 17;
            case 17: return [2];
        }
    });
}); };
export var deserializeAws_restJson1GetSmsTemplateCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var contents, data, _a;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                if (output.statusCode !== 200 && output.statusCode >= 300) {
                    return [2, deserializeAws_restJson1GetSmsTemplateCommandError(output, context)];
                }
                contents = map({
                    $metadata: deserializeMetadata(output),
                });
                _a = __expectObject;
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.apply(void 0, [_b.sent()]);
                contents.SMSTemplateResponse = deserializeAws_restJson1SMSTemplateResponse(data, context);
                return [2, contents];
        }
    });
}); };
var deserializeAws_restJson1GetSmsTemplateCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, errorCode, _b, parsedBody;
    var _c;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                _a = [__assign({}, output)];
                _c = {};
                return [4, parseErrorBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_c.body = _d.sent(), _c)]));
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "BadRequestException": return [3, 2];
                    case "com.amazonaws.pinpoint#BadRequestException": return [3, 2];
                    case "ForbiddenException": return [3, 4];
                    case "com.amazonaws.pinpoint#ForbiddenException": return [3, 4];
                    case "InternalServerErrorException": return [3, 6];
                    case "com.amazonaws.pinpoint#InternalServerErrorException": return [3, 6];
                    case "MethodNotAllowedException": return [3, 8];
                    case "com.amazonaws.pinpoint#MethodNotAllowedException": return [3, 8];
                    case "NotFoundException": return [3, 10];
                    case "com.amazonaws.pinpoint#NotFoundException": return [3, 10];
                    case "PayloadTooLargeException": return [3, 12];
                    case "com.amazonaws.pinpoint#PayloadTooLargeException": return [3, 12];
                    case "TooManyRequestsException": return [3, 14];
                    case "com.amazonaws.pinpoint#TooManyRequestsException": return [3, 14];
                }
                return [3, 16];
            case 2: return [4, deserializeAws_restJson1BadRequestExceptionResponse(parsedOutput, context)];
            case 3: throw _d.sent();
            case 4: return [4, deserializeAws_restJson1ForbiddenExceptionResponse(parsedOutput, context)];
            case 5: throw _d.sent();
            case 6: return [4, deserializeAws_restJson1InternalServerErrorExceptionResponse(parsedOutput, context)];
            case 7: throw _d.sent();
            case 8: return [4, deserializeAws_restJson1MethodNotAllowedExceptionResponse(parsedOutput, context)];
            case 9: throw _d.sent();
            case 10: return [4, deserializeAws_restJson1NotFoundExceptionResponse(parsedOutput, context)];
            case 11: throw _d.sent();
            case 12: return [4, deserializeAws_restJson1PayloadTooLargeExceptionResponse(parsedOutput, context)];
            case 13: throw _d.sent();
            case 14: return [4, deserializeAws_restJson1TooManyRequestsExceptionResponse(parsedOutput, context)];
            case 15: throw _d.sent();
            case 16:
                parsedBody = parsedOutput.body;
                throwDefaultError({
                    output: output,
                    parsedBody: parsedBody,
                    exceptionCtor: __BaseException,
                    errorCode: errorCode,
                });
                _d.label = 17;
            case 17: return [2];
        }
    });
}); };
export var deserializeAws_restJson1GetUserEndpointsCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var contents, data, _a;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                if (output.statusCode !== 200 && output.statusCode >= 300) {
                    return [2, deserializeAws_restJson1GetUserEndpointsCommandError(output, context)];
                }
                contents = map({
                    $metadata: deserializeMetadata(output),
                });
                _a = __expectObject;
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.apply(void 0, [_b.sent()]);
                contents.EndpointsResponse = deserializeAws_restJson1EndpointsResponse(data, context);
                return [2, contents];
        }
    });
}); };
var deserializeAws_restJson1GetUserEndpointsCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, errorCode, _b, parsedBody;
    var _c;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                _a = [__assign({}, output)];
                _c = {};
                return [4, parseErrorBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_c.body = _d.sent(), _c)]));
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "BadRequestException": return [3, 2];
                    case "com.amazonaws.pinpoint#BadRequestException": return [3, 2];
                    case "ForbiddenException": return [3, 4];
                    case "com.amazonaws.pinpoint#ForbiddenException": return [3, 4];
                    case "InternalServerErrorException": return [3, 6];
                    case "com.amazonaws.pinpoint#InternalServerErrorException": return [3, 6];
                    case "MethodNotAllowedException": return [3, 8];
                    case "com.amazonaws.pinpoint#MethodNotAllowedException": return [3, 8];
                    case "NotFoundException": return [3, 10];
                    case "com.amazonaws.pinpoint#NotFoundException": return [3, 10];
                    case "PayloadTooLargeException": return [3, 12];
                    case "com.amazonaws.pinpoint#PayloadTooLargeException": return [3, 12];
                    case "TooManyRequestsException": return [3, 14];
                    case "com.amazonaws.pinpoint#TooManyRequestsException": return [3, 14];
                }
                return [3, 16];
            case 2: return [4, deserializeAws_restJson1BadRequestExceptionResponse(parsedOutput, context)];
            case 3: throw _d.sent();
            case 4: return [4, deserializeAws_restJson1ForbiddenExceptionResponse(parsedOutput, context)];
            case 5: throw _d.sent();
            case 6: return [4, deserializeAws_restJson1InternalServerErrorExceptionResponse(parsedOutput, context)];
            case 7: throw _d.sent();
            case 8: return [4, deserializeAws_restJson1MethodNotAllowedExceptionResponse(parsedOutput, context)];
            case 9: throw _d.sent();
            case 10: return [4, deserializeAws_restJson1NotFoundExceptionResponse(parsedOutput, context)];
            case 11: throw _d.sent();
            case 12: return [4, deserializeAws_restJson1PayloadTooLargeExceptionResponse(parsedOutput, context)];
            case 13: throw _d.sent();
            case 14: return [4, deserializeAws_restJson1TooManyRequestsExceptionResponse(parsedOutput, context)];
            case 15: throw _d.sent();
            case 16:
                parsedBody = parsedOutput.body;
                throwDefaultError({
                    output: output,
                    parsedBody: parsedBody,
                    exceptionCtor: __BaseException,
                    errorCode: errorCode,
                });
                _d.label = 17;
            case 17: return [2];
        }
    });
}); };
export var deserializeAws_restJson1GetVoiceChannelCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var contents, data, _a;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                if (output.statusCode !== 200 && output.statusCode >= 300) {
                    return [2, deserializeAws_restJson1GetVoiceChannelCommandError(output, context)];
                }
                contents = map({
                    $metadata: deserializeMetadata(output),
                });
                _a = __expectObject;
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.apply(void 0, [_b.sent()]);
                contents.VoiceChannelResponse = deserializeAws_restJson1VoiceChannelResponse(data, context);
                return [2, contents];
        }
    });
}); };
var deserializeAws_restJson1GetVoiceChannelCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, errorCode, _b, parsedBody;
    var _c;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                _a = [__assign({}, output)];
                _c = {};
                return [4, parseErrorBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_c.body = _d.sent(), _c)]));
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "BadRequestException": return [3, 2];
                    case "com.amazonaws.pinpoint#BadRequestException": return [3, 2];
                    case "ForbiddenException": return [3, 4];
                    case "com.amazonaws.pinpoint#ForbiddenException": return [3, 4];
                    case "InternalServerErrorException": return [3, 6];
                    case "com.amazonaws.pinpoint#InternalServerErrorException": return [3, 6];
                    case "MethodNotAllowedException": return [3, 8];
                    case "com.amazonaws.pinpoint#MethodNotAllowedException": return [3, 8];
                    case "NotFoundException": return [3, 10];
                    case "com.amazonaws.pinpoint#NotFoundException": return [3, 10];
                    case "PayloadTooLargeException": return [3, 12];
                    case "com.amazonaws.pinpoint#PayloadTooLargeException": return [3, 12];
                    case "TooManyRequestsException": return [3, 14];
                    case "com.amazonaws.pinpoint#TooManyRequestsException": return [3, 14];
                }
                return [3, 16];
            case 2: return [4, deserializeAws_restJson1BadRequestExceptionResponse(parsedOutput, context)];
            case 3: throw _d.sent();
            case 4: return [4, deserializeAws_restJson1ForbiddenExceptionResponse(parsedOutput, context)];
            case 5: throw _d.sent();
            case 6: return [4, deserializeAws_restJson1InternalServerErrorExceptionResponse(parsedOutput, context)];
            case 7: throw _d.sent();
            case 8: return [4, deserializeAws_restJson1MethodNotAllowedExceptionResponse(parsedOutput, context)];
            case 9: throw _d.sent();
            case 10: return [4, deserializeAws_restJson1NotFoundExceptionResponse(parsedOutput, context)];
            case 11: throw _d.sent();
            case 12: return [4, deserializeAws_restJson1PayloadTooLargeExceptionResponse(parsedOutput, context)];
            case 13: throw _d.sent();
            case 14: return [4, deserializeAws_restJson1TooManyRequestsExceptionResponse(parsedOutput, context)];
            case 15: throw _d.sent();
            case 16:
                parsedBody = parsedOutput.body;
                throwDefaultError({
                    output: output,
                    parsedBody: parsedBody,
                    exceptionCtor: __BaseException,
                    errorCode: errorCode,
                });
                _d.label = 17;
            case 17: return [2];
        }
    });
}); };
export var deserializeAws_restJson1GetVoiceTemplateCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var contents, data, _a;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                if (output.statusCode !== 200 && output.statusCode >= 300) {
                    return [2, deserializeAws_restJson1GetVoiceTemplateCommandError(output, context)];
                }
                contents = map({
                    $metadata: deserializeMetadata(output),
                });
                _a = __expectObject;
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.apply(void 0, [_b.sent()]);
                contents.VoiceTemplateResponse = deserializeAws_restJson1VoiceTemplateResponse(data, context);
                return [2, contents];
        }
    });
}); };
var deserializeAws_restJson1GetVoiceTemplateCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, errorCode, _b, parsedBody;
    var _c;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                _a = [__assign({}, output)];
                _c = {};
                return [4, parseErrorBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_c.body = _d.sent(), _c)]));
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "BadRequestException": return [3, 2];
                    case "com.amazonaws.pinpoint#BadRequestException": return [3, 2];
                    case "ForbiddenException": return [3, 4];
                    case "com.amazonaws.pinpoint#ForbiddenException": return [3, 4];
                    case "InternalServerErrorException": return [3, 6];
                    case "com.amazonaws.pinpoint#InternalServerErrorException": return [3, 6];
                    case "MethodNotAllowedException": return [3, 8];
                    case "com.amazonaws.pinpoint#MethodNotAllowedException": return [3, 8];
                    case "NotFoundException": return [3, 10];
                    case "com.amazonaws.pinpoint#NotFoundException": return [3, 10];
                    case "PayloadTooLargeException": return [3, 12];
                    case "com.amazonaws.pinpoint#PayloadTooLargeException": return [3, 12];
                    case "TooManyRequestsException": return [3, 14];
                    case "com.amazonaws.pinpoint#TooManyRequestsException": return [3, 14];
                }
                return [3, 16];
            case 2: return [4, deserializeAws_restJson1BadRequestExceptionResponse(parsedOutput, context)];
            case 3: throw _d.sent();
            case 4: return [4, deserializeAws_restJson1ForbiddenExceptionResponse(parsedOutput, context)];
            case 5: throw _d.sent();
            case 6: return [4, deserializeAws_restJson1InternalServerErrorExceptionResponse(parsedOutput, context)];
            case 7: throw _d.sent();
            case 8: return [4, deserializeAws_restJson1MethodNotAllowedExceptionResponse(parsedOutput, context)];
            case 9: throw _d.sent();
            case 10: return [4, deserializeAws_restJson1NotFoundExceptionResponse(parsedOutput, context)];
            case 11: throw _d.sent();
            case 12: return [4, deserializeAws_restJson1PayloadTooLargeExceptionResponse(parsedOutput, context)];
            case 13: throw _d.sent();
            case 14: return [4, deserializeAws_restJson1TooManyRequestsExceptionResponse(parsedOutput, context)];
            case 15: throw _d.sent();
            case 16:
                parsedBody = parsedOutput.body;
                throwDefaultError({
                    output: output,
                    parsedBody: parsedBody,
                    exceptionCtor: __BaseException,
                    errorCode: errorCode,
                });
                _d.label = 17;
            case 17: return [2];
        }
    });
}); };
export var deserializeAws_restJson1ListJourneysCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var contents, data, _a;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                if (output.statusCode !== 200 && output.statusCode >= 300) {
                    return [2, deserializeAws_restJson1ListJourneysCommandError(output, context)];
                }
                contents = map({
                    $metadata: deserializeMetadata(output),
                });
                _a = __expectObject;
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.apply(void 0, [_b.sent()]);
                contents.JourneysResponse = deserializeAws_restJson1JourneysResponse(data, context);
                return [2, contents];
        }
    });
}); };
var deserializeAws_restJson1ListJourneysCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, errorCode, _b, parsedBody;
    var _c;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                _a = [__assign({}, output)];
                _c = {};
                return [4, parseErrorBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_c.body = _d.sent(), _c)]));
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "BadRequestException": return [3, 2];
                    case "com.amazonaws.pinpoint#BadRequestException": return [3, 2];
                    case "ForbiddenException": return [3, 4];
                    case "com.amazonaws.pinpoint#ForbiddenException": return [3, 4];
                    case "InternalServerErrorException": return [3, 6];
                    case "com.amazonaws.pinpoint#InternalServerErrorException": return [3, 6];
                    case "MethodNotAllowedException": return [3, 8];
                    case "com.amazonaws.pinpoint#MethodNotAllowedException": return [3, 8];
                    case "NotFoundException": return [3, 10];
                    case "com.amazonaws.pinpoint#NotFoundException": return [3, 10];
                    case "PayloadTooLargeException": return [3, 12];
                    case "com.amazonaws.pinpoint#PayloadTooLargeException": return [3, 12];
                    case "TooManyRequestsException": return [3, 14];
                    case "com.amazonaws.pinpoint#TooManyRequestsException": return [3, 14];
                }
                return [3, 16];
            case 2: return [4, deserializeAws_restJson1BadRequestExceptionResponse(parsedOutput, context)];
            case 3: throw _d.sent();
            case 4: return [4, deserializeAws_restJson1ForbiddenExceptionResponse(parsedOutput, context)];
            case 5: throw _d.sent();
            case 6: return [4, deserializeAws_restJson1InternalServerErrorExceptionResponse(parsedOutput, context)];
            case 7: throw _d.sent();
            case 8: return [4, deserializeAws_restJson1MethodNotAllowedExceptionResponse(parsedOutput, context)];
            case 9: throw _d.sent();
            case 10: return [4, deserializeAws_restJson1NotFoundExceptionResponse(parsedOutput, context)];
            case 11: throw _d.sent();
            case 12: return [4, deserializeAws_restJson1PayloadTooLargeExceptionResponse(parsedOutput, context)];
            case 13: throw _d.sent();
            case 14: return [4, deserializeAws_restJson1TooManyRequestsExceptionResponse(parsedOutput, context)];
            case 15: throw _d.sent();
            case 16:
                parsedBody = parsedOutput.body;
                throwDefaultError({
                    output: output,
                    parsedBody: parsedBody,
                    exceptionCtor: __BaseException,
                    errorCode: errorCode,
                });
                _d.label = 17;
            case 17: return [2];
        }
    });
}); };
export var deserializeAws_restJson1ListTagsForResourceCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var contents, data, _a;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                if (output.statusCode !== 200 && output.statusCode >= 300) {
                    return [2, deserializeAws_restJson1ListTagsForResourceCommandError(output, context)];
                }
                contents = map({
                    $metadata: deserializeMetadata(output),
                });
                _a = __expectObject;
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.apply(void 0, [_b.sent()]);
                contents.TagsModel = deserializeAws_restJson1TagsModel(data, context);
                return [2, contents];
        }
    });
}); };
var deserializeAws_restJson1ListTagsForResourceCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, errorCode, parsedBody;
    var _b;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                _a = [__assign({}, output)];
                _b = {};
                return [4, parseErrorBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_b.body = _c.sent(), _b)]));
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                parsedBody = parsedOutput.body;
                throwDefaultError({
                    output: output,
                    parsedBody: parsedBody,
                    exceptionCtor: __BaseException,
                    errorCode: errorCode,
                });
                return [2];
        }
    });
}); };
export var deserializeAws_restJson1ListTemplatesCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var contents, data, _a;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                if (output.statusCode !== 200 && output.statusCode >= 300) {
                    return [2, deserializeAws_restJson1ListTemplatesCommandError(output, context)];
                }
                contents = map({
                    $metadata: deserializeMetadata(output),
                });
                _a = __expectObject;
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.apply(void 0, [_b.sent()]);
                contents.TemplatesResponse = deserializeAws_restJson1TemplatesResponse(data, context);
                return [2, contents];
        }
    });
}); };
var deserializeAws_restJson1ListTemplatesCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, errorCode, _b, parsedBody;
    var _c;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                _a = [__assign({}, output)];
                _c = {};
                return [4, parseErrorBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_c.body = _d.sent(), _c)]));
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "BadRequestException": return [3, 2];
                    case "com.amazonaws.pinpoint#BadRequestException": return [3, 2];
                    case "ForbiddenException": return [3, 4];
                    case "com.amazonaws.pinpoint#ForbiddenException": return [3, 4];
                    case "InternalServerErrorException": return [3, 6];
                    case "com.amazonaws.pinpoint#InternalServerErrorException": return [3, 6];
                    case "MethodNotAllowedException": return [3, 8];
                    case "com.amazonaws.pinpoint#MethodNotAllowedException": return [3, 8];
                    case "TooManyRequestsException": return [3, 10];
                    case "com.amazonaws.pinpoint#TooManyRequestsException": return [3, 10];
                }
                return [3, 12];
            case 2: return [4, deserializeAws_restJson1BadRequestExceptionResponse(parsedOutput, context)];
            case 3: throw _d.sent();
            case 4: return [4, deserializeAws_restJson1ForbiddenExceptionResponse(parsedOutput, context)];
            case 5: throw _d.sent();
            case 6: return [4, deserializeAws_restJson1InternalServerErrorExceptionResponse(parsedOutput, context)];
            case 7: throw _d.sent();
            case 8: return [4, deserializeAws_restJson1MethodNotAllowedExceptionResponse(parsedOutput, context)];
            case 9: throw _d.sent();
            case 10: return [4, deserializeAws_restJson1TooManyRequestsExceptionResponse(parsedOutput, context)];
            case 11: throw _d.sent();
            case 12:
                parsedBody = parsedOutput.body;
                throwDefaultError({
                    output: output,
                    parsedBody: parsedBody,
                    exceptionCtor: __BaseException,
                    errorCode: errorCode,
                });
                _d.label = 13;
            case 13: return [2];
        }
    });
}); };
export var deserializeAws_restJson1ListTemplateVersionsCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var contents, data, _a;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                if (output.statusCode !== 200 && output.statusCode >= 300) {
                    return [2, deserializeAws_restJson1ListTemplateVersionsCommandError(output, context)];
                }
                contents = map({
                    $metadata: deserializeMetadata(output),
                });
                _a = __expectObject;
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.apply(void 0, [_b.sent()]);
                contents.TemplateVersionsResponse = deserializeAws_restJson1TemplateVersionsResponse(data, context);
                return [2, contents];
        }
    });
}); };
var deserializeAws_restJson1ListTemplateVersionsCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, errorCode, _b, parsedBody;
    var _c;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                _a = [__assign({}, output)];
                _c = {};
                return [4, parseErrorBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_c.body = _d.sent(), _c)]));
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "BadRequestException": return [3, 2];
                    case "com.amazonaws.pinpoint#BadRequestException": return [3, 2];
                    case "ForbiddenException": return [3, 4];
                    case "com.amazonaws.pinpoint#ForbiddenException": return [3, 4];
                    case "InternalServerErrorException": return [3, 6];
                    case "com.amazonaws.pinpoint#InternalServerErrorException": return [3, 6];
                    case "MethodNotAllowedException": return [3, 8];
                    case "com.amazonaws.pinpoint#MethodNotAllowedException": return [3, 8];
                    case "NotFoundException": return [3, 10];
                    case "com.amazonaws.pinpoint#NotFoundException": return [3, 10];
                    case "PayloadTooLargeException": return [3, 12];
                    case "com.amazonaws.pinpoint#PayloadTooLargeException": return [3, 12];
                    case "TooManyRequestsException": return [3, 14];
                    case "com.amazonaws.pinpoint#TooManyRequestsException": return [3, 14];
                }
                return [3, 16];
            case 2: return [4, deserializeAws_restJson1BadRequestExceptionResponse(parsedOutput, context)];
            case 3: throw _d.sent();
            case 4: return [4, deserializeAws_restJson1ForbiddenExceptionResponse(parsedOutput, context)];
            case 5: throw _d.sent();
            case 6: return [4, deserializeAws_restJson1InternalServerErrorExceptionResponse(parsedOutput, context)];
            case 7: throw _d.sent();
            case 8: return [4, deserializeAws_restJson1MethodNotAllowedExceptionResponse(parsedOutput, context)];
            case 9: throw _d.sent();
            case 10: return [4, deserializeAws_restJson1NotFoundExceptionResponse(parsedOutput, context)];
            case 11: throw _d.sent();
            case 12: return [4, deserializeAws_restJson1PayloadTooLargeExceptionResponse(parsedOutput, context)];
            case 13: throw _d.sent();
            case 14: return [4, deserializeAws_restJson1TooManyRequestsExceptionResponse(parsedOutput, context)];
            case 15: throw _d.sent();
            case 16:
                parsedBody = parsedOutput.body;
                throwDefaultError({
                    output: output,
                    parsedBody: parsedBody,
                    exceptionCtor: __BaseException,
                    errorCode: errorCode,
                });
                _d.label = 17;
            case 17: return [2];
        }
    });
}); };
export var deserializeAws_restJson1PhoneNumberValidateCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var contents, data, _a;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                if (output.statusCode !== 200 && output.statusCode >= 300) {
                    return [2, deserializeAws_restJson1PhoneNumberValidateCommandError(output, context)];
                }
                contents = map({
                    $metadata: deserializeMetadata(output),
                });
                _a = __expectObject;
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.apply(void 0, [_b.sent()]);
                contents.NumberValidateResponse = deserializeAws_restJson1NumberValidateResponse(data, context);
                return [2, contents];
        }
    });
}); };
var deserializeAws_restJson1PhoneNumberValidateCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, errorCode, _b, parsedBody;
    var _c;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                _a = [__assign({}, output)];
                _c = {};
                return [4, parseErrorBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_c.body = _d.sent(), _c)]));
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "BadRequestException": return [3, 2];
                    case "com.amazonaws.pinpoint#BadRequestException": return [3, 2];
                    case "ForbiddenException": return [3, 4];
                    case "com.amazonaws.pinpoint#ForbiddenException": return [3, 4];
                    case "InternalServerErrorException": return [3, 6];
                    case "com.amazonaws.pinpoint#InternalServerErrorException": return [3, 6];
                    case "MethodNotAllowedException": return [3, 8];
                    case "com.amazonaws.pinpoint#MethodNotAllowedException": return [3, 8];
                    case "NotFoundException": return [3, 10];
                    case "com.amazonaws.pinpoint#NotFoundException": return [3, 10];
                    case "PayloadTooLargeException": return [3, 12];
                    case "com.amazonaws.pinpoint#PayloadTooLargeException": return [3, 12];
                    case "TooManyRequestsException": return [3, 14];
                    case "com.amazonaws.pinpoint#TooManyRequestsException": return [3, 14];
                }
                return [3, 16];
            case 2: return [4, deserializeAws_restJson1BadRequestExceptionResponse(parsedOutput, context)];
            case 3: throw _d.sent();
            case 4: return [4, deserializeAws_restJson1ForbiddenExceptionResponse(parsedOutput, context)];
            case 5: throw _d.sent();
            case 6: return [4, deserializeAws_restJson1InternalServerErrorExceptionResponse(parsedOutput, context)];
            case 7: throw _d.sent();
            case 8: return [4, deserializeAws_restJson1MethodNotAllowedExceptionResponse(parsedOutput, context)];
            case 9: throw _d.sent();
            case 10: return [4, deserializeAws_restJson1NotFoundExceptionResponse(parsedOutput, context)];
            case 11: throw _d.sent();
            case 12: return [4, deserializeAws_restJson1PayloadTooLargeExceptionResponse(parsedOutput, context)];
            case 13: throw _d.sent();
            case 14: return [4, deserializeAws_restJson1TooManyRequestsExceptionResponse(parsedOutput, context)];
            case 15: throw _d.sent();
            case 16:
                parsedBody = parsedOutput.body;
                throwDefaultError({
                    output: output,
                    parsedBody: parsedBody,
                    exceptionCtor: __BaseException,
                    errorCode: errorCode,
                });
                _d.label = 17;
            case 17: return [2];
        }
    });
}); };
export var deserializeAws_restJson1PutEventsCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var contents, data, _a;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                if (output.statusCode !== 202 && output.statusCode >= 300) {
                    return [2, deserializeAws_restJson1PutEventsCommandError(output, context)];
                }
                contents = map({
                    $metadata: deserializeMetadata(output),
                });
                _a = __expectObject;
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.apply(void 0, [_b.sent()]);
                contents.EventsResponse = deserializeAws_restJson1EventsResponse(data, context);
                return [2, contents];
        }
    });
}); };
var deserializeAws_restJson1PutEventsCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, errorCode, _b, parsedBody;
    var _c;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                _a = [__assign({}, output)];
                _c = {};
                return [4, parseErrorBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_c.body = _d.sent(), _c)]));
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "BadRequestException": return [3, 2];
                    case "com.amazonaws.pinpoint#BadRequestException": return [3, 2];
                    case "ForbiddenException": return [3, 4];
                    case "com.amazonaws.pinpoint#ForbiddenException": return [3, 4];
                    case "InternalServerErrorException": return [3, 6];
                    case "com.amazonaws.pinpoint#InternalServerErrorException": return [3, 6];
                    case "MethodNotAllowedException": return [3, 8];
                    case "com.amazonaws.pinpoint#MethodNotAllowedException": return [3, 8];
                    case "NotFoundException": return [3, 10];
                    case "com.amazonaws.pinpoint#NotFoundException": return [3, 10];
                    case "PayloadTooLargeException": return [3, 12];
                    case "com.amazonaws.pinpoint#PayloadTooLargeException": return [3, 12];
                    case "TooManyRequestsException": return [3, 14];
                    case "com.amazonaws.pinpoint#TooManyRequestsException": return [3, 14];
                }
                return [3, 16];
            case 2: return [4, deserializeAws_restJson1BadRequestExceptionResponse(parsedOutput, context)];
            case 3: throw _d.sent();
            case 4: return [4, deserializeAws_restJson1ForbiddenExceptionResponse(parsedOutput, context)];
            case 5: throw _d.sent();
            case 6: return [4, deserializeAws_restJson1InternalServerErrorExceptionResponse(parsedOutput, context)];
            case 7: throw _d.sent();
            case 8: return [4, deserializeAws_restJson1MethodNotAllowedExceptionResponse(parsedOutput, context)];
            case 9: throw _d.sent();
            case 10: return [4, deserializeAws_restJson1NotFoundExceptionResponse(parsedOutput, context)];
            case 11: throw _d.sent();
            case 12: return [4, deserializeAws_restJson1PayloadTooLargeExceptionResponse(parsedOutput, context)];
            case 13: throw _d.sent();
            case 14: return [4, deserializeAws_restJson1TooManyRequestsExceptionResponse(parsedOutput, context)];
            case 15: throw _d.sent();
            case 16:
                parsedBody = parsedOutput.body;
                throwDefaultError({
                    output: output,
                    parsedBody: parsedBody,
                    exceptionCtor: __BaseException,
                    errorCode: errorCode,
                });
                _d.label = 17;
            case 17: return [2];
        }
    });
}); };
export var deserializeAws_restJson1PutEventStreamCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var contents, data, _a;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                if (output.statusCode !== 200 && output.statusCode >= 300) {
                    return [2, deserializeAws_restJson1PutEventStreamCommandError(output, context)];
                }
                contents = map({
                    $metadata: deserializeMetadata(output),
                });
                _a = __expectObject;
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.apply(void 0, [_b.sent()]);
                contents.EventStream = deserializeAws_restJson1EventStream(data, context);
                return [2, contents];
        }
    });
}); };
var deserializeAws_restJson1PutEventStreamCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, errorCode, _b, parsedBody;
    var _c;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                _a = [__assign({}, output)];
                _c = {};
                return [4, parseErrorBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_c.body = _d.sent(), _c)]));
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "BadRequestException": return [3, 2];
                    case "com.amazonaws.pinpoint#BadRequestException": return [3, 2];
                    case "ForbiddenException": return [3, 4];
                    case "com.amazonaws.pinpoint#ForbiddenException": return [3, 4];
                    case "InternalServerErrorException": return [3, 6];
                    case "com.amazonaws.pinpoint#InternalServerErrorException": return [3, 6];
                    case "MethodNotAllowedException": return [3, 8];
                    case "com.amazonaws.pinpoint#MethodNotAllowedException": return [3, 8];
                    case "NotFoundException": return [3, 10];
                    case "com.amazonaws.pinpoint#NotFoundException": return [3, 10];
                    case "PayloadTooLargeException": return [3, 12];
                    case "com.amazonaws.pinpoint#PayloadTooLargeException": return [3, 12];
                    case "TooManyRequestsException": return [3, 14];
                    case "com.amazonaws.pinpoint#TooManyRequestsException": return [3, 14];
                }
                return [3, 16];
            case 2: return [4, deserializeAws_restJson1BadRequestExceptionResponse(parsedOutput, context)];
            case 3: throw _d.sent();
            case 4: return [4, deserializeAws_restJson1ForbiddenExceptionResponse(parsedOutput, context)];
            case 5: throw _d.sent();
            case 6: return [4, deserializeAws_restJson1InternalServerErrorExceptionResponse(parsedOutput, context)];
            case 7: throw _d.sent();
            case 8: return [4, deserializeAws_restJson1MethodNotAllowedExceptionResponse(parsedOutput, context)];
            case 9: throw _d.sent();
            case 10: return [4, deserializeAws_restJson1NotFoundExceptionResponse(parsedOutput, context)];
            case 11: throw _d.sent();
            case 12: return [4, deserializeAws_restJson1PayloadTooLargeExceptionResponse(parsedOutput, context)];
            case 13: throw _d.sent();
            case 14: return [4, deserializeAws_restJson1TooManyRequestsExceptionResponse(parsedOutput, context)];
            case 15: throw _d.sent();
            case 16:
                parsedBody = parsedOutput.body;
                throwDefaultError({
                    output: output,
                    parsedBody: parsedBody,
                    exceptionCtor: __BaseException,
                    errorCode: errorCode,
                });
                _d.label = 17;
            case 17: return [2];
        }
    });
}); };
export var deserializeAws_restJson1RemoveAttributesCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var contents, data, _a;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                if (output.statusCode !== 200 && output.statusCode >= 300) {
                    return [2, deserializeAws_restJson1RemoveAttributesCommandError(output, context)];
                }
                contents = map({
                    $metadata: deserializeMetadata(output),
                });
                _a = __expectObject;
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.apply(void 0, [_b.sent()]);
                contents.AttributesResource = deserializeAws_restJson1AttributesResource(data, context);
                return [2, contents];
        }
    });
}); };
var deserializeAws_restJson1RemoveAttributesCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, errorCode, _b, parsedBody;
    var _c;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                _a = [__assign({}, output)];
                _c = {};
                return [4, parseErrorBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_c.body = _d.sent(), _c)]));
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "BadRequestException": return [3, 2];
                    case "com.amazonaws.pinpoint#BadRequestException": return [3, 2];
                    case "ForbiddenException": return [3, 4];
                    case "com.amazonaws.pinpoint#ForbiddenException": return [3, 4];
                    case "InternalServerErrorException": return [3, 6];
                    case "com.amazonaws.pinpoint#InternalServerErrorException": return [3, 6];
                    case "MethodNotAllowedException": return [3, 8];
                    case "com.amazonaws.pinpoint#MethodNotAllowedException": return [3, 8];
                    case "NotFoundException": return [3, 10];
                    case "com.amazonaws.pinpoint#NotFoundException": return [3, 10];
                    case "PayloadTooLargeException": return [3, 12];
                    case "com.amazonaws.pinpoint#PayloadTooLargeException": return [3, 12];
                    case "TooManyRequestsException": return [3, 14];
                    case "com.amazonaws.pinpoint#TooManyRequestsException": return [3, 14];
                }
                return [3, 16];
            case 2: return [4, deserializeAws_restJson1BadRequestExceptionResponse(parsedOutput, context)];
            case 3: throw _d.sent();
            case 4: return [4, deserializeAws_restJson1ForbiddenExceptionResponse(parsedOutput, context)];
            case 5: throw _d.sent();
            case 6: return [4, deserializeAws_restJson1InternalServerErrorExceptionResponse(parsedOutput, context)];
            case 7: throw _d.sent();
            case 8: return [4, deserializeAws_restJson1MethodNotAllowedExceptionResponse(parsedOutput, context)];
            case 9: throw _d.sent();
            case 10: return [4, deserializeAws_restJson1NotFoundExceptionResponse(parsedOutput, context)];
            case 11: throw _d.sent();
            case 12: return [4, deserializeAws_restJson1PayloadTooLargeExceptionResponse(parsedOutput, context)];
            case 13: throw _d.sent();
            case 14: return [4, deserializeAws_restJson1TooManyRequestsExceptionResponse(parsedOutput, context)];
            case 15: throw _d.sent();
            case 16:
                parsedBody = parsedOutput.body;
                throwDefaultError({
                    output: output,
                    parsedBody: parsedBody,
                    exceptionCtor: __BaseException,
                    errorCode: errorCode,
                });
                _d.label = 17;
            case 17: return [2];
        }
    });
}); };
export var deserializeAws_restJson1SendMessagesCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var contents, data, _a;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                if (output.statusCode !== 200 && output.statusCode >= 300) {
                    return [2, deserializeAws_restJson1SendMessagesCommandError(output, context)];
                }
                contents = map({
                    $metadata: deserializeMetadata(output),
                });
                _a = __expectObject;
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.apply(void 0, [_b.sent()]);
                contents.MessageResponse = deserializeAws_restJson1MessageResponse(data, context);
                return [2, contents];
        }
    });
}); };
var deserializeAws_restJson1SendMessagesCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, errorCode, _b, parsedBody;
    var _c;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                _a = [__assign({}, output)];
                _c = {};
                return [4, parseErrorBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_c.body = _d.sent(), _c)]));
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "BadRequestException": return [3, 2];
                    case "com.amazonaws.pinpoint#BadRequestException": return [3, 2];
                    case "ForbiddenException": return [3, 4];
                    case "com.amazonaws.pinpoint#ForbiddenException": return [3, 4];
                    case "InternalServerErrorException": return [3, 6];
                    case "com.amazonaws.pinpoint#InternalServerErrorException": return [3, 6];
                    case "MethodNotAllowedException": return [3, 8];
                    case "com.amazonaws.pinpoint#MethodNotAllowedException": return [3, 8];
                    case "NotFoundException": return [3, 10];
                    case "com.amazonaws.pinpoint#NotFoundException": return [3, 10];
                    case "PayloadTooLargeException": return [3, 12];
                    case "com.amazonaws.pinpoint#PayloadTooLargeException": return [3, 12];
                    case "TooManyRequestsException": return [3, 14];
                    case "com.amazonaws.pinpoint#TooManyRequestsException": return [3, 14];
                }
                return [3, 16];
            case 2: return [4, deserializeAws_restJson1BadRequestExceptionResponse(parsedOutput, context)];
            case 3: throw _d.sent();
            case 4: return [4, deserializeAws_restJson1ForbiddenExceptionResponse(parsedOutput, context)];
            case 5: throw _d.sent();
            case 6: return [4, deserializeAws_restJson1InternalServerErrorExceptionResponse(parsedOutput, context)];
            case 7: throw _d.sent();
            case 8: return [4, deserializeAws_restJson1MethodNotAllowedExceptionResponse(parsedOutput, context)];
            case 9: throw _d.sent();
            case 10: return [4, deserializeAws_restJson1NotFoundExceptionResponse(parsedOutput, context)];
            case 11: throw _d.sent();
            case 12: return [4, deserializeAws_restJson1PayloadTooLargeExceptionResponse(parsedOutput, context)];
            case 13: throw _d.sent();
            case 14: return [4, deserializeAws_restJson1TooManyRequestsExceptionResponse(parsedOutput, context)];
            case 15: throw _d.sent();
            case 16:
                parsedBody = parsedOutput.body;
                throwDefaultError({
                    output: output,
                    parsedBody: parsedBody,
                    exceptionCtor: __BaseException,
                    errorCode: errorCode,
                });
                _d.label = 17;
            case 17: return [2];
        }
    });
}); };
export var deserializeAws_restJson1SendOTPMessageCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var contents, data, _a;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                if (output.statusCode !== 200 && output.statusCode >= 300) {
                    return [2, deserializeAws_restJson1SendOTPMessageCommandError(output, context)];
                }
                contents = map({
                    $metadata: deserializeMetadata(output),
                });
                _a = __expectObject;
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.apply(void 0, [_b.sent()]);
                contents.MessageResponse = deserializeAws_restJson1MessageResponse(data, context);
                return [2, contents];
        }
    });
}); };
var deserializeAws_restJson1SendOTPMessageCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, errorCode, _b, parsedBody;
    var _c;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                _a = [__assign({}, output)];
                _c = {};
                return [4, parseErrorBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_c.body = _d.sent(), _c)]));
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "BadRequestException": return [3, 2];
                    case "com.amazonaws.pinpoint#BadRequestException": return [3, 2];
                    case "ForbiddenException": return [3, 4];
                    case "com.amazonaws.pinpoint#ForbiddenException": return [3, 4];
                    case "InternalServerErrorException": return [3, 6];
                    case "com.amazonaws.pinpoint#InternalServerErrorException": return [3, 6];
                    case "MethodNotAllowedException": return [3, 8];
                    case "com.amazonaws.pinpoint#MethodNotAllowedException": return [3, 8];
                    case "NotFoundException": return [3, 10];
                    case "com.amazonaws.pinpoint#NotFoundException": return [3, 10];
                    case "PayloadTooLargeException": return [3, 12];
                    case "com.amazonaws.pinpoint#PayloadTooLargeException": return [3, 12];
                    case "TooManyRequestsException": return [3, 14];
                    case "com.amazonaws.pinpoint#TooManyRequestsException": return [3, 14];
                }
                return [3, 16];
            case 2: return [4, deserializeAws_restJson1BadRequestExceptionResponse(parsedOutput, context)];
            case 3: throw _d.sent();
            case 4: return [4, deserializeAws_restJson1ForbiddenExceptionResponse(parsedOutput, context)];
            case 5: throw _d.sent();
            case 6: return [4, deserializeAws_restJson1InternalServerErrorExceptionResponse(parsedOutput, context)];
            case 7: throw _d.sent();
            case 8: return [4, deserializeAws_restJson1MethodNotAllowedExceptionResponse(parsedOutput, context)];
            case 9: throw _d.sent();
            case 10: return [4, deserializeAws_restJson1NotFoundExceptionResponse(parsedOutput, context)];
            case 11: throw _d.sent();
            case 12: return [4, deserializeAws_restJson1PayloadTooLargeExceptionResponse(parsedOutput, context)];
            case 13: throw _d.sent();
            case 14: return [4, deserializeAws_restJson1TooManyRequestsExceptionResponse(parsedOutput, context)];
            case 15: throw _d.sent();
            case 16:
                parsedBody = parsedOutput.body;
                throwDefaultError({
                    output: output,
                    parsedBody: parsedBody,
                    exceptionCtor: __BaseException,
                    errorCode: errorCode,
                });
                _d.label = 17;
            case 17: return [2];
        }
    });
}); };
export var deserializeAws_restJson1SendUsersMessagesCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var contents, data, _a;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                if (output.statusCode !== 200 && output.statusCode >= 300) {
                    return [2, deserializeAws_restJson1SendUsersMessagesCommandError(output, context)];
                }
                contents = map({
                    $metadata: deserializeMetadata(output),
                });
                _a = __expectObject;
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.apply(void 0, [_b.sent()]);
                contents.SendUsersMessageResponse = deserializeAws_restJson1SendUsersMessageResponse(data, context);
                return [2, contents];
        }
    });
}); };
var deserializeAws_restJson1SendUsersMessagesCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, errorCode, _b, parsedBody;
    var _c;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                _a = [__assign({}, output)];
                _c = {};
                return [4, parseErrorBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_c.body = _d.sent(), _c)]));
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "BadRequestException": return [3, 2];
                    case "com.amazonaws.pinpoint#BadRequestException": return [3, 2];
                    case "ForbiddenException": return [3, 4];
                    case "com.amazonaws.pinpoint#ForbiddenException": return [3, 4];
                    case "InternalServerErrorException": return [3, 6];
                    case "com.amazonaws.pinpoint#InternalServerErrorException": return [3, 6];
                    case "MethodNotAllowedException": return [3, 8];
                    case "com.amazonaws.pinpoint#MethodNotAllowedException": return [3, 8];
                    case "NotFoundException": return [3, 10];
                    case "com.amazonaws.pinpoint#NotFoundException": return [3, 10];
                    case "PayloadTooLargeException": return [3, 12];
                    case "com.amazonaws.pinpoint#PayloadTooLargeException": return [3, 12];
                    case "TooManyRequestsException": return [3, 14];
                    case "com.amazonaws.pinpoint#TooManyRequestsException": return [3, 14];
                }
                return [3, 16];
            case 2: return [4, deserializeAws_restJson1BadRequestExceptionResponse(parsedOutput, context)];
            case 3: throw _d.sent();
            case 4: return [4, deserializeAws_restJson1ForbiddenExceptionResponse(parsedOutput, context)];
            case 5: throw _d.sent();
            case 6: return [4, deserializeAws_restJson1InternalServerErrorExceptionResponse(parsedOutput, context)];
            case 7: throw _d.sent();
            case 8: return [4, deserializeAws_restJson1MethodNotAllowedExceptionResponse(parsedOutput, context)];
            case 9: throw _d.sent();
            case 10: return [4, deserializeAws_restJson1NotFoundExceptionResponse(parsedOutput, context)];
            case 11: throw _d.sent();
            case 12: return [4, deserializeAws_restJson1PayloadTooLargeExceptionResponse(parsedOutput, context)];
            case 13: throw _d.sent();
            case 14: return [4, deserializeAws_restJson1TooManyRequestsExceptionResponse(parsedOutput, context)];
            case 15: throw _d.sent();
            case 16:
                parsedBody = parsedOutput.body;
                throwDefaultError({
                    output: output,
                    parsedBody: parsedBody,
                    exceptionCtor: __BaseException,
                    errorCode: errorCode,
                });
                _d.label = 17;
            case 17: return [2];
        }
    });
}); };
export var deserializeAws_restJson1TagResourceCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var contents;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (output.statusCode !== 204 && output.statusCode >= 300) {
                    return [2, deserializeAws_restJson1TagResourceCommandError(output, context)];
                }
                contents = map({
                    $metadata: deserializeMetadata(output),
                });
                return [4, collectBody(output.body, context)];
            case 1:
                _a.sent();
                return [2, contents];
        }
    });
}); };
var deserializeAws_restJson1TagResourceCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, errorCode, parsedBody;
    var _b;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                _a = [__assign({}, output)];
                _b = {};
                return [4, parseErrorBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_b.body = _c.sent(), _b)]));
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                parsedBody = parsedOutput.body;
                throwDefaultError({
                    output: output,
                    parsedBody: parsedBody,
                    exceptionCtor: __BaseException,
                    errorCode: errorCode,
                });
                return [2];
        }
    });
}); };
export var deserializeAws_restJson1UntagResourceCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var contents;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (output.statusCode !== 204 && output.statusCode >= 300) {
                    return [2, deserializeAws_restJson1UntagResourceCommandError(output, context)];
                }
                contents = map({
                    $metadata: deserializeMetadata(output),
                });
                return [4, collectBody(output.body, context)];
            case 1:
                _a.sent();
                return [2, contents];
        }
    });
}); };
var deserializeAws_restJson1UntagResourceCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, errorCode, parsedBody;
    var _b;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                _a = [__assign({}, output)];
                _b = {};
                return [4, parseErrorBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_b.body = _c.sent(), _b)]));
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                parsedBody = parsedOutput.body;
                throwDefaultError({
                    output: output,
                    parsedBody: parsedBody,
                    exceptionCtor: __BaseException,
                    errorCode: errorCode,
                });
                return [2];
        }
    });
}); };
export var deserializeAws_restJson1UpdateAdmChannelCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var contents, data, _a;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                if (output.statusCode !== 200 && output.statusCode >= 300) {
                    return [2, deserializeAws_restJson1UpdateAdmChannelCommandError(output, context)];
                }
                contents = map({
                    $metadata: deserializeMetadata(output),
                });
                _a = __expectObject;
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.apply(void 0, [_b.sent()]);
                contents.ADMChannelResponse = deserializeAws_restJson1ADMChannelResponse(data, context);
                return [2, contents];
        }
    });
}); };
var deserializeAws_restJson1UpdateAdmChannelCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, errorCode, _b, parsedBody;
    var _c;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                _a = [__assign({}, output)];
                _c = {};
                return [4, parseErrorBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_c.body = _d.sent(), _c)]));
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "BadRequestException": return [3, 2];
                    case "com.amazonaws.pinpoint#BadRequestException": return [3, 2];
                    case "ForbiddenException": return [3, 4];
                    case "com.amazonaws.pinpoint#ForbiddenException": return [3, 4];
                    case "InternalServerErrorException": return [3, 6];
                    case "com.amazonaws.pinpoint#InternalServerErrorException": return [3, 6];
                    case "MethodNotAllowedException": return [3, 8];
                    case "com.amazonaws.pinpoint#MethodNotAllowedException": return [3, 8];
                    case "NotFoundException": return [3, 10];
                    case "com.amazonaws.pinpoint#NotFoundException": return [3, 10];
                    case "PayloadTooLargeException": return [3, 12];
                    case "com.amazonaws.pinpoint#PayloadTooLargeException": return [3, 12];
                    case "TooManyRequestsException": return [3, 14];
                    case "com.amazonaws.pinpoint#TooManyRequestsException": return [3, 14];
                }
                return [3, 16];
            case 2: return [4, deserializeAws_restJson1BadRequestExceptionResponse(parsedOutput, context)];
            case 3: throw _d.sent();
            case 4: return [4, deserializeAws_restJson1ForbiddenExceptionResponse(parsedOutput, context)];
            case 5: throw _d.sent();
            case 6: return [4, deserializeAws_restJson1InternalServerErrorExceptionResponse(parsedOutput, context)];
            case 7: throw _d.sent();
            case 8: return [4, deserializeAws_restJson1MethodNotAllowedExceptionResponse(parsedOutput, context)];
            case 9: throw _d.sent();
            case 10: return [4, deserializeAws_restJson1NotFoundExceptionResponse(parsedOutput, context)];
            case 11: throw _d.sent();
            case 12: return [4, deserializeAws_restJson1PayloadTooLargeExceptionResponse(parsedOutput, context)];
            case 13: throw _d.sent();
            case 14: return [4, deserializeAws_restJson1TooManyRequestsExceptionResponse(parsedOutput, context)];
            case 15: throw _d.sent();
            case 16:
                parsedBody = parsedOutput.body;
                throwDefaultError({
                    output: output,
                    parsedBody: parsedBody,
                    exceptionCtor: __BaseException,
                    errorCode: errorCode,
                });
                _d.label = 17;
            case 17: return [2];
        }
    });
}); };
export var deserializeAws_restJson1UpdateApnsChannelCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var contents, data, _a;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                if (output.statusCode !== 200 && output.statusCode >= 300) {
                    return [2, deserializeAws_restJson1UpdateApnsChannelCommandError(output, context)];
                }
                contents = map({
                    $metadata: deserializeMetadata(output),
                });
                _a = __expectObject;
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.apply(void 0, [_b.sent()]);
                contents.APNSChannelResponse = deserializeAws_restJson1APNSChannelResponse(data, context);
                return [2, contents];
        }
    });
}); };
var deserializeAws_restJson1UpdateApnsChannelCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, errorCode, _b, parsedBody;
    var _c;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                _a = [__assign({}, output)];
                _c = {};
                return [4, parseErrorBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_c.body = _d.sent(), _c)]));
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "BadRequestException": return [3, 2];
                    case "com.amazonaws.pinpoint#BadRequestException": return [3, 2];
                    case "ForbiddenException": return [3, 4];
                    case "com.amazonaws.pinpoint#ForbiddenException": return [3, 4];
                    case "InternalServerErrorException": return [3, 6];
                    case "com.amazonaws.pinpoint#InternalServerErrorException": return [3, 6];
                    case "MethodNotAllowedException": return [3, 8];
                    case "com.amazonaws.pinpoint#MethodNotAllowedException": return [3, 8];
                    case "NotFoundException": return [3, 10];
                    case "com.amazonaws.pinpoint#NotFoundException": return [3, 10];
                    case "PayloadTooLargeException": return [3, 12];
                    case "com.amazonaws.pinpoint#PayloadTooLargeException": return [3, 12];
                    case "TooManyRequestsException": return [3, 14];
                    case "com.amazonaws.pinpoint#TooManyRequestsException": return [3, 14];
                }
                return [3, 16];
            case 2: return [4, deserializeAws_restJson1BadRequestExceptionResponse(parsedOutput, context)];
            case 3: throw _d.sent();
            case 4: return [4, deserializeAws_restJson1ForbiddenExceptionResponse(parsedOutput, context)];
            case 5: throw _d.sent();
            case 6: return [4, deserializeAws_restJson1InternalServerErrorExceptionResponse(parsedOutput, context)];
            case 7: throw _d.sent();
            case 8: return [4, deserializeAws_restJson1MethodNotAllowedExceptionResponse(parsedOutput, context)];
            case 9: throw _d.sent();
            case 10: return [4, deserializeAws_restJson1NotFoundExceptionResponse(parsedOutput, context)];
            case 11: throw _d.sent();
            case 12: return [4, deserializeAws_restJson1PayloadTooLargeExceptionResponse(parsedOutput, context)];
            case 13: throw _d.sent();
            case 14: return [4, deserializeAws_restJson1TooManyRequestsExceptionResponse(parsedOutput, context)];
            case 15: throw _d.sent();
            case 16:
                parsedBody = parsedOutput.body;
                throwDefaultError({
                    output: output,
                    parsedBody: parsedBody,
                    exceptionCtor: __BaseException,
                    errorCode: errorCode,
                });
                _d.label = 17;
            case 17: return [2];
        }
    });
}); };
export var deserializeAws_restJson1UpdateApnsSandboxChannelCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var contents, data, _a;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                if (output.statusCode !== 200 && output.statusCode >= 300) {
                    return [2, deserializeAws_restJson1UpdateApnsSandboxChannelCommandError(output, context)];
                }
                contents = map({
                    $metadata: deserializeMetadata(output),
                });
                _a = __expectObject;
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.apply(void 0, [_b.sent()]);
                contents.APNSSandboxChannelResponse = deserializeAws_restJson1APNSSandboxChannelResponse(data, context);
                return [2, contents];
        }
    });
}); };
var deserializeAws_restJson1UpdateApnsSandboxChannelCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, errorCode, _b, parsedBody;
    var _c;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                _a = [__assign({}, output)];
                _c = {};
                return [4, parseErrorBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_c.body = _d.sent(), _c)]));
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "BadRequestException": return [3, 2];
                    case "com.amazonaws.pinpoint#BadRequestException": return [3, 2];
                    case "ForbiddenException": return [3, 4];
                    case "com.amazonaws.pinpoint#ForbiddenException": return [3, 4];
                    case "InternalServerErrorException": return [3, 6];
                    case "com.amazonaws.pinpoint#InternalServerErrorException": return [3, 6];
                    case "MethodNotAllowedException": return [3, 8];
                    case "com.amazonaws.pinpoint#MethodNotAllowedException": return [3, 8];
                    case "NotFoundException": return [3, 10];
                    case "com.amazonaws.pinpoint#NotFoundException": return [3, 10];
                    case "PayloadTooLargeException": return [3, 12];
                    case "com.amazonaws.pinpoint#PayloadTooLargeException": return [3, 12];
                    case "TooManyRequestsException": return [3, 14];
                    case "com.amazonaws.pinpoint#TooManyRequestsException": return [3, 14];
                }
                return [3, 16];
            case 2: return [4, deserializeAws_restJson1BadRequestExceptionResponse(parsedOutput, context)];
            case 3: throw _d.sent();
            case 4: return [4, deserializeAws_restJson1ForbiddenExceptionResponse(parsedOutput, context)];
            case 5: throw _d.sent();
            case 6: return [4, deserializeAws_restJson1InternalServerErrorExceptionResponse(parsedOutput, context)];
            case 7: throw _d.sent();
            case 8: return [4, deserializeAws_restJson1MethodNotAllowedExceptionResponse(parsedOutput, context)];
            case 9: throw _d.sent();
            case 10: return [4, deserializeAws_restJson1NotFoundExceptionResponse(parsedOutput, context)];
            case 11: throw _d.sent();
            case 12: return [4, deserializeAws_restJson1PayloadTooLargeExceptionResponse(parsedOutput, context)];
            case 13: throw _d.sent();
            case 14: return [4, deserializeAws_restJson1TooManyRequestsExceptionResponse(parsedOutput, context)];
            case 15: throw _d.sent();
            case 16:
                parsedBody = parsedOutput.body;
                throwDefaultError({
                    output: output,
                    parsedBody: parsedBody,
                    exceptionCtor: __BaseException,
                    errorCode: errorCode,
                });
                _d.label = 17;
            case 17: return [2];
        }
    });
}); };
export var deserializeAws_restJson1UpdateApnsVoipChannelCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var contents, data, _a;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                if (output.statusCode !== 200 && output.statusCode >= 300) {
                    return [2, deserializeAws_restJson1UpdateApnsVoipChannelCommandError(output, context)];
                }
                contents = map({
                    $metadata: deserializeMetadata(output),
                });
                _a = __expectObject;
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.apply(void 0, [_b.sent()]);
                contents.APNSVoipChannelResponse = deserializeAws_restJson1APNSVoipChannelResponse(data, context);
                return [2, contents];
        }
    });
}); };
var deserializeAws_restJson1UpdateApnsVoipChannelCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, errorCode, _b, parsedBody;
    var _c;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                _a = [__assign({}, output)];
                _c = {};
                return [4, parseErrorBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_c.body = _d.sent(), _c)]));
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "BadRequestException": return [3, 2];
                    case "com.amazonaws.pinpoint#BadRequestException": return [3, 2];
                    case "ForbiddenException": return [3, 4];
                    case "com.amazonaws.pinpoint#ForbiddenException": return [3, 4];
                    case "InternalServerErrorException": return [3, 6];
                    case "com.amazonaws.pinpoint#InternalServerErrorException": return [3, 6];
                    case "MethodNotAllowedException": return [3, 8];
                    case "com.amazonaws.pinpoint#MethodNotAllowedException": return [3, 8];
                    case "NotFoundException": return [3, 10];
                    case "com.amazonaws.pinpoint#NotFoundException": return [3, 10];
                    case "PayloadTooLargeException": return [3, 12];
                    case "com.amazonaws.pinpoint#PayloadTooLargeException": return [3, 12];
                    case "TooManyRequestsException": return [3, 14];
                    case "com.amazonaws.pinpoint#TooManyRequestsException": return [3, 14];
                }
                return [3, 16];
            case 2: return [4, deserializeAws_restJson1BadRequestExceptionResponse(parsedOutput, context)];
            case 3: throw _d.sent();
            case 4: return [4, deserializeAws_restJson1ForbiddenExceptionResponse(parsedOutput, context)];
            case 5: throw _d.sent();
            case 6: return [4, deserializeAws_restJson1InternalServerErrorExceptionResponse(parsedOutput, context)];
            case 7: throw _d.sent();
            case 8: return [4, deserializeAws_restJson1MethodNotAllowedExceptionResponse(parsedOutput, context)];
            case 9: throw _d.sent();
            case 10: return [4, deserializeAws_restJson1NotFoundExceptionResponse(parsedOutput, context)];
            case 11: throw _d.sent();
            case 12: return [4, deserializeAws_restJson1PayloadTooLargeExceptionResponse(parsedOutput, context)];
            case 13: throw _d.sent();
            case 14: return [4, deserializeAws_restJson1TooManyRequestsExceptionResponse(parsedOutput, context)];
            case 15: throw _d.sent();
            case 16:
                parsedBody = parsedOutput.body;
                throwDefaultError({
                    output: output,
                    parsedBody: parsedBody,
                    exceptionCtor: __BaseException,
                    errorCode: errorCode,
                });
                _d.label = 17;
            case 17: return [2];
        }
    });
}); };
export var deserializeAws_restJson1UpdateApnsVoipSandboxChannelCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var contents, data, _a;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                if (output.statusCode !== 200 && output.statusCode >= 300) {
                    return [2, deserializeAws_restJson1UpdateApnsVoipSandboxChannelCommandError(output, context)];
                }
                contents = map({
                    $metadata: deserializeMetadata(output),
                });
                _a = __expectObject;
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.apply(void 0, [_b.sent()]);
                contents.APNSVoipSandboxChannelResponse = deserializeAws_restJson1APNSVoipSandboxChannelResponse(data, context);
                return [2, contents];
        }
    });
}); };
var deserializeAws_restJson1UpdateApnsVoipSandboxChannelCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, errorCode, _b, parsedBody;
    var _c;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                _a = [__assign({}, output)];
                _c = {};
                return [4, parseErrorBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_c.body = _d.sent(), _c)]));
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "BadRequestException": return [3, 2];
                    case "com.amazonaws.pinpoint#BadRequestException": return [3, 2];
                    case "ForbiddenException": return [3, 4];
                    case "com.amazonaws.pinpoint#ForbiddenException": return [3, 4];
                    case "InternalServerErrorException": return [3, 6];
                    case "com.amazonaws.pinpoint#InternalServerErrorException": return [3, 6];
                    case "MethodNotAllowedException": return [3, 8];
                    case "com.amazonaws.pinpoint#MethodNotAllowedException": return [3, 8];
                    case "NotFoundException": return [3, 10];
                    case "com.amazonaws.pinpoint#NotFoundException": return [3, 10];
                    case "PayloadTooLargeException": return [3, 12];
                    case "com.amazonaws.pinpoint#PayloadTooLargeException": return [3, 12];
                    case "TooManyRequestsException": return [3, 14];
                    case "com.amazonaws.pinpoint#TooManyRequestsException": return [3, 14];
                }
                return [3, 16];
            case 2: return [4, deserializeAws_restJson1BadRequestExceptionResponse(parsedOutput, context)];
            case 3: throw _d.sent();
            case 4: return [4, deserializeAws_restJson1ForbiddenExceptionResponse(parsedOutput, context)];
            case 5: throw _d.sent();
            case 6: return [4, deserializeAws_restJson1InternalServerErrorExceptionResponse(parsedOutput, context)];
            case 7: throw _d.sent();
            case 8: return [4, deserializeAws_restJson1MethodNotAllowedExceptionResponse(parsedOutput, context)];
            case 9: throw _d.sent();
            case 10: return [4, deserializeAws_restJson1NotFoundExceptionResponse(parsedOutput, context)];
            case 11: throw _d.sent();
            case 12: return [4, deserializeAws_restJson1PayloadTooLargeExceptionResponse(parsedOutput, context)];
            case 13: throw _d.sent();
            case 14: return [4, deserializeAws_restJson1TooManyRequestsExceptionResponse(parsedOutput, context)];
            case 15: throw _d.sent();
            case 16:
                parsedBody = parsedOutput.body;
                throwDefaultError({
                    output: output,
                    parsedBody: parsedBody,
                    exceptionCtor: __BaseException,
                    errorCode: errorCode,
                });
                _d.label = 17;
            case 17: return [2];
        }
    });
}); };
export var deserializeAws_restJson1UpdateApplicationSettingsCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var contents, data, _a;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                if (output.statusCode !== 200 && output.statusCode >= 300) {
                    return [2, deserializeAws_restJson1UpdateApplicationSettingsCommandError(output, context)];
                }
                contents = map({
                    $metadata: deserializeMetadata(output),
                });
                _a = __expectObject;
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.apply(void 0, [_b.sent()]);
                contents.ApplicationSettingsResource = deserializeAws_restJson1ApplicationSettingsResource(data, context);
                return [2, contents];
        }
    });
}); };
var deserializeAws_restJson1UpdateApplicationSettingsCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, errorCode, _b, parsedBody;
    var _c;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                _a = [__assign({}, output)];
                _c = {};
                return [4, parseErrorBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_c.body = _d.sent(), _c)]));
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "BadRequestException": return [3, 2];
                    case "com.amazonaws.pinpoint#BadRequestException": return [3, 2];
                    case "ForbiddenException": return [3, 4];
                    case "com.amazonaws.pinpoint#ForbiddenException": return [3, 4];
                    case "InternalServerErrorException": return [3, 6];
                    case "com.amazonaws.pinpoint#InternalServerErrorException": return [3, 6];
                    case "MethodNotAllowedException": return [3, 8];
                    case "com.amazonaws.pinpoint#MethodNotAllowedException": return [3, 8];
                    case "NotFoundException": return [3, 10];
                    case "com.amazonaws.pinpoint#NotFoundException": return [3, 10];
                    case "PayloadTooLargeException": return [3, 12];
                    case "com.amazonaws.pinpoint#PayloadTooLargeException": return [3, 12];
                    case "TooManyRequestsException": return [3, 14];
                    case "com.amazonaws.pinpoint#TooManyRequestsException": return [3, 14];
                }
                return [3, 16];
            case 2: return [4, deserializeAws_restJson1BadRequestExceptionResponse(parsedOutput, context)];
            case 3: throw _d.sent();
            case 4: return [4, deserializeAws_restJson1ForbiddenExceptionResponse(parsedOutput, context)];
            case 5: throw _d.sent();
            case 6: return [4, deserializeAws_restJson1InternalServerErrorExceptionResponse(parsedOutput, context)];
            case 7: throw _d.sent();
            case 8: return [4, deserializeAws_restJson1MethodNotAllowedExceptionResponse(parsedOutput, context)];
            case 9: throw _d.sent();
            case 10: return [4, deserializeAws_restJson1NotFoundExceptionResponse(parsedOutput, context)];
            case 11: throw _d.sent();
            case 12: return [4, deserializeAws_restJson1PayloadTooLargeExceptionResponse(parsedOutput, context)];
            case 13: throw _d.sent();
            case 14: return [4, deserializeAws_restJson1TooManyRequestsExceptionResponse(parsedOutput, context)];
            case 15: throw _d.sent();
            case 16:
                parsedBody = parsedOutput.body;
                throwDefaultError({
                    output: output,
                    parsedBody: parsedBody,
                    exceptionCtor: __BaseException,
                    errorCode: errorCode,
                });
                _d.label = 17;
            case 17: return [2];
        }
    });
}); };
export var deserializeAws_restJson1UpdateBaiduChannelCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var contents, data, _a;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                if (output.statusCode !== 200 && output.statusCode >= 300) {
                    return [2, deserializeAws_restJson1UpdateBaiduChannelCommandError(output, context)];
                }
                contents = map({
                    $metadata: deserializeMetadata(output),
                });
                _a = __expectObject;
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.apply(void 0, [_b.sent()]);
                contents.BaiduChannelResponse = deserializeAws_restJson1BaiduChannelResponse(data, context);
                return [2, contents];
        }
    });
}); };
var deserializeAws_restJson1UpdateBaiduChannelCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, errorCode, _b, parsedBody;
    var _c;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                _a = [__assign({}, output)];
                _c = {};
                return [4, parseErrorBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_c.body = _d.sent(), _c)]));
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "BadRequestException": return [3, 2];
                    case "com.amazonaws.pinpoint#BadRequestException": return [3, 2];
                    case "ForbiddenException": return [3, 4];
                    case "com.amazonaws.pinpoint#ForbiddenException": return [3, 4];
                    case "InternalServerErrorException": return [3, 6];
                    case "com.amazonaws.pinpoint#InternalServerErrorException": return [3, 6];
                    case "MethodNotAllowedException": return [3, 8];
                    case "com.amazonaws.pinpoint#MethodNotAllowedException": return [3, 8];
                    case "NotFoundException": return [3, 10];
                    case "com.amazonaws.pinpoint#NotFoundException": return [3, 10];
                    case "PayloadTooLargeException": return [3, 12];
                    case "com.amazonaws.pinpoint#PayloadTooLargeException": return [3, 12];
                    case "TooManyRequestsException": return [3, 14];
                    case "com.amazonaws.pinpoint#TooManyRequestsException": return [3, 14];
                }
                return [3, 16];
            case 2: return [4, deserializeAws_restJson1BadRequestExceptionResponse(parsedOutput, context)];
            case 3: throw _d.sent();
            case 4: return [4, deserializeAws_restJson1ForbiddenExceptionResponse(parsedOutput, context)];
            case 5: throw _d.sent();
            case 6: return [4, deserializeAws_restJson1InternalServerErrorExceptionResponse(parsedOutput, context)];
            case 7: throw _d.sent();
            case 8: return [4, deserializeAws_restJson1MethodNotAllowedExceptionResponse(parsedOutput, context)];
            case 9: throw _d.sent();
            case 10: return [4, deserializeAws_restJson1NotFoundExceptionResponse(parsedOutput, context)];
            case 11: throw _d.sent();
            case 12: return [4, deserializeAws_restJson1PayloadTooLargeExceptionResponse(parsedOutput, context)];
            case 13: throw _d.sent();
            case 14: return [4, deserializeAws_restJson1TooManyRequestsExceptionResponse(parsedOutput, context)];
            case 15: throw _d.sent();
            case 16:
                parsedBody = parsedOutput.body;
                throwDefaultError({
                    output: output,
                    parsedBody: parsedBody,
                    exceptionCtor: __BaseException,
                    errorCode: errorCode,
                });
                _d.label = 17;
            case 17: return [2];
        }
    });
}); };
export var deserializeAws_restJson1UpdateCampaignCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var contents, data, _a;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                if (output.statusCode !== 200 && output.statusCode >= 300) {
                    return [2, deserializeAws_restJson1UpdateCampaignCommandError(output, context)];
                }
                contents = map({
                    $metadata: deserializeMetadata(output),
                });
                _a = __expectObject;
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.apply(void 0, [_b.sent()]);
                contents.CampaignResponse = deserializeAws_restJson1CampaignResponse(data, context);
                return [2, contents];
        }
    });
}); };
var deserializeAws_restJson1UpdateCampaignCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, errorCode, _b, parsedBody;
    var _c;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                _a = [__assign({}, output)];
                _c = {};
                return [4, parseErrorBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_c.body = _d.sent(), _c)]));
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "BadRequestException": return [3, 2];
                    case "com.amazonaws.pinpoint#BadRequestException": return [3, 2];
                    case "ForbiddenException": return [3, 4];
                    case "com.amazonaws.pinpoint#ForbiddenException": return [3, 4];
                    case "InternalServerErrorException": return [3, 6];
                    case "com.amazonaws.pinpoint#InternalServerErrorException": return [3, 6];
                    case "MethodNotAllowedException": return [3, 8];
                    case "com.amazonaws.pinpoint#MethodNotAllowedException": return [3, 8];
                    case "NotFoundException": return [3, 10];
                    case "com.amazonaws.pinpoint#NotFoundException": return [3, 10];
                    case "PayloadTooLargeException": return [3, 12];
                    case "com.amazonaws.pinpoint#PayloadTooLargeException": return [3, 12];
                    case "TooManyRequestsException": return [3, 14];
                    case "com.amazonaws.pinpoint#TooManyRequestsException": return [3, 14];
                }
                return [3, 16];
            case 2: return [4, deserializeAws_restJson1BadRequestExceptionResponse(parsedOutput, context)];
            case 3: throw _d.sent();
            case 4: return [4, deserializeAws_restJson1ForbiddenExceptionResponse(parsedOutput, context)];
            case 5: throw _d.sent();
            case 6: return [4, deserializeAws_restJson1InternalServerErrorExceptionResponse(parsedOutput, context)];
            case 7: throw _d.sent();
            case 8: return [4, deserializeAws_restJson1MethodNotAllowedExceptionResponse(parsedOutput, context)];
            case 9: throw _d.sent();
            case 10: return [4, deserializeAws_restJson1NotFoundExceptionResponse(parsedOutput, context)];
            case 11: throw _d.sent();
            case 12: return [4, deserializeAws_restJson1PayloadTooLargeExceptionResponse(parsedOutput, context)];
            case 13: throw _d.sent();
            case 14: return [4, deserializeAws_restJson1TooManyRequestsExceptionResponse(parsedOutput, context)];
            case 15: throw _d.sent();
            case 16:
                parsedBody = parsedOutput.body;
                throwDefaultError({
                    output: output,
                    parsedBody: parsedBody,
                    exceptionCtor: __BaseException,
                    errorCode: errorCode,
                });
                _d.label = 17;
            case 17: return [2];
        }
    });
}); };
export var deserializeAws_restJson1UpdateEmailChannelCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var contents, data, _a;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                if (output.statusCode !== 200 && output.statusCode >= 300) {
                    return [2, deserializeAws_restJson1UpdateEmailChannelCommandError(output, context)];
                }
                contents = map({
                    $metadata: deserializeMetadata(output),
                });
                _a = __expectObject;
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.apply(void 0, [_b.sent()]);
                contents.EmailChannelResponse = deserializeAws_restJson1EmailChannelResponse(data, context);
                return [2, contents];
        }
    });
}); };
var deserializeAws_restJson1UpdateEmailChannelCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, errorCode, _b, parsedBody;
    var _c;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                _a = [__assign({}, output)];
                _c = {};
                return [4, parseErrorBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_c.body = _d.sent(), _c)]));
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "BadRequestException": return [3, 2];
                    case "com.amazonaws.pinpoint#BadRequestException": return [3, 2];
                    case "ForbiddenException": return [3, 4];
                    case "com.amazonaws.pinpoint#ForbiddenException": return [3, 4];
                    case "InternalServerErrorException": return [3, 6];
                    case "com.amazonaws.pinpoint#InternalServerErrorException": return [3, 6];
                    case "MethodNotAllowedException": return [3, 8];
                    case "com.amazonaws.pinpoint#MethodNotAllowedException": return [3, 8];
                    case "NotFoundException": return [3, 10];
                    case "com.amazonaws.pinpoint#NotFoundException": return [3, 10];
                    case "PayloadTooLargeException": return [3, 12];
                    case "com.amazonaws.pinpoint#PayloadTooLargeException": return [3, 12];
                    case "TooManyRequestsException": return [3, 14];
                    case "com.amazonaws.pinpoint#TooManyRequestsException": return [3, 14];
                }
                return [3, 16];
            case 2: return [4, deserializeAws_restJson1BadRequestExceptionResponse(parsedOutput, context)];
            case 3: throw _d.sent();
            case 4: return [4, deserializeAws_restJson1ForbiddenExceptionResponse(parsedOutput, context)];
            case 5: throw _d.sent();
            case 6: return [4, deserializeAws_restJson1InternalServerErrorExceptionResponse(parsedOutput, context)];
            case 7: throw _d.sent();
            case 8: return [4, deserializeAws_restJson1MethodNotAllowedExceptionResponse(parsedOutput, context)];
            case 9: throw _d.sent();
            case 10: return [4, deserializeAws_restJson1NotFoundExceptionResponse(parsedOutput, context)];
            case 11: throw _d.sent();
            case 12: return [4, deserializeAws_restJson1PayloadTooLargeExceptionResponse(parsedOutput, context)];
            case 13: throw _d.sent();
            case 14: return [4, deserializeAws_restJson1TooManyRequestsExceptionResponse(parsedOutput, context)];
            case 15: throw _d.sent();
            case 16:
                parsedBody = parsedOutput.body;
                throwDefaultError({
                    output: output,
                    parsedBody: parsedBody,
                    exceptionCtor: __BaseException,
                    errorCode: errorCode,
                });
                _d.label = 17;
            case 17: return [2];
        }
    });
}); };
export var deserializeAws_restJson1UpdateEmailTemplateCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var contents, data, _a;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                if (output.statusCode !== 202 && output.statusCode >= 300) {
                    return [2, deserializeAws_restJson1UpdateEmailTemplateCommandError(output, context)];
                }
                contents = map({
                    $metadata: deserializeMetadata(output),
                });
                _a = __expectObject;
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.apply(void 0, [_b.sent()]);
                contents.MessageBody = deserializeAws_restJson1MessageBody(data, context);
                return [2, contents];
        }
    });
}); };
var deserializeAws_restJson1UpdateEmailTemplateCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, errorCode, _b, parsedBody;
    var _c;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                _a = [__assign({}, output)];
                _c = {};
                return [4, parseErrorBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_c.body = _d.sent(), _c)]));
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "BadRequestException": return [3, 2];
                    case "com.amazonaws.pinpoint#BadRequestException": return [3, 2];
                    case "ForbiddenException": return [3, 4];
                    case "com.amazonaws.pinpoint#ForbiddenException": return [3, 4];
                    case "InternalServerErrorException": return [3, 6];
                    case "com.amazonaws.pinpoint#InternalServerErrorException": return [3, 6];
                    case "MethodNotAllowedException": return [3, 8];
                    case "com.amazonaws.pinpoint#MethodNotAllowedException": return [3, 8];
                    case "NotFoundException": return [3, 10];
                    case "com.amazonaws.pinpoint#NotFoundException": return [3, 10];
                    case "PayloadTooLargeException": return [3, 12];
                    case "com.amazonaws.pinpoint#PayloadTooLargeException": return [3, 12];
                    case "TooManyRequestsException": return [3, 14];
                    case "com.amazonaws.pinpoint#TooManyRequestsException": return [3, 14];
                }
                return [3, 16];
            case 2: return [4, deserializeAws_restJson1BadRequestExceptionResponse(parsedOutput, context)];
            case 3: throw _d.sent();
            case 4: return [4, deserializeAws_restJson1ForbiddenExceptionResponse(parsedOutput, context)];
            case 5: throw _d.sent();
            case 6: return [4, deserializeAws_restJson1InternalServerErrorExceptionResponse(parsedOutput, context)];
            case 7: throw _d.sent();
            case 8: return [4, deserializeAws_restJson1MethodNotAllowedExceptionResponse(parsedOutput, context)];
            case 9: throw _d.sent();
            case 10: return [4, deserializeAws_restJson1NotFoundExceptionResponse(parsedOutput, context)];
            case 11: throw _d.sent();
            case 12: return [4, deserializeAws_restJson1PayloadTooLargeExceptionResponse(parsedOutput, context)];
            case 13: throw _d.sent();
            case 14: return [4, deserializeAws_restJson1TooManyRequestsExceptionResponse(parsedOutput, context)];
            case 15: throw _d.sent();
            case 16:
                parsedBody = parsedOutput.body;
                throwDefaultError({
                    output: output,
                    parsedBody: parsedBody,
                    exceptionCtor: __BaseException,
                    errorCode: errorCode,
                });
                _d.label = 17;
            case 17: return [2];
        }
    });
}); };
export var deserializeAws_restJson1UpdateEndpointCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var contents, data, _a;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                if (output.statusCode !== 202 && output.statusCode >= 300) {
                    return [2, deserializeAws_restJson1UpdateEndpointCommandError(output, context)];
                }
                contents = map({
                    $metadata: deserializeMetadata(output),
                });
                _a = __expectObject;
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.apply(void 0, [_b.sent()]);
                contents.MessageBody = deserializeAws_restJson1MessageBody(data, context);
                return [2, contents];
        }
    });
}); };
var deserializeAws_restJson1UpdateEndpointCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, errorCode, _b, parsedBody;
    var _c;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                _a = [__assign({}, output)];
                _c = {};
                return [4, parseErrorBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_c.body = _d.sent(), _c)]));
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "BadRequestException": return [3, 2];
                    case "com.amazonaws.pinpoint#BadRequestException": return [3, 2];
                    case "ForbiddenException": return [3, 4];
                    case "com.amazonaws.pinpoint#ForbiddenException": return [3, 4];
                    case "InternalServerErrorException": return [3, 6];
                    case "com.amazonaws.pinpoint#InternalServerErrorException": return [3, 6];
                    case "MethodNotAllowedException": return [3, 8];
                    case "com.amazonaws.pinpoint#MethodNotAllowedException": return [3, 8];
                    case "NotFoundException": return [3, 10];
                    case "com.amazonaws.pinpoint#NotFoundException": return [3, 10];
                    case "PayloadTooLargeException": return [3, 12];
                    case "com.amazonaws.pinpoint#PayloadTooLargeException": return [3, 12];
                    case "TooManyRequestsException": return [3, 14];
                    case "com.amazonaws.pinpoint#TooManyRequestsException": return [3, 14];
                }
                return [3, 16];
            case 2: return [4, deserializeAws_restJson1BadRequestExceptionResponse(parsedOutput, context)];
            case 3: throw _d.sent();
            case 4: return [4, deserializeAws_restJson1ForbiddenExceptionResponse(parsedOutput, context)];
            case 5: throw _d.sent();
            case 6: return [4, deserializeAws_restJson1InternalServerErrorExceptionResponse(parsedOutput, context)];
            case 7: throw _d.sent();
            case 8: return [4, deserializeAws_restJson1MethodNotAllowedExceptionResponse(parsedOutput, context)];
            case 9: throw _d.sent();
            case 10: return [4, deserializeAws_restJson1NotFoundExceptionResponse(parsedOutput, context)];
            case 11: throw _d.sent();
            case 12: return [4, deserializeAws_restJson1PayloadTooLargeExceptionResponse(parsedOutput, context)];
            case 13: throw _d.sent();
            case 14: return [4, deserializeAws_restJson1TooManyRequestsExceptionResponse(parsedOutput, context)];
            case 15: throw _d.sent();
            case 16:
                parsedBody = parsedOutput.body;
                throwDefaultError({
                    output: output,
                    parsedBody: parsedBody,
                    exceptionCtor: __BaseException,
                    errorCode: errorCode,
                });
                _d.label = 17;
            case 17: return [2];
        }
    });
}); };
export var deserializeAws_restJson1UpdateEndpointsBatchCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var contents, data, _a;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                if (output.statusCode !== 202 && output.statusCode >= 300) {
                    return [2, deserializeAws_restJson1UpdateEndpointsBatchCommandError(output, context)];
                }
                contents = map({
                    $metadata: deserializeMetadata(output),
                });
                _a = __expectObject;
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.apply(void 0, [_b.sent()]);
                contents.MessageBody = deserializeAws_restJson1MessageBody(data, context);
                return [2, contents];
        }
    });
}); };
var deserializeAws_restJson1UpdateEndpointsBatchCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, errorCode, _b, parsedBody;
    var _c;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                _a = [__assign({}, output)];
                _c = {};
                return [4, parseErrorBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_c.body = _d.sent(), _c)]));
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "BadRequestException": return [3, 2];
                    case "com.amazonaws.pinpoint#BadRequestException": return [3, 2];
                    case "ForbiddenException": return [3, 4];
                    case "com.amazonaws.pinpoint#ForbiddenException": return [3, 4];
                    case "InternalServerErrorException": return [3, 6];
                    case "com.amazonaws.pinpoint#InternalServerErrorException": return [3, 6];
                    case "MethodNotAllowedException": return [3, 8];
                    case "com.amazonaws.pinpoint#MethodNotAllowedException": return [3, 8];
                    case "NotFoundException": return [3, 10];
                    case "com.amazonaws.pinpoint#NotFoundException": return [3, 10];
                    case "PayloadTooLargeException": return [3, 12];
                    case "com.amazonaws.pinpoint#PayloadTooLargeException": return [3, 12];
                    case "TooManyRequestsException": return [3, 14];
                    case "com.amazonaws.pinpoint#TooManyRequestsException": return [3, 14];
                }
                return [3, 16];
            case 2: return [4, deserializeAws_restJson1BadRequestExceptionResponse(parsedOutput, context)];
            case 3: throw _d.sent();
            case 4: return [4, deserializeAws_restJson1ForbiddenExceptionResponse(parsedOutput, context)];
            case 5: throw _d.sent();
            case 6: return [4, deserializeAws_restJson1InternalServerErrorExceptionResponse(parsedOutput, context)];
            case 7: throw _d.sent();
            case 8: return [4, deserializeAws_restJson1MethodNotAllowedExceptionResponse(parsedOutput, context)];
            case 9: throw _d.sent();
            case 10: return [4, deserializeAws_restJson1NotFoundExceptionResponse(parsedOutput, context)];
            case 11: throw _d.sent();
            case 12: return [4, deserializeAws_restJson1PayloadTooLargeExceptionResponse(parsedOutput, context)];
            case 13: throw _d.sent();
            case 14: return [4, deserializeAws_restJson1TooManyRequestsExceptionResponse(parsedOutput, context)];
            case 15: throw _d.sent();
            case 16:
                parsedBody = parsedOutput.body;
                throwDefaultError({
                    output: output,
                    parsedBody: parsedBody,
                    exceptionCtor: __BaseException,
                    errorCode: errorCode,
                });
                _d.label = 17;
            case 17: return [2];
        }
    });
}); };
export var deserializeAws_restJson1UpdateGcmChannelCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var contents, data, _a;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                if (output.statusCode !== 200 && output.statusCode >= 300) {
                    return [2, deserializeAws_restJson1UpdateGcmChannelCommandError(output, context)];
                }
                contents = map({
                    $metadata: deserializeMetadata(output),
                });
                _a = __expectObject;
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.apply(void 0, [_b.sent()]);
                contents.GCMChannelResponse = deserializeAws_restJson1GCMChannelResponse(data, context);
                return [2, contents];
        }
    });
}); };
var deserializeAws_restJson1UpdateGcmChannelCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, errorCode, _b, parsedBody;
    var _c;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                _a = [__assign({}, output)];
                _c = {};
                return [4, parseErrorBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_c.body = _d.sent(), _c)]));
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "BadRequestException": return [3, 2];
                    case "com.amazonaws.pinpoint#BadRequestException": return [3, 2];
                    case "ForbiddenException": return [3, 4];
                    case "com.amazonaws.pinpoint#ForbiddenException": return [3, 4];
                    case "InternalServerErrorException": return [3, 6];
                    case "com.amazonaws.pinpoint#InternalServerErrorException": return [3, 6];
                    case "MethodNotAllowedException": return [3, 8];
                    case "com.amazonaws.pinpoint#MethodNotAllowedException": return [3, 8];
                    case "NotFoundException": return [3, 10];
                    case "com.amazonaws.pinpoint#NotFoundException": return [3, 10];
                    case "PayloadTooLargeException": return [3, 12];
                    case "com.amazonaws.pinpoint#PayloadTooLargeException": return [3, 12];
                    case "TooManyRequestsException": return [3, 14];
                    case "com.amazonaws.pinpoint#TooManyRequestsException": return [3, 14];
                }
                return [3, 16];
            case 2: return [4, deserializeAws_restJson1BadRequestExceptionResponse(parsedOutput, context)];
            case 3: throw _d.sent();
            case 4: return [4, deserializeAws_restJson1ForbiddenExceptionResponse(parsedOutput, context)];
            case 5: throw _d.sent();
            case 6: return [4, deserializeAws_restJson1InternalServerErrorExceptionResponse(parsedOutput, context)];
            case 7: throw _d.sent();
            case 8: return [4, deserializeAws_restJson1MethodNotAllowedExceptionResponse(parsedOutput, context)];
            case 9: throw _d.sent();
            case 10: return [4, deserializeAws_restJson1NotFoundExceptionResponse(parsedOutput, context)];
            case 11: throw _d.sent();
            case 12: return [4, deserializeAws_restJson1PayloadTooLargeExceptionResponse(parsedOutput, context)];
            case 13: throw _d.sent();
            case 14: return [4, deserializeAws_restJson1TooManyRequestsExceptionResponse(parsedOutput, context)];
            case 15: throw _d.sent();
            case 16:
                parsedBody = parsedOutput.body;
                throwDefaultError({
                    output: output,
                    parsedBody: parsedBody,
                    exceptionCtor: __BaseException,
                    errorCode: errorCode,
                });
                _d.label = 17;
            case 17: return [2];
        }
    });
}); };
export var deserializeAws_restJson1UpdateInAppTemplateCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var contents, data, _a;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                if (output.statusCode !== 202 && output.statusCode >= 300) {
                    return [2, deserializeAws_restJson1UpdateInAppTemplateCommandError(output, context)];
                }
                contents = map({
                    $metadata: deserializeMetadata(output),
                });
                _a = __expectObject;
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.apply(void 0, [_b.sent()]);
                contents.MessageBody = deserializeAws_restJson1MessageBody(data, context);
                return [2, contents];
        }
    });
}); };
var deserializeAws_restJson1UpdateInAppTemplateCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, errorCode, _b, parsedBody;
    var _c;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                _a = [__assign({}, output)];
                _c = {};
                return [4, parseErrorBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_c.body = _d.sent(), _c)]));
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "BadRequestException": return [3, 2];
                    case "com.amazonaws.pinpoint#BadRequestException": return [3, 2];
                    case "ForbiddenException": return [3, 4];
                    case "com.amazonaws.pinpoint#ForbiddenException": return [3, 4];
                    case "InternalServerErrorException": return [3, 6];
                    case "com.amazonaws.pinpoint#InternalServerErrorException": return [3, 6];
                    case "MethodNotAllowedException": return [3, 8];
                    case "com.amazonaws.pinpoint#MethodNotAllowedException": return [3, 8];
                    case "NotFoundException": return [3, 10];
                    case "com.amazonaws.pinpoint#NotFoundException": return [3, 10];
                    case "PayloadTooLargeException": return [3, 12];
                    case "com.amazonaws.pinpoint#PayloadTooLargeException": return [3, 12];
                    case "TooManyRequestsException": return [3, 14];
                    case "com.amazonaws.pinpoint#TooManyRequestsException": return [3, 14];
                }
                return [3, 16];
            case 2: return [4, deserializeAws_restJson1BadRequestExceptionResponse(parsedOutput, context)];
            case 3: throw _d.sent();
            case 4: return [4, deserializeAws_restJson1ForbiddenExceptionResponse(parsedOutput, context)];
            case 5: throw _d.sent();
            case 6: return [4, deserializeAws_restJson1InternalServerErrorExceptionResponse(parsedOutput, context)];
            case 7: throw _d.sent();
            case 8: return [4, deserializeAws_restJson1MethodNotAllowedExceptionResponse(parsedOutput, context)];
            case 9: throw _d.sent();
            case 10: return [4, deserializeAws_restJson1NotFoundExceptionResponse(parsedOutput, context)];
            case 11: throw _d.sent();
            case 12: return [4, deserializeAws_restJson1PayloadTooLargeExceptionResponse(parsedOutput, context)];
            case 13: throw _d.sent();
            case 14: return [4, deserializeAws_restJson1TooManyRequestsExceptionResponse(parsedOutput, context)];
            case 15: throw _d.sent();
            case 16:
                parsedBody = parsedOutput.body;
                throwDefaultError({
                    output: output,
                    parsedBody: parsedBody,
                    exceptionCtor: __BaseException,
                    errorCode: errorCode,
                });
                _d.label = 17;
            case 17: return [2];
        }
    });
}); };
export var deserializeAws_restJson1UpdateJourneyCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var contents, data, _a;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                if (output.statusCode !== 200 && output.statusCode >= 300) {
                    return [2, deserializeAws_restJson1UpdateJourneyCommandError(output, context)];
                }
                contents = map({
                    $metadata: deserializeMetadata(output),
                });
                _a = __expectObject;
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.apply(void 0, [_b.sent()]);
                contents.JourneyResponse = deserializeAws_restJson1JourneyResponse(data, context);
                return [2, contents];
        }
    });
}); };
var deserializeAws_restJson1UpdateJourneyCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, errorCode, _b, parsedBody;
    var _c;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                _a = [__assign({}, output)];
                _c = {};
                return [4, parseErrorBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_c.body = _d.sent(), _c)]));
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "BadRequestException": return [3, 2];
                    case "com.amazonaws.pinpoint#BadRequestException": return [3, 2];
                    case "ConflictException": return [3, 4];
                    case "com.amazonaws.pinpoint#ConflictException": return [3, 4];
                    case "ForbiddenException": return [3, 6];
                    case "com.amazonaws.pinpoint#ForbiddenException": return [3, 6];
                    case "InternalServerErrorException": return [3, 8];
                    case "com.amazonaws.pinpoint#InternalServerErrorException": return [3, 8];
                    case "MethodNotAllowedException": return [3, 10];
                    case "com.amazonaws.pinpoint#MethodNotAllowedException": return [3, 10];
                    case "NotFoundException": return [3, 12];
                    case "com.amazonaws.pinpoint#NotFoundException": return [3, 12];
                    case "PayloadTooLargeException": return [3, 14];
                    case "com.amazonaws.pinpoint#PayloadTooLargeException": return [3, 14];
                    case "TooManyRequestsException": return [3, 16];
                    case "com.amazonaws.pinpoint#TooManyRequestsException": return [3, 16];
                }
                return [3, 18];
            case 2: return [4, deserializeAws_restJson1BadRequestExceptionResponse(parsedOutput, context)];
            case 3: throw _d.sent();
            case 4: return [4, deserializeAws_restJson1ConflictExceptionResponse(parsedOutput, context)];
            case 5: throw _d.sent();
            case 6: return [4, deserializeAws_restJson1ForbiddenExceptionResponse(parsedOutput, context)];
            case 7: throw _d.sent();
            case 8: return [4, deserializeAws_restJson1InternalServerErrorExceptionResponse(parsedOutput, context)];
            case 9: throw _d.sent();
            case 10: return [4, deserializeAws_restJson1MethodNotAllowedExceptionResponse(parsedOutput, context)];
            case 11: throw _d.sent();
            case 12: return [4, deserializeAws_restJson1NotFoundExceptionResponse(parsedOutput, context)];
            case 13: throw _d.sent();
            case 14: return [4, deserializeAws_restJson1PayloadTooLargeExceptionResponse(parsedOutput, context)];
            case 15: throw _d.sent();
            case 16: return [4, deserializeAws_restJson1TooManyRequestsExceptionResponse(parsedOutput, context)];
            case 17: throw _d.sent();
            case 18:
                parsedBody = parsedOutput.body;
                throwDefaultError({
                    output: output,
                    parsedBody: parsedBody,
                    exceptionCtor: __BaseException,
                    errorCode: errorCode,
                });
                _d.label = 19;
            case 19: return [2];
        }
    });
}); };
export var deserializeAws_restJson1UpdateJourneyStateCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var contents, data, _a;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                if (output.statusCode !== 200 && output.statusCode >= 300) {
                    return [2, deserializeAws_restJson1UpdateJourneyStateCommandError(output, context)];
                }
                contents = map({
                    $metadata: deserializeMetadata(output),
                });
                _a = __expectObject;
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.apply(void 0, [_b.sent()]);
                contents.JourneyResponse = deserializeAws_restJson1JourneyResponse(data, context);
                return [2, contents];
        }
    });
}); };
var deserializeAws_restJson1UpdateJourneyStateCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, errorCode, _b, parsedBody;
    var _c;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                _a = [__assign({}, output)];
                _c = {};
                return [4, parseErrorBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_c.body = _d.sent(), _c)]));
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "BadRequestException": return [3, 2];
                    case "com.amazonaws.pinpoint#BadRequestException": return [3, 2];
                    case "ForbiddenException": return [3, 4];
                    case "com.amazonaws.pinpoint#ForbiddenException": return [3, 4];
                    case "InternalServerErrorException": return [3, 6];
                    case "com.amazonaws.pinpoint#InternalServerErrorException": return [3, 6];
                    case "MethodNotAllowedException": return [3, 8];
                    case "com.amazonaws.pinpoint#MethodNotAllowedException": return [3, 8];
                    case "NotFoundException": return [3, 10];
                    case "com.amazonaws.pinpoint#NotFoundException": return [3, 10];
                    case "PayloadTooLargeException": return [3, 12];
                    case "com.amazonaws.pinpoint#PayloadTooLargeException": return [3, 12];
                    case "TooManyRequestsException": return [3, 14];
                    case "com.amazonaws.pinpoint#TooManyRequestsException": return [3, 14];
                }
                return [3, 16];
            case 2: return [4, deserializeAws_restJson1BadRequestExceptionResponse(parsedOutput, context)];
            case 3: throw _d.sent();
            case 4: return [4, deserializeAws_restJson1ForbiddenExceptionResponse(parsedOutput, context)];
            case 5: throw _d.sent();
            case 6: return [4, deserializeAws_restJson1InternalServerErrorExceptionResponse(parsedOutput, context)];
            case 7: throw _d.sent();
            case 8: return [4, deserializeAws_restJson1MethodNotAllowedExceptionResponse(parsedOutput, context)];
            case 9: throw _d.sent();
            case 10: return [4, deserializeAws_restJson1NotFoundExceptionResponse(parsedOutput, context)];
            case 11: throw _d.sent();
            case 12: return [4, deserializeAws_restJson1PayloadTooLargeExceptionResponse(parsedOutput, context)];
            case 13: throw _d.sent();
            case 14: return [4, deserializeAws_restJson1TooManyRequestsExceptionResponse(parsedOutput, context)];
            case 15: throw _d.sent();
            case 16:
                parsedBody = parsedOutput.body;
                throwDefaultError({
                    output: output,
                    parsedBody: parsedBody,
                    exceptionCtor: __BaseException,
                    errorCode: errorCode,
                });
                _d.label = 17;
            case 17: return [2];
        }
    });
}); };
export var deserializeAws_restJson1UpdatePushTemplateCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var contents, data, _a;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                if (output.statusCode !== 202 && output.statusCode >= 300) {
                    return [2, deserializeAws_restJson1UpdatePushTemplateCommandError(output, context)];
                }
                contents = map({
                    $metadata: deserializeMetadata(output),
                });
                _a = __expectObject;
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.apply(void 0, [_b.sent()]);
                contents.MessageBody = deserializeAws_restJson1MessageBody(data, context);
                return [2, contents];
        }
    });
}); };
var deserializeAws_restJson1UpdatePushTemplateCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, errorCode, _b, parsedBody;
    var _c;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                _a = [__assign({}, output)];
                _c = {};
                return [4, parseErrorBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_c.body = _d.sent(), _c)]));
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "BadRequestException": return [3, 2];
                    case "com.amazonaws.pinpoint#BadRequestException": return [3, 2];
                    case "ForbiddenException": return [3, 4];
                    case "com.amazonaws.pinpoint#ForbiddenException": return [3, 4];
                    case "InternalServerErrorException": return [3, 6];
                    case "com.amazonaws.pinpoint#InternalServerErrorException": return [3, 6];
                    case "MethodNotAllowedException": return [3, 8];
                    case "com.amazonaws.pinpoint#MethodNotAllowedException": return [3, 8];
                    case "NotFoundException": return [3, 10];
                    case "com.amazonaws.pinpoint#NotFoundException": return [3, 10];
                    case "PayloadTooLargeException": return [3, 12];
                    case "com.amazonaws.pinpoint#PayloadTooLargeException": return [3, 12];
                    case "TooManyRequestsException": return [3, 14];
                    case "com.amazonaws.pinpoint#TooManyRequestsException": return [3, 14];
                }
                return [3, 16];
            case 2: return [4, deserializeAws_restJson1BadRequestExceptionResponse(parsedOutput, context)];
            case 3: throw _d.sent();
            case 4: return [4, deserializeAws_restJson1ForbiddenExceptionResponse(parsedOutput, context)];
            case 5: throw _d.sent();
            case 6: return [4, deserializeAws_restJson1InternalServerErrorExceptionResponse(parsedOutput, context)];
            case 7: throw _d.sent();
            case 8: return [4, deserializeAws_restJson1MethodNotAllowedExceptionResponse(parsedOutput, context)];
            case 9: throw _d.sent();
            case 10: return [4, deserializeAws_restJson1NotFoundExceptionResponse(parsedOutput, context)];
            case 11: throw _d.sent();
            case 12: return [4, deserializeAws_restJson1PayloadTooLargeExceptionResponse(parsedOutput, context)];
            case 13: throw _d.sent();
            case 14: return [4, deserializeAws_restJson1TooManyRequestsExceptionResponse(parsedOutput, context)];
            case 15: throw _d.sent();
            case 16:
                parsedBody = parsedOutput.body;
                throwDefaultError({
                    output: output,
                    parsedBody: parsedBody,
                    exceptionCtor: __BaseException,
                    errorCode: errorCode,
                });
                _d.label = 17;
            case 17: return [2];
        }
    });
}); };
export var deserializeAws_restJson1UpdateRecommenderConfigurationCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var contents, data, _a;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                if (output.statusCode !== 200 && output.statusCode >= 300) {
                    return [2, deserializeAws_restJson1UpdateRecommenderConfigurationCommandError(output, context)];
                }
                contents = map({
                    $metadata: deserializeMetadata(output),
                });
                _a = __expectObject;
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.apply(void 0, [_b.sent()]);
                contents.RecommenderConfigurationResponse = deserializeAws_restJson1RecommenderConfigurationResponse(data, context);
                return [2, contents];
        }
    });
}); };
var deserializeAws_restJson1UpdateRecommenderConfigurationCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, errorCode, _b, parsedBody;
    var _c;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                _a = [__assign({}, output)];
                _c = {};
                return [4, parseErrorBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_c.body = _d.sent(), _c)]));
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "BadRequestException": return [3, 2];
                    case "com.amazonaws.pinpoint#BadRequestException": return [3, 2];
                    case "ForbiddenException": return [3, 4];
                    case "com.amazonaws.pinpoint#ForbiddenException": return [3, 4];
                    case "InternalServerErrorException": return [3, 6];
                    case "com.amazonaws.pinpoint#InternalServerErrorException": return [3, 6];
                    case "MethodNotAllowedException": return [3, 8];
                    case "com.amazonaws.pinpoint#MethodNotAllowedException": return [3, 8];
                    case "NotFoundException": return [3, 10];
                    case "com.amazonaws.pinpoint#NotFoundException": return [3, 10];
                    case "PayloadTooLargeException": return [3, 12];
                    case "com.amazonaws.pinpoint#PayloadTooLargeException": return [3, 12];
                    case "TooManyRequestsException": return [3, 14];
                    case "com.amazonaws.pinpoint#TooManyRequestsException": return [3, 14];
                }
                return [3, 16];
            case 2: return [4, deserializeAws_restJson1BadRequestExceptionResponse(parsedOutput, context)];
            case 3: throw _d.sent();
            case 4: return [4, deserializeAws_restJson1ForbiddenExceptionResponse(parsedOutput, context)];
            case 5: throw _d.sent();
            case 6: return [4, deserializeAws_restJson1InternalServerErrorExceptionResponse(parsedOutput, context)];
            case 7: throw _d.sent();
            case 8: return [4, deserializeAws_restJson1MethodNotAllowedExceptionResponse(parsedOutput, context)];
            case 9: throw _d.sent();
            case 10: return [4, deserializeAws_restJson1NotFoundExceptionResponse(parsedOutput, context)];
            case 11: throw _d.sent();
            case 12: return [4, deserializeAws_restJson1PayloadTooLargeExceptionResponse(parsedOutput, context)];
            case 13: throw _d.sent();
            case 14: return [4, deserializeAws_restJson1TooManyRequestsExceptionResponse(parsedOutput, context)];
            case 15: throw _d.sent();
            case 16:
                parsedBody = parsedOutput.body;
                throwDefaultError({
                    output: output,
                    parsedBody: parsedBody,
                    exceptionCtor: __BaseException,
                    errorCode: errorCode,
                });
                _d.label = 17;
            case 17: return [2];
        }
    });
}); };
export var deserializeAws_restJson1UpdateSegmentCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var contents, data, _a;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                if (output.statusCode !== 200 && output.statusCode >= 300) {
                    return [2, deserializeAws_restJson1UpdateSegmentCommandError(output, context)];
                }
                contents = map({
                    $metadata: deserializeMetadata(output),
                });
                _a = __expectObject;
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.apply(void 0, [_b.sent()]);
                contents.SegmentResponse = deserializeAws_restJson1SegmentResponse(data, context);
                return [2, contents];
        }
    });
}); };
var deserializeAws_restJson1UpdateSegmentCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, errorCode, _b, parsedBody;
    var _c;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                _a = [__assign({}, output)];
                _c = {};
                return [4, parseErrorBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_c.body = _d.sent(), _c)]));
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "BadRequestException": return [3, 2];
                    case "com.amazonaws.pinpoint#BadRequestException": return [3, 2];
                    case "ForbiddenException": return [3, 4];
                    case "com.amazonaws.pinpoint#ForbiddenException": return [3, 4];
                    case "InternalServerErrorException": return [3, 6];
                    case "com.amazonaws.pinpoint#InternalServerErrorException": return [3, 6];
                    case "MethodNotAllowedException": return [3, 8];
                    case "com.amazonaws.pinpoint#MethodNotAllowedException": return [3, 8];
                    case "NotFoundException": return [3, 10];
                    case "com.amazonaws.pinpoint#NotFoundException": return [3, 10];
                    case "PayloadTooLargeException": return [3, 12];
                    case "com.amazonaws.pinpoint#PayloadTooLargeException": return [3, 12];
                    case "TooManyRequestsException": return [3, 14];
                    case "com.amazonaws.pinpoint#TooManyRequestsException": return [3, 14];
                }
                return [3, 16];
            case 2: return [4, deserializeAws_restJson1BadRequestExceptionResponse(parsedOutput, context)];
            case 3: throw _d.sent();
            case 4: return [4, deserializeAws_restJson1ForbiddenExceptionResponse(parsedOutput, context)];
            case 5: throw _d.sent();
            case 6: return [4, deserializeAws_restJson1InternalServerErrorExceptionResponse(parsedOutput, context)];
            case 7: throw _d.sent();
            case 8: return [4, deserializeAws_restJson1MethodNotAllowedExceptionResponse(parsedOutput, context)];
            case 9: throw _d.sent();
            case 10: return [4, deserializeAws_restJson1NotFoundExceptionResponse(parsedOutput, context)];
            case 11: throw _d.sent();
            case 12: return [4, deserializeAws_restJson1PayloadTooLargeExceptionResponse(parsedOutput, context)];
            case 13: throw _d.sent();
            case 14: return [4, deserializeAws_restJson1TooManyRequestsExceptionResponse(parsedOutput, context)];
            case 15: throw _d.sent();
            case 16:
                parsedBody = parsedOutput.body;
                throwDefaultError({
                    output: output,
                    parsedBody: parsedBody,
                    exceptionCtor: __BaseException,
                    errorCode: errorCode,
                });
                _d.label = 17;
            case 17: return [2];
        }
    });
}); };
export var deserializeAws_restJson1UpdateSmsChannelCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var contents, data, _a;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                if (output.statusCode !== 200 && output.statusCode >= 300) {
                    return [2, deserializeAws_restJson1UpdateSmsChannelCommandError(output, context)];
                }
                contents = map({
                    $metadata: deserializeMetadata(output),
                });
                _a = __expectObject;
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.apply(void 0, [_b.sent()]);
                contents.SMSChannelResponse = deserializeAws_restJson1SMSChannelResponse(data, context);
                return [2, contents];
        }
    });
}); };
var deserializeAws_restJson1UpdateSmsChannelCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, errorCode, _b, parsedBody;
    var _c;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                _a = [__assign({}, output)];
                _c = {};
                return [4, parseErrorBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_c.body = _d.sent(), _c)]));
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "BadRequestException": return [3, 2];
                    case "com.amazonaws.pinpoint#BadRequestException": return [3, 2];
                    case "ForbiddenException": return [3, 4];
                    case "com.amazonaws.pinpoint#ForbiddenException": return [3, 4];
                    case "InternalServerErrorException": return [3, 6];
                    case "com.amazonaws.pinpoint#InternalServerErrorException": return [3, 6];
                    case "MethodNotAllowedException": return [3, 8];
                    case "com.amazonaws.pinpoint#MethodNotAllowedException": return [3, 8];
                    case "NotFoundException": return [3, 10];
                    case "com.amazonaws.pinpoint#NotFoundException": return [3, 10];
                    case "PayloadTooLargeException": return [3, 12];
                    case "com.amazonaws.pinpoint#PayloadTooLargeException": return [3, 12];
                    case "TooManyRequestsException": return [3, 14];
                    case "com.amazonaws.pinpoint#TooManyRequestsException": return [3, 14];
                }
                return [3, 16];
            case 2: return [4, deserializeAws_restJson1BadRequestExceptionResponse(parsedOutput, context)];
            case 3: throw _d.sent();
            case 4: return [4, deserializeAws_restJson1ForbiddenExceptionResponse(parsedOutput, context)];
            case 5: throw _d.sent();
            case 6: return [4, deserializeAws_restJson1InternalServerErrorExceptionResponse(parsedOutput, context)];
            case 7: throw _d.sent();
            case 8: return [4, deserializeAws_restJson1MethodNotAllowedExceptionResponse(parsedOutput, context)];
            case 9: throw _d.sent();
            case 10: return [4, deserializeAws_restJson1NotFoundExceptionResponse(parsedOutput, context)];
            case 11: throw _d.sent();
            case 12: return [4, deserializeAws_restJson1PayloadTooLargeExceptionResponse(parsedOutput, context)];
            case 13: throw _d.sent();
            case 14: return [4, deserializeAws_restJson1TooManyRequestsExceptionResponse(parsedOutput, context)];
            case 15: throw _d.sent();
            case 16:
                parsedBody = parsedOutput.body;
                throwDefaultError({
                    output: output,
                    parsedBody: parsedBody,
                    exceptionCtor: __BaseException,
                    errorCode: errorCode,
                });
                _d.label = 17;
            case 17: return [2];
        }
    });
}); };
export var deserializeAws_restJson1UpdateSmsTemplateCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var contents, data, _a;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                if (output.statusCode !== 202 && output.statusCode >= 300) {
                    return [2, deserializeAws_restJson1UpdateSmsTemplateCommandError(output, context)];
                }
                contents = map({
                    $metadata: deserializeMetadata(output),
                });
                _a = __expectObject;
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.apply(void 0, [_b.sent()]);
                contents.MessageBody = deserializeAws_restJson1MessageBody(data, context);
                return [2, contents];
        }
    });
}); };
var deserializeAws_restJson1UpdateSmsTemplateCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, errorCode, _b, parsedBody;
    var _c;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                _a = [__assign({}, output)];
                _c = {};
                return [4, parseErrorBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_c.body = _d.sent(), _c)]));
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "BadRequestException": return [3, 2];
                    case "com.amazonaws.pinpoint#BadRequestException": return [3, 2];
                    case "ForbiddenException": return [3, 4];
                    case "com.amazonaws.pinpoint#ForbiddenException": return [3, 4];
                    case "InternalServerErrorException": return [3, 6];
                    case "com.amazonaws.pinpoint#InternalServerErrorException": return [3, 6];
                    case "MethodNotAllowedException": return [3, 8];
                    case "com.amazonaws.pinpoint#MethodNotAllowedException": return [3, 8];
                    case "NotFoundException": return [3, 10];
                    case "com.amazonaws.pinpoint#NotFoundException": return [3, 10];
                    case "PayloadTooLargeException": return [3, 12];
                    case "com.amazonaws.pinpoint#PayloadTooLargeException": return [3, 12];
                    case "TooManyRequestsException": return [3, 14];
                    case "com.amazonaws.pinpoint#TooManyRequestsException": return [3, 14];
                }
                return [3, 16];
            case 2: return [4, deserializeAws_restJson1BadRequestExceptionResponse(parsedOutput, context)];
            case 3: throw _d.sent();
            case 4: return [4, deserializeAws_restJson1ForbiddenExceptionResponse(parsedOutput, context)];
            case 5: throw _d.sent();
            case 6: return [4, deserializeAws_restJson1InternalServerErrorExceptionResponse(parsedOutput, context)];
            case 7: throw _d.sent();
            case 8: return [4, deserializeAws_restJson1MethodNotAllowedExceptionResponse(parsedOutput, context)];
            case 9: throw _d.sent();
            case 10: return [4, deserializeAws_restJson1NotFoundExceptionResponse(parsedOutput, context)];
            case 11: throw _d.sent();
            case 12: return [4, deserializeAws_restJson1PayloadTooLargeExceptionResponse(parsedOutput, context)];
            case 13: throw _d.sent();
            case 14: return [4, deserializeAws_restJson1TooManyRequestsExceptionResponse(parsedOutput, context)];
            case 15: throw _d.sent();
            case 16:
                parsedBody = parsedOutput.body;
                throwDefaultError({
                    output: output,
                    parsedBody: parsedBody,
                    exceptionCtor: __BaseException,
                    errorCode: errorCode,
                });
                _d.label = 17;
            case 17: return [2];
        }
    });
}); };
export var deserializeAws_restJson1UpdateTemplateActiveVersionCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var contents, data, _a;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                if (output.statusCode !== 200 && output.statusCode >= 300) {
                    return [2, deserializeAws_restJson1UpdateTemplateActiveVersionCommandError(output, context)];
                }
                contents = map({
                    $metadata: deserializeMetadata(output),
                });
                _a = __expectObject;
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.apply(void 0, [_b.sent()]);
                contents.MessageBody = deserializeAws_restJson1MessageBody(data, context);
                return [2, contents];
        }
    });
}); };
var deserializeAws_restJson1UpdateTemplateActiveVersionCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, errorCode, _b, parsedBody;
    var _c;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                _a = [__assign({}, output)];
                _c = {};
                return [4, parseErrorBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_c.body = _d.sent(), _c)]));
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "BadRequestException": return [3, 2];
                    case "com.amazonaws.pinpoint#BadRequestException": return [3, 2];
                    case "ForbiddenException": return [3, 4];
                    case "com.amazonaws.pinpoint#ForbiddenException": return [3, 4];
                    case "InternalServerErrorException": return [3, 6];
                    case "com.amazonaws.pinpoint#InternalServerErrorException": return [3, 6];
                    case "MethodNotAllowedException": return [3, 8];
                    case "com.amazonaws.pinpoint#MethodNotAllowedException": return [3, 8];
                    case "NotFoundException": return [3, 10];
                    case "com.amazonaws.pinpoint#NotFoundException": return [3, 10];
                    case "PayloadTooLargeException": return [3, 12];
                    case "com.amazonaws.pinpoint#PayloadTooLargeException": return [3, 12];
                    case "TooManyRequestsException": return [3, 14];
                    case "com.amazonaws.pinpoint#TooManyRequestsException": return [3, 14];
                }
                return [3, 16];
            case 2: return [4, deserializeAws_restJson1BadRequestExceptionResponse(parsedOutput, context)];
            case 3: throw _d.sent();
            case 4: return [4, deserializeAws_restJson1ForbiddenExceptionResponse(parsedOutput, context)];
            case 5: throw _d.sent();
            case 6: return [4, deserializeAws_restJson1InternalServerErrorExceptionResponse(parsedOutput, context)];
            case 7: throw _d.sent();
            case 8: return [4, deserializeAws_restJson1MethodNotAllowedExceptionResponse(parsedOutput, context)];
            case 9: throw _d.sent();
            case 10: return [4, deserializeAws_restJson1NotFoundExceptionResponse(parsedOutput, context)];
            case 11: throw _d.sent();
            case 12: return [4, deserializeAws_restJson1PayloadTooLargeExceptionResponse(parsedOutput, context)];
            case 13: throw _d.sent();
            case 14: return [4, deserializeAws_restJson1TooManyRequestsExceptionResponse(parsedOutput, context)];
            case 15: throw _d.sent();
            case 16:
                parsedBody = parsedOutput.body;
                throwDefaultError({
                    output: output,
                    parsedBody: parsedBody,
                    exceptionCtor: __BaseException,
                    errorCode: errorCode,
                });
                _d.label = 17;
            case 17: return [2];
        }
    });
}); };
export var deserializeAws_restJson1UpdateVoiceChannelCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var contents, data, _a;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                if (output.statusCode !== 200 && output.statusCode >= 300) {
                    return [2, deserializeAws_restJson1UpdateVoiceChannelCommandError(output, context)];
                }
                contents = map({
                    $metadata: deserializeMetadata(output),
                });
                _a = __expectObject;
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.apply(void 0, [_b.sent()]);
                contents.VoiceChannelResponse = deserializeAws_restJson1VoiceChannelResponse(data, context);
                return [2, contents];
        }
    });
}); };
var deserializeAws_restJson1UpdateVoiceChannelCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, errorCode, _b, parsedBody;
    var _c;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                _a = [__assign({}, output)];
                _c = {};
                return [4, parseErrorBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_c.body = _d.sent(), _c)]));
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "BadRequestException": return [3, 2];
                    case "com.amazonaws.pinpoint#BadRequestException": return [3, 2];
                    case "ForbiddenException": return [3, 4];
                    case "com.amazonaws.pinpoint#ForbiddenException": return [3, 4];
                    case "InternalServerErrorException": return [3, 6];
                    case "com.amazonaws.pinpoint#InternalServerErrorException": return [3, 6];
                    case "MethodNotAllowedException": return [3, 8];
                    case "com.amazonaws.pinpoint#MethodNotAllowedException": return [3, 8];
                    case "NotFoundException": return [3, 10];
                    case "com.amazonaws.pinpoint#NotFoundException": return [3, 10];
                    case "PayloadTooLargeException": return [3, 12];
                    case "com.amazonaws.pinpoint#PayloadTooLargeException": return [3, 12];
                    case "TooManyRequestsException": return [3, 14];
                    case "com.amazonaws.pinpoint#TooManyRequestsException": return [3, 14];
                }
                return [3, 16];
            case 2: return [4, deserializeAws_restJson1BadRequestExceptionResponse(parsedOutput, context)];
            case 3: throw _d.sent();
            case 4: return [4, deserializeAws_restJson1ForbiddenExceptionResponse(parsedOutput, context)];
            case 5: throw _d.sent();
            case 6: return [4, deserializeAws_restJson1InternalServerErrorExceptionResponse(parsedOutput, context)];
            case 7: throw _d.sent();
            case 8: return [4, deserializeAws_restJson1MethodNotAllowedExceptionResponse(parsedOutput, context)];
            case 9: throw _d.sent();
            case 10: return [4, deserializeAws_restJson1NotFoundExceptionResponse(parsedOutput, context)];
            case 11: throw _d.sent();
            case 12: return [4, deserializeAws_restJson1PayloadTooLargeExceptionResponse(parsedOutput, context)];
            case 13: throw _d.sent();
            case 14: return [4, deserializeAws_restJson1TooManyRequestsExceptionResponse(parsedOutput, context)];
            case 15: throw _d.sent();
            case 16:
                parsedBody = parsedOutput.body;
                throwDefaultError({
                    output: output,
                    parsedBody: parsedBody,
                    exceptionCtor: __BaseException,
                    errorCode: errorCode,
                });
                _d.label = 17;
            case 17: return [2];
        }
    });
}); };
export var deserializeAws_restJson1UpdateVoiceTemplateCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var contents, data, _a;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                if (output.statusCode !== 202 && output.statusCode >= 300) {
                    return [2, deserializeAws_restJson1UpdateVoiceTemplateCommandError(output, context)];
                }
                contents = map({
                    $metadata: deserializeMetadata(output),
                });
                _a = __expectObject;
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.apply(void 0, [_b.sent()]);
                contents.MessageBody = deserializeAws_restJson1MessageBody(data, context);
                return [2, contents];
        }
    });
}); };
var deserializeAws_restJson1UpdateVoiceTemplateCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, errorCode, _b, parsedBody;
    var _c;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                _a = [__assign({}, output)];
                _c = {};
                return [4, parseErrorBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_c.body = _d.sent(), _c)]));
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "BadRequestException": return [3, 2];
                    case "com.amazonaws.pinpoint#BadRequestException": return [3, 2];
                    case "ForbiddenException": return [3, 4];
                    case "com.amazonaws.pinpoint#ForbiddenException": return [3, 4];
                    case "InternalServerErrorException": return [3, 6];
                    case "com.amazonaws.pinpoint#InternalServerErrorException": return [3, 6];
                    case "MethodNotAllowedException": return [3, 8];
                    case "com.amazonaws.pinpoint#MethodNotAllowedException": return [3, 8];
                    case "NotFoundException": return [3, 10];
                    case "com.amazonaws.pinpoint#NotFoundException": return [3, 10];
                    case "PayloadTooLargeException": return [3, 12];
                    case "com.amazonaws.pinpoint#PayloadTooLargeException": return [3, 12];
                    case "TooManyRequestsException": return [3, 14];
                    case "com.amazonaws.pinpoint#TooManyRequestsException": return [3, 14];
                }
                return [3, 16];
            case 2: return [4, deserializeAws_restJson1BadRequestExceptionResponse(parsedOutput, context)];
            case 3: throw _d.sent();
            case 4: return [4, deserializeAws_restJson1ForbiddenExceptionResponse(parsedOutput, context)];
            case 5: throw _d.sent();
            case 6: return [4, deserializeAws_restJson1InternalServerErrorExceptionResponse(parsedOutput, context)];
            case 7: throw _d.sent();
            case 8: return [4, deserializeAws_restJson1MethodNotAllowedExceptionResponse(parsedOutput, context)];
            case 9: throw _d.sent();
            case 10: return [4, deserializeAws_restJson1NotFoundExceptionResponse(parsedOutput, context)];
            case 11: throw _d.sent();
            case 12: return [4, deserializeAws_restJson1PayloadTooLargeExceptionResponse(parsedOutput, context)];
            case 13: throw _d.sent();
            case 14: return [4, deserializeAws_restJson1TooManyRequestsExceptionResponse(parsedOutput, context)];
            case 15: throw _d.sent();
            case 16:
                parsedBody = parsedOutput.body;
                throwDefaultError({
                    output: output,
                    parsedBody: parsedBody,
                    exceptionCtor: __BaseException,
                    errorCode: errorCode,
                });
                _d.label = 17;
            case 17: return [2];
        }
    });
}); };
export var deserializeAws_restJson1VerifyOTPMessageCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var contents, data, _a;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                if (output.statusCode !== 200 && output.statusCode >= 300) {
                    return [2, deserializeAws_restJson1VerifyOTPMessageCommandError(output, context)];
                }
                contents = map({
                    $metadata: deserializeMetadata(output),
                });
                _a = __expectObject;
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.apply(void 0, [_b.sent()]);
                contents.VerificationResponse = deserializeAws_restJson1VerificationResponse(data, context);
                return [2, contents];
        }
    });
}); };
var deserializeAws_restJson1VerifyOTPMessageCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, errorCode, _b, parsedBody;
    var _c;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                _a = [__assign({}, output)];
                _c = {};
                return [4, parseErrorBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_c.body = _d.sent(), _c)]));
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "BadRequestException": return [3, 2];
                    case "com.amazonaws.pinpoint#BadRequestException": return [3, 2];
                    case "ForbiddenException": return [3, 4];
                    case "com.amazonaws.pinpoint#ForbiddenException": return [3, 4];
                    case "InternalServerErrorException": return [3, 6];
                    case "com.amazonaws.pinpoint#InternalServerErrorException": return [3, 6];
                    case "MethodNotAllowedException": return [3, 8];
                    case "com.amazonaws.pinpoint#MethodNotAllowedException": return [3, 8];
                    case "NotFoundException": return [3, 10];
                    case "com.amazonaws.pinpoint#NotFoundException": return [3, 10];
                    case "PayloadTooLargeException": return [3, 12];
                    case "com.amazonaws.pinpoint#PayloadTooLargeException": return [3, 12];
                    case "TooManyRequestsException": return [3, 14];
                    case "com.amazonaws.pinpoint#TooManyRequestsException": return [3, 14];
                }
                return [3, 16];
            case 2: return [4, deserializeAws_restJson1BadRequestExceptionResponse(parsedOutput, context)];
            case 3: throw _d.sent();
            case 4: return [4, deserializeAws_restJson1ForbiddenExceptionResponse(parsedOutput, context)];
            case 5: throw _d.sent();
            case 6: return [4, deserializeAws_restJson1InternalServerErrorExceptionResponse(parsedOutput, context)];
            case 7: throw _d.sent();
            case 8: return [4, deserializeAws_restJson1MethodNotAllowedExceptionResponse(parsedOutput, context)];
            case 9: throw _d.sent();
            case 10: return [4, deserializeAws_restJson1NotFoundExceptionResponse(parsedOutput, context)];
            case 11: throw _d.sent();
            case 12: return [4, deserializeAws_restJson1PayloadTooLargeExceptionResponse(parsedOutput, context)];
            case 13: throw _d.sent();
            case 14: return [4, deserializeAws_restJson1TooManyRequestsExceptionResponse(parsedOutput, context)];
            case 15: throw _d.sent();
            case 16:
                parsedBody = parsedOutput.body;
                throwDefaultError({
                    output: output,
                    parsedBody: parsedBody,
                    exceptionCtor: __BaseException,
                    errorCode: errorCode,
                });
                _d.label = 17;
            case 17: return [2];
        }
    });
}); };
var map = __map;
var deserializeAws_restJson1BadRequestExceptionResponse = function (parsedOutput, context) { return __awaiter(void 0, void 0, void 0, function () {
    var contents, data, exception;
    return __generator(this, function (_a) {
        contents = map({});
        data = parsedOutput.body;
        if (data.Message != null) {
            contents.Message = __expectString(data.Message);
        }
        if (data.RequestID != null) {
            contents.RequestID = __expectString(data.RequestID);
        }
        exception = new BadRequestException(__assign({ $metadata: deserializeMetadata(parsedOutput) }, contents));
        return [2, __decorateServiceException(exception, parsedOutput.body)];
    });
}); };
var deserializeAws_restJson1ConflictExceptionResponse = function (parsedOutput, context) { return __awaiter(void 0, void 0, void 0, function () {
    var contents, data, exception;
    return __generator(this, function (_a) {
        contents = map({});
        data = parsedOutput.body;
        if (data.Message != null) {
            contents.Message = __expectString(data.Message);
        }
        if (data.RequestID != null) {
            contents.RequestID = __expectString(data.RequestID);
        }
        exception = new ConflictException(__assign({ $metadata: deserializeMetadata(parsedOutput) }, contents));
        return [2, __decorateServiceException(exception, parsedOutput.body)];
    });
}); };
var deserializeAws_restJson1ForbiddenExceptionResponse = function (parsedOutput, context) { return __awaiter(void 0, void 0, void 0, function () {
    var contents, data, exception;
    return __generator(this, function (_a) {
        contents = map({});
        data = parsedOutput.body;
        if (data.Message != null) {
            contents.Message = __expectString(data.Message);
        }
        if (data.RequestID != null) {
            contents.RequestID = __expectString(data.RequestID);
        }
        exception = new ForbiddenException(__assign({ $metadata: deserializeMetadata(parsedOutput) }, contents));
        return [2, __decorateServiceException(exception, parsedOutput.body)];
    });
}); };
var deserializeAws_restJson1InternalServerErrorExceptionResponse = function (parsedOutput, context) { return __awaiter(void 0, void 0, void 0, function () {
    var contents, data, exception;
    return __generator(this, function (_a) {
        contents = map({});
        data = parsedOutput.body;
        if (data.Message != null) {
            contents.Message = __expectString(data.Message);
        }
        if (data.RequestID != null) {
            contents.RequestID = __expectString(data.RequestID);
        }
        exception = new InternalServerErrorException(__assign({ $metadata: deserializeMetadata(parsedOutput) }, contents));
        return [2, __decorateServiceException(exception, parsedOutput.body)];
    });
}); };
var deserializeAws_restJson1MethodNotAllowedExceptionResponse = function (parsedOutput, context) { return __awaiter(void 0, void 0, void 0, function () {
    var contents, data, exception;
    return __generator(this, function (_a) {
        contents = map({});
        data = parsedOutput.body;
        if (data.Message != null) {
            contents.Message = __expectString(data.Message);
        }
        if (data.RequestID != null) {
            contents.RequestID = __expectString(data.RequestID);
        }
        exception = new MethodNotAllowedException(__assign({ $metadata: deserializeMetadata(parsedOutput) }, contents));
        return [2, __decorateServiceException(exception, parsedOutput.body)];
    });
}); };
var deserializeAws_restJson1NotFoundExceptionResponse = function (parsedOutput, context) { return __awaiter(void 0, void 0, void 0, function () {
    var contents, data, exception;
    return __generator(this, function (_a) {
        contents = map({});
        data = parsedOutput.body;
        if (data.Message != null) {
            contents.Message = __expectString(data.Message);
        }
        if (data.RequestID != null) {
            contents.RequestID = __expectString(data.RequestID);
        }
        exception = new NotFoundException(__assign({ $metadata: deserializeMetadata(parsedOutput) }, contents));
        return [2, __decorateServiceException(exception, parsedOutput.body)];
    });
}); };
var deserializeAws_restJson1PayloadTooLargeExceptionResponse = function (parsedOutput, context) { return __awaiter(void 0, void 0, void 0, function () {
    var contents, data, exception;
    return __generator(this, function (_a) {
        contents = map({});
        data = parsedOutput.body;
        if (data.Message != null) {
            contents.Message = __expectString(data.Message);
        }
        if (data.RequestID != null) {
            contents.RequestID = __expectString(data.RequestID);
        }
        exception = new PayloadTooLargeException(__assign({ $metadata: deserializeMetadata(parsedOutput) }, contents));
        return [2, __decorateServiceException(exception, parsedOutput.body)];
    });
}); };
var deserializeAws_restJson1TooManyRequestsExceptionResponse = function (parsedOutput, context) { return __awaiter(void 0, void 0, void 0, function () {
    var contents, data, exception;
    return __generator(this, function (_a) {
        contents = map({});
        data = parsedOutput.body;
        if (data.Message != null) {
            contents.Message = __expectString(data.Message);
        }
        if (data.RequestID != null) {
            contents.RequestID = __expectString(data.RequestID);
        }
        exception = new TooManyRequestsException(__assign({ $metadata: deserializeMetadata(parsedOutput) }, contents));
        return [2, __decorateServiceException(exception, parsedOutput.body)];
    });
}); };
var serializeAws_restJson1Activity = function (input, context) {
    return __assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign({}, (input.CUSTOM != null && { CUSTOM: serializeAws_restJson1CustomMessageActivity(input.CUSTOM, context) })), (input.ConditionalSplit != null && {
        ConditionalSplit: serializeAws_restJson1ConditionalSplitActivity(input.ConditionalSplit, context),
    })), (input.ContactCenter != null && {
        ContactCenter: serializeAws_restJson1ContactCenterActivity(input.ContactCenter, context),
    })), (input.Description != null && { Description: input.Description })), (input.EMAIL != null && { EMAIL: serializeAws_restJson1EmailMessageActivity(input.EMAIL, context) })), (input.Holdout != null && { Holdout: serializeAws_restJson1HoldoutActivity(input.Holdout, context) })), (input.MultiCondition != null && {
        MultiCondition: serializeAws_restJson1MultiConditionalSplitActivity(input.MultiCondition, context),
    })), (input.PUSH != null && { PUSH: serializeAws_restJson1PushMessageActivity(input.PUSH, context) })), (input.RandomSplit != null && {
        RandomSplit: serializeAws_restJson1RandomSplitActivity(input.RandomSplit, context),
    })), (input.SMS != null && { SMS: serializeAws_restJson1SMSMessageActivity(input.SMS, context) })), (input.Wait != null && { Wait: serializeAws_restJson1WaitActivity(input.Wait, context) }));
};
var serializeAws_restJson1AddressConfiguration = function (input, context) {
    return __assign(__assign(__assign(__assign(__assign(__assign({}, (input.BodyOverride != null && { BodyOverride: input.BodyOverride })), (input.ChannelType != null && { ChannelType: input.ChannelType })), (input.Context != null && { Context: serializeAws_restJson1MapOf__string(input.Context, context) })), (input.RawContent != null && { RawContent: input.RawContent })), (input.Substitutions != null && {
        Substitutions: serializeAws_restJson1MapOfListOf__string(input.Substitutions, context),
    })), (input.TitleOverride != null && { TitleOverride: input.TitleOverride }));
};
var serializeAws_restJson1ADMChannelRequest = function (input, context) {
    return __assign(__assign(__assign({}, (input.ClientId != null && { ClientId: input.ClientId })), (input.ClientSecret != null && { ClientSecret: input.ClientSecret })), (input.Enabled != null && { Enabled: input.Enabled }));
};
var serializeAws_restJson1ADMMessage = function (input, context) {
    return __assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign({}, (input.Action != null && { Action: input.Action })), (input.Body != null && { Body: input.Body })), (input.ConsolidationKey != null && { ConsolidationKey: input.ConsolidationKey })), (input.Data != null && { Data: serializeAws_restJson1MapOf__string(input.Data, context) })), (input.ExpiresAfter != null && { ExpiresAfter: input.ExpiresAfter })), (input.IconReference != null && { IconReference: input.IconReference })), (input.ImageIconUrl != null && { ImageIconUrl: input.ImageIconUrl })), (input.ImageUrl != null && { ImageUrl: input.ImageUrl })), (input.MD5 != null && { MD5: input.MD5 })), (input.RawContent != null && { RawContent: input.RawContent })), (input.SilentPush != null && { SilentPush: input.SilentPush })), (input.SmallImageIconUrl != null && { SmallImageIconUrl: input.SmallImageIconUrl })), (input.Sound != null && { Sound: input.Sound })), (input.Substitutions != null && {
        Substitutions: serializeAws_restJson1MapOfListOf__string(input.Substitutions, context),
    })), (input.Title != null && { Title: input.Title })), (input.Url != null && { Url: input.Url }));
};
var serializeAws_restJson1AndroidPushNotificationTemplate = function (input, context) {
    return __assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign({}, (input.Action != null && { Action: input.Action })), (input.Body != null && { Body: input.Body })), (input.ImageIconUrl != null && { ImageIconUrl: input.ImageIconUrl })), (input.ImageUrl != null && { ImageUrl: input.ImageUrl })), (input.RawContent != null && { RawContent: input.RawContent })), (input.SmallImageIconUrl != null && { SmallImageIconUrl: input.SmallImageIconUrl })), (input.Sound != null && { Sound: input.Sound })), (input.Title != null && { Title: input.Title })), (input.Url != null && { Url: input.Url }));
};
var serializeAws_restJson1APNSChannelRequest = function (input, context) {
    return __assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign({}, (input.BundleId != null && { BundleId: input.BundleId })), (input.Certificate != null && { Certificate: input.Certificate })), (input.DefaultAuthenticationMethod != null && {
        DefaultAuthenticationMethod: input.DefaultAuthenticationMethod,
    })), (input.Enabled != null && { Enabled: input.Enabled })), (input.PrivateKey != null && { PrivateKey: input.PrivateKey })), (input.TeamId != null && { TeamId: input.TeamId })), (input.TokenKey != null && { TokenKey: input.TokenKey })), (input.TokenKeyId != null && { TokenKeyId: input.TokenKeyId }));
};
var serializeAws_restJson1APNSMessage = function (input, context) {
    return __assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign({}, (input.APNSPushType != null && { APNSPushType: input.APNSPushType })), (input.Action != null && { Action: input.Action })), (input.Badge != null && { Badge: input.Badge })), (input.Body != null && { Body: input.Body })), (input.Category != null && { Category: input.Category })), (input.CollapseId != null && { CollapseId: input.CollapseId })), (input.Data != null && { Data: serializeAws_restJson1MapOf__string(input.Data, context) })), (input.MediaUrl != null && { MediaUrl: input.MediaUrl })), (input.PreferredAuthenticationMethod != null && {
        PreferredAuthenticationMethod: input.PreferredAuthenticationMethod,
    })), (input.Priority != null && { Priority: input.Priority })), (input.RawContent != null && { RawContent: input.RawContent })), (input.SilentPush != null && { SilentPush: input.SilentPush })), (input.Sound != null && { Sound: input.Sound })), (input.Substitutions != null && {
        Substitutions: serializeAws_restJson1MapOfListOf__string(input.Substitutions, context),
    })), (input.ThreadId != null && { ThreadId: input.ThreadId })), (input.TimeToLive != null && { TimeToLive: input.TimeToLive })), (input.Title != null && { Title: input.Title })), (input.Url != null && { Url: input.Url }));
};
var serializeAws_restJson1APNSPushNotificationTemplate = function (input, context) {
    return __assign(__assign(__assign(__assign(__assign(__assign(__assign({}, (input.Action != null && { Action: input.Action })), (input.Body != null && { Body: input.Body })), (input.MediaUrl != null && { MediaUrl: input.MediaUrl })), (input.RawContent != null && { RawContent: input.RawContent })), (input.Sound != null && { Sound: input.Sound })), (input.Title != null && { Title: input.Title })), (input.Url != null && { Url: input.Url }));
};
var serializeAws_restJson1APNSSandboxChannelRequest = function (input, context) {
    return __assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign({}, (input.BundleId != null && { BundleId: input.BundleId })), (input.Certificate != null && { Certificate: input.Certificate })), (input.DefaultAuthenticationMethod != null && {
        DefaultAuthenticationMethod: input.DefaultAuthenticationMethod,
    })), (input.Enabled != null && { Enabled: input.Enabled })), (input.PrivateKey != null && { PrivateKey: input.PrivateKey })), (input.TeamId != null && { TeamId: input.TeamId })), (input.TokenKey != null && { TokenKey: input.TokenKey })), (input.TokenKeyId != null && { TokenKeyId: input.TokenKeyId }));
};
var serializeAws_restJson1APNSVoipChannelRequest = function (input, context) {
    return __assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign({}, (input.BundleId != null && { BundleId: input.BundleId })), (input.Certificate != null && { Certificate: input.Certificate })), (input.DefaultAuthenticationMethod != null && {
        DefaultAuthenticationMethod: input.DefaultAuthenticationMethod,
    })), (input.Enabled != null && { Enabled: input.Enabled })), (input.PrivateKey != null && { PrivateKey: input.PrivateKey })), (input.TeamId != null && { TeamId: input.TeamId })), (input.TokenKey != null && { TokenKey: input.TokenKey })), (input.TokenKeyId != null && { TokenKeyId: input.TokenKeyId }));
};
var serializeAws_restJson1APNSVoipSandboxChannelRequest = function (input, context) {
    return __assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign({}, (input.BundleId != null && { BundleId: input.BundleId })), (input.Certificate != null && { Certificate: input.Certificate })), (input.DefaultAuthenticationMethod != null && {
        DefaultAuthenticationMethod: input.DefaultAuthenticationMethod,
    })), (input.Enabled != null && { Enabled: input.Enabled })), (input.PrivateKey != null && { PrivateKey: input.PrivateKey })), (input.TeamId != null && { TeamId: input.TeamId })), (input.TokenKey != null && { TokenKey: input.TokenKey })), (input.TokenKeyId != null && { TokenKeyId: input.TokenKeyId }));
};
var serializeAws_restJson1AttributeDimension = function (input, context) {
    return __assign(__assign({}, (input.AttributeType != null && { AttributeType: input.AttributeType })), (input.Values != null && { Values: serializeAws_restJson1ListOf__string(input.Values, context) }));
};
var serializeAws_restJson1BaiduChannelRequest = function (input, context) {
    return __assign(__assign(__assign({}, (input.ApiKey != null && { ApiKey: input.ApiKey })), (input.Enabled != null && { Enabled: input.Enabled })), (input.SecretKey != null && { SecretKey: input.SecretKey }));
};
var serializeAws_restJson1BaiduMessage = function (input, context) {
    return __assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign({}, (input.Action != null && { Action: input.Action })), (input.Body != null && { Body: input.Body })), (input.Data != null && { Data: serializeAws_restJson1MapOf__string(input.Data, context) })), (input.IconReference != null && { IconReference: input.IconReference })), (input.ImageIconUrl != null && { ImageIconUrl: input.ImageIconUrl })), (input.ImageUrl != null && { ImageUrl: input.ImageUrl })), (input.RawContent != null && { RawContent: input.RawContent })), (input.SilentPush != null && { SilentPush: input.SilentPush })), (input.SmallImageIconUrl != null && { SmallImageIconUrl: input.SmallImageIconUrl })), (input.Sound != null && { Sound: input.Sound })), (input.Substitutions != null && {
        Substitutions: serializeAws_restJson1MapOfListOf__string(input.Substitutions, context),
    })), (input.TimeToLive != null && { TimeToLive: input.TimeToLive })), (input.Title != null && { Title: input.Title })), (input.Url != null && { Url: input.Url }));
};
var serializeAws_restJson1CampaignCustomMessage = function (input, context) {
    return __assign({}, (input.Data != null && { Data: input.Data }));
};
var serializeAws_restJson1CampaignEmailMessage = function (input, context) {
    return __assign(__assign(__assign(__assign({}, (input.Body != null && { Body: input.Body })), (input.FromAddress != null && { FromAddress: input.FromAddress })), (input.HtmlBody != null && { HtmlBody: input.HtmlBody })), (input.Title != null && { Title: input.Title }));
};
var serializeAws_restJson1CampaignEventFilter = function (input, context) {
    return __assign(__assign({}, (input.Dimensions != null && { Dimensions: serializeAws_restJson1EventDimensions(input.Dimensions, context) })), (input.FilterType != null && { FilterType: input.FilterType }));
};
var serializeAws_restJson1CampaignHook = function (input, context) {
    return __assign(__assign(__assign({}, (input.LambdaFunctionName != null && { LambdaFunctionName: input.LambdaFunctionName })), (input.Mode != null && { Mode: input.Mode })), (input.WebUrl != null && { WebUrl: input.WebUrl }));
};
var serializeAws_restJson1CampaignInAppMessage = function (input, context) {
    return __assign(__assign(__assign(__assign({}, (input.Body != null && { Body: input.Body })), (input.Content != null && { Content: serializeAws_restJson1ListOfInAppMessageContent(input.Content, context) })), (input.CustomConfig != null && {
        CustomConfig: serializeAws_restJson1MapOf__string(input.CustomConfig, context),
    })), (input.Layout != null && { Layout: input.Layout }));
};
var serializeAws_restJson1CampaignLimits = function (input, context) {
    return __assign(__assign(__assign(__assign(__assign({}, (input.Daily != null && { Daily: input.Daily })), (input.MaximumDuration != null && { MaximumDuration: input.MaximumDuration })), (input.MessagesPerSecond != null && { MessagesPerSecond: input.MessagesPerSecond })), (input.Session != null && { Session: input.Session })), (input.Total != null && { Total: input.Total }));
};
var serializeAws_restJson1CampaignSmsMessage = function (input, context) {
    return __assign(__assign(__assign(__assign(__assign(__assign({}, (input.Body != null && { Body: input.Body })), (input.EntityId != null && { EntityId: input.EntityId })), (input.MessageType != null && { MessageType: input.MessageType })), (input.OriginationNumber != null && { OriginationNumber: input.OriginationNumber })), (input.SenderId != null && { SenderId: input.SenderId })), (input.TemplateId != null && { TemplateId: input.TemplateId }));
};
var serializeAws_restJson1ClosedDays = function (input, context) {
    return __assign(__assign(__assign(__assign(__assign({}, (input.CUSTOM != null && { CUSTOM: serializeAws_restJson1ListOfClosedDaysRules(input.CUSTOM, context) })), (input.EMAIL != null && { EMAIL: serializeAws_restJson1ListOfClosedDaysRules(input.EMAIL, context) })), (input.PUSH != null && { PUSH: serializeAws_restJson1ListOfClosedDaysRules(input.PUSH, context) })), (input.SMS != null && { SMS: serializeAws_restJson1ListOfClosedDaysRules(input.SMS, context) })), (input.VOICE != null && { VOICE: serializeAws_restJson1ListOfClosedDaysRules(input.VOICE, context) }));
};
var serializeAws_restJson1ClosedDaysRule = function (input, context) {
    return __assign(__assign(__assign({}, (input.EndDateTime != null && { EndDateTime: input.EndDateTime })), (input.Name != null && { Name: input.Name })), (input.StartDateTime != null && { StartDateTime: input.StartDateTime }));
};
var serializeAws_restJson1Condition = function (input, context) {
    return __assign(__assign({}, (input.Conditions != null && {
        Conditions: serializeAws_restJson1ListOfSimpleCondition(input.Conditions, context),
    })), (input.Operator != null && { Operator: input.Operator }));
};
var serializeAws_restJson1ConditionalSplitActivity = function (input, context) {
    return __assign(__assign(__assign(__assign({}, (input.Condition != null && { Condition: serializeAws_restJson1Condition(input.Condition, context) })), (input.EvaluationWaitTime != null && {
        EvaluationWaitTime: serializeAws_restJson1WaitTime(input.EvaluationWaitTime, context),
    })), (input.FalseActivity != null && { FalseActivity: input.FalseActivity })), (input.TrueActivity != null && { TrueActivity: input.TrueActivity }));
};
var serializeAws_restJson1ContactCenterActivity = function (input, context) {
    return __assign({}, (input.NextActivity != null && { NextActivity: input.NextActivity }));
};
var serializeAws_restJson1CreateApplicationRequest = function (input, context) {
    return __assign(__assign({}, (input.Name != null && { Name: input.Name })), (input.tags != null && { tags: serializeAws_restJson1MapOf__string(input.tags, context) }));
};
var serializeAws_restJson1CreateRecommenderConfigurationShape = function (input, context) {
    return __assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign({}, (input.Attributes != null && { Attributes: serializeAws_restJson1MapOf__string(input.Attributes, context) })), (input.Description != null && { Description: input.Description })), (input.Name != null && { Name: input.Name })), (input.RecommendationProviderIdType != null && {
        RecommendationProviderIdType: input.RecommendationProviderIdType,
    })), (input.RecommendationProviderRoleArn != null && {
        RecommendationProviderRoleArn: input.RecommendationProviderRoleArn,
    })), (input.RecommendationProviderUri != null && { RecommendationProviderUri: input.RecommendationProviderUri })), (input.RecommendationTransformerUri != null && {
        RecommendationTransformerUri: input.RecommendationTransformerUri,
    })), (input.RecommendationsDisplayName != null && { RecommendationsDisplayName: input.RecommendationsDisplayName })), (input.RecommendationsPerMessage != null && { RecommendationsPerMessage: input.RecommendationsPerMessage }));
};
var serializeAws_restJson1CustomDeliveryConfiguration = function (input, context) {
    return __assign(__assign({}, (input.DeliveryUri != null && { DeliveryUri: input.DeliveryUri })), (input.EndpointTypes != null && {
        EndpointTypes: serializeAws_restJson1ListOf__EndpointTypesElement(input.EndpointTypes, context),
    }));
};
var serializeAws_restJson1CustomMessageActivity = function (input, context) {
    return __assign(__assign(__assign(__assign(__assign(__assign({}, (input.DeliveryUri != null && { DeliveryUri: input.DeliveryUri })), (input.EndpointTypes != null && {
        EndpointTypes: serializeAws_restJson1ListOf__EndpointTypesElement(input.EndpointTypes, context),
    })), (input.MessageConfig != null && {
        MessageConfig: serializeAws_restJson1JourneyCustomMessage(input.MessageConfig, context),
    })), (input.NextActivity != null && { NextActivity: input.NextActivity })), (input.TemplateName != null && { TemplateName: input.TemplateName })), (input.TemplateVersion != null && { TemplateVersion: input.TemplateVersion }));
};
var serializeAws_restJson1DefaultButtonConfiguration = function (input, context) {
    return __assign(__assign(__assign(__assign(__assign(__assign({}, (input.BackgroundColor != null && { BackgroundColor: input.BackgroundColor })), (input.BorderRadius != null && { BorderRadius: input.BorderRadius })), (input.ButtonAction != null && { ButtonAction: input.ButtonAction })), (input.Link != null && { Link: input.Link })), (input.Text != null && { Text: input.Text })), (input.TextColor != null && { TextColor: input.TextColor }));
};
var serializeAws_restJson1DefaultMessage = function (input, context) {
    return __assign(__assign({}, (input.Body != null && { Body: input.Body })), (input.Substitutions != null && {
        Substitutions: serializeAws_restJson1MapOfListOf__string(input.Substitutions, context),
    }));
};
var serializeAws_restJson1DefaultPushNotificationMessage = function (input, context) {
    return __assign(__assign(__assign(__assign(__assign(__assign(__assign({}, (input.Action != null && { Action: input.Action })), (input.Body != null && { Body: input.Body })), (input.Data != null && { Data: serializeAws_restJson1MapOf__string(input.Data, context) })), (input.SilentPush != null && { SilentPush: input.SilentPush })), (input.Substitutions != null && {
        Substitutions: serializeAws_restJson1MapOfListOf__string(input.Substitutions, context),
    })), (input.Title != null && { Title: input.Title })), (input.Url != null && { Url: input.Url }));
};
var serializeAws_restJson1DefaultPushNotificationTemplate = function (input, context) {
    return __assign(__assign(__assign(__assign(__assign({}, (input.Action != null && { Action: input.Action })), (input.Body != null && { Body: input.Body })), (input.Sound != null && { Sound: input.Sound })), (input.Title != null && { Title: input.Title })), (input.Url != null && { Url: input.Url }));
};
var serializeAws_restJson1DirectMessageConfiguration = function (input, context) {
    return __assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign({}, (input.ADMMessage != null && { ADMMessage: serializeAws_restJson1ADMMessage(input.ADMMessage, context) })), (input.APNSMessage != null && { APNSMessage: serializeAws_restJson1APNSMessage(input.APNSMessage, context) })), (input.BaiduMessage != null && {
        BaiduMessage: serializeAws_restJson1BaiduMessage(input.BaiduMessage, context),
    })), (input.DefaultMessage != null && {
        DefaultMessage: serializeAws_restJson1DefaultMessage(input.DefaultMessage, context),
    })), (input.DefaultPushNotificationMessage != null && {
        DefaultPushNotificationMessage: serializeAws_restJson1DefaultPushNotificationMessage(input.DefaultPushNotificationMessage, context),
    })), (input.EmailMessage != null && {
        EmailMessage: serializeAws_restJson1EmailMessage(input.EmailMessage, context),
    })), (input.GCMMessage != null && { GCMMessage: serializeAws_restJson1GCMMessage(input.GCMMessage, context) })), (input.SMSMessage != null && { SMSMessage: serializeAws_restJson1SMSMessage(input.SMSMessage, context) })), (input.VoiceMessage != null && {
        VoiceMessage: serializeAws_restJson1VoiceMessage(input.VoiceMessage, context),
    }));
};
var serializeAws_restJson1EmailChannelRequest = function (input, context) {
    return __assign(__assign(__assign(__assign(__assign({}, (input.ConfigurationSet != null && { ConfigurationSet: input.ConfigurationSet })), (input.Enabled != null && { Enabled: input.Enabled })), (input.FromAddress != null && { FromAddress: input.FromAddress })), (input.Identity != null && { Identity: input.Identity })), (input.RoleArn != null && { RoleArn: input.RoleArn }));
};
var serializeAws_restJson1EmailMessage = function (input, context) {
    return __assign(__assign(__assign(__assign(__assign(__assign(__assign({}, (input.Body != null && { Body: input.Body })), (input.FeedbackForwardingAddress != null && { FeedbackForwardingAddress: input.FeedbackForwardingAddress })), (input.FromAddress != null && { FromAddress: input.FromAddress })), (input.RawEmail != null && { RawEmail: serializeAws_restJson1RawEmail(input.RawEmail, context) })), (input.ReplyToAddresses != null && {
        ReplyToAddresses: serializeAws_restJson1ListOf__string(input.ReplyToAddresses, context),
    })), (input.SimpleEmail != null && { SimpleEmail: serializeAws_restJson1SimpleEmail(input.SimpleEmail, context) })), (input.Substitutions != null && {
        Substitutions: serializeAws_restJson1MapOfListOf__string(input.Substitutions, context),
    }));
};
var serializeAws_restJson1EmailMessageActivity = function (input, context) {
    return __assign(__assign(__assign(__assign({}, (input.MessageConfig != null && {
        MessageConfig: serializeAws_restJson1JourneyEmailMessage(input.MessageConfig, context),
    })), (input.NextActivity != null && { NextActivity: input.NextActivity })), (input.TemplateName != null && { TemplateName: input.TemplateName })), (input.TemplateVersion != null && { TemplateVersion: input.TemplateVersion }));
};
var serializeAws_restJson1EmailTemplateRequest = function (input, context) {
    return __assign(__assign(__assign(__assign(__assign(__assign(__assign({}, (input.DefaultSubstitutions != null && { DefaultSubstitutions: input.DefaultSubstitutions })), (input.HtmlPart != null && { HtmlPart: input.HtmlPart })), (input.RecommenderId != null && { RecommenderId: input.RecommenderId })), (input.Subject != null && { Subject: input.Subject })), (input.TemplateDescription != null && { TemplateDescription: input.TemplateDescription })), (input.TextPart != null && { TextPart: input.TextPart })), (input.tags != null && { tags: serializeAws_restJson1MapOf__string(input.tags, context) }));
};
var serializeAws_restJson1EndpointBatchItem = function (input, context) {
    return __assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign({}, (input.Address != null && { Address: input.Address })), (input.Attributes != null && {
        Attributes: serializeAws_restJson1MapOfListOf__string(input.Attributes, context),
    })), (input.ChannelType != null && { ChannelType: input.ChannelType })), (input.Demographic != null && {
        Demographic: serializeAws_restJson1EndpointDemographic(input.Demographic, context),
    })), (input.EffectiveDate != null && { EffectiveDate: input.EffectiveDate })), (input.EndpointStatus != null && { EndpointStatus: input.EndpointStatus })), (input.Id != null && { Id: input.Id })), (input.Location != null && { Location: serializeAws_restJson1EndpointLocation(input.Location, context) })), (input.Metrics != null && { Metrics: serializeAws_restJson1MapOf__double(input.Metrics, context) })), (input.OptOut != null && { OptOut: input.OptOut })), (input.RequestId != null && { RequestId: input.RequestId })), (input.User != null && { User: serializeAws_restJson1EndpointUser(input.User, context) }));
};
var serializeAws_restJson1EndpointBatchRequest = function (input, context) {
    return __assign({}, (input.Item != null && { Item: serializeAws_restJson1ListOfEndpointBatchItem(input.Item, context) }));
};
var serializeAws_restJson1EndpointDemographic = function (input, context) {
    return __assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign({}, (input.AppVersion != null && { AppVersion: input.AppVersion })), (input.Locale != null && { Locale: input.Locale })), (input.Make != null && { Make: input.Make })), (input.Model != null && { Model: input.Model })), (input.ModelVersion != null && { ModelVersion: input.ModelVersion })), (input.Platform != null && { Platform: input.Platform })), (input.PlatformVersion != null && { PlatformVersion: input.PlatformVersion })), (input.Timezone != null && { Timezone: input.Timezone }));
};
var serializeAws_restJson1EndpointLocation = function (input, context) {
    return __assign(__assign(__assign(__assign(__assign(__assign({}, (input.City != null && { City: input.City })), (input.Country != null && { Country: input.Country })), (input.Latitude != null && { Latitude: __serializeFloat(input.Latitude) })), (input.Longitude != null && { Longitude: __serializeFloat(input.Longitude) })), (input.PostalCode != null && { PostalCode: input.PostalCode })), (input.Region != null && { Region: input.Region }));
};
var serializeAws_restJson1EndpointRequest = function (input, context) {
    return __assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign({}, (input.Address != null && { Address: input.Address })), (input.Attributes != null && {
        Attributes: serializeAws_restJson1MapOfListOf__string(input.Attributes, context),
    })), (input.ChannelType != null && { ChannelType: input.ChannelType })), (input.Demographic != null && {
        Demographic: serializeAws_restJson1EndpointDemographic(input.Demographic, context),
    })), (input.EffectiveDate != null && { EffectiveDate: input.EffectiveDate })), (input.EndpointStatus != null && { EndpointStatus: input.EndpointStatus })), (input.Location != null && { Location: serializeAws_restJson1EndpointLocation(input.Location, context) })), (input.Metrics != null && { Metrics: serializeAws_restJson1MapOf__double(input.Metrics, context) })), (input.OptOut != null && { OptOut: input.OptOut })), (input.RequestId != null && { RequestId: input.RequestId })), (input.User != null && { User: serializeAws_restJson1EndpointUser(input.User, context) }));
};
var serializeAws_restJson1EndpointSendConfiguration = function (input, context) {
    return __assign(__assign(__assign(__assign(__assign({}, (input.BodyOverride != null && { BodyOverride: input.BodyOverride })), (input.Context != null && { Context: serializeAws_restJson1MapOf__string(input.Context, context) })), (input.RawContent != null && { RawContent: input.RawContent })), (input.Substitutions != null && {
        Substitutions: serializeAws_restJson1MapOfListOf__string(input.Substitutions, context),
    })), (input.TitleOverride != null && { TitleOverride: input.TitleOverride }));
};
var serializeAws_restJson1EndpointUser = function (input, context) {
    return __assign(__assign({}, (input.UserAttributes != null && {
        UserAttributes: serializeAws_restJson1MapOfListOf__string(input.UserAttributes, context),
    })), (input.UserId != null && { UserId: input.UserId }));
};
var serializeAws_restJson1Event = function (input, context) {
    return __assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign({}, (input.AppPackageName != null && { AppPackageName: input.AppPackageName })), (input.AppTitle != null && { AppTitle: input.AppTitle })), (input.AppVersionCode != null && { AppVersionCode: input.AppVersionCode })), (input.Attributes != null && { Attributes: serializeAws_restJson1MapOf__string(input.Attributes, context) })), (input.ClientSdkVersion != null && { ClientSdkVersion: input.ClientSdkVersion })), (input.EventType != null && { EventType: input.EventType })), (input.Metrics != null && { Metrics: serializeAws_restJson1MapOf__double(input.Metrics, context) })), (input.SdkName != null && { SdkName: input.SdkName })), (input.Session != null && { Session: serializeAws_restJson1Session(input.Session, context) })), (input.Timestamp != null && { Timestamp: input.Timestamp }));
};
var serializeAws_restJson1EventCondition = function (input, context) {
    return __assign(__assign({}, (input.Dimensions != null && { Dimensions: serializeAws_restJson1EventDimensions(input.Dimensions, context) })), (input.MessageActivity != null && { MessageActivity: input.MessageActivity }));
};
var serializeAws_restJson1EventDimensions = function (input, context) {
    return __assign(__assign(__assign({}, (input.Attributes != null && {
        Attributes: serializeAws_restJson1MapOfAttributeDimension(input.Attributes, context),
    })), (input.EventType != null && { EventType: serializeAws_restJson1SetDimension(input.EventType, context) })), (input.Metrics != null && { Metrics: serializeAws_restJson1MapOfMetricDimension(input.Metrics, context) }));
};
var serializeAws_restJson1EventFilter = function (input, context) {
    return __assign(__assign({}, (input.Dimensions != null && { Dimensions: serializeAws_restJson1EventDimensions(input.Dimensions, context) })), (input.FilterType != null && { FilterType: input.FilterType }));
};
var serializeAws_restJson1EventsBatch = function (input, context) {
    return __assign(__assign({}, (input.Endpoint != null && { Endpoint: serializeAws_restJson1PublicEndpoint(input.Endpoint, context) })), (input.Events != null && { Events: serializeAws_restJson1MapOfEvent(input.Events, context) }));
};
var serializeAws_restJson1EventsRequest = function (input, context) {
    return __assign({}, (input.BatchItem != null && { BatchItem: serializeAws_restJson1MapOfEventsBatch(input.BatchItem, context) }));
};
var serializeAws_restJson1EventStartCondition = function (input, context) {
    return __assign(__assign({}, (input.EventFilter != null && { EventFilter: serializeAws_restJson1EventFilter(input.EventFilter, context) })), (input.SegmentId != null && { SegmentId: input.SegmentId }));
};
var serializeAws_restJson1ExportJobRequest = function (input, context) {
    return __assign(__assign(__assign(__assign({}, (input.RoleArn != null && { RoleArn: input.RoleArn })), (input.S3UrlPrefix != null && { S3UrlPrefix: input.S3UrlPrefix })), (input.SegmentId != null && { SegmentId: input.SegmentId })), (input.SegmentVersion != null && { SegmentVersion: input.SegmentVersion }));
};
var serializeAws_restJson1GCMChannelRequest = function (input, context) {
    return __assign(__assign({}, (input.ApiKey != null && { ApiKey: input.ApiKey })), (input.Enabled != null && { Enabled: input.Enabled }));
};
var serializeAws_restJson1GCMMessage = function (input, context) {
    return __assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign({}, (input.Action != null && { Action: input.Action })), (input.Body != null && { Body: input.Body })), (input.CollapseKey != null && { CollapseKey: input.CollapseKey })), (input.Data != null && { Data: serializeAws_restJson1MapOf__string(input.Data, context) })), (input.IconReference != null && { IconReference: input.IconReference })), (input.ImageIconUrl != null && { ImageIconUrl: input.ImageIconUrl })), (input.ImageUrl != null && { ImageUrl: input.ImageUrl })), (input.Priority != null && { Priority: input.Priority })), (input.RawContent != null && { RawContent: input.RawContent })), (input.RestrictedPackageName != null && { RestrictedPackageName: input.RestrictedPackageName })), (input.SilentPush != null && { SilentPush: input.SilentPush })), (input.SmallImageIconUrl != null && { SmallImageIconUrl: input.SmallImageIconUrl })), (input.Sound != null && { Sound: input.Sound })), (input.Substitutions != null && {
        Substitutions: serializeAws_restJson1MapOfListOf__string(input.Substitutions, context),
    })), (input.TimeToLive != null && { TimeToLive: input.TimeToLive })), (input.Title != null && { Title: input.Title })), (input.Url != null && { Url: input.Url }));
};
var serializeAws_restJson1GPSCoordinates = function (input, context) {
    return __assign(__assign({}, (input.Latitude != null && { Latitude: __serializeFloat(input.Latitude) })), (input.Longitude != null && { Longitude: __serializeFloat(input.Longitude) }));
};
var serializeAws_restJson1GPSPointDimension = function (input, context) {
    return __assign(__assign({}, (input.Coordinates != null && { Coordinates: serializeAws_restJson1GPSCoordinates(input.Coordinates, context) })), (input.RangeInKilometers != null && { RangeInKilometers: __serializeFloat(input.RangeInKilometers) }));
};
var serializeAws_restJson1HoldoutActivity = function (input, context) {
    return __assign(__assign({}, (input.NextActivity != null && { NextActivity: input.NextActivity })), (input.Percentage != null && { Percentage: input.Percentage }));
};
var serializeAws_restJson1ImportJobRequest = function (input, context) {
    return __assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign({}, (input.DefineSegment != null && { DefineSegment: input.DefineSegment })), (input.ExternalId != null && { ExternalId: input.ExternalId })), (input.Format != null && { Format: input.Format })), (input.RegisterEndpoints != null && { RegisterEndpoints: input.RegisterEndpoints })), (input.RoleArn != null && { RoleArn: input.RoleArn })), (input.S3Url != null && { S3Url: input.S3Url })), (input.SegmentId != null && { SegmentId: input.SegmentId })), (input.SegmentName != null && { SegmentName: input.SegmentName }));
};
var serializeAws_restJson1InAppMessageBodyConfig = function (input, context) {
    return __assign(__assign(__assign({}, (input.Alignment != null && { Alignment: input.Alignment })), (input.Body != null && { Body: input.Body })), (input.TextColor != null && { TextColor: input.TextColor }));
};
var serializeAws_restJson1InAppMessageButton = function (input, context) {
    return __assign(__assign(__assign(__assign({}, (input.Android != null && {
        Android: serializeAws_restJson1OverrideButtonConfiguration(input.Android, context),
    })), (input.DefaultConfig != null && {
        DefaultConfig: serializeAws_restJson1DefaultButtonConfiguration(input.DefaultConfig, context),
    })), (input.IOS != null && { IOS: serializeAws_restJson1OverrideButtonConfiguration(input.IOS, context) })), (input.Web != null && { Web: serializeAws_restJson1OverrideButtonConfiguration(input.Web, context) }));
};
var serializeAws_restJson1InAppMessageContent = function (input, context) {
    return __assign(__assign(__assign(__assign(__assign(__assign({}, (input.BackgroundColor != null && { BackgroundColor: input.BackgroundColor })), (input.BodyConfig != null && {
        BodyConfig: serializeAws_restJson1InAppMessageBodyConfig(input.BodyConfig, context),
    })), (input.HeaderConfig != null && {
        HeaderConfig: serializeAws_restJson1InAppMessageHeaderConfig(input.HeaderConfig, context),
    })), (input.ImageUrl != null && { ImageUrl: input.ImageUrl })), (input.PrimaryBtn != null && {
        PrimaryBtn: serializeAws_restJson1InAppMessageButton(input.PrimaryBtn, context),
    })), (input.SecondaryBtn != null && {
        SecondaryBtn: serializeAws_restJson1InAppMessageButton(input.SecondaryBtn, context),
    }));
};
var serializeAws_restJson1InAppMessageHeaderConfig = function (input, context) {
    return __assign(__assign(__assign({}, (input.Alignment != null && { Alignment: input.Alignment })), (input.Header != null && { Header: input.Header })), (input.TextColor != null && { TextColor: input.TextColor }));
};
var serializeAws_restJson1InAppTemplateRequest = function (input, context) {
    return __assign(__assign(__assign(__assign(__assign({}, (input.Content != null && { Content: serializeAws_restJson1ListOfInAppMessageContent(input.Content, context) })), (input.CustomConfig != null && {
        CustomConfig: serializeAws_restJson1MapOf__string(input.CustomConfig, context),
    })), (input.Layout != null && { Layout: input.Layout })), (input.TemplateDescription != null && { TemplateDescription: input.TemplateDescription })), (input.tags != null && { tags: serializeAws_restJson1MapOf__string(input.tags, context) }));
};
var serializeAws_restJson1JourneyChannelSettings = function (input, context) {
    return __assign(__assign({}, (input.ConnectCampaignArn != null && { ConnectCampaignArn: input.ConnectCampaignArn })), (input.ConnectCampaignExecutionRoleArn != null && {
        ConnectCampaignExecutionRoleArn: input.ConnectCampaignExecutionRoleArn,
    }));
};
var serializeAws_restJson1JourneyCustomMessage = function (input, context) {
    return __assign({}, (input.Data != null && { Data: input.Data }));
};
var serializeAws_restJson1JourneyEmailMessage = function (input, context) {
    return __assign({}, (input.FromAddress != null && { FromAddress: input.FromAddress }));
};
var serializeAws_restJson1JourneyLimits = function (input, context) {
    return __assign(__assign(__assign(__assign({}, (input.DailyCap != null && { DailyCap: input.DailyCap })), (input.EndpointReentryCap != null && { EndpointReentryCap: input.EndpointReentryCap })), (input.EndpointReentryInterval != null && { EndpointReentryInterval: input.EndpointReentryInterval })), (input.MessagesPerSecond != null && { MessagesPerSecond: input.MessagesPerSecond }));
};
var serializeAws_restJson1JourneyPushMessage = function (input, context) {
    return __assign({}, (input.TimeToLive != null && { TimeToLive: input.TimeToLive }));
};
var serializeAws_restJson1JourneySchedule = function (input, context) {
    return __assign(__assign(__assign({}, (input.EndTime != null && { EndTime: input.EndTime.toISOString().split(".")[0] + "Z" })), (input.StartTime != null && { StartTime: input.StartTime.toISOString().split(".")[0] + "Z" })), (input.Timezone != null && { Timezone: input.Timezone }));
};
var serializeAws_restJson1JourneySMSMessage = function (input, context) {
    return __assign(__assign(__assign(__assign(__assign({}, (input.EntityId != null && { EntityId: input.EntityId })), (input.MessageType != null && { MessageType: input.MessageType })), (input.OriginationNumber != null && { OriginationNumber: input.OriginationNumber })), (input.SenderId != null && { SenderId: input.SenderId })), (input.TemplateId != null && { TemplateId: input.TemplateId }));
};
var serializeAws_restJson1JourneyStateRequest = function (input, context) {
    return __assign({}, (input.State != null && { State: input.State }));
};
var serializeAws_restJson1ListOf__EndpointTypesElement = function (input, context) {
    return input
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        return entry;
    });
};
var serializeAws_restJson1ListOf__string = function (input, context) {
    return input
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        return entry;
    });
};
var serializeAws_restJson1ListOfClosedDaysRules = function (input, context) {
    return input
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        return serializeAws_restJson1ClosedDaysRule(entry, context);
    });
};
var serializeAws_restJson1ListOfEndpointBatchItem = function (input, context) {
    return input
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        return serializeAws_restJson1EndpointBatchItem(entry, context);
    });
};
var serializeAws_restJson1ListOfInAppMessageContent = function (input, context) {
    return input
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        return serializeAws_restJson1InAppMessageContent(entry, context);
    });
};
var serializeAws_restJson1ListOfMultiConditionalBranch = function (input, context) {
    return input
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        return serializeAws_restJson1MultiConditionalBranch(entry, context);
    });
};
var serializeAws_restJson1ListOfOpenHoursRules = function (input, context) {
    return input
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        return serializeAws_restJson1OpenHoursRule(entry, context);
    });
};
var serializeAws_restJson1ListOfRandomSplitEntry = function (input, context) {
    return input
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        return serializeAws_restJson1RandomSplitEntry(entry, context);
    });
};
var serializeAws_restJson1ListOfSegmentDimensions = function (input, context) {
    return input
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        return serializeAws_restJson1SegmentDimensions(entry, context);
    });
};
var serializeAws_restJson1ListOfSegmentGroup = function (input, context) {
    return input
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        return serializeAws_restJson1SegmentGroup(entry, context);
    });
};
var serializeAws_restJson1ListOfSegmentReference = function (input, context) {
    return input
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        return serializeAws_restJson1SegmentReference(entry, context);
    });
};
var serializeAws_restJson1ListOfSimpleCondition = function (input, context) {
    return input
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        return serializeAws_restJson1SimpleCondition(entry, context);
    });
};
var serializeAws_restJson1ListOfWriteTreatmentResource = function (input, context) {
    return input
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        return serializeAws_restJson1WriteTreatmentResource(entry, context);
    });
};
var serializeAws_restJson1MapOf__double = function (input, context) {
    return Object.entries(input).reduce(function (acc, _a) {
        var _b;
        var _c = __read(_a, 2), key = _c[0], value = _c[1];
        if (value === null) {
            return acc;
        }
        return __assign(__assign({}, acc), (_b = {}, _b[key] = __serializeFloat(value), _b));
    }, {});
};
var serializeAws_restJson1MapOf__string = function (input, context) {
    return Object.entries(input).reduce(function (acc, _a) {
        var _b;
        var _c = __read(_a, 2), key = _c[0], value = _c[1];
        if (value === null) {
            return acc;
        }
        return __assign(__assign({}, acc), (_b = {}, _b[key] = value, _b));
    }, {});
};
var serializeAws_restJson1MapOfActivity = function (input, context) {
    return Object.entries(input).reduce(function (acc, _a) {
        var _b;
        var _c = __read(_a, 2), key = _c[0], value = _c[1];
        if (value === null) {
            return acc;
        }
        return __assign(__assign({}, acc), (_b = {}, _b[key] = serializeAws_restJson1Activity(value, context), _b));
    }, {});
};
var serializeAws_restJson1MapOfAddressConfiguration = function (input, context) {
    return Object.entries(input).reduce(function (acc, _a) {
        var _b;
        var _c = __read(_a, 2), key = _c[0], value = _c[1];
        if (value === null) {
            return acc;
        }
        return __assign(__assign({}, acc), (_b = {}, _b[key] = serializeAws_restJson1AddressConfiguration(value, context), _b));
    }, {});
};
var serializeAws_restJson1MapOfAttributeDimension = function (input, context) {
    return Object.entries(input).reduce(function (acc, _a) {
        var _b;
        var _c = __read(_a, 2), key = _c[0], value = _c[1];
        if (value === null) {
            return acc;
        }
        return __assign(__assign({}, acc), (_b = {}, _b[key] = serializeAws_restJson1AttributeDimension(value, context), _b));
    }, {});
};
var serializeAws_restJson1MapOfEndpointSendConfiguration = function (input, context) {
    return Object.entries(input).reduce(function (acc, _a) {
        var _b;
        var _c = __read(_a, 2), key = _c[0], value = _c[1];
        if (value === null) {
            return acc;
        }
        return __assign(__assign({}, acc), (_b = {}, _b[key] = serializeAws_restJson1EndpointSendConfiguration(value, context), _b));
    }, {});
};
var serializeAws_restJson1MapOfEvent = function (input, context) {
    return Object.entries(input).reduce(function (acc, _a) {
        var _b;
        var _c = __read(_a, 2), key = _c[0], value = _c[1];
        if (value === null) {
            return acc;
        }
        return __assign(__assign({}, acc), (_b = {}, _b[key] = serializeAws_restJson1Event(value, context), _b));
    }, {});
};
var serializeAws_restJson1MapOfEventsBatch = function (input, context) {
    return Object.entries(input).reduce(function (acc, _a) {
        var _b;
        var _c = __read(_a, 2), key = _c[0], value = _c[1];
        if (value === null) {
            return acc;
        }
        return __assign(__assign({}, acc), (_b = {}, _b[key] = serializeAws_restJson1EventsBatch(value, context), _b));
    }, {});
};
var serializeAws_restJson1MapOfListOf__string = function (input, context) {
    return Object.entries(input).reduce(function (acc, _a) {
        var _b;
        var _c = __read(_a, 2), key = _c[0], value = _c[1];
        if (value === null) {
            return acc;
        }
        return __assign(__assign({}, acc), (_b = {}, _b[key] = serializeAws_restJson1ListOf__string(value, context), _b));
    }, {});
};
var serializeAws_restJson1MapOfListOfOpenHoursRules = function (input, context) {
    return Object.entries(input).reduce(function (acc, _a) {
        var _b;
        var _c = __read(_a, 2), key = _c[0], value = _c[1];
        if (value === null) {
            return acc;
        }
        return __assign(__assign({}, acc), (_b = {}, _b[key] = serializeAws_restJson1ListOfOpenHoursRules(value, context), _b));
    }, {});
};
var serializeAws_restJson1MapOfMetricDimension = function (input, context) {
    return Object.entries(input).reduce(function (acc, _a) {
        var _b;
        var _c = __read(_a, 2), key = _c[0], value = _c[1];
        if (value === null) {
            return acc;
        }
        return __assign(__assign({}, acc), (_b = {}, _b[key] = serializeAws_restJson1MetricDimension(value, context), _b));
    }, {});
};
var serializeAws_restJson1Message = function (input, context) {
    return __assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign({}, (input.Action != null && { Action: input.Action })), (input.Body != null && { Body: input.Body })), (input.ImageIconUrl != null && { ImageIconUrl: input.ImageIconUrl })), (input.ImageSmallIconUrl != null && { ImageSmallIconUrl: input.ImageSmallIconUrl })), (input.ImageUrl != null && { ImageUrl: input.ImageUrl })), (input.JsonBody != null && { JsonBody: input.JsonBody })), (input.MediaUrl != null && { MediaUrl: input.MediaUrl })), (input.RawContent != null && { RawContent: input.RawContent })), (input.SilentPush != null && { SilentPush: input.SilentPush })), (input.TimeToLive != null && { TimeToLive: input.TimeToLive })), (input.Title != null && { Title: input.Title })), (input.Url != null && { Url: input.Url }));
};
var serializeAws_restJson1MessageConfiguration = function (input, context) {
    return __assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign({}, (input.ADMMessage != null && { ADMMessage: serializeAws_restJson1Message(input.ADMMessage, context) })), (input.APNSMessage != null && { APNSMessage: serializeAws_restJson1Message(input.APNSMessage, context) })), (input.BaiduMessage != null && { BaiduMessage: serializeAws_restJson1Message(input.BaiduMessage, context) })), (input.CustomMessage != null && {
        CustomMessage: serializeAws_restJson1CampaignCustomMessage(input.CustomMessage, context),
    })), (input.DefaultMessage != null && {
        DefaultMessage: serializeAws_restJson1Message(input.DefaultMessage, context),
    })), (input.EmailMessage != null && {
        EmailMessage: serializeAws_restJson1CampaignEmailMessage(input.EmailMessage, context),
    })), (input.GCMMessage != null && { GCMMessage: serializeAws_restJson1Message(input.GCMMessage, context) })), (input.InAppMessage != null && {
        InAppMessage: serializeAws_restJson1CampaignInAppMessage(input.InAppMessage, context),
    })), (input.SMSMessage != null && {
        SMSMessage: serializeAws_restJson1CampaignSmsMessage(input.SMSMessage, context),
    }));
};
var serializeAws_restJson1MessageRequest = function (input, context) {
    return __assign(__assign(__assign(__assign(__assign(__assign({}, (input.Addresses != null && {
        Addresses: serializeAws_restJson1MapOfAddressConfiguration(input.Addresses, context),
    })), (input.Context != null && { Context: serializeAws_restJson1MapOf__string(input.Context, context) })), (input.Endpoints != null && {
        Endpoints: serializeAws_restJson1MapOfEndpointSendConfiguration(input.Endpoints, context),
    })), (input.MessageConfiguration != null && {
        MessageConfiguration: serializeAws_restJson1DirectMessageConfiguration(input.MessageConfiguration, context),
    })), (input.TemplateConfiguration != null && {
        TemplateConfiguration: serializeAws_restJson1TemplateConfiguration(input.TemplateConfiguration, context),
    })), (input.TraceId != null && { TraceId: input.TraceId }));
};
var serializeAws_restJson1MetricDimension = function (input, context) {
    return __assign(__assign({}, (input.ComparisonOperator != null && { ComparisonOperator: input.ComparisonOperator })), (input.Value != null && { Value: __serializeFloat(input.Value) }));
};
var serializeAws_restJson1MultiConditionalBranch = function (input, context) {
    return __assign(__assign({}, (input.Condition != null && { Condition: serializeAws_restJson1SimpleCondition(input.Condition, context) })), (input.NextActivity != null && { NextActivity: input.NextActivity }));
};
var serializeAws_restJson1MultiConditionalSplitActivity = function (input, context) {
    return __assign(__assign(__assign({}, (input.Branches != null && {
        Branches: serializeAws_restJson1ListOfMultiConditionalBranch(input.Branches, context),
    })), (input.DefaultActivity != null && { DefaultActivity: input.DefaultActivity })), (input.EvaluationWaitTime != null && {
        EvaluationWaitTime: serializeAws_restJson1WaitTime(input.EvaluationWaitTime, context),
    }));
};
var serializeAws_restJson1NumberValidateRequest = function (input, context) {
    return __assign(__assign({}, (input.IsoCountryCode != null && { IsoCountryCode: input.IsoCountryCode })), (input.PhoneNumber != null && { PhoneNumber: input.PhoneNumber }));
};
var serializeAws_restJson1OpenHours = function (input, context) {
    return __assign(__assign(__assign(__assign(__assign({}, (input.CUSTOM != null && { CUSTOM: serializeAws_restJson1MapOfListOfOpenHoursRules(input.CUSTOM, context) })), (input.EMAIL != null && { EMAIL: serializeAws_restJson1MapOfListOfOpenHoursRules(input.EMAIL, context) })), (input.PUSH != null && { PUSH: serializeAws_restJson1MapOfListOfOpenHoursRules(input.PUSH, context) })), (input.SMS != null && { SMS: serializeAws_restJson1MapOfListOfOpenHoursRules(input.SMS, context) })), (input.VOICE != null && { VOICE: serializeAws_restJson1MapOfListOfOpenHoursRules(input.VOICE, context) }));
};
var serializeAws_restJson1OpenHoursRule = function (input, context) {
    return __assign(__assign({}, (input.EndTime != null && { EndTime: input.EndTime })), (input.StartTime != null && { StartTime: input.StartTime }));
};
var serializeAws_restJson1OverrideButtonConfiguration = function (input, context) {
    return __assign(__assign({}, (input.ButtonAction != null && { ButtonAction: input.ButtonAction })), (input.Link != null && { Link: input.Link }));
};
var serializeAws_restJson1PublicEndpoint = function (input, context) {
    return __assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign({}, (input.Address != null && { Address: input.Address })), (input.Attributes != null && {
        Attributes: serializeAws_restJson1MapOfListOf__string(input.Attributes, context),
    })), (input.ChannelType != null && { ChannelType: input.ChannelType })), (input.Demographic != null && {
        Demographic: serializeAws_restJson1EndpointDemographic(input.Demographic, context),
    })), (input.EffectiveDate != null && { EffectiveDate: input.EffectiveDate })), (input.EndpointStatus != null && { EndpointStatus: input.EndpointStatus })), (input.Location != null && { Location: serializeAws_restJson1EndpointLocation(input.Location, context) })), (input.Metrics != null && { Metrics: serializeAws_restJson1MapOf__double(input.Metrics, context) })), (input.OptOut != null && { OptOut: input.OptOut })), (input.RequestId != null && { RequestId: input.RequestId })), (input.User != null && { User: serializeAws_restJson1EndpointUser(input.User, context) }));
};
var serializeAws_restJson1PushMessageActivity = function (input, context) {
    return __assign(__assign(__assign(__assign({}, (input.MessageConfig != null && {
        MessageConfig: serializeAws_restJson1JourneyPushMessage(input.MessageConfig, context),
    })), (input.NextActivity != null && { NextActivity: input.NextActivity })), (input.TemplateName != null && { TemplateName: input.TemplateName })), (input.TemplateVersion != null && { TemplateVersion: input.TemplateVersion }));
};
var serializeAws_restJson1PushNotificationTemplateRequest = function (input, context) {
    return __assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign({}, (input.ADM != null && { ADM: serializeAws_restJson1AndroidPushNotificationTemplate(input.ADM, context) })), (input.APNS != null && { APNS: serializeAws_restJson1APNSPushNotificationTemplate(input.APNS, context) })), (input.Baidu != null && { Baidu: serializeAws_restJson1AndroidPushNotificationTemplate(input.Baidu, context) })), (input.Default != null && {
        Default: serializeAws_restJson1DefaultPushNotificationTemplate(input.Default, context),
    })), (input.DefaultSubstitutions != null && { DefaultSubstitutions: input.DefaultSubstitutions })), (input.GCM != null && { GCM: serializeAws_restJson1AndroidPushNotificationTemplate(input.GCM, context) })), (input.RecommenderId != null && { RecommenderId: input.RecommenderId })), (input.TemplateDescription != null && { TemplateDescription: input.TemplateDescription })), (input.tags != null && { tags: serializeAws_restJson1MapOf__string(input.tags, context) }));
};
var serializeAws_restJson1QuietTime = function (input, context) {
    return __assign(__assign({}, (input.End != null && { End: input.End })), (input.Start != null && { Start: input.Start }));
};
var serializeAws_restJson1RandomSplitActivity = function (input, context) {
    return __assign({}, (input.Branches != null && { Branches: serializeAws_restJson1ListOfRandomSplitEntry(input.Branches, context) }));
};
var serializeAws_restJson1RandomSplitEntry = function (input, context) {
    return __assign(__assign({}, (input.NextActivity != null && { NextActivity: input.NextActivity })), (input.Percentage != null && { Percentage: input.Percentage }));
};
var serializeAws_restJson1RawEmail = function (input, context) {
    return __assign({}, (input.Data != null && { Data: context.base64Encoder(input.Data) }));
};
var serializeAws_restJson1RecencyDimension = function (input, context) {
    return __assign(__assign({}, (input.Duration != null && { Duration: input.Duration })), (input.RecencyType != null && { RecencyType: input.RecencyType }));
};
var serializeAws_restJson1Schedule = function (input, context) {
    return __assign(__assign(__assign(__assign(__assign(__assign(__assign({}, (input.EndTime != null && { EndTime: input.EndTime })), (input.EventFilter != null && {
        EventFilter: serializeAws_restJson1CampaignEventFilter(input.EventFilter, context),
    })), (input.Frequency != null && { Frequency: input.Frequency })), (input.IsLocalTime != null && { IsLocalTime: input.IsLocalTime })), (input.QuietTime != null && { QuietTime: serializeAws_restJson1QuietTime(input.QuietTime, context) })), (input.StartTime != null && { StartTime: input.StartTime })), (input.Timezone != null && { Timezone: input.Timezone }));
};
var serializeAws_restJson1SegmentBehaviors = function (input, context) {
    return __assign({}, (input.Recency != null && { Recency: serializeAws_restJson1RecencyDimension(input.Recency, context) }));
};
var serializeAws_restJson1SegmentCondition = function (input, context) {
    return __assign({}, (input.SegmentId != null && { SegmentId: input.SegmentId }));
};
var serializeAws_restJson1SegmentDemographics = function (input, context) {
    return __assign(__assign(__assign(__assign(__assign(__assign({}, (input.AppVersion != null && { AppVersion: serializeAws_restJson1SetDimension(input.AppVersion, context) })), (input.Channel != null && { Channel: serializeAws_restJson1SetDimension(input.Channel, context) })), (input.DeviceType != null && { DeviceType: serializeAws_restJson1SetDimension(input.DeviceType, context) })), (input.Make != null && { Make: serializeAws_restJson1SetDimension(input.Make, context) })), (input.Model != null && { Model: serializeAws_restJson1SetDimension(input.Model, context) })), (input.Platform != null && { Platform: serializeAws_restJson1SetDimension(input.Platform, context) }));
};
var serializeAws_restJson1SegmentDimensions = function (input, context) {
    return __assign(__assign(__assign(__assign(__assign(__assign({}, (input.Attributes != null && {
        Attributes: serializeAws_restJson1MapOfAttributeDimension(input.Attributes, context),
    })), (input.Behavior != null && { Behavior: serializeAws_restJson1SegmentBehaviors(input.Behavior, context) })), (input.Demographic != null && {
        Demographic: serializeAws_restJson1SegmentDemographics(input.Demographic, context),
    })), (input.Location != null && { Location: serializeAws_restJson1SegmentLocation(input.Location, context) })), (input.Metrics != null && { Metrics: serializeAws_restJson1MapOfMetricDimension(input.Metrics, context) })), (input.UserAttributes != null && {
        UserAttributes: serializeAws_restJson1MapOfAttributeDimension(input.UserAttributes, context),
    }));
};
var serializeAws_restJson1SegmentGroup = function (input, context) {
    return __assign(__assign(__assign(__assign({}, (input.Dimensions != null && {
        Dimensions: serializeAws_restJson1ListOfSegmentDimensions(input.Dimensions, context),
    })), (input.SourceSegments != null && {
        SourceSegments: serializeAws_restJson1ListOfSegmentReference(input.SourceSegments, context),
    })), (input.SourceType != null && { SourceType: input.SourceType })), (input.Type != null && { Type: input.Type }));
};
var serializeAws_restJson1SegmentGroupList = function (input, context) {
    return __assign(__assign({}, (input.Groups != null && { Groups: serializeAws_restJson1ListOfSegmentGroup(input.Groups, context) })), (input.Include != null && { Include: input.Include }));
};
var serializeAws_restJson1SegmentLocation = function (input, context) {
    return __assign(__assign({}, (input.Country != null && { Country: serializeAws_restJson1SetDimension(input.Country, context) })), (input.GPSPoint != null && { GPSPoint: serializeAws_restJson1GPSPointDimension(input.GPSPoint, context) }));
};
var serializeAws_restJson1SegmentReference = function (input, context) {
    return __assign(__assign({}, (input.Id != null && { Id: input.Id })), (input.Version != null && { Version: input.Version }));
};
var serializeAws_restJson1SendOTPMessageRequestParameters = function (input, context) {
    return __assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign({}, (input.AllowedAttempts != null && { AllowedAttempts: input.AllowedAttempts })), (input.BrandName != null && { BrandName: input.BrandName })), (input.Channel != null && { Channel: input.Channel })), (input.CodeLength != null && { CodeLength: input.CodeLength })), (input.DestinationIdentity != null && { DestinationIdentity: input.DestinationIdentity })), (input.EntityId != null && { EntityId: input.EntityId })), (input.Language != null && { Language: input.Language })), (input.OriginationIdentity != null && { OriginationIdentity: input.OriginationIdentity })), (input.ReferenceId != null && { ReferenceId: input.ReferenceId })), (input.TemplateId != null && { TemplateId: input.TemplateId })), (input.ValidityPeriod != null && { ValidityPeriod: input.ValidityPeriod }));
};
var serializeAws_restJson1SendUsersMessageRequest = function (input, context) {
    return __assign(__assign(__assign(__assign(__assign({}, (input.Context != null && { Context: serializeAws_restJson1MapOf__string(input.Context, context) })), (input.MessageConfiguration != null && {
        MessageConfiguration: serializeAws_restJson1DirectMessageConfiguration(input.MessageConfiguration, context),
    })), (input.TemplateConfiguration != null && {
        TemplateConfiguration: serializeAws_restJson1TemplateConfiguration(input.TemplateConfiguration, context),
    })), (input.TraceId != null && { TraceId: input.TraceId })), (input.Users != null && { Users: serializeAws_restJson1MapOfEndpointSendConfiguration(input.Users, context) }));
};
var serializeAws_restJson1Session = function (input, context) {
    return __assign(__assign(__assign(__assign({}, (input.Duration != null && { Duration: input.Duration })), (input.Id != null && { Id: input.Id })), (input.StartTimestamp != null && { StartTimestamp: input.StartTimestamp })), (input.StopTimestamp != null && { StopTimestamp: input.StopTimestamp }));
};
var serializeAws_restJson1SetDimension = function (input, context) {
    return __assign(__assign({}, (input.DimensionType != null && { DimensionType: input.DimensionType })), (input.Values != null && { Values: serializeAws_restJson1ListOf__string(input.Values, context) }));
};
var serializeAws_restJson1SimpleCondition = function (input, context) {
    return __assign(__assign(__assign({}, (input.EventCondition != null && {
        EventCondition: serializeAws_restJson1EventCondition(input.EventCondition, context),
    })), (input.SegmentCondition != null && {
        SegmentCondition: serializeAws_restJson1SegmentCondition(input.SegmentCondition, context),
    })), (input.SegmentDimensions != null && {
        segmentDimensions: serializeAws_restJson1SegmentDimensions(input.SegmentDimensions, context),
    }));
};
var serializeAws_restJson1SimpleEmail = function (input, context) {
    return __assign(__assign(__assign({}, (input.HtmlPart != null && { HtmlPart: serializeAws_restJson1SimpleEmailPart(input.HtmlPart, context) })), (input.Subject != null && { Subject: serializeAws_restJson1SimpleEmailPart(input.Subject, context) })), (input.TextPart != null && { TextPart: serializeAws_restJson1SimpleEmailPart(input.TextPart, context) }));
};
var serializeAws_restJson1SimpleEmailPart = function (input, context) {
    return __assign(__assign({}, (input.Charset != null && { Charset: input.Charset })), (input.Data != null && { Data: input.Data }));
};
var serializeAws_restJson1SMSChannelRequest = function (input, context) {
    return __assign(__assign(__assign({}, (input.Enabled != null && { Enabled: input.Enabled })), (input.SenderId != null && { SenderId: input.SenderId })), (input.ShortCode != null && { ShortCode: input.ShortCode }));
};
var serializeAws_restJson1SMSMessage = function (input, context) {
    return __assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign({}, (input.Body != null && { Body: input.Body })), (input.EntityId != null && { EntityId: input.EntityId })), (input.Keyword != null && { Keyword: input.Keyword })), (input.MediaUrl != null && { MediaUrl: input.MediaUrl })), (input.MessageType != null && { MessageType: input.MessageType })), (input.OriginationNumber != null && { OriginationNumber: input.OriginationNumber })), (input.SenderId != null && { SenderId: input.SenderId })), (input.Substitutions != null && {
        Substitutions: serializeAws_restJson1MapOfListOf__string(input.Substitutions, context),
    })), (input.TemplateId != null && { TemplateId: input.TemplateId }));
};
var serializeAws_restJson1SMSMessageActivity = function (input, context) {
    return __assign(__assign(__assign(__assign({}, (input.MessageConfig != null && {
        MessageConfig: serializeAws_restJson1JourneySMSMessage(input.MessageConfig, context),
    })), (input.NextActivity != null && { NextActivity: input.NextActivity })), (input.TemplateName != null && { TemplateName: input.TemplateName })), (input.TemplateVersion != null && { TemplateVersion: input.TemplateVersion }));
};
var serializeAws_restJson1SMSTemplateRequest = function (input, context) {
    return __assign(__assign(__assign(__assign(__assign({}, (input.Body != null && { Body: input.Body })), (input.DefaultSubstitutions != null && { DefaultSubstitutions: input.DefaultSubstitutions })), (input.RecommenderId != null && { RecommenderId: input.RecommenderId })), (input.TemplateDescription != null && { TemplateDescription: input.TemplateDescription })), (input.tags != null && { tags: serializeAws_restJson1MapOf__string(input.tags, context) }));
};
var serializeAws_restJson1StartCondition = function (input, context) {
    return __assign(__assign(__assign({}, (input.Description != null && { Description: input.Description })), (input.EventStartCondition != null && {
        EventStartCondition: serializeAws_restJson1EventStartCondition(input.EventStartCondition, context),
    })), (input.SegmentStartCondition != null && {
        SegmentStartCondition: serializeAws_restJson1SegmentCondition(input.SegmentStartCondition, context),
    }));
};
var serializeAws_restJson1TagsModel = function (input, context) {
    return __assign({}, (input.tags != null && { tags: serializeAws_restJson1MapOf__string(input.tags, context) }));
};
var serializeAws_restJson1Template = function (input, context) {
    return __assign(__assign({}, (input.Name != null && { Name: input.Name })), (input.Version != null && { Version: input.Version }));
};
var serializeAws_restJson1TemplateActiveVersionRequest = function (input, context) {
    return __assign({}, (input.Version != null && { Version: input.Version }));
};
var serializeAws_restJson1TemplateConfiguration = function (input, context) {
    return __assign(__assign(__assign(__assign({}, (input.EmailTemplate != null && { EmailTemplate: serializeAws_restJson1Template(input.EmailTemplate, context) })), (input.PushTemplate != null && { PushTemplate: serializeAws_restJson1Template(input.PushTemplate, context) })), (input.SMSTemplate != null && { SMSTemplate: serializeAws_restJson1Template(input.SMSTemplate, context) })), (input.VoiceTemplate != null && { VoiceTemplate: serializeAws_restJson1Template(input.VoiceTemplate, context) }));
};
var serializeAws_restJson1UpdateAttributesRequest = function (input, context) {
    return __assign({}, (input.Blacklist != null && { Blacklist: serializeAws_restJson1ListOf__string(input.Blacklist, context) }));
};
var serializeAws_restJson1UpdateRecommenderConfigurationShape = function (input, context) {
    return __assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign({}, (input.Attributes != null && { Attributes: serializeAws_restJson1MapOf__string(input.Attributes, context) })), (input.Description != null && { Description: input.Description })), (input.Name != null && { Name: input.Name })), (input.RecommendationProviderIdType != null && {
        RecommendationProviderIdType: input.RecommendationProviderIdType,
    })), (input.RecommendationProviderRoleArn != null && {
        RecommendationProviderRoleArn: input.RecommendationProviderRoleArn,
    })), (input.RecommendationProviderUri != null && { RecommendationProviderUri: input.RecommendationProviderUri })), (input.RecommendationTransformerUri != null && {
        RecommendationTransformerUri: input.RecommendationTransformerUri,
    })), (input.RecommendationsDisplayName != null && { RecommendationsDisplayName: input.RecommendationsDisplayName })), (input.RecommendationsPerMessage != null && { RecommendationsPerMessage: input.RecommendationsPerMessage }));
};
var serializeAws_restJson1VerifyOTPMessageRequestParameters = function (input, context) {
    return __assign(__assign(__assign({}, (input.DestinationIdentity != null && { DestinationIdentity: input.DestinationIdentity })), (input.Otp != null && { Otp: input.Otp })), (input.ReferenceId != null && { ReferenceId: input.ReferenceId }));
};
var serializeAws_restJson1VoiceChannelRequest = function (input, context) {
    return __assign({}, (input.Enabled != null && { Enabled: input.Enabled }));
};
var serializeAws_restJson1VoiceMessage = function (input, context) {
    return __assign(__assign(__assign(__assign(__assign({}, (input.Body != null && { Body: input.Body })), (input.LanguageCode != null && { LanguageCode: input.LanguageCode })), (input.OriginationNumber != null && { OriginationNumber: input.OriginationNumber })), (input.Substitutions != null && {
        Substitutions: serializeAws_restJson1MapOfListOf__string(input.Substitutions, context),
    })), (input.VoiceId != null && { VoiceId: input.VoiceId }));
};
var serializeAws_restJson1VoiceTemplateRequest = function (input, context) {
    return __assign(__assign(__assign(__assign(__assign(__assign({}, (input.Body != null && { Body: input.Body })), (input.DefaultSubstitutions != null && { DefaultSubstitutions: input.DefaultSubstitutions })), (input.LanguageCode != null && { LanguageCode: input.LanguageCode })), (input.TemplateDescription != null && { TemplateDescription: input.TemplateDescription })), (input.VoiceId != null && { VoiceId: input.VoiceId })), (input.tags != null && { tags: serializeAws_restJson1MapOf__string(input.tags, context) }));
};
var serializeAws_restJson1WaitActivity = function (input, context) {
    return __assign(__assign({}, (input.NextActivity != null && { NextActivity: input.NextActivity })), (input.WaitTime != null && { WaitTime: serializeAws_restJson1WaitTime(input.WaitTime, context) }));
};
var serializeAws_restJson1WaitTime = function (input, context) {
    return __assign(__assign({}, (input.WaitFor != null && { WaitFor: input.WaitFor })), (input.WaitUntil != null && { WaitUntil: input.WaitUntil }));
};
var serializeAws_restJson1WriteApplicationSettingsRequest = function (input, context) {
    return __assign(__assign(__assign(__assign(__assign({}, (input.CampaignHook != null && {
        CampaignHook: serializeAws_restJson1CampaignHook(input.CampaignHook, context),
    })), (input.CloudWatchMetricsEnabled != null && { CloudWatchMetricsEnabled: input.CloudWatchMetricsEnabled })), (input.EventTaggingEnabled != null && { EventTaggingEnabled: input.EventTaggingEnabled })), (input.Limits != null && { Limits: serializeAws_restJson1CampaignLimits(input.Limits, context) })), (input.QuietTime != null && { QuietTime: serializeAws_restJson1QuietTime(input.QuietTime, context) }));
};
var serializeAws_restJson1WriteCampaignRequest = function (input, context) {
    return __assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign({}, (input.AdditionalTreatments != null && {
        AdditionalTreatments: serializeAws_restJson1ListOfWriteTreatmentResource(input.AdditionalTreatments, context),
    })), (input.CustomDeliveryConfiguration != null && {
        CustomDeliveryConfiguration: serializeAws_restJson1CustomDeliveryConfiguration(input.CustomDeliveryConfiguration, context),
    })), (input.Description != null && { Description: input.Description })), (input.HoldoutPercent != null && { HoldoutPercent: input.HoldoutPercent })), (input.Hook != null && { Hook: serializeAws_restJson1CampaignHook(input.Hook, context) })), (input.IsPaused != null && { IsPaused: input.IsPaused })), (input.Limits != null && { Limits: serializeAws_restJson1CampaignLimits(input.Limits, context) })), (input.MessageConfiguration != null && {
        MessageConfiguration: serializeAws_restJson1MessageConfiguration(input.MessageConfiguration, context),
    })), (input.Name != null && { Name: input.Name })), (input.Priority != null && { Priority: input.Priority })), (input.Schedule != null && { Schedule: serializeAws_restJson1Schedule(input.Schedule, context) })), (input.SegmentId != null && { SegmentId: input.SegmentId })), (input.SegmentVersion != null && { SegmentVersion: input.SegmentVersion })), (input.TemplateConfiguration != null && {
        TemplateConfiguration: serializeAws_restJson1TemplateConfiguration(input.TemplateConfiguration, context),
    })), (input.TreatmentDescription != null && { TreatmentDescription: input.TreatmentDescription })), (input.TreatmentName != null && { TreatmentName: input.TreatmentName })), (input.tags != null && { tags: serializeAws_restJson1MapOf__string(input.tags, context) }));
};
var serializeAws_restJson1WriteEventStream = function (input, context) {
    return __assign(__assign({}, (input.DestinationStreamArn != null && { DestinationStreamArn: input.DestinationStreamArn })), (input.RoleArn != null && { RoleArn: input.RoleArn }));
};
var serializeAws_restJson1WriteJourneyRequest = function (input, context) {
    return __assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign({}, (input.Activities != null && { Activities: serializeAws_restJson1MapOfActivity(input.Activities, context) })), (input.ClosedDays != null && { ClosedDays: serializeAws_restJson1ClosedDays(input.ClosedDays, context) })), (input.CreationDate != null && { CreationDate: input.CreationDate })), (input.JourneyChannelSettings != null && {
        JourneyChannelSettings: serializeAws_restJson1JourneyChannelSettings(input.JourneyChannelSettings, context),
    })), (input.LastModifiedDate != null && { LastModifiedDate: input.LastModifiedDate })), (input.Limits != null && { Limits: serializeAws_restJson1JourneyLimits(input.Limits, context) })), (input.LocalTime != null && { LocalTime: input.LocalTime })), (input.Name != null && { Name: input.Name })), (input.OpenHours != null && { OpenHours: serializeAws_restJson1OpenHours(input.OpenHours, context) })), (input.QuietTime != null && { QuietTime: serializeAws_restJson1QuietTime(input.QuietTime, context) })), (input.RefreshFrequency != null && { RefreshFrequency: input.RefreshFrequency })), (input.RefreshOnSegmentUpdate != null && { RefreshOnSegmentUpdate: input.RefreshOnSegmentUpdate })), (input.Schedule != null && { Schedule: serializeAws_restJson1JourneySchedule(input.Schedule, context) })), (input.SendingSchedule != null && { SendingSchedule: input.SendingSchedule })), (input.StartActivity != null && { StartActivity: input.StartActivity })), (input.StartCondition != null && {
        StartCondition: serializeAws_restJson1StartCondition(input.StartCondition, context),
    })), (input.State != null && { State: input.State })), (input.WaitForQuietTime != null && { WaitForQuietTime: input.WaitForQuietTime }));
};
var serializeAws_restJson1WriteSegmentRequest = function (input, context) {
    return __assign(__assign(__assign(__assign({}, (input.Dimensions != null && { Dimensions: serializeAws_restJson1SegmentDimensions(input.Dimensions, context) })), (input.Name != null && { Name: input.Name })), (input.SegmentGroups != null && {
        SegmentGroups: serializeAws_restJson1SegmentGroupList(input.SegmentGroups, context),
    })), (input.tags != null && { tags: serializeAws_restJson1MapOf__string(input.tags, context) }));
};
var serializeAws_restJson1WriteTreatmentResource = function (input, context) {
    return __assign(__assign(__assign(__assign(__assign(__assign(__assign({}, (input.CustomDeliveryConfiguration != null && {
        CustomDeliveryConfiguration: serializeAws_restJson1CustomDeliveryConfiguration(input.CustomDeliveryConfiguration, context),
    })), (input.MessageConfiguration != null && {
        MessageConfiguration: serializeAws_restJson1MessageConfiguration(input.MessageConfiguration, context),
    })), (input.Schedule != null && { Schedule: serializeAws_restJson1Schedule(input.Schedule, context) })), (input.SizePercent != null && { SizePercent: input.SizePercent })), (input.TemplateConfiguration != null && {
        TemplateConfiguration: serializeAws_restJson1TemplateConfiguration(input.TemplateConfiguration, context),
    })), (input.TreatmentDescription != null && { TreatmentDescription: input.TreatmentDescription })), (input.TreatmentName != null && { TreatmentName: input.TreatmentName }));
};
var deserializeAws_restJson1ActivitiesResponse = function (output, context) {
    return {
        Item: output.Item != null ? deserializeAws_restJson1ListOfActivityResponse(output.Item, context) : undefined,
        NextToken: __expectString(output.NextToken),
    };
};
var deserializeAws_restJson1Activity = function (output, context) {
    return {
        CUSTOM: output.CUSTOM != null ? deserializeAws_restJson1CustomMessageActivity(output.CUSTOM, context) : undefined,
        ConditionalSplit: output.ConditionalSplit != null
            ? deserializeAws_restJson1ConditionalSplitActivity(output.ConditionalSplit, context)
            : undefined,
        ContactCenter: output.ContactCenter != null
            ? deserializeAws_restJson1ContactCenterActivity(output.ContactCenter, context)
            : undefined,
        Description: __expectString(output.Description),
        EMAIL: output.EMAIL != null ? deserializeAws_restJson1EmailMessageActivity(output.EMAIL, context) : undefined,
        Holdout: output.Holdout != null ? deserializeAws_restJson1HoldoutActivity(output.Holdout, context) : undefined,
        MultiCondition: output.MultiCondition != null
            ? deserializeAws_restJson1MultiConditionalSplitActivity(output.MultiCondition, context)
            : undefined,
        PUSH: output.PUSH != null ? deserializeAws_restJson1PushMessageActivity(output.PUSH, context) : undefined,
        RandomSplit: output.RandomSplit != null ? deserializeAws_restJson1RandomSplitActivity(output.RandomSplit, context) : undefined,
        SMS: output.SMS != null ? deserializeAws_restJson1SMSMessageActivity(output.SMS, context) : undefined,
        Wait: output.Wait != null ? deserializeAws_restJson1WaitActivity(output.Wait, context) : undefined,
    };
};
var deserializeAws_restJson1ActivityResponse = function (output, context) {
    return {
        ApplicationId: __expectString(output.ApplicationId),
        CampaignId: __expectString(output.CampaignId),
        End: __expectString(output.End),
        Id: __expectString(output.Id),
        Result: __expectString(output.Result),
        ScheduledStart: __expectString(output.ScheduledStart),
        Start: __expectString(output.Start),
        State: __expectString(output.State),
        SuccessfulEndpointCount: __expectInt32(output.SuccessfulEndpointCount),
        TimezonesCompletedCount: __expectInt32(output.TimezonesCompletedCount),
        TimezonesTotalCount: __expectInt32(output.TimezonesTotalCount),
        TotalEndpointCount: __expectInt32(output.TotalEndpointCount),
        TreatmentId: __expectString(output.TreatmentId),
    };
};
var deserializeAws_restJson1ADMChannelResponse = function (output, context) {
    return {
        ApplicationId: __expectString(output.ApplicationId),
        CreationDate: __expectString(output.CreationDate),
        Enabled: __expectBoolean(output.Enabled),
        HasCredential: __expectBoolean(output.HasCredential),
        Id: __expectString(output.Id),
        IsArchived: __expectBoolean(output.IsArchived),
        LastModifiedBy: __expectString(output.LastModifiedBy),
        LastModifiedDate: __expectString(output.LastModifiedDate),
        Platform: __expectString(output.Platform),
        Version: __expectInt32(output.Version),
    };
};
var deserializeAws_restJson1AndroidPushNotificationTemplate = function (output, context) {
    return {
        Action: __expectString(output.Action),
        Body: __expectString(output.Body),
        ImageIconUrl: __expectString(output.ImageIconUrl),
        ImageUrl: __expectString(output.ImageUrl),
        RawContent: __expectString(output.RawContent),
        SmallImageIconUrl: __expectString(output.SmallImageIconUrl),
        Sound: __expectString(output.Sound),
        Title: __expectString(output.Title),
        Url: __expectString(output.Url),
    };
};
var deserializeAws_restJson1APNSChannelResponse = function (output, context) {
    return {
        ApplicationId: __expectString(output.ApplicationId),
        CreationDate: __expectString(output.CreationDate),
        DefaultAuthenticationMethod: __expectString(output.DefaultAuthenticationMethod),
        Enabled: __expectBoolean(output.Enabled),
        HasCredential: __expectBoolean(output.HasCredential),
        HasTokenKey: __expectBoolean(output.HasTokenKey),
        Id: __expectString(output.Id),
        IsArchived: __expectBoolean(output.IsArchived),
        LastModifiedBy: __expectString(output.LastModifiedBy),
        LastModifiedDate: __expectString(output.LastModifiedDate),
        Platform: __expectString(output.Platform),
        Version: __expectInt32(output.Version),
    };
};
var deserializeAws_restJson1APNSPushNotificationTemplate = function (output, context) {
    return {
        Action: __expectString(output.Action),
        Body: __expectString(output.Body),
        MediaUrl: __expectString(output.MediaUrl),
        RawContent: __expectString(output.RawContent),
        Sound: __expectString(output.Sound),
        Title: __expectString(output.Title),
        Url: __expectString(output.Url),
    };
};
var deserializeAws_restJson1APNSSandboxChannelResponse = function (output, context) {
    return {
        ApplicationId: __expectString(output.ApplicationId),
        CreationDate: __expectString(output.CreationDate),
        DefaultAuthenticationMethod: __expectString(output.DefaultAuthenticationMethod),
        Enabled: __expectBoolean(output.Enabled),
        HasCredential: __expectBoolean(output.HasCredential),
        HasTokenKey: __expectBoolean(output.HasTokenKey),
        Id: __expectString(output.Id),
        IsArchived: __expectBoolean(output.IsArchived),
        LastModifiedBy: __expectString(output.LastModifiedBy),
        LastModifiedDate: __expectString(output.LastModifiedDate),
        Platform: __expectString(output.Platform),
        Version: __expectInt32(output.Version),
    };
};
var deserializeAws_restJson1APNSVoipChannelResponse = function (output, context) {
    return {
        ApplicationId: __expectString(output.ApplicationId),
        CreationDate: __expectString(output.CreationDate),
        DefaultAuthenticationMethod: __expectString(output.DefaultAuthenticationMethod),
        Enabled: __expectBoolean(output.Enabled),
        HasCredential: __expectBoolean(output.HasCredential),
        HasTokenKey: __expectBoolean(output.HasTokenKey),
        Id: __expectString(output.Id),
        IsArchived: __expectBoolean(output.IsArchived),
        LastModifiedBy: __expectString(output.LastModifiedBy),
        LastModifiedDate: __expectString(output.LastModifiedDate),
        Platform: __expectString(output.Platform),
        Version: __expectInt32(output.Version),
    };
};
var deserializeAws_restJson1APNSVoipSandboxChannelResponse = function (output, context) {
    return {
        ApplicationId: __expectString(output.ApplicationId),
        CreationDate: __expectString(output.CreationDate),
        DefaultAuthenticationMethod: __expectString(output.DefaultAuthenticationMethod),
        Enabled: __expectBoolean(output.Enabled),
        HasCredential: __expectBoolean(output.HasCredential),
        HasTokenKey: __expectBoolean(output.HasTokenKey),
        Id: __expectString(output.Id),
        IsArchived: __expectBoolean(output.IsArchived),
        LastModifiedBy: __expectString(output.LastModifiedBy),
        LastModifiedDate: __expectString(output.LastModifiedDate),
        Platform: __expectString(output.Platform),
        Version: __expectInt32(output.Version),
    };
};
var deserializeAws_restJson1ApplicationDateRangeKpiResponse = function (output, context) {
    return {
        ApplicationId: __expectString(output.ApplicationId),
        EndTime: output.EndTime != null ? __expectNonNull(__parseRfc3339DateTime(output.EndTime)) : undefined,
        KpiName: __expectString(output.KpiName),
        KpiResult: output.KpiResult != null ? deserializeAws_restJson1BaseKpiResult(output.KpiResult, context) : undefined,
        NextToken: __expectString(output.NextToken),
        StartTime: output.StartTime != null ? __expectNonNull(__parseRfc3339DateTime(output.StartTime)) : undefined,
    };
};
var deserializeAws_restJson1ApplicationResponse = function (output, context) {
    return {
        Arn: __expectString(output.Arn),
        CreationDate: __expectString(output.CreationDate),
        Id: __expectString(output.Id),
        Name: __expectString(output.Name),
        tags: output.tags != null ? deserializeAws_restJson1MapOf__string(output.tags, context) : undefined,
    };
};
var deserializeAws_restJson1ApplicationSettingsResource = function (output, context) {
    return {
        ApplicationId: __expectString(output.ApplicationId),
        CampaignHook: output.CampaignHook != null ? deserializeAws_restJson1CampaignHook(output.CampaignHook, context) : undefined,
        LastModifiedDate: __expectString(output.LastModifiedDate),
        Limits: output.Limits != null ? deserializeAws_restJson1CampaignLimits(output.Limits, context) : undefined,
        QuietTime: output.QuietTime != null ? deserializeAws_restJson1QuietTime(output.QuietTime, context) : undefined,
    };
};
var deserializeAws_restJson1ApplicationsResponse = function (output, context) {
    return {
        Item: output.Item != null ? deserializeAws_restJson1ListOfApplicationResponse(output.Item, context) : undefined,
        NextToken: __expectString(output.NextToken),
    };
};
var deserializeAws_restJson1AttributeDimension = function (output, context) {
    return {
        AttributeType: __expectString(output.AttributeType),
        Values: output.Values != null ? deserializeAws_restJson1ListOf__string(output.Values, context) : undefined,
    };
};
var deserializeAws_restJson1AttributesResource = function (output, context) {
    return {
        ApplicationId: __expectString(output.ApplicationId),
        AttributeType: __expectString(output.AttributeType),
        Attributes: output.Attributes != null ? deserializeAws_restJson1ListOf__string(output.Attributes, context) : undefined,
    };
};
var deserializeAws_restJson1BaiduChannelResponse = function (output, context) {
    return {
        ApplicationId: __expectString(output.ApplicationId),
        CreationDate: __expectString(output.CreationDate),
        Credential: __expectString(output.Credential),
        Enabled: __expectBoolean(output.Enabled),
        HasCredential: __expectBoolean(output.HasCredential),
        Id: __expectString(output.Id),
        IsArchived: __expectBoolean(output.IsArchived),
        LastModifiedBy: __expectString(output.LastModifiedBy),
        LastModifiedDate: __expectString(output.LastModifiedDate),
        Platform: __expectString(output.Platform),
        Version: __expectInt32(output.Version),
    };
};
var deserializeAws_restJson1BaseKpiResult = function (output, context) {
    return {
        Rows: output.Rows != null ? deserializeAws_restJson1ListOfResultRow(output.Rows, context) : undefined,
    };
};
var deserializeAws_restJson1CampaignCustomMessage = function (output, context) {
    return {
        Data: __expectString(output.Data),
    };
};
var deserializeAws_restJson1CampaignDateRangeKpiResponse = function (output, context) {
    return {
        ApplicationId: __expectString(output.ApplicationId),
        CampaignId: __expectString(output.CampaignId),
        EndTime: output.EndTime != null ? __expectNonNull(__parseRfc3339DateTime(output.EndTime)) : undefined,
        KpiName: __expectString(output.KpiName),
        KpiResult: output.KpiResult != null ? deserializeAws_restJson1BaseKpiResult(output.KpiResult, context) : undefined,
        NextToken: __expectString(output.NextToken),
        StartTime: output.StartTime != null ? __expectNonNull(__parseRfc3339DateTime(output.StartTime)) : undefined,
    };
};
var deserializeAws_restJson1CampaignEmailMessage = function (output, context) {
    return {
        Body: __expectString(output.Body),
        FromAddress: __expectString(output.FromAddress),
        HtmlBody: __expectString(output.HtmlBody),
        Title: __expectString(output.Title),
    };
};
var deserializeAws_restJson1CampaignEventFilter = function (output, context) {
    return {
        Dimensions: output.Dimensions != null ? deserializeAws_restJson1EventDimensions(output.Dimensions, context) : undefined,
        FilterType: __expectString(output.FilterType),
    };
};
var deserializeAws_restJson1CampaignHook = function (output, context) {
    return {
        LambdaFunctionName: __expectString(output.LambdaFunctionName),
        Mode: __expectString(output.Mode),
        WebUrl: __expectString(output.WebUrl),
    };
};
var deserializeAws_restJson1CampaignInAppMessage = function (output, context) {
    return {
        Body: __expectString(output.Body),
        Content: output.Content != null ? deserializeAws_restJson1ListOfInAppMessageContent(output.Content, context) : undefined,
        CustomConfig: output.CustomConfig != null ? deserializeAws_restJson1MapOf__string(output.CustomConfig, context) : undefined,
        Layout: __expectString(output.Layout),
    };
};
var deserializeAws_restJson1CampaignLimits = function (output, context) {
    return {
        Daily: __expectInt32(output.Daily),
        MaximumDuration: __expectInt32(output.MaximumDuration),
        MessagesPerSecond: __expectInt32(output.MessagesPerSecond),
        Session: __expectInt32(output.Session),
        Total: __expectInt32(output.Total),
    };
};
var deserializeAws_restJson1CampaignResponse = function (output, context) {
    return {
        AdditionalTreatments: output.AdditionalTreatments != null
            ? deserializeAws_restJson1ListOfTreatmentResource(output.AdditionalTreatments, context)
            : undefined,
        ApplicationId: __expectString(output.ApplicationId),
        Arn: __expectString(output.Arn),
        CreationDate: __expectString(output.CreationDate),
        CustomDeliveryConfiguration: output.CustomDeliveryConfiguration != null
            ? deserializeAws_restJson1CustomDeliveryConfiguration(output.CustomDeliveryConfiguration, context)
            : undefined,
        DefaultState: output.DefaultState != null ? deserializeAws_restJson1CampaignState(output.DefaultState, context) : undefined,
        Description: __expectString(output.Description),
        HoldoutPercent: __expectInt32(output.HoldoutPercent),
        Hook: output.Hook != null ? deserializeAws_restJson1CampaignHook(output.Hook, context) : undefined,
        Id: __expectString(output.Id),
        IsPaused: __expectBoolean(output.IsPaused),
        LastModifiedDate: __expectString(output.LastModifiedDate),
        Limits: output.Limits != null ? deserializeAws_restJson1CampaignLimits(output.Limits, context) : undefined,
        MessageConfiguration: output.MessageConfiguration != null
            ? deserializeAws_restJson1MessageConfiguration(output.MessageConfiguration, context)
            : undefined,
        Name: __expectString(output.Name),
        Priority: __expectInt32(output.Priority),
        Schedule: output.Schedule != null ? deserializeAws_restJson1Schedule(output.Schedule, context) : undefined,
        SegmentId: __expectString(output.SegmentId),
        SegmentVersion: __expectInt32(output.SegmentVersion),
        State: output.State != null ? deserializeAws_restJson1CampaignState(output.State, context) : undefined,
        TemplateConfiguration: output.TemplateConfiguration != null
            ? deserializeAws_restJson1TemplateConfiguration(output.TemplateConfiguration, context)
            : undefined,
        TreatmentDescription: __expectString(output.TreatmentDescription),
        TreatmentName: __expectString(output.TreatmentName),
        Version: __expectInt32(output.Version),
        tags: output.tags != null ? deserializeAws_restJson1MapOf__string(output.tags, context) : undefined,
    };
};
var deserializeAws_restJson1CampaignSmsMessage = function (output, context) {
    return {
        Body: __expectString(output.Body),
        EntityId: __expectString(output.EntityId),
        MessageType: __expectString(output.MessageType),
        OriginationNumber: __expectString(output.OriginationNumber),
        SenderId: __expectString(output.SenderId),
        TemplateId: __expectString(output.TemplateId),
    };
};
var deserializeAws_restJson1CampaignsResponse = function (output, context) {
    return {
        Item: output.Item != null ? deserializeAws_restJson1ListOfCampaignResponse(output.Item, context) : undefined,
        NextToken: __expectString(output.NextToken),
    };
};
var deserializeAws_restJson1CampaignState = function (output, context) {
    return {
        CampaignStatus: __expectString(output.CampaignStatus),
    };
};
var deserializeAws_restJson1ChannelResponse = function (output, context) {
    return {
        ApplicationId: __expectString(output.ApplicationId),
        CreationDate: __expectString(output.CreationDate),
        Enabled: __expectBoolean(output.Enabled),
        HasCredential: __expectBoolean(output.HasCredential),
        Id: __expectString(output.Id),
        IsArchived: __expectBoolean(output.IsArchived),
        LastModifiedBy: __expectString(output.LastModifiedBy),
        LastModifiedDate: __expectString(output.LastModifiedDate),
        Version: __expectInt32(output.Version),
    };
};
var deserializeAws_restJson1ChannelsResponse = function (output, context) {
    return {
        Channels: output.Channels != null ? deserializeAws_restJson1MapOfChannelResponse(output.Channels, context) : undefined,
    };
};
var deserializeAws_restJson1ClosedDays = function (output, context) {
    return {
        CUSTOM: output.CUSTOM != null ? deserializeAws_restJson1ListOfClosedDaysRules(output.CUSTOM, context) : undefined,
        EMAIL: output.EMAIL != null ? deserializeAws_restJson1ListOfClosedDaysRules(output.EMAIL, context) : undefined,
        PUSH: output.PUSH != null ? deserializeAws_restJson1ListOfClosedDaysRules(output.PUSH, context) : undefined,
        SMS: output.SMS != null ? deserializeAws_restJson1ListOfClosedDaysRules(output.SMS, context) : undefined,
        VOICE: output.VOICE != null ? deserializeAws_restJson1ListOfClosedDaysRules(output.VOICE, context) : undefined,
    };
};
var deserializeAws_restJson1ClosedDaysRule = function (output, context) {
    return {
        EndDateTime: __expectString(output.EndDateTime),
        Name: __expectString(output.Name),
        StartDateTime: __expectString(output.StartDateTime),
    };
};
var deserializeAws_restJson1Condition = function (output, context) {
    return {
        Conditions: output.Conditions != null ? deserializeAws_restJson1ListOfSimpleCondition(output.Conditions, context) : undefined,
        Operator: __expectString(output.Operator),
    };
};
var deserializeAws_restJson1ConditionalSplitActivity = function (output, context) {
    return {
        Condition: output.Condition != null ? deserializeAws_restJson1Condition(output.Condition, context) : undefined,
        EvaluationWaitTime: output.EvaluationWaitTime != null
            ? deserializeAws_restJson1WaitTime(output.EvaluationWaitTime, context)
            : undefined,
        FalseActivity: __expectString(output.FalseActivity),
        TrueActivity: __expectString(output.TrueActivity),
    };
};
var deserializeAws_restJson1ContactCenterActivity = function (output, context) {
    return {
        NextActivity: __expectString(output.NextActivity),
    };
};
var deserializeAws_restJson1CreateTemplateMessageBody = function (output, context) {
    return {
        Arn: __expectString(output.Arn),
        Message: __expectString(output.Message),
        RequestID: __expectString(output.RequestID),
    };
};
var deserializeAws_restJson1CustomDeliveryConfiguration = function (output, context) {
    return {
        DeliveryUri: __expectString(output.DeliveryUri),
        EndpointTypes: output.EndpointTypes != null
            ? deserializeAws_restJson1ListOf__EndpointTypesElement(output.EndpointTypes, context)
            : undefined,
    };
};
var deserializeAws_restJson1CustomMessageActivity = function (output, context) {
    return {
        DeliveryUri: __expectString(output.DeliveryUri),
        EndpointTypes: output.EndpointTypes != null
            ? deserializeAws_restJson1ListOf__EndpointTypesElement(output.EndpointTypes, context)
            : undefined,
        MessageConfig: output.MessageConfig != null
            ? deserializeAws_restJson1JourneyCustomMessage(output.MessageConfig, context)
            : undefined,
        NextActivity: __expectString(output.NextActivity),
        TemplateName: __expectString(output.TemplateName),
        TemplateVersion: __expectString(output.TemplateVersion),
    };
};
var deserializeAws_restJson1DefaultButtonConfiguration = function (output, context) {
    return {
        BackgroundColor: __expectString(output.BackgroundColor),
        BorderRadius: __expectInt32(output.BorderRadius),
        ButtonAction: __expectString(output.ButtonAction),
        Link: __expectString(output.Link),
        Text: __expectString(output.Text),
        TextColor: __expectString(output.TextColor),
    };
};
var deserializeAws_restJson1DefaultPushNotificationTemplate = function (output, context) {
    return {
        Action: __expectString(output.Action),
        Body: __expectString(output.Body),
        Sound: __expectString(output.Sound),
        Title: __expectString(output.Title),
        Url: __expectString(output.Url),
    };
};
var deserializeAws_restJson1EmailChannelResponse = function (output, context) {
    return {
        ApplicationId: __expectString(output.ApplicationId),
        ConfigurationSet: __expectString(output.ConfigurationSet),
        CreationDate: __expectString(output.CreationDate),
        Enabled: __expectBoolean(output.Enabled),
        FromAddress: __expectString(output.FromAddress),
        HasCredential: __expectBoolean(output.HasCredential),
        Id: __expectString(output.Id),
        Identity: __expectString(output.Identity),
        IsArchived: __expectBoolean(output.IsArchived),
        LastModifiedBy: __expectString(output.LastModifiedBy),
        LastModifiedDate: __expectString(output.LastModifiedDate),
        MessagesPerSecond: __expectInt32(output.MessagesPerSecond),
        Platform: __expectString(output.Platform),
        RoleArn: __expectString(output.RoleArn),
        Version: __expectInt32(output.Version),
    };
};
var deserializeAws_restJson1EmailMessageActivity = function (output, context) {
    return {
        MessageConfig: output.MessageConfig != null
            ? deserializeAws_restJson1JourneyEmailMessage(output.MessageConfig, context)
            : undefined,
        NextActivity: __expectString(output.NextActivity),
        TemplateName: __expectString(output.TemplateName),
        TemplateVersion: __expectString(output.TemplateVersion),
    };
};
var deserializeAws_restJson1EmailTemplateResponse = function (output, context) {
    return {
        Arn: __expectString(output.Arn),
        CreationDate: __expectString(output.CreationDate),
        DefaultSubstitutions: __expectString(output.DefaultSubstitutions),
        HtmlPart: __expectString(output.HtmlPart),
        LastModifiedDate: __expectString(output.LastModifiedDate),
        RecommenderId: __expectString(output.RecommenderId),
        Subject: __expectString(output.Subject),
        TemplateDescription: __expectString(output.TemplateDescription),
        TemplateName: __expectString(output.TemplateName),
        TemplateType: __expectString(output.TemplateType),
        TextPart: __expectString(output.TextPart),
        Version: __expectString(output.Version),
        tags: output.tags != null ? deserializeAws_restJson1MapOf__string(output.tags, context) : undefined,
    };
};
var deserializeAws_restJson1EndpointDemographic = function (output, context) {
    return {
        AppVersion: __expectString(output.AppVersion),
        Locale: __expectString(output.Locale),
        Make: __expectString(output.Make),
        Model: __expectString(output.Model),
        ModelVersion: __expectString(output.ModelVersion),
        Platform: __expectString(output.Platform),
        PlatformVersion: __expectString(output.PlatformVersion),
        Timezone: __expectString(output.Timezone),
    };
};
var deserializeAws_restJson1EndpointItemResponse = function (output, context) {
    return {
        Message: __expectString(output.Message),
        StatusCode: __expectInt32(output.StatusCode),
    };
};
var deserializeAws_restJson1EndpointLocation = function (output, context) {
    return {
        City: __expectString(output.City),
        Country: __expectString(output.Country),
        Latitude: __limitedParseDouble(output.Latitude),
        Longitude: __limitedParseDouble(output.Longitude),
        PostalCode: __expectString(output.PostalCode),
        Region: __expectString(output.Region),
    };
};
var deserializeAws_restJson1EndpointMessageResult = function (output, context) {
    return {
        Address: __expectString(output.Address),
        DeliveryStatus: __expectString(output.DeliveryStatus),
        MessageId: __expectString(output.MessageId),
        StatusCode: __expectInt32(output.StatusCode),
        StatusMessage: __expectString(output.StatusMessage),
        UpdatedToken: __expectString(output.UpdatedToken),
    };
};
var deserializeAws_restJson1EndpointResponse = function (output, context) {
    return {
        Address: __expectString(output.Address),
        ApplicationId: __expectString(output.ApplicationId),
        Attributes: output.Attributes != null ? deserializeAws_restJson1MapOfListOf__string(output.Attributes, context) : undefined,
        ChannelType: __expectString(output.ChannelType),
        CohortId: __expectString(output.CohortId),
        CreationDate: __expectString(output.CreationDate),
        Demographic: output.Demographic != null ? deserializeAws_restJson1EndpointDemographic(output.Demographic, context) : undefined,
        EffectiveDate: __expectString(output.EffectiveDate),
        EndpointStatus: __expectString(output.EndpointStatus),
        Id: __expectString(output.Id),
        Location: output.Location != null ? deserializeAws_restJson1EndpointLocation(output.Location, context) : undefined,
        Metrics: output.Metrics != null ? deserializeAws_restJson1MapOf__double(output.Metrics, context) : undefined,
        OptOut: __expectString(output.OptOut),
        RequestId: __expectString(output.RequestId),
        User: output.User != null ? deserializeAws_restJson1EndpointUser(output.User, context) : undefined,
    };
};
var deserializeAws_restJson1EndpointsResponse = function (output, context) {
    return {
        Item: output.Item != null ? deserializeAws_restJson1ListOfEndpointResponse(output.Item, context) : undefined,
    };
};
var deserializeAws_restJson1EndpointUser = function (output, context) {
    return {
        UserAttributes: output.UserAttributes != null
            ? deserializeAws_restJson1MapOfListOf__string(output.UserAttributes, context)
            : undefined,
        UserId: __expectString(output.UserId),
    };
};
var deserializeAws_restJson1EventCondition = function (output, context) {
    return {
        Dimensions: output.Dimensions != null ? deserializeAws_restJson1EventDimensions(output.Dimensions, context) : undefined,
        MessageActivity: __expectString(output.MessageActivity),
    };
};
var deserializeAws_restJson1EventDimensions = function (output, context) {
    return {
        Attributes: output.Attributes != null
            ? deserializeAws_restJson1MapOfAttributeDimension(output.Attributes, context)
            : undefined,
        EventType: output.EventType != null ? deserializeAws_restJson1SetDimension(output.EventType, context) : undefined,
        Metrics: output.Metrics != null ? deserializeAws_restJson1MapOfMetricDimension(output.Metrics, context) : undefined,
    };
};
var deserializeAws_restJson1EventFilter = function (output, context) {
    return {
        Dimensions: output.Dimensions != null ? deserializeAws_restJson1EventDimensions(output.Dimensions, context) : undefined,
        FilterType: __expectString(output.FilterType),
    };
};
var deserializeAws_restJson1EventItemResponse = function (output, context) {
    return {
        Message: __expectString(output.Message),
        StatusCode: __expectInt32(output.StatusCode),
    };
};
var deserializeAws_restJson1EventsResponse = function (output, context) {
    return {
        Results: output.Results != null ? deserializeAws_restJson1MapOfItemResponse(output.Results, context) : undefined,
    };
};
var deserializeAws_restJson1EventStartCondition = function (output, context) {
    return {
        EventFilter: output.EventFilter != null ? deserializeAws_restJson1EventFilter(output.EventFilter, context) : undefined,
        SegmentId: __expectString(output.SegmentId),
    };
};
var deserializeAws_restJson1EventStream = function (output, context) {
    return {
        ApplicationId: __expectString(output.ApplicationId),
        DestinationStreamArn: __expectString(output.DestinationStreamArn),
        ExternalId: __expectString(output.ExternalId),
        LastModifiedDate: __expectString(output.LastModifiedDate),
        LastUpdatedBy: __expectString(output.LastUpdatedBy),
        RoleArn: __expectString(output.RoleArn),
    };
};
var deserializeAws_restJson1ExportJobResource = function (output, context) {
    return {
        RoleArn: __expectString(output.RoleArn),
        S3UrlPrefix: __expectString(output.S3UrlPrefix),
        SegmentId: __expectString(output.SegmentId),
        SegmentVersion: __expectInt32(output.SegmentVersion),
    };
};
var deserializeAws_restJson1ExportJobResponse = function (output, context) {
    return {
        ApplicationId: __expectString(output.ApplicationId),
        CompletedPieces: __expectInt32(output.CompletedPieces),
        CompletionDate: __expectString(output.CompletionDate),
        CreationDate: __expectString(output.CreationDate),
        Definition: output.Definition != null ? deserializeAws_restJson1ExportJobResource(output.Definition, context) : undefined,
        FailedPieces: __expectInt32(output.FailedPieces),
        Failures: output.Failures != null ? deserializeAws_restJson1ListOf__string(output.Failures, context) : undefined,
        Id: __expectString(output.Id),
        JobStatus: __expectString(output.JobStatus),
        TotalFailures: __expectInt32(output.TotalFailures),
        TotalPieces: __expectInt32(output.TotalPieces),
        TotalProcessed: __expectInt32(output.TotalProcessed),
        Type: __expectString(output.Type),
    };
};
var deserializeAws_restJson1ExportJobsResponse = function (output, context) {
    return {
        Item: output.Item != null ? deserializeAws_restJson1ListOfExportJobResponse(output.Item, context) : undefined,
        NextToken: __expectString(output.NextToken),
    };
};
var deserializeAws_restJson1GCMChannelResponse = function (output, context) {
    return {
        ApplicationId: __expectString(output.ApplicationId),
        CreationDate: __expectString(output.CreationDate),
        Credential: __expectString(output.Credential),
        Enabled: __expectBoolean(output.Enabled),
        HasCredential: __expectBoolean(output.HasCredential),
        Id: __expectString(output.Id),
        IsArchived: __expectBoolean(output.IsArchived),
        LastModifiedBy: __expectString(output.LastModifiedBy),
        LastModifiedDate: __expectString(output.LastModifiedDate),
        Platform: __expectString(output.Platform),
        Version: __expectInt32(output.Version),
    };
};
var deserializeAws_restJson1GPSCoordinates = function (output, context) {
    return {
        Latitude: __limitedParseDouble(output.Latitude),
        Longitude: __limitedParseDouble(output.Longitude),
    };
};
var deserializeAws_restJson1GPSPointDimension = function (output, context) {
    return {
        Coordinates: output.Coordinates != null ? deserializeAws_restJson1GPSCoordinates(output.Coordinates, context) : undefined,
        RangeInKilometers: __limitedParseDouble(output.RangeInKilometers),
    };
};
var deserializeAws_restJson1HoldoutActivity = function (output, context) {
    return {
        NextActivity: __expectString(output.NextActivity),
        Percentage: __expectInt32(output.Percentage),
    };
};
var deserializeAws_restJson1ImportJobResource = function (output, context) {
    return {
        DefineSegment: __expectBoolean(output.DefineSegment),
        ExternalId: __expectString(output.ExternalId),
        Format: __expectString(output.Format),
        RegisterEndpoints: __expectBoolean(output.RegisterEndpoints),
        RoleArn: __expectString(output.RoleArn),
        S3Url: __expectString(output.S3Url),
        SegmentId: __expectString(output.SegmentId),
        SegmentName: __expectString(output.SegmentName),
    };
};
var deserializeAws_restJson1ImportJobResponse = function (output, context) {
    return {
        ApplicationId: __expectString(output.ApplicationId),
        CompletedPieces: __expectInt32(output.CompletedPieces),
        CompletionDate: __expectString(output.CompletionDate),
        CreationDate: __expectString(output.CreationDate),
        Definition: output.Definition != null ? deserializeAws_restJson1ImportJobResource(output.Definition, context) : undefined,
        FailedPieces: __expectInt32(output.FailedPieces),
        Failures: output.Failures != null ? deserializeAws_restJson1ListOf__string(output.Failures, context) : undefined,
        Id: __expectString(output.Id),
        JobStatus: __expectString(output.JobStatus),
        TotalFailures: __expectInt32(output.TotalFailures),
        TotalPieces: __expectInt32(output.TotalPieces),
        TotalProcessed: __expectInt32(output.TotalProcessed),
        Type: __expectString(output.Type),
    };
};
var deserializeAws_restJson1ImportJobsResponse = function (output, context) {
    return {
        Item: output.Item != null ? deserializeAws_restJson1ListOfImportJobResponse(output.Item, context) : undefined,
        NextToken: __expectString(output.NextToken),
    };
};
var deserializeAws_restJson1InAppCampaignSchedule = function (output, context) {
    return {
        EndDate: __expectString(output.EndDate),
        EventFilter: output.EventFilter != null ? deserializeAws_restJson1CampaignEventFilter(output.EventFilter, context) : undefined,
        QuietTime: output.QuietTime != null ? deserializeAws_restJson1QuietTime(output.QuietTime, context) : undefined,
    };
};
var deserializeAws_restJson1InAppMessage = function (output, context) {
    return {
        Content: output.Content != null ? deserializeAws_restJson1ListOfInAppMessageContent(output.Content, context) : undefined,
        CustomConfig: output.CustomConfig != null ? deserializeAws_restJson1MapOf__string(output.CustomConfig, context) : undefined,
        Layout: __expectString(output.Layout),
    };
};
var deserializeAws_restJson1InAppMessageBodyConfig = function (output, context) {
    return {
        Alignment: __expectString(output.Alignment),
        Body: __expectString(output.Body),
        TextColor: __expectString(output.TextColor),
    };
};
var deserializeAws_restJson1InAppMessageButton = function (output, context) {
    return {
        Android: output.Android != null ? deserializeAws_restJson1OverrideButtonConfiguration(output.Android, context) : undefined,
        DefaultConfig: output.DefaultConfig != null
            ? deserializeAws_restJson1DefaultButtonConfiguration(output.DefaultConfig, context)
            : undefined,
        IOS: output.IOS != null ? deserializeAws_restJson1OverrideButtonConfiguration(output.IOS, context) : undefined,
        Web: output.Web != null ? deserializeAws_restJson1OverrideButtonConfiguration(output.Web, context) : undefined,
    };
};
var deserializeAws_restJson1InAppMessageCampaign = function (output, context) {
    return {
        CampaignId: __expectString(output.CampaignId),
        DailyCap: __expectInt32(output.DailyCap),
        InAppMessage: output.InAppMessage != null ? deserializeAws_restJson1InAppMessage(output.InAppMessage, context) : undefined,
        Priority: __expectInt32(output.Priority),
        Schedule: output.Schedule != null ? deserializeAws_restJson1InAppCampaignSchedule(output.Schedule, context) : undefined,
        SessionCap: __expectInt32(output.SessionCap),
        TotalCap: __expectInt32(output.TotalCap),
        TreatmentId: __expectString(output.TreatmentId),
    };
};
var deserializeAws_restJson1InAppMessageContent = function (output, context) {
    return {
        BackgroundColor: __expectString(output.BackgroundColor),
        BodyConfig: output.BodyConfig != null
            ? deserializeAws_restJson1InAppMessageBodyConfig(output.BodyConfig, context)
            : undefined,
        HeaderConfig: output.HeaderConfig != null
            ? deserializeAws_restJson1InAppMessageHeaderConfig(output.HeaderConfig, context)
            : undefined,
        ImageUrl: __expectString(output.ImageUrl),
        PrimaryBtn: output.PrimaryBtn != null ? deserializeAws_restJson1InAppMessageButton(output.PrimaryBtn, context) : undefined,
        SecondaryBtn: output.SecondaryBtn != null
            ? deserializeAws_restJson1InAppMessageButton(output.SecondaryBtn, context)
            : undefined,
    };
};
var deserializeAws_restJson1InAppMessageHeaderConfig = function (output, context) {
    return {
        Alignment: __expectString(output.Alignment),
        Header: __expectString(output.Header),
        TextColor: __expectString(output.TextColor),
    };
};
var deserializeAws_restJson1InAppMessagesResponse = function (output, context) {
    return {
        InAppMessageCampaigns: output.InAppMessageCampaigns != null
            ? deserializeAws_restJson1ListOfInAppMessageCampaign(output.InAppMessageCampaigns, context)
            : undefined,
    };
};
var deserializeAws_restJson1InAppTemplateResponse = function (output, context) {
    return {
        Arn: __expectString(output.Arn),
        Content: output.Content != null ? deserializeAws_restJson1ListOfInAppMessageContent(output.Content, context) : undefined,
        CreationDate: __expectString(output.CreationDate),
        CustomConfig: output.CustomConfig != null ? deserializeAws_restJson1MapOf__string(output.CustomConfig, context) : undefined,
        LastModifiedDate: __expectString(output.LastModifiedDate),
        Layout: __expectString(output.Layout),
        TemplateDescription: __expectString(output.TemplateDescription),
        TemplateName: __expectString(output.TemplateName),
        TemplateType: __expectString(output.TemplateType),
        Version: __expectString(output.Version),
        tags: output.tags != null ? deserializeAws_restJson1MapOf__string(output.tags, context) : undefined,
    };
};
var deserializeAws_restJson1ItemResponse = function (output, context) {
    return {
        EndpointItemResponse: output.EndpointItemResponse != null
            ? deserializeAws_restJson1EndpointItemResponse(output.EndpointItemResponse, context)
            : undefined,
        EventsItemResponse: output.EventsItemResponse != null
            ? deserializeAws_restJson1MapOfEventItemResponse(output.EventsItemResponse, context)
            : undefined,
    };
};
var deserializeAws_restJson1JourneyChannelSettings = function (output, context) {
    return {
        ConnectCampaignArn: __expectString(output.ConnectCampaignArn),
        ConnectCampaignExecutionRoleArn: __expectString(output.ConnectCampaignExecutionRoleArn),
    };
};
var deserializeAws_restJson1JourneyCustomMessage = function (output, context) {
    return {
        Data: __expectString(output.Data),
    };
};
var deserializeAws_restJson1JourneyDateRangeKpiResponse = function (output, context) {
    return {
        ApplicationId: __expectString(output.ApplicationId),
        EndTime: output.EndTime != null ? __expectNonNull(__parseRfc3339DateTime(output.EndTime)) : undefined,
        JourneyId: __expectString(output.JourneyId),
        KpiName: __expectString(output.KpiName),
        KpiResult: output.KpiResult != null ? deserializeAws_restJson1BaseKpiResult(output.KpiResult, context) : undefined,
        NextToken: __expectString(output.NextToken),
        StartTime: output.StartTime != null ? __expectNonNull(__parseRfc3339DateTime(output.StartTime)) : undefined,
    };
};
var deserializeAws_restJson1JourneyEmailMessage = function (output, context) {
    return {
        FromAddress: __expectString(output.FromAddress),
    };
};
var deserializeAws_restJson1JourneyExecutionActivityMetricsResponse = function (output, context) {
    return {
        ActivityType: __expectString(output.ActivityType),
        ApplicationId: __expectString(output.ApplicationId),
        JourneyActivityId: __expectString(output.JourneyActivityId),
        JourneyId: __expectString(output.JourneyId),
        LastEvaluatedTime: __expectString(output.LastEvaluatedTime),
        Metrics: output.Metrics != null ? deserializeAws_restJson1MapOf__string(output.Metrics, context) : undefined,
    };
};
var deserializeAws_restJson1JourneyExecutionMetricsResponse = function (output, context) {
    return {
        ApplicationId: __expectString(output.ApplicationId),
        JourneyId: __expectString(output.JourneyId),
        LastEvaluatedTime: __expectString(output.LastEvaluatedTime),
        Metrics: output.Metrics != null ? deserializeAws_restJson1MapOf__string(output.Metrics, context) : undefined,
    };
};
var deserializeAws_restJson1JourneyLimits = function (output, context) {
    return {
        DailyCap: __expectInt32(output.DailyCap),
        EndpointReentryCap: __expectInt32(output.EndpointReentryCap),
        EndpointReentryInterval: __expectString(output.EndpointReentryInterval),
        MessagesPerSecond: __expectInt32(output.MessagesPerSecond),
    };
};
var deserializeAws_restJson1JourneyPushMessage = function (output, context) {
    return {
        TimeToLive: __expectString(output.TimeToLive),
    };
};
var deserializeAws_restJson1JourneyResponse = function (output, context) {
    return {
        Activities: output.Activities != null ? deserializeAws_restJson1MapOfActivity(output.Activities, context) : undefined,
        ApplicationId: __expectString(output.ApplicationId),
        ClosedDays: output.ClosedDays != null ? deserializeAws_restJson1ClosedDays(output.ClosedDays, context) : undefined,
        CreationDate: __expectString(output.CreationDate),
        Id: __expectString(output.Id),
        JourneyChannelSettings: output.JourneyChannelSettings != null
            ? deserializeAws_restJson1JourneyChannelSettings(output.JourneyChannelSettings, context)
            : undefined,
        LastModifiedDate: __expectString(output.LastModifiedDate),
        Limits: output.Limits != null ? deserializeAws_restJson1JourneyLimits(output.Limits, context) : undefined,
        LocalTime: __expectBoolean(output.LocalTime),
        Name: __expectString(output.Name),
        OpenHours: output.OpenHours != null ? deserializeAws_restJson1OpenHours(output.OpenHours, context) : undefined,
        QuietTime: output.QuietTime != null ? deserializeAws_restJson1QuietTime(output.QuietTime, context) : undefined,
        RefreshFrequency: __expectString(output.RefreshFrequency),
        RefreshOnSegmentUpdate: __expectBoolean(output.RefreshOnSegmentUpdate),
        Schedule: output.Schedule != null ? deserializeAws_restJson1JourneySchedule(output.Schedule, context) : undefined,
        SendingSchedule: __expectBoolean(output.SendingSchedule),
        StartActivity: __expectString(output.StartActivity),
        StartCondition: output.StartCondition != null
            ? deserializeAws_restJson1StartCondition(output.StartCondition, context)
            : undefined,
        State: __expectString(output.State),
        WaitForQuietTime: __expectBoolean(output.WaitForQuietTime),
        tags: output.tags != null ? deserializeAws_restJson1MapOf__string(output.tags, context) : undefined,
    };
};
var deserializeAws_restJson1JourneySchedule = function (output, context) {
    return {
        EndTime: output.EndTime != null ? __expectNonNull(__parseRfc3339DateTime(output.EndTime)) : undefined,
        StartTime: output.StartTime != null ? __expectNonNull(__parseRfc3339DateTime(output.StartTime)) : undefined,
        Timezone: __expectString(output.Timezone),
    };
};
var deserializeAws_restJson1JourneySMSMessage = function (output, context) {
    return {
        EntityId: __expectString(output.EntityId),
        MessageType: __expectString(output.MessageType),
        OriginationNumber: __expectString(output.OriginationNumber),
        SenderId: __expectString(output.SenderId),
        TemplateId: __expectString(output.TemplateId),
    };
};
var deserializeAws_restJson1JourneysResponse = function (output, context) {
    return {
        Item: output.Item != null ? deserializeAws_restJson1ListOfJourneyResponse(output.Item, context) : undefined,
        NextToken: __expectString(output.NextToken),
    };
};
var deserializeAws_restJson1ListOf__EndpointTypesElement = function (output, context) {
    var retVal = (output || [])
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return __expectString(entry);
    });
    return retVal;
};
var deserializeAws_restJson1ListOf__string = function (output, context) {
    var retVal = (output || [])
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return __expectString(entry);
    });
    return retVal;
};
var deserializeAws_restJson1ListOfActivityResponse = function (output, context) {
    var retVal = (output || [])
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return deserializeAws_restJson1ActivityResponse(entry, context);
    });
    return retVal;
};
var deserializeAws_restJson1ListOfApplicationResponse = function (output, context) {
    var retVal = (output || [])
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return deserializeAws_restJson1ApplicationResponse(entry, context);
    });
    return retVal;
};
var deserializeAws_restJson1ListOfCampaignResponse = function (output, context) {
    var retVal = (output || [])
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return deserializeAws_restJson1CampaignResponse(entry, context);
    });
    return retVal;
};
var deserializeAws_restJson1ListOfClosedDaysRules = function (output, context) {
    var retVal = (output || [])
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return deserializeAws_restJson1ClosedDaysRule(entry, context);
    });
    return retVal;
};
var deserializeAws_restJson1ListOfEndpointResponse = function (output, context) {
    var retVal = (output || [])
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return deserializeAws_restJson1EndpointResponse(entry, context);
    });
    return retVal;
};
var deserializeAws_restJson1ListOfExportJobResponse = function (output, context) {
    var retVal = (output || [])
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return deserializeAws_restJson1ExportJobResponse(entry, context);
    });
    return retVal;
};
var deserializeAws_restJson1ListOfImportJobResponse = function (output, context) {
    var retVal = (output || [])
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return deserializeAws_restJson1ImportJobResponse(entry, context);
    });
    return retVal;
};
var deserializeAws_restJson1ListOfInAppMessageCampaign = function (output, context) {
    var retVal = (output || [])
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return deserializeAws_restJson1InAppMessageCampaign(entry, context);
    });
    return retVal;
};
var deserializeAws_restJson1ListOfInAppMessageContent = function (output, context) {
    var retVal = (output || [])
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return deserializeAws_restJson1InAppMessageContent(entry, context);
    });
    return retVal;
};
var deserializeAws_restJson1ListOfJourneyResponse = function (output, context) {
    var retVal = (output || [])
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return deserializeAws_restJson1JourneyResponse(entry, context);
    });
    return retVal;
};
var deserializeAws_restJson1ListOfMultiConditionalBranch = function (output, context) {
    var retVal = (output || [])
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return deserializeAws_restJson1MultiConditionalBranch(entry, context);
    });
    return retVal;
};
var deserializeAws_restJson1ListOfOpenHoursRules = function (output, context) {
    var retVal = (output || [])
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return deserializeAws_restJson1OpenHoursRule(entry, context);
    });
    return retVal;
};
var deserializeAws_restJson1ListOfRandomSplitEntry = function (output, context) {
    var retVal = (output || [])
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return deserializeAws_restJson1RandomSplitEntry(entry, context);
    });
    return retVal;
};
var deserializeAws_restJson1ListOfRecommenderConfigurationResponse = function (output, context) {
    var retVal = (output || [])
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return deserializeAws_restJson1RecommenderConfigurationResponse(entry, context);
    });
    return retVal;
};
var deserializeAws_restJson1ListOfResultRow = function (output, context) {
    var retVal = (output || [])
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return deserializeAws_restJson1ResultRow(entry, context);
    });
    return retVal;
};
var deserializeAws_restJson1ListOfResultRowValue = function (output, context) {
    var retVal = (output || [])
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return deserializeAws_restJson1ResultRowValue(entry, context);
    });
    return retVal;
};
var deserializeAws_restJson1ListOfSegmentDimensions = function (output, context) {
    var retVal = (output || [])
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return deserializeAws_restJson1SegmentDimensions(entry, context);
    });
    return retVal;
};
var deserializeAws_restJson1ListOfSegmentGroup = function (output, context) {
    var retVal = (output || [])
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return deserializeAws_restJson1SegmentGroup(entry, context);
    });
    return retVal;
};
var deserializeAws_restJson1ListOfSegmentReference = function (output, context) {
    var retVal = (output || [])
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return deserializeAws_restJson1SegmentReference(entry, context);
    });
    return retVal;
};
var deserializeAws_restJson1ListOfSegmentResponse = function (output, context) {
    var retVal = (output || [])
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return deserializeAws_restJson1SegmentResponse(entry, context);
    });
    return retVal;
};
var deserializeAws_restJson1ListOfSimpleCondition = function (output, context) {
    var retVal = (output || [])
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return deserializeAws_restJson1SimpleCondition(entry, context);
    });
    return retVal;
};
var deserializeAws_restJson1ListOfTemplateResponse = function (output, context) {
    var retVal = (output || [])
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return deserializeAws_restJson1TemplateResponse(entry, context);
    });
    return retVal;
};
var deserializeAws_restJson1ListOfTemplateVersionResponse = function (output, context) {
    var retVal = (output || [])
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return deserializeAws_restJson1TemplateVersionResponse(entry, context);
    });
    return retVal;
};
var deserializeAws_restJson1ListOfTreatmentResource = function (output, context) {
    var retVal = (output || [])
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return deserializeAws_restJson1TreatmentResource(entry, context);
    });
    return retVal;
};
var deserializeAws_restJson1ListRecommenderConfigurationsResponse = function (output, context) {
    return {
        Item: output.Item != null
            ? deserializeAws_restJson1ListOfRecommenderConfigurationResponse(output.Item, context)
            : undefined,
        NextToken: __expectString(output.NextToken),
    };
};
var deserializeAws_restJson1MapOf__double = function (output, context) {
    return Object.entries(output).reduce(function (acc, _a) {
        var _b;
        var _c = __read(_a, 2), key = _c[0], value = _c[1];
        if (value === null) {
            return acc;
        }
        return __assign(__assign({}, acc), (_b = {}, _b[key] = __limitedParseDouble(value), _b));
    }, {});
};
var deserializeAws_restJson1MapOf__integer = function (output, context) {
    return Object.entries(output).reduce(function (acc, _a) {
        var _b;
        var _c = __read(_a, 2), key = _c[0], value = _c[1];
        if (value === null) {
            return acc;
        }
        return __assign(__assign({}, acc), (_b = {}, _b[key] = __expectInt32(value), _b));
    }, {});
};
var deserializeAws_restJson1MapOf__string = function (output, context) {
    return Object.entries(output).reduce(function (acc, _a) {
        var _b;
        var _c = __read(_a, 2), key = _c[0], value = _c[1];
        if (value === null) {
            return acc;
        }
        return __assign(__assign({}, acc), (_b = {}, _b[key] = __expectString(value), _b));
    }, {});
};
var deserializeAws_restJson1MapOfActivity = function (output, context) {
    return Object.entries(output).reduce(function (acc, _a) {
        var _b;
        var _c = __read(_a, 2), key = _c[0], value = _c[1];
        if (value === null) {
            return acc;
        }
        return __assign(__assign({}, acc), (_b = {}, _b[key] = deserializeAws_restJson1Activity(value, context), _b));
    }, {});
};
var deserializeAws_restJson1MapOfAttributeDimension = function (output, context) {
    return Object.entries(output).reduce(function (acc, _a) {
        var _b;
        var _c = __read(_a, 2), key = _c[0], value = _c[1];
        if (value === null) {
            return acc;
        }
        return __assign(__assign({}, acc), (_b = {}, _b[key] = deserializeAws_restJson1AttributeDimension(value, context), _b));
    }, {});
};
var deserializeAws_restJson1MapOfChannelResponse = function (output, context) {
    return Object.entries(output).reduce(function (acc, _a) {
        var _b;
        var _c = __read(_a, 2), key = _c[0], value = _c[1];
        if (value === null) {
            return acc;
        }
        return __assign(__assign({}, acc), (_b = {}, _b[key] = deserializeAws_restJson1ChannelResponse(value, context), _b));
    }, {});
};
var deserializeAws_restJson1MapOfEndpointMessageResult = function (output, context) {
    return Object.entries(output).reduce(function (acc, _a) {
        var _b;
        var _c = __read(_a, 2), key = _c[0], value = _c[1];
        if (value === null) {
            return acc;
        }
        return __assign(__assign({}, acc), (_b = {}, _b[key] = deserializeAws_restJson1EndpointMessageResult(value, context), _b));
    }, {});
};
var deserializeAws_restJson1MapOfEventItemResponse = function (output, context) {
    return Object.entries(output).reduce(function (acc, _a) {
        var _b;
        var _c = __read(_a, 2), key = _c[0], value = _c[1];
        if (value === null) {
            return acc;
        }
        return __assign(__assign({}, acc), (_b = {}, _b[key] = deserializeAws_restJson1EventItemResponse(value, context), _b));
    }, {});
};
var deserializeAws_restJson1MapOfItemResponse = function (output, context) {
    return Object.entries(output).reduce(function (acc, _a) {
        var _b;
        var _c = __read(_a, 2), key = _c[0], value = _c[1];
        if (value === null) {
            return acc;
        }
        return __assign(__assign({}, acc), (_b = {}, _b[key] = deserializeAws_restJson1ItemResponse(value, context), _b));
    }, {});
};
var deserializeAws_restJson1MapOfListOf__string = function (output, context) {
    return Object.entries(output).reduce(function (acc, _a) {
        var _b;
        var _c = __read(_a, 2), key = _c[0], value = _c[1];
        if (value === null) {
            return acc;
        }
        return __assign(__assign({}, acc), (_b = {}, _b[key] = deserializeAws_restJson1ListOf__string(value, context), _b));
    }, {});
};
var deserializeAws_restJson1MapOfListOfOpenHoursRules = function (output, context) {
    return Object.entries(output).reduce(function (acc, _a) {
        var _b;
        var _c = __read(_a, 2), key = _c[0], value = _c[1];
        if (value === null) {
            return acc;
        }
        return __assign(__assign({}, acc), (_b = {}, _b[key] = deserializeAws_restJson1ListOfOpenHoursRules(value, context), _b));
    }, {});
};
var deserializeAws_restJson1MapOfMapOfEndpointMessageResult = function (output, context) {
    return Object.entries(output).reduce(function (acc, _a) {
        var _b;
        var _c = __read(_a, 2), key = _c[0], value = _c[1];
        if (value === null) {
            return acc;
        }
        return __assign(__assign({}, acc), (_b = {}, _b[key] = deserializeAws_restJson1MapOfEndpointMessageResult(value, context), _b));
    }, {});
};
var deserializeAws_restJson1MapOfMessageResult = function (output, context) {
    return Object.entries(output).reduce(function (acc, _a) {
        var _b;
        var _c = __read(_a, 2), key = _c[0], value = _c[1];
        if (value === null) {
            return acc;
        }
        return __assign(__assign({}, acc), (_b = {}, _b[key] = deserializeAws_restJson1MessageResult(value, context), _b));
    }, {});
};
var deserializeAws_restJson1MapOfMetricDimension = function (output, context) {
    return Object.entries(output).reduce(function (acc, _a) {
        var _b;
        var _c = __read(_a, 2), key = _c[0], value = _c[1];
        if (value === null) {
            return acc;
        }
        return __assign(__assign({}, acc), (_b = {}, _b[key] = deserializeAws_restJson1MetricDimension(value, context), _b));
    }, {});
};
var deserializeAws_restJson1Message = function (output, context) {
    return {
        Action: __expectString(output.Action),
        Body: __expectString(output.Body),
        ImageIconUrl: __expectString(output.ImageIconUrl),
        ImageSmallIconUrl: __expectString(output.ImageSmallIconUrl),
        ImageUrl: __expectString(output.ImageUrl),
        JsonBody: __expectString(output.JsonBody),
        MediaUrl: __expectString(output.MediaUrl),
        RawContent: __expectString(output.RawContent),
        SilentPush: __expectBoolean(output.SilentPush),
        TimeToLive: __expectInt32(output.TimeToLive),
        Title: __expectString(output.Title),
        Url: __expectString(output.Url),
    };
};
var deserializeAws_restJson1MessageBody = function (output, context) {
    return {
        Message: __expectString(output.Message),
        RequestID: __expectString(output.RequestID),
    };
};
var deserializeAws_restJson1MessageConfiguration = function (output, context) {
    return {
        ADMMessage: output.ADMMessage != null ? deserializeAws_restJson1Message(output.ADMMessage, context) : undefined,
        APNSMessage: output.APNSMessage != null ? deserializeAws_restJson1Message(output.APNSMessage, context) : undefined,
        BaiduMessage: output.BaiduMessage != null ? deserializeAws_restJson1Message(output.BaiduMessage, context) : undefined,
        CustomMessage: output.CustomMessage != null
            ? deserializeAws_restJson1CampaignCustomMessage(output.CustomMessage, context)
            : undefined,
        DefaultMessage: output.DefaultMessage != null ? deserializeAws_restJson1Message(output.DefaultMessage, context) : undefined,
        EmailMessage: output.EmailMessage != null
            ? deserializeAws_restJson1CampaignEmailMessage(output.EmailMessage, context)
            : undefined,
        GCMMessage: output.GCMMessage != null ? deserializeAws_restJson1Message(output.GCMMessage, context) : undefined,
        InAppMessage: output.InAppMessage != null
            ? deserializeAws_restJson1CampaignInAppMessage(output.InAppMessage, context)
            : undefined,
        SMSMessage: output.SMSMessage != null ? deserializeAws_restJson1CampaignSmsMessage(output.SMSMessage, context) : undefined,
    };
};
var deserializeAws_restJson1MessageResponse = function (output, context) {
    return {
        ApplicationId: __expectString(output.ApplicationId),
        EndpointResult: output.EndpointResult != null
            ? deserializeAws_restJson1MapOfEndpointMessageResult(output.EndpointResult, context)
            : undefined,
        RequestId: __expectString(output.RequestId),
        Result: output.Result != null ? deserializeAws_restJson1MapOfMessageResult(output.Result, context) : undefined,
    };
};
var deserializeAws_restJson1MessageResult = function (output, context) {
    return {
        DeliveryStatus: __expectString(output.DeliveryStatus),
        MessageId: __expectString(output.MessageId),
        StatusCode: __expectInt32(output.StatusCode),
        StatusMessage: __expectString(output.StatusMessage),
        UpdatedToken: __expectString(output.UpdatedToken),
    };
};
var deserializeAws_restJson1MetricDimension = function (output, context) {
    return {
        ComparisonOperator: __expectString(output.ComparisonOperator),
        Value: __limitedParseDouble(output.Value),
    };
};
var deserializeAws_restJson1MultiConditionalBranch = function (output, context) {
    return {
        Condition: output.Condition != null ? deserializeAws_restJson1SimpleCondition(output.Condition, context) : undefined,
        NextActivity: __expectString(output.NextActivity),
    };
};
var deserializeAws_restJson1MultiConditionalSplitActivity = function (output, context) {
    return {
        Branches: output.Branches != null
            ? deserializeAws_restJson1ListOfMultiConditionalBranch(output.Branches, context)
            : undefined,
        DefaultActivity: __expectString(output.DefaultActivity),
        EvaluationWaitTime: output.EvaluationWaitTime != null
            ? deserializeAws_restJson1WaitTime(output.EvaluationWaitTime, context)
            : undefined,
    };
};
var deserializeAws_restJson1NumberValidateResponse = function (output, context) {
    return {
        Carrier: __expectString(output.Carrier),
        City: __expectString(output.City),
        CleansedPhoneNumberE164: __expectString(output.CleansedPhoneNumberE164),
        CleansedPhoneNumberNational: __expectString(output.CleansedPhoneNumberNational),
        Country: __expectString(output.Country),
        CountryCodeIso2: __expectString(output.CountryCodeIso2),
        CountryCodeNumeric: __expectString(output.CountryCodeNumeric),
        County: __expectString(output.County),
        OriginalCountryCodeIso2: __expectString(output.OriginalCountryCodeIso2),
        OriginalPhoneNumber: __expectString(output.OriginalPhoneNumber),
        PhoneType: __expectString(output.PhoneType),
        PhoneTypeCode: __expectInt32(output.PhoneTypeCode),
        Timezone: __expectString(output.Timezone),
        ZipCode: __expectString(output.ZipCode),
    };
};
var deserializeAws_restJson1OpenHours = function (output, context) {
    return {
        CUSTOM: output.CUSTOM != null ? deserializeAws_restJson1MapOfListOfOpenHoursRules(output.CUSTOM, context) : undefined,
        EMAIL: output.EMAIL != null ? deserializeAws_restJson1MapOfListOfOpenHoursRules(output.EMAIL, context) : undefined,
        PUSH: output.PUSH != null ? deserializeAws_restJson1MapOfListOfOpenHoursRules(output.PUSH, context) : undefined,
        SMS: output.SMS != null ? deserializeAws_restJson1MapOfListOfOpenHoursRules(output.SMS, context) : undefined,
        VOICE: output.VOICE != null ? deserializeAws_restJson1MapOfListOfOpenHoursRules(output.VOICE, context) : undefined,
    };
};
var deserializeAws_restJson1OpenHoursRule = function (output, context) {
    return {
        EndTime: __expectString(output.EndTime),
        StartTime: __expectString(output.StartTime),
    };
};
var deserializeAws_restJson1OverrideButtonConfiguration = function (output, context) {
    return {
        ButtonAction: __expectString(output.ButtonAction),
        Link: __expectString(output.Link),
    };
};
var deserializeAws_restJson1PushMessageActivity = function (output, context) {
    return {
        MessageConfig: output.MessageConfig != null
            ? deserializeAws_restJson1JourneyPushMessage(output.MessageConfig, context)
            : undefined,
        NextActivity: __expectString(output.NextActivity),
        TemplateName: __expectString(output.TemplateName),
        TemplateVersion: __expectString(output.TemplateVersion),
    };
};
var deserializeAws_restJson1PushNotificationTemplateResponse = function (output, context) {
    return {
        ADM: output.ADM != null ? deserializeAws_restJson1AndroidPushNotificationTemplate(output.ADM, context) : undefined,
        APNS: output.APNS != null ? deserializeAws_restJson1APNSPushNotificationTemplate(output.APNS, context) : undefined,
        Arn: __expectString(output.Arn),
        Baidu: output.Baidu != null ? deserializeAws_restJson1AndroidPushNotificationTemplate(output.Baidu, context) : undefined,
        CreationDate: __expectString(output.CreationDate),
        Default: output.Default != null
            ? deserializeAws_restJson1DefaultPushNotificationTemplate(output.Default, context)
            : undefined,
        DefaultSubstitutions: __expectString(output.DefaultSubstitutions),
        GCM: output.GCM != null ? deserializeAws_restJson1AndroidPushNotificationTemplate(output.GCM, context) : undefined,
        LastModifiedDate: __expectString(output.LastModifiedDate),
        RecommenderId: __expectString(output.RecommenderId),
        TemplateDescription: __expectString(output.TemplateDescription),
        TemplateName: __expectString(output.TemplateName),
        TemplateType: __expectString(output.TemplateType),
        Version: __expectString(output.Version),
        tags: output.tags != null ? deserializeAws_restJson1MapOf__string(output.tags, context) : undefined,
    };
};
var deserializeAws_restJson1QuietTime = function (output, context) {
    return {
        End: __expectString(output.End),
        Start: __expectString(output.Start),
    };
};
var deserializeAws_restJson1RandomSplitActivity = function (output, context) {
    return {
        Branches: output.Branches != null ? deserializeAws_restJson1ListOfRandomSplitEntry(output.Branches, context) : undefined,
    };
};
var deserializeAws_restJson1RandomSplitEntry = function (output, context) {
    return {
        NextActivity: __expectString(output.NextActivity),
        Percentage: __expectInt32(output.Percentage),
    };
};
var deserializeAws_restJson1RecencyDimension = function (output, context) {
    return {
        Duration: __expectString(output.Duration),
        RecencyType: __expectString(output.RecencyType),
    };
};
var deserializeAws_restJson1RecommenderConfigurationResponse = function (output, context) {
    return {
        Attributes: output.Attributes != null ? deserializeAws_restJson1MapOf__string(output.Attributes, context) : undefined,
        CreationDate: __expectString(output.CreationDate),
        Description: __expectString(output.Description),
        Id: __expectString(output.Id),
        LastModifiedDate: __expectString(output.LastModifiedDate),
        Name: __expectString(output.Name),
        RecommendationProviderIdType: __expectString(output.RecommendationProviderIdType),
        RecommendationProviderRoleArn: __expectString(output.RecommendationProviderRoleArn),
        RecommendationProviderUri: __expectString(output.RecommendationProviderUri),
        RecommendationTransformerUri: __expectString(output.RecommendationTransformerUri),
        RecommendationsDisplayName: __expectString(output.RecommendationsDisplayName),
        RecommendationsPerMessage: __expectInt32(output.RecommendationsPerMessage),
    };
};
var deserializeAws_restJson1ResultRow = function (output, context) {
    return {
        GroupedBys: output.GroupedBys != null ? deserializeAws_restJson1ListOfResultRowValue(output.GroupedBys, context) : undefined,
        Values: output.Values != null ? deserializeAws_restJson1ListOfResultRowValue(output.Values, context) : undefined,
    };
};
var deserializeAws_restJson1ResultRowValue = function (output, context) {
    return {
        Key: __expectString(output.Key),
        Type: __expectString(output.Type),
        Value: __expectString(output.Value),
    };
};
var deserializeAws_restJson1Schedule = function (output, context) {
    return {
        EndTime: __expectString(output.EndTime),
        EventFilter: output.EventFilter != null ? deserializeAws_restJson1CampaignEventFilter(output.EventFilter, context) : undefined,
        Frequency: __expectString(output.Frequency),
        IsLocalTime: __expectBoolean(output.IsLocalTime),
        QuietTime: output.QuietTime != null ? deserializeAws_restJson1QuietTime(output.QuietTime, context) : undefined,
        StartTime: __expectString(output.StartTime),
        Timezone: __expectString(output.Timezone),
    };
};
var deserializeAws_restJson1SegmentBehaviors = function (output, context) {
    return {
        Recency: output.Recency != null ? deserializeAws_restJson1RecencyDimension(output.Recency, context) : undefined,
    };
};
var deserializeAws_restJson1SegmentCondition = function (output, context) {
    return {
        SegmentId: __expectString(output.SegmentId),
    };
};
var deserializeAws_restJson1SegmentDemographics = function (output, context) {
    return {
        AppVersion: output.AppVersion != null ? deserializeAws_restJson1SetDimension(output.AppVersion, context) : undefined,
        Channel: output.Channel != null ? deserializeAws_restJson1SetDimension(output.Channel, context) : undefined,
        DeviceType: output.DeviceType != null ? deserializeAws_restJson1SetDimension(output.DeviceType, context) : undefined,
        Make: output.Make != null ? deserializeAws_restJson1SetDimension(output.Make, context) : undefined,
        Model: output.Model != null ? deserializeAws_restJson1SetDimension(output.Model, context) : undefined,
        Platform: output.Platform != null ? deserializeAws_restJson1SetDimension(output.Platform, context) : undefined,
    };
};
var deserializeAws_restJson1SegmentDimensions = function (output, context) {
    return {
        Attributes: output.Attributes != null
            ? deserializeAws_restJson1MapOfAttributeDimension(output.Attributes, context)
            : undefined,
        Behavior: output.Behavior != null ? deserializeAws_restJson1SegmentBehaviors(output.Behavior, context) : undefined,
        Demographic: output.Demographic != null ? deserializeAws_restJson1SegmentDemographics(output.Demographic, context) : undefined,
        Location: output.Location != null ? deserializeAws_restJson1SegmentLocation(output.Location, context) : undefined,
        Metrics: output.Metrics != null ? deserializeAws_restJson1MapOfMetricDimension(output.Metrics, context) : undefined,
        UserAttributes: output.UserAttributes != null
            ? deserializeAws_restJson1MapOfAttributeDimension(output.UserAttributes, context)
            : undefined,
    };
};
var deserializeAws_restJson1SegmentGroup = function (output, context) {
    return {
        Dimensions: output.Dimensions != null
            ? deserializeAws_restJson1ListOfSegmentDimensions(output.Dimensions, context)
            : undefined,
        SourceSegments: output.SourceSegments != null
            ? deserializeAws_restJson1ListOfSegmentReference(output.SourceSegments, context)
            : undefined,
        SourceType: __expectString(output.SourceType),
        Type: __expectString(output.Type),
    };
};
var deserializeAws_restJson1SegmentGroupList = function (output, context) {
    return {
        Groups: output.Groups != null ? deserializeAws_restJson1ListOfSegmentGroup(output.Groups, context) : undefined,
        Include: __expectString(output.Include),
    };
};
var deserializeAws_restJson1SegmentImportResource = function (output, context) {
    return {
        ChannelCounts: output.ChannelCounts != null ? deserializeAws_restJson1MapOf__integer(output.ChannelCounts, context) : undefined,
        ExternalId: __expectString(output.ExternalId),
        Format: __expectString(output.Format),
        RoleArn: __expectString(output.RoleArn),
        S3Url: __expectString(output.S3Url),
        Size: __expectInt32(output.Size),
    };
};
var deserializeAws_restJson1SegmentLocation = function (output, context) {
    return {
        Country: output.Country != null ? deserializeAws_restJson1SetDimension(output.Country, context) : undefined,
        GPSPoint: output.GPSPoint != null ? deserializeAws_restJson1GPSPointDimension(output.GPSPoint, context) : undefined,
    };
};
var deserializeAws_restJson1SegmentReference = function (output, context) {
    return {
        Id: __expectString(output.Id),
        Version: __expectInt32(output.Version),
    };
};
var deserializeAws_restJson1SegmentResponse = function (output, context) {
    return {
        ApplicationId: __expectString(output.ApplicationId),
        Arn: __expectString(output.Arn),
        CreationDate: __expectString(output.CreationDate),
        Dimensions: output.Dimensions != null ? deserializeAws_restJson1SegmentDimensions(output.Dimensions, context) : undefined,
        Id: __expectString(output.Id),
        ImportDefinition: output.ImportDefinition != null
            ? deserializeAws_restJson1SegmentImportResource(output.ImportDefinition, context)
            : undefined,
        LastModifiedDate: __expectString(output.LastModifiedDate),
        Name: __expectString(output.Name),
        SegmentGroups: output.SegmentGroups != null
            ? deserializeAws_restJson1SegmentGroupList(output.SegmentGroups, context)
            : undefined,
        SegmentType: __expectString(output.SegmentType),
        Version: __expectInt32(output.Version),
        tags: output.tags != null ? deserializeAws_restJson1MapOf__string(output.tags, context) : undefined,
    };
};
var deserializeAws_restJson1SegmentsResponse = function (output, context) {
    return {
        Item: output.Item != null ? deserializeAws_restJson1ListOfSegmentResponse(output.Item, context) : undefined,
        NextToken: __expectString(output.NextToken),
    };
};
var deserializeAws_restJson1SendUsersMessageResponse = function (output, context) {
    return {
        ApplicationId: __expectString(output.ApplicationId),
        RequestId: __expectString(output.RequestId),
        Result: output.Result != null
            ? deserializeAws_restJson1MapOfMapOfEndpointMessageResult(output.Result, context)
            : undefined,
    };
};
var deserializeAws_restJson1SetDimension = function (output, context) {
    return {
        DimensionType: __expectString(output.DimensionType),
        Values: output.Values != null ? deserializeAws_restJson1ListOf__string(output.Values, context) : undefined,
    };
};
var deserializeAws_restJson1SimpleCondition = function (output, context) {
    return {
        EventCondition: output.EventCondition != null
            ? deserializeAws_restJson1EventCondition(output.EventCondition, context)
            : undefined,
        SegmentCondition: output.SegmentCondition != null
            ? deserializeAws_restJson1SegmentCondition(output.SegmentCondition, context)
            : undefined,
        SegmentDimensions: output.segmentDimensions != null
            ? deserializeAws_restJson1SegmentDimensions(output.segmentDimensions, context)
            : undefined,
    };
};
var deserializeAws_restJson1SMSChannelResponse = function (output, context) {
    return {
        ApplicationId: __expectString(output.ApplicationId),
        CreationDate: __expectString(output.CreationDate),
        Enabled: __expectBoolean(output.Enabled),
        HasCredential: __expectBoolean(output.HasCredential),
        Id: __expectString(output.Id),
        IsArchived: __expectBoolean(output.IsArchived),
        LastModifiedBy: __expectString(output.LastModifiedBy),
        LastModifiedDate: __expectString(output.LastModifiedDate),
        Platform: __expectString(output.Platform),
        PromotionalMessagesPerSecond: __expectInt32(output.PromotionalMessagesPerSecond),
        SenderId: __expectString(output.SenderId),
        ShortCode: __expectString(output.ShortCode),
        TransactionalMessagesPerSecond: __expectInt32(output.TransactionalMessagesPerSecond),
        Version: __expectInt32(output.Version),
    };
};
var deserializeAws_restJson1SMSMessageActivity = function (output, context) {
    return {
        MessageConfig: output.MessageConfig != null
            ? deserializeAws_restJson1JourneySMSMessage(output.MessageConfig, context)
            : undefined,
        NextActivity: __expectString(output.NextActivity),
        TemplateName: __expectString(output.TemplateName),
        TemplateVersion: __expectString(output.TemplateVersion),
    };
};
var deserializeAws_restJson1SMSTemplateResponse = function (output, context) {
    return {
        Arn: __expectString(output.Arn),
        Body: __expectString(output.Body),
        CreationDate: __expectString(output.CreationDate),
        DefaultSubstitutions: __expectString(output.DefaultSubstitutions),
        LastModifiedDate: __expectString(output.LastModifiedDate),
        RecommenderId: __expectString(output.RecommenderId),
        TemplateDescription: __expectString(output.TemplateDescription),
        TemplateName: __expectString(output.TemplateName),
        TemplateType: __expectString(output.TemplateType),
        Version: __expectString(output.Version),
        tags: output.tags != null ? deserializeAws_restJson1MapOf__string(output.tags, context) : undefined,
    };
};
var deserializeAws_restJson1StartCondition = function (output, context) {
    return {
        Description: __expectString(output.Description),
        EventStartCondition: output.EventStartCondition != null
            ? deserializeAws_restJson1EventStartCondition(output.EventStartCondition, context)
            : undefined,
        SegmentStartCondition: output.SegmentStartCondition != null
            ? deserializeAws_restJson1SegmentCondition(output.SegmentStartCondition, context)
            : undefined,
    };
};
var deserializeAws_restJson1TagsModel = function (output, context) {
    return {
        tags: output.tags != null ? deserializeAws_restJson1MapOf__string(output.tags, context) : undefined,
    };
};
var deserializeAws_restJson1Template = function (output, context) {
    return {
        Name: __expectString(output.Name),
        Version: __expectString(output.Version),
    };
};
var deserializeAws_restJson1TemplateConfiguration = function (output, context) {
    return {
        EmailTemplate: output.EmailTemplate != null ? deserializeAws_restJson1Template(output.EmailTemplate, context) : undefined,
        PushTemplate: output.PushTemplate != null ? deserializeAws_restJson1Template(output.PushTemplate, context) : undefined,
        SMSTemplate: output.SMSTemplate != null ? deserializeAws_restJson1Template(output.SMSTemplate, context) : undefined,
        VoiceTemplate: output.VoiceTemplate != null ? deserializeAws_restJson1Template(output.VoiceTemplate, context) : undefined,
    };
};
var deserializeAws_restJson1TemplateCreateMessageBody = function (output, context) {
    return {
        Arn: __expectString(output.Arn),
        Message: __expectString(output.Message),
        RequestID: __expectString(output.RequestID),
    };
};
var deserializeAws_restJson1TemplateResponse = function (output, context) {
    return {
        Arn: __expectString(output.Arn),
        CreationDate: __expectString(output.CreationDate),
        DefaultSubstitutions: __expectString(output.DefaultSubstitutions),
        LastModifiedDate: __expectString(output.LastModifiedDate),
        TemplateDescription: __expectString(output.TemplateDescription),
        TemplateName: __expectString(output.TemplateName),
        TemplateType: __expectString(output.TemplateType),
        Version: __expectString(output.Version),
        tags: output.tags != null ? deserializeAws_restJson1MapOf__string(output.tags, context) : undefined,
    };
};
var deserializeAws_restJson1TemplatesResponse = function (output, context) {
    return {
        Item: output.Item != null ? deserializeAws_restJson1ListOfTemplateResponse(output.Item, context) : undefined,
        NextToken: __expectString(output.NextToken),
    };
};
var deserializeAws_restJson1TemplateVersionResponse = function (output, context) {
    return {
        CreationDate: __expectString(output.CreationDate),
        DefaultSubstitutions: __expectString(output.DefaultSubstitutions),
        LastModifiedDate: __expectString(output.LastModifiedDate),
        TemplateDescription: __expectString(output.TemplateDescription),
        TemplateName: __expectString(output.TemplateName),
        TemplateType: __expectString(output.TemplateType),
        Version: __expectString(output.Version),
    };
};
var deserializeAws_restJson1TemplateVersionsResponse = function (output, context) {
    return {
        Item: output.Item != null ? deserializeAws_restJson1ListOfTemplateVersionResponse(output.Item, context) : undefined,
        Message: __expectString(output.Message),
        NextToken: __expectString(output.NextToken),
        RequestID: __expectString(output.RequestID),
    };
};
var deserializeAws_restJson1TreatmentResource = function (output, context) {
    return {
        CustomDeliveryConfiguration: output.CustomDeliveryConfiguration != null
            ? deserializeAws_restJson1CustomDeliveryConfiguration(output.CustomDeliveryConfiguration, context)
            : undefined,
        Id: __expectString(output.Id),
        MessageConfiguration: output.MessageConfiguration != null
            ? deserializeAws_restJson1MessageConfiguration(output.MessageConfiguration, context)
            : undefined,
        Schedule: output.Schedule != null ? deserializeAws_restJson1Schedule(output.Schedule, context) : undefined,
        SizePercent: __expectInt32(output.SizePercent),
        State: output.State != null ? deserializeAws_restJson1CampaignState(output.State, context) : undefined,
        TemplateConfiguration: output.TemplateConfiguration != null
            ? deserializeAws_restJson1TemplateConfiguration(output.TemplateConfiguration, context)
            : undefined,
        TreatmentDescription: __expectString(output.TreatmentDescription),
        TreatmentName: __expectString(output.TreatmentName),
    };
};
var deserializeAws_restJson1VerificationResponse = function (output, context) {
    return {
        Valid: __expectBoolean(output.Valid),
    };
};
var deserializeAws_restJson1VoiceChannelResponse = function (output, context) {
    return {
        ApplicationId: __expectString(output.ApplicationId),
        CreationDate: __expectString(output.CreationDate),
        Enabled: __expectBoolean(output.Enabled),
        HasCredential: __expectBoolean(output.HasCredential),
        Id: __expectString(output.Id),
        IsArchived: __expectBoolean(output.IsArchived),
        LastModifiedBy: __expectString(output.LastModifiedBy),
        LastModifiedDate: __expectString(output.LastModifiedDate),
        Platform: __expectString(output.Platform),
        Version: __expectInt32(output.Version),
    };
};
var deserializeAws_restJson1VoiceTemplateResponse = function (output, context) {
    return {
        Arn: __expectString(output.Arn),
        Body: __expectString(output.Body),
        CreationDate: __expectString(output.CreationDate),
        DefaultSubstitutions: __expectString(output.DefaultSubstitutions),
        LanguageCode: __expectString(output.LanguageCode),
        LastModifiedDate: __expectString(output.LastModifiedDate),
        TemplateDescription: __expectString(output.TemplateDescription),
        TemplateName: __expectString(output.TemplateName),
        TemplateType: __expectString(output.TemplateType),
        Version: __expectString(output.Version),
        VoiceId: __expectString(output.VoiceId),
        tags: output.tags != null ? deserializeAws_restJson1MapOf__string(output.tags, context) : undefined,
    };
};
var deserializeAws_restJson1WaitActivity = function (output, context) {
    return {
        NextActivity: __expectString(output.NextActivity),
        WaitTime: output.WaitTime != null ? deserializeAws_restJson1WaitTime(output.WaitTime, context) : undefined,
    };
};
var deserializeAws_restJson1WaitTime = function (output, context) {
    return {
        WaitFor: __expectString(output.WaitFor),
        WaitUntil: __expectString(output.WaitUntil),
    };
};
var deserializeMetadata = function (output) {
    var _a, _b;
    return ({
        httpStatusCode: output.statusCode,
        requestId: (_b = (_a = output.headers["x-amzn-requestid"]) !== null && _a !== void 0 ? _a : output.headers["x-amzn-request-id"]) !== null && _b !== void 0 ? _b : output.headers["x-amz-request-id"],
        extendedRequestId: output.headers["x-amz-id-2"],
        cfId: output.headers["x-amz-cf-id"],
    });
};
var collectBody = function (streamBody, context) {
    if (streamBody === void 0) { streamBody = new Uint8Array(); }
    if (streamBody instanceof Uint8Array) {
        return Promise.resolve(streamBody);
    }
    return context.streamCollector(streamBody) || Promise.resolve(new Uint8Array());
};
var collectBodyString = function (streamBody, context) {
    return collectBody(streamBody, context).then(function (body) { return context.utf8Encoder(body); });
};
var isSerializableHeaderValue = function (value) {
    return value !== undefined &&
        value !== null &&
        value !== "" &&
        (!Object.getOwnPropertyNames(value).includes("length") || value.length != 0) &&
        (!Object.getOwnPropertyNames(value).includes("size") || value.size != 0);
};
var parseBody = function (streamBody, context) {
    return collectBodyString(streamBody, context).then(function (encoded) {
        if (encoded.length) {
            return JSON.parse(encoded);
        }
        return {};
    });
};
var parseErrorBody = function (errorBody, context) { return __awaiter(void 0, void 0, void 0, function () {
    var value;
    var _a;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0: return [4, parseBody(errorBody, context)];
            case 1:
                value = _b.sent();
                value.message = (_a = value.message) !== null && _a !== void 0 ? _a : value.Message;
                return [2, value];
        }
    });
}); };
var loadRestJsonErrorCode = function (output, data) {
    var findKey = function (object, key) { return Object.keys(object).find(function (k) { return k.toLowerCase() === key.toLowerCase(); }); };
    var sanitizeErrorCode = function (rawValue) {
        var cleanValue = rawValue;
        if (typeof cleanValue === "number") {
            cleanValue = cleanValue.toString();
        }
        if (cleanValue.indexOf(",") >= 0) {
            cleanValue = cleanValue.split(",")[0];
        }
        if (cleanValue.indexOf(":") >= 0) {
            cleanValue = cleanValue.split(":")[0];
        }
        if (cleanValue.indexOf("#") >= 0) {
            cleanValue = cleanValue.split("#")[1];
        }
        return cleanValue;
    };
    var headerKey = findKey(output.headers, "x-amzn-errortype");
    if (headerKey !== undefined) {
        return sanitizeErrorCode(output.headers[headerKey]);
    }
    if (data.code !== undefined) {
        return sanitizeErrorCode(data.code);
    }
    if (data["__type"] !== undefined) {
        return sanitizeErrorCode(data["__type"]);
    }
};
