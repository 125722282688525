/// Generated by expo-google-fonts/generator
/// Do not edit by hand unless you know what you are doing
///

export { useFonts } from './useFonts';

export { default as __metadata__ } from './metadata.json';
export const SpaceGrotesk_300Light = require('./SpaceGrotesk_300Light.ttf');
export const SpaceGrotesk_400Regular = require('./SpaceGrotesk_400Regular.ttf');
export const SpaceGrotesk_500Medium = require('./SpaceGrotesk_500Medium.ttf');
export const SpaceGrotesk_600SemiBold = require('./SpaceGrotesk_600SemiBold.ttf');
export const SpaceGrotesk_700Bold = require('./SpaceGrotesk_700Bold.ttf');
