import { __assign } from "tslib";
export var GetChannelsRequestFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var GetChannelsResponseFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var GetEmailChannelRequestFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var GetEmailChannelResponseFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var GetEmailTemplateRequestFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var GetEmailTemplateResponseFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var GetEndpointRequestFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var GetEndpointResponseFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var GetEventStreamRequestFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var GetEventStreamResponseFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var GetExportJobRequestFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var GetExportJobResponseFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var GetExportJobsRequestFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var GetExportJobsResponseFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var GetGcmChannelRequestFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var GetGcmChannelResponseFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var GetImportJobRequestFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var GetImportJobResponseFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var GetImportJobsRequestFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var ImportJobsResponseFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var GetImportJobsResponseFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var GetInAppMessagesRequestFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var InAppMessageFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var InAppCampaignScheduleFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var InAppMessageCampaignFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var InAppMessagesResponseFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var GetInAppMessagesResponseFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var GetInAppTemplateRequestFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var InAppTemplateResponseFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var GetInAppTemplateResponseFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var GetJourneyRequestFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var GetJourneyResponseFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var GetJourneyDateRangeKpiRequestFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var JourneyDateRangeKpiResponseFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var GetJourneyDateRangeKpiResponseFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var GetJourneyExecutionActivityMetricsRequestFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var JourneyExecutionActivityMetricsResponseFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var GetJourneyExecutionActivityMetricsResponseFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var GetJourneyExecutionMetricsRequestFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var JourneyExecutionMetricsResponseFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var GetJourneyExecutionMetricsResponseFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var GetPushTemplateRequestFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var PushNotificationTemplateResponseFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var GetPushTemplateResponseFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var GetRecommenderConfigurationRequestFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var GetRecommenderConfigurationResponseFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var GetRecommenderConfigurationsRequestFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var ListRecommenderConfigurationsResponseFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var GetRecommenderConfigurationsResponseFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var GetSegmentRequestFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var GetSegmentResponseFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var GetSegmentExportJobsRequestFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var GetSegmentExportJobsResponseFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var GetSegmentImportJobsRequestFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var GetSegmentImportJobsResponseFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var GetSegmentsRequestFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var SegmentsResponseFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var GetSegmentsResponseFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var GetSegmentVersionRequestFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var GetSegmentVersionResponseFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var GetSegmentVersionsRequestFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var GetSegmentVersionsResponseFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var GetSmsChannelRequestFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var GetSmsChannelResponseFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var GetSmsTemplateRequestFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var SMSTemplateResponseFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var GetSmsTemplateResponseFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var GetUserEndpointsRequestFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var GetUserEndpointsResponseFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var GetVoiceChannelRequestFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var GetVoiceChannelResponseFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var GetVoiceTemplateRequestFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var VoiceTemplateResponseFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var GetVoiceTemplateResponseFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var JourneysResponseFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var JourneyStateRequestFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var ListJourneysRequestFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var ListJourneysResponseFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var TemplateResponseFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var TemplateVersionResponseFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var ListTagsForResourceRequestFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var TagsModelFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var ListTagsForResourceResponseFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var ListTemplatesRequestFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var TemplatesResponseFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var ListTemplatesResponseFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var ListTemplateVersionsRequestFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var TemplateVersionsResponseFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var ListTemplateVersionsResponseFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var MessageResultFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var MessageRequestFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var MessageResponseFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var NumberValidateRequestFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var NumberValidateResponseFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var PhoneNumberValidateRequestFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var PhoneNumberValidateResponseFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var PutEventsRequestFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var PutEventsResponseFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var WriteEventStreamFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var PutEventStreamRequestFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var PutEventStreamResponseFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var UpdateAttributesRequestFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var RemoveAttributesRequestFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var RemoveAttributesResponseFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var SendMessagesRequestFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var SendMessagesResponseFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var SendOTPMessageRequestParametersFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var SendOTPMessageRequestFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var SendOTPMessageResponseFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var SendUsersMessageRequestFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var SendUsersMessagesRequestFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var SendUsersMessageResponseFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var SendUsersMessagesResponseFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var TagResourceRequestFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var UntagResourceRequestFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var UpdateAdmChannelRequestFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var UpdateAdmChannelResponseFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var UpdateApnsChannelRequestFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var UpdateApnsChannelResponseFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var UpdateApnsSandboxChannelRequestFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var UpdateApnsSandboxChannelResponseFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var UpdateApnsVoipChannelRequestFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var UpdateApnsVoipChannelResponseFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var UpdateApnsVoipSandboxChannelRequestFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var UpdateApnsVoipSandboxChannelResponseFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var WriteApplicationSettingsRequestFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var UpdateApplicationSettingsRequestFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var UpdateApplicationSettingsResponseFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var UpdateBaiduChannelRequestFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var UpdateBaiduChannelResponseFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var UpdateCampaignRequestFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var UpdateCampaignResponseFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var UpdateEmailChannelRequestFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var UpdateEmailChannelResponseFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var UpdateEmailTemplateRequestFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var UpdateEmailTemplateResponseFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var UpdateEndpointRequestFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var UpdateEndpointResponseFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var UpdateEndpointsBatchRequestFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var UpdateEndpointsBatchResponseFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var UpdateGcmChannelRequestFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var UpdateGcmChannelResponseFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var UpdateInAppTemplateRequestFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var UpdateInAppTemplateResponseFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var UpdateJourneyRequestFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var UpdateJourneyResponseFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var UpdateJourneyStateRequestFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var UpdateJourneyStateResponseFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var UpdatePushTemplateRequestFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var UpdatePushTemplateResponseFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var UpdateRecommenderConfigurationShapeFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var UpdateRecommenderConfigurationRequestFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var UpdateRecommenderConfigurationResponseFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var UpdateSegmentRequestFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var UpdateSegmentResponseFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var SMSChannelRequestFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var UpdateSmsChannelRequestFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var UpdateSmsChannelResponseFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var UpdateSmsTemplateRequestFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var UpdateSmsTemplateResponseFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var TemplateActiveVersionRequestFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var UpdateTemplateActiveVersionRequestFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var UpdateTemplateActiveVersionResponseFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var VoiceChannelRequestFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var UpdateVoiceChannelRequestFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var UpdateVoiceChannelResponseFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var UpdateVoiceTemplateRequestFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var UpdateVoiceTemplateResponseFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var VerifyOTPMessageRequestParametersFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var VerifyOTPMessageRequestFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var VerificationResponseFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var VerifyOTPMessageResponseFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
